<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="flex-container3">
    <div style="text-align: center; background: bisque;border-width: 0 0 1px;padding: 15px;">
        <span><i class="fa fa-warning" style="font-size:20px;color:#FBBD08!important;margin-right: 10px"></i> 
        This page is internal for Ricardo's team only, note: any action on this page will not be record in audit history. so use with caution.
        </span>
    </div>
  <div *ngIf="success">
    <div>
        <mat-card>
         <div  *ngIf="isNew">
            <mat-card-title style="color:grey;font-size: 28px">Create Recovery</mat-card-title>
         </div>
          <mat-card-content>
            <div *ngIf="!isNew">
              <div *ngIf="isPsRecovery" style="color:red">
                This recovery belongs to a problem signature, please navigate to Classify page to update. 
                <div><button class="btn" type="button" (click)="onSearchProblemsiganture()">Click to find the problem signature</button></div>
                <div *ngIf="!!problemsignature"><a [routerLink]="['/custombuckets', problemsignature.Id,problemsignature.Client]">Click to navigate to problem signature page</a> </div>
                <div *ngIf="problemsignatureNotExists">The problem signature doesnt exists anymore</div>
              </div>
            </div>
            
            <div [ngClass]="{'inactiveDiv': isPsRecovery}">
                <form [formGroup]="recoveryForm">
                  <h1>Message to User</h1>
                  <div>
                      <div class="form-group">
                        <label for="IdId">Id</label>
                        <input class="form-control" id="IdId" type="text" [readonly]="!isNew"  placeholder="enter Id" formControlName="Id" />
                        <mat-error *ngIf="recoveryForm.controls.Id.hasError('isidguid')">Id must be guid</mat-error>
                      </div>
                      <div class="form-group">
                        <label for="AreaId">Area</label>
                        <input class="form-control" id="AreaId" type="text" placeholder="enter Area" formControlName="Area" />
                      </div>
                      <div class="form-group">
                        <label for="NameId">Name</label>
                        <input class="form-control" id="NameId" type="text" placeholder="enter Name" formControlName="Name" />
                      </div>
                      <div class="form-group">
                          <label for="RecoveryTitleId">Recovery Title</label>
                          <input class="form-control" id="RecoveryTitleId" type="text" placeholder="enter recovery title (required)" formControlName="RecoveryTitle" />
                          <mat-error *ngIf="recoveryForm.controls.RecoveryTitle.hasError('required')">Required</mat-error>
                      </div>

                      <div class="form-group">
                        <label for="DescriptionId">Description</label>
                        <input class="form-control" id="DescriptionId" type="text" placeholder="enter description" formControlName="Description" />
                      </div>
                     
                      <div class="form-group">
                        <label for="UserMsgId">UserMsg</label>
                        <input class="form-control" id="UserMsgId" type="text" placeholder="enter UserMsg" formControlName="UserMsg" />
                      </div>
                      <div class="form-group">
                        <label for="AdminMsgId">AdminMsg</label>
                        <input class="form-control" id="AdminMsgId" type="text" placeholder="enter AdminMsg" formControlName="AdminMsg" />
                      </div>
                      <div class="form-group">
                        <label for="SupportMsgId">SupportMsg</label>
                        <input class="form-control" id="SupportMsgId" type="text" placeholder="enter SupportMsg" formControlName="SupportMsg" />
                      </div>
                      <div class="form-group">
                        <label for="SymptomPathId">SymptomPath</label>
                        <input class="form-control" id="SymptomPathId" type="text" placeholder="enter SymptomPath" formControlName="SymptomPath" />
                      </div>
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>RecoveryState</mat-label>
                          <mat-select formControlName="RecoveryState">
                            <mat-option value="0">Test</mat-option>
                            <mat-option value="1">Production</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                     
                      <div class="form-group">
                        <label for="CreatedById">CreatedBy</label>
                        <input class="form-control" id="CreatedById" type="text" placeholder="please enter the requestor's email, so that in future localization team can contact the owner of the string" formControlName="CreatedBy" />
                      </div>

                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Type</mat-label>
                          <mat-select formControlName="Type">
                            <mat-option value="CloudCheck">CloudCheck</mat-option>
                            <mat-option value="NotSet">NotSet</mat-option>
                            <mat-option value="Rule">Rule</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Has A fix</mat-label>
                          <mat-select formControlName="HasAFix">
                            <mat-option value="false">false</mat-option>
                            <mat-option value="true">true</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Authoritative</mat-label>
                          <mat-select formControlName="Authoritative">
                            <mat-option value="false">false</mat-option>
                            <mat-option value="true">true</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>ContactAdmin</mat-label>
                          <mat-select formControlName="ContactAdmin">
                            <mat-option value="false">false</mat-option>
                            <mat-option value="true">true</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <label for="ProblemDescriptionId">ProblemDescription</label>
                        <input class="form-control" id="ProblemDescriptionId" type="text" placeholder="enter Problem description" formControlName="ProblemDescription" />
                      </div>
                      <!--Dialog For Image Upload Template-->
                      <ng-template #ImageUploadDialog>
                        <h2 matDialogTitle>Upload Image</h2>
                            <mat-dialog-content>
                              <p>To add an image please choose an image file</p>
                              <label for="ImageChangeTextId">Image Description</label>
                              <br/>
                              <input type="text" id="ImageDescription" (change)="OnImageChangeText()" placeholder="Enter a description for the image to upload" style="width: 100%;"/>
                              <br/>
                              <label for="ImageFileId">Image Upload</label>
                              <input type="file" (change)="OnImageChangeFile($event)" base-sixty-four-input/>
                            </mat-dialog-content>
                            <footer>
                              <div style="position: absolute;display: flex; padding-inline-start: 200px;">
                                <button [disabled]="isSubmitImageDisabled" type="button" (click)="OnSubmitImage()" value="Submit" mat-button matDialogClose>Submit</button>
                                <button mat-button matDialogClose>Cancel</button>
                              </div>
                            </footer>
                      </ng-template>
      
                      <div class="form-group">
                        <label for="RecoveryElementsId">Recovery Elements</label>
                        <br />
                        <select name="dropdown" id="ElementSelection" (change)="onRecoveryElementsSelection()">
                            <option value="Add Elements" selected>Add Elements</option>
                            <option value="List">List</option>
                            <option value="Numbered List">Numbered List</option>
                            <option value="Subtitle">Subtitle</option>
                            <option value="Body Text">Body Text</option>
                            <option value="Image">Image</option>
                        </select>
                        <small class="text-danger">*Note to add an element select from the dropdown and edit the content in the "Elements" array</small>
                        <br />
                        <br />
                        <div style="display:flex; width: 100%;">
                            <div style="display: block; width: 50%;">
                                <textarea class="form-control" id="RecoveryElementsId" type="text" placeholder="Enter the JSON for the Rich Recovery" formControlName="RecoveryElements" style="height:120px; width:100%;"></textarea>
                                <small *ngIf="!RecoveryElementsValid()" class="text-danger">Not a valid JSON For Creating Rich Recoveries</small>
                            </div>
                            <div style="display:block; width: 35%">
                                <mat-card style="margin-inline-start: 15%; width: 70%; margin-inline-end: 15%;">
                                    <mat-card-title style="display: flex; font-size: small; font-weight: bold; color: black;">{{ recoveryForm.controls.RecoveryTitle.value }}</mat-card-title>
                                    <div style="display: flex; width: 100%;">
                                        <div style="display: left; width: 80%;">
                                            <p style="padding-left: 0%;">{{ recoveryForm.controls.ProblemDescription.value }}</p> 
                                        </div>
                                        <div style="display: right; width: 10%; padding-inline-end: -10%;">
                                            <button *ngIf="collapsed" (click)="collapsed=false" style="background-color: #ffffff;"><i  class="icon-cheveron, fa fa-chevron-down" style="color: rgb(2, 152, 211);margin-left: 20px"></i></button>
                                            <button *ngIf="!collapsed" (click)="collapsed=true" style="background-color: #ffffff;"><i  class="icon-cheveron, fa fa-chevron-up" style="color: rgb(2, 152, 211);margin-left: 20px"></i></button>
                                        </div>
                                    </div> 
                                    <mat-card-content *ngIf="!collapsed">
                                        <div *ngIf="RecoveryElements">
                                            <div *ngFor="let element of RecoveryElements">
                                                <div *ngIf="element.Type === 'Subtitle'">
                                                    <div *ngIf="element.Elements && element.Elements.length > 0">
                                                        <label style="font-weight: bold; color: black;">{{element.Elements[0]}}</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="element.Type === 'BodyText'">
                                                    <div *ngIf="element.Elements && element.Elements.length > 0">
                                                        <p>{{element.Elements[0]}}</p>
                                                    </div>
                                                </div>
                                                <div *ngIf="element.Type === 'List'">
                                                    <div *ngIf="element.Elements && element.Elements.length > 0">
                                                        <ul>
                                                            <li *ngFor="let unorderedElement of element.Elements">{{unorderedElement}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div *ngIf="element.Type === 'NumberedList'">
                                                    <div *ngIf="element.Elements && element.Elements.length > 0">
                                                        <ol>
                                                            <li *ngFor="let orderedElement of element.Elements">{{orderedElement}}</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div *ngIf="element.Type === 'Image'">
                                                  <div *ngIf="element.Elements && element.Elements.length > 0" style="padding-left:10%;">
                                                    <img src="{{element.Elements[0]}}" width="80%" height="auto" style="align-items: center;">
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                      </div>

                      <div *ngIf="recoveryForm.controls.HasAFix.value=='true'" class="form-group">
                          <label for="RecoveryStepsId">RecoverySteps</label>
                          <input class="form-control" id="RecoveryStepsId" type="text" placeholder="enter recovery steps (required)" formControlName="RecoverySteps" />
                      </div>
                      <div class="form-group">
                        <label for="LinkId">Link</label>
                        <input class="form-control" id="LinkId" type="text" placeholder="enter support link" formControlName="Link" />
                      </div>
                  </div>
                  
                  <div>
                      <b>Flight Audience</b>
                      <label formArrayName="flightAudiences" *ngFor="let item of formData.controls; let i = index" style="display: block; font-weight: normal">
                          <input type="checkbox" [formControlName]="i">
                          {{flightAudiences[i].display}}
                      </label>
                  </div>
              </form>   
            </div>
            <loc-status [localized]="recoveryId" page="singleRecovery" #locstatusComponent></loc-status>
           
            <div>
              <button class="btn" style="float: right" [routerLink]="[ '/recovery']">Cancel</button>
              <button class="btn" style="float: right" type="button" (click)="onSubmit()">Save</button>
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
