
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { CrashInfo} from '../shared/models/WfsModels';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utils } from '../shared/Utils/utils';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
@Component({
    templateUrl: './crashresolutionlist.component.html',
    styleUrls: ['../app.component.css']
})

export class CrashResolutionListComponent implements OnInit, OnDestroy {
    public data: CrashInfo[];
    public filteredData: CrashInfo[];
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    
    constructor(private service: DiagDefinitionService, private spinService: SpinnerService, private util:Utils, private notificationBarService:NotificationBarService) {
    }
    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            order: [[0, 'asc']]
          };

        this.filteredData = new Array<CrashInfo>();
        this.spinService.displayLoader(true);
        
        let subscription = this.service.GetCrashResolutionsAsync().subscribe(
            crashes => {
            this.spinService.displayLoader(false);
            this.data = crashes.filter((crash: CrashInfo) => crash.FailureId != undefined && !(crash.WorkItemNumber == null && crash.FixBuild == null));
            this.filteredData = this.data;
            this.dtTrigger.next();
            if (!this.filteredData || this.filteredData.length == 0) {
                this.notificationBarService.openSnackBar("no crashes exist.",'','Info');
            }
        }, error => {
            this.spinService.displayLoader(false);
            this.finalize(subscription);
        });        
    }

    ngOnDestroy(): void {
        this.notificationBarService.dismiss();
        this.dtTrigger.unsubscribe();
    }

    private finalize(subscription: Subscription):void
    {
        if(subscription)
        {
            subscription.unsubscribe();
        }
    }

    public getAudience(audienceList:string[])
    {
        if(!audienceList || audienceList.length ==0)
        {
            return null;
        }else
        {
            return audienceList.join(", ");
        }
    }
}