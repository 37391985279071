import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentRoutingModule } from './incident-routing.module';
import { IncidentListComponent } from './incident-list.component';

import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FlexLayoutModule} from "@angular/flex-layout";
import { IncidentDetailComponent } from './incident-detail.component';
import { SharedModule } from '../shared/sharedmodule/shared.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    MatCardModule, MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,
    FlexLayoutModule,
    FormsModule, 
    ReactiveFormsModule,
    IncidentRoutingModule,
    SharedModule
  ],
  declarations: [
    IncidentListComponent,
    IncidentDetailComponent
  ]
})

export class IncidentModule { }
