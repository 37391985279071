import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OncallRoutingModule } from './oncall-routing.module';
import { FlightsListComponent } from './flights-list.component';
import { FlightsDetailComponent } from './flights-detail.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
@NgModule({
  declarations: [FlightsListComponent, FlightsDetailComponent],
  imports: [
    CommonModule,
    OncallRoutingModule,
    MatCardModule,MatChipsModule, MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,
    FormsModule, ReactiveFormsModule,
    DataTablesModule
  ]
})
export class OncallModule { }
