<div class="flex-container3">
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/oncall/experimentflight', 0,0]" right="align">+ Add an experiment flight</a>
    </div>
      <mat-card>
          <mat-card-title>Experiment Flights</mat-card-title>
          <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
              <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                      <tr>
                          <th style="width: 10%">
                              Environment Name
                          </th>
                          <th style="width: 35%">
                              Flight Name
                          </th>
                          <th style="width: 15%">
                              Flight Enabled
                          </th>
                          <th style="width: 20%">
                              Flight Percentage
                          </th>
                          <th style="width: 10%">
                              Flight Salt
                          </th>
                          <th style="width: 10%">
                            Flighted Users
                        </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of filteredList">
                          <td>{{ item.EnvironmentName }}</td>
                          <td><a [routerLink]="['/oncall/experimentflight', item.EnvironmentName, item.FlightName]">{{ item.FlightName }}</a></td>
                          <td>{{ item.FlightEnabled }}</td>
                          <td>{{ item.FlightPercentage }}</td>
                          <td>{{ item.FlightSalt}}</td>
                          <td>{{ getUsers(item.FlightedUsers) }}</td>
                      </tr>
                  </tbody>
              </table>
          </mat-card-content>
      </mat-card>
</div>
