import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangereqConfigComponent } from './changereq-config.component';
import { ChangeRequestDetailComponent } from './changerequest-detail.component';
import { ChangerequestListComponent } from './changerequest-list.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(
    [
      {
          path: 'changerequest',
          children: [
            {
              path: '',
              component: ChangerequestListComponent,
            },
            {
                path: ':Id/:Type/:Decision',
                component: ChangeRequestDetailComponent,
            },
            {
                path: 'config',
                component: ChangereqConfigComponent,
            }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class ChangeRequestRoutingModule { }
