import { Utils } from '../shared/Utils/utils';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute, Router} from '@angular/router';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { CrashPortalContract, AuditHistory } from '../shared/models/WfsModels';
import { HistoryDisplayComponent } from '../shared/sharedmodule/historydisplay.component';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
@Component({
    templateUrl: './crashdetails.component.html',
    styleUrls: ['../app.component.css']
})


export class CrashDetailsComponent implements OnInit, OnDestroy {
    public crashForm: FormGroup;
    public id:string;
    public version:string;
    public currentCrash: CrashPortalContract = null;
    public success: boolean;
    public auditHistory: AuditHistory[] = [];
    @ViewChild('historyComponent') historyComponent: HistoryDisplayComponent;
    constructor(private fb: FormBuilder, private service: DiagDefinitionService, private spinnerService: SpinnerService, private router: Router, private route: ActivatedRoute, private util: Utils, private notificationBarService:NotificationBarService)
    { }

    ngOnInit() {
        this.crashForm = this.fb.group({
            WatsonFailureId: [''],
            Channel: [''],
            WorkItemNo: [''],
            FixBuild: [''],
        });

        this.id = this.route.snapshot.params['Id'];
        this.version = this.route.snapshot.params['Version'];
        if (this.id != '0') 
        {
            this.spinnerService.displayLoader(true);
            let subscription = this.service.GetCrashResolutionAsync(this.id, this.version).subscribe(response => {
                this.currentCrash = response;
                this.auditHistory = this.currentCrash.AuditHistory;
                if (this.currentCrash != null && this.currentCrash.CrashResolutionData != null) 
                {
                    this.crashForm.patchValue({
                        WatsonFailureId: this.currentCrash.CrashResolutionData.FailureId,
                        Channel: this.currentCrash.CrashResolutionData.ChannelIdAndOfficeMajorVersion,
                        WorkItemNo: this.currentCrash.CrashResolutionData.WorkItemNumber,
                        FixBuild: this.currentCrash.CrashResolutionData.FixBuild,
                    });

                    this.success = true;
                }
                else
                {
                    this.notificationBarService.openSnackBar("Crash doesn't exist",'','Warn');
                }
                
                this.spinnerService.displayLoader(false);
                this.finalize(subscription);
            }, error => {
                this.spinnerService.displayLoader(false);
                this.finalize(subscription);
            });
        }
    }
    ngOnDestroy(): void {
        this.notificationBarService.dismiss();
    }
    public onDelete()
    {
        this.spinnerService.displayLoader(true);
        let sub1 = this.service.DeleteCrashResolutionAsync(this.id).subscribe(response => {
            this.finalize(sub1);
            this.spinnerService.displayLoader(false);
            this.router.navigate(['/crashes']);
        }, error => {
            this.finalize(sub1);
        });
    }

    private finalize(subscribtion:Subscription): void {
        if (subscribtion) {
            subscribtion.unsubscribe();
        }
    }
}