<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="ticket-container">
        <div *ngIf='statusMessage' style="margin-top:25px; margin-left:25px">
            <font style="font-size: 100%" color="blue">
                <p [innerHTML]="statusMessage"> </p>
            </font>
        </div>
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"  (selectedTabChange)="onQueueChange($event)">
            <mat-tab *ngFor="let queue of queues; let index = index" [label]="queue.DisplayName">
            </mat-tab>
        </mat-tab-group>
        <div *ngIf='openSearch' style="margin-top: 15px; margin-bottom: 15px;">
            <label>Search by session id or user upn</label>
            <input class="form-control" type="text" placeholder="type to start searching by session id or user upn" (keydown.enter)="onSearch($event.target.value)"/>
        </div>

        <mat-card >
            <mat-card-title *ngIf="!!currentQueue&&!openSearch">Diagnostic Sessions in Queue: <span style="text-transform: capitalize;font-weight: 600;">{{currentQueue.QueueLabel}}</span><i *ngIf="!!currentQueue" class="fa fa-refresh" aria-hidden="true" (click)="onOpenQueue(currentQueue)" style="cursor: pointer;margin-left: 30px;font-size: medium;"><b>Refresh</b></i></mat-card-title>
            <mat-card-title *ngIf="openSearch">Search Result</mat-card-title>
            <mat-card-content>
                <table class="table table-bordered table-striped table-hover" style="word-break:break-word" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width: 5%">
                                Vip
                            </th>
                            <th style="width: 10%">
                                Ticket Status
                            </th>
                            <th style="width: 5%">
                                Ticket System
                            </th>
                            <th style="width: 7%">
                                Ticket Id
                            </th>
                            <th style="width: 5%">
                                Assign To
                            </th>
                            <th style="width: 10%">
                                Tags
                            </th>
                            <th style="width: 20%">
                                Problem Statement
                            </th>
                            <th style="width: 10%">
                                App Version
                            </th>
                            <th style="width: 10%">
                                User Upn
                            </th>
                            <th style="width: 8%">
                                Created Time
                            </th>
                            <th style="width: 15%">
                                Chat Last Modified Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ticket of filteredtickets">
                            <td><span class="fa fa-star checked" style="color:#FFD700" *ngIf="ticket.IsVip"></span></td>
                            <td style="text-transform: capitalize">{{ticket.UXTicketStatus}}</td>
                            <td>{{ticket.TicketingSystem}}</td>
                            <td>
                                <a *ngIf="ticket.TicketId" (click)="onOpenTicket(ticket.DiagnosticSessionId)" class="previewicon">{{ ticket.TicketId }}</a>
                                <a *ngIf="!ticket.TicketId" (click)="onOpenTicket(ticket.DiagnosticSessionId)" class="previewicon">{{ ticket.DiagnosticSessionId }}</a>
                            </td>
                            <td>
                                <div *ngIf='ticket.TicketId'>
                                    <div *ngIf="ticket.TicketingSystem!='Rave'">
                                            <div *ngIf='(ticket.TicketStatus=="resolved"||ticket.TicketStatus=="rejected")'>
                                                    <p>{{getAliasFromEmail(ticket.TicketAssignee)}}</p>
                                            </div>
                                            <div  *ngIf='ticket.TicketStatus!=="resolved"&&ticket.TicketStatus!=="rejected"'>
                                                <p *ngIf='ticket.TicketAssignee&&ticket.TicketAssignee.includes("acompli")'>{{ticket.TicketAssignee}}</p>
                                                <select (change)="onUpdateTicketAssignee(ticket,$event.target.value)">
                                                    <option></option>
                                                    <option [value]="signInAgentEmail">-Me-</option>
                                                    <option *ngFor="let agent of supportAgents" [value]="agent.DiagPortalEmail" [selected]="ticket.TicketAssignee==getAliasFromEmail(agent.DiagPortalEmail)">
                                                        {{getAliasFromEmail(agent.DiagPortalEmail)}}
                                                    </option>
                                                </select>
                                            </div>
                                    </div>
                                    <div *ngIf="ticket.TicketingSystem=='Rave'">
                                            {{ticket.TicketAssignee}}
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{ ticket.Tags.join(', ') }}
                            </td>
                            <td>{{ ticket.ProblemStatement }}</td>
                            <td>{{ ticket.ProductVersion }}</td>
                            <td>{{ ticket.UserUpn }}</td>
                            <td>{{ ticket.CreatedTime | date:'yyyy/MM/dd HH:mm' }}</td>
                            <td>{{ ticket.ChatLastModifiedTimeInDate| date:'yyyy/MM/dd HH:mm'}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
</div>
