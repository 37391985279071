<form [formGroup]="recoveryForm">
    <h1>Message to User</h1>
    <div [ngClass]="{'inactiveDiv': haspendingrequest}">
        <div>
            <label>Issue Type</label>
            <table>
                <tr *ngFor="let choice of choices; let idx = index" style="float: left">
                    <td>
                        <input type="radio" name="radiogroup"
                            [checked]="choice.choiceValue == hasFix"
                            [value]="choice.choiceId"
                            (change)="onSelectionChange(choice)">
                    </td>
                    <td>{{ choice.choiceText }}</td>
                    <td> &nbsp;&nbsp;</td>
                </tr>
            </table>
        </div>
        <div class="form-group">
            <label for="LinkId">More Info Link</label>
            <input class="form-control" id="LinkId" type="text" placeholder="enter support link (required)" formControlName="Link" />
            <small *ngIf="!recoveryForm.controls.Link.valid" class="text-danger">Only required for outlookdesktop client, must be a valid url</small>
        </div>
        <div style="margin-bottom:20px">
            <label><input type="checkbox" id="isArticle" (click)="OnIsArticleChange()" [checked]="isArticle===true"/>Populate from article.</label>
            <div style="color: brown" [innerHTML]="articleMessage"></div> 
        </div> 
        <div class="form-group">
            <label for="RecoveryTitleId">Title</label>
            <input class="form-control" id="RecoveryTitleId" type="text" placeholder="enter recovery title (required)" maxlength="500" formControlName="RecoveryTitle" />
            <small *ngIf="recoveryForm.controls.RecoveryTitle.invalid" class="text-danger">Required</small>
            <small *ngIf="titleContainsHtml()" class="text-danger">HTML tags is not allowed</small>
        </div>
        <div class="form-group">
            <label for="ProblemDescriptionId">Problem Description</label>
            <input class="form-control" id="ProblemDescriptionId" type="text" placeholder="enter Problem description (required)" maxlength="500" formControlName="ProblemDescription" />
            <small *ngIf="recoveryForm.controls.ProblemDescription.invalid" class="text-danger">Required</small>
            <small *ngIf="psContainsHtml()" class="text-danger">HTML tags is not allowed</small>
        </div>
        <!--Dialog For Image Upload Template-->
        <ng-template #ImageUploadDialog>
            <h2 matDialogTitle>Upload Image</h2>
            <mat-dialog-content>
                <p>To add an image please choose an image file</p>
                <label for="ImageChangeTextId">Image Description</label>
                <br/>
                <input type="text" id="ImageDescription" (change)="OnImageChangeText()" placeholder="Enter a description for the image to upload" style="width: 100%;"/>
                <br/>
                <label for="ImageFileId">Image Upload</label>
                <input type="file" (change)="OnImageChangeFile($event)" base-sixty-four-input/>
            </mat-dialog-content>
            <footer>
                <div style="position: absolute;display: flex; padding-inline-start: 200px;">
                    <button [disabled]="isSubmitImageDisabled" type="button" (click)="OnSubmitImage()" value="Submit" mat-button matDialogClose>Submit</button>
                    <button mat-button matDialogClose>Cancel</button>
                </div>
            </footer>
        </ng-template>

        <div class="form-group">
            <label for="RecoveryElementsId">Recovery Elements</label>
            <br />
            <select name="dropdown" id="ElementSelection" (change)="onRecoveryElementsSelection()">
                <option value="Add Elements" selected>Add Elements</option>
                <option value="List">List</option>
                <option value="Numbered List">Numbered List</option>
                <option value="Subtitle">Subtitle</option>
                <option value="Body Text">Body Text</option>
                <option value="Image">Image</option>
            </select>
            <small class="text-danger">*Note to add an element select from the dropdown and edit the content in the "Elements" array</small>
            <br />
            <br />
            <div style="display:flex; width: 100%;">
                <div style="display: block; width: 50%;">
                    <textarea class="form-control" id="RecoveryElementsId" type="text" placeholder="Enter the JSON for the Rich Recovery" formControlName="RecoveryElements" style="height:120px; width:100%;"></textarea>
                    <small *ngIf="!RecoveryElementsValid()" class="text-danger">Not a valid JSON For Creating Rich Recoveries</small>
                </div>
                <div style="display:block; width: 35%">
                    <mat-card style="margin-inline-start: 15%; width: 70%; margin-inline-end: 15%;">
                        <mat-card-title style="display: flex; font-size: small; font-weight: bold; color: black;">{{ recoveryForm.controls.RecoveryTitle.value }}</mat-card-title>
                        <div style="display: flex; width: 100%;">
                            <div style="display: left; width: 80%;">
                                <p style="padding-left: 0%;">{{ recoveryForm.controls.ProblemDescription.value }}</p> 
                            </div>
                            <div style="display: right; width: 10%; padding-inline-end: -10%;">
                                <button *ngIf="collapsed" (click)="collapsed=false" style="background-color: #ffffff;"><i  class="icon-cheveron, fa fa-chevron-down" style="color: rgb(2, 152, 211);margin-left: 20px"></i></button>
                                <button *ngIf="!collapsed" (click)="collapsed=true" style="background-color: #ffffff;"><i  class="icon-cheveron, fa fa-chevron-up" style="color: rgb(2, 152, 211);margin-left: 20px"></i></button>
                            </div>
                        </div> 
                        <mat-card-content *ngIf="!collapsed">
                            <div *ngIf="RecoveryElements">
                                <div *ngFor="let element of RecoveryElements">
                                    <div *ngIf="element.Type === 'Subtitle'">
                                        <div *ngIf="element.Elements && element.Elements.length > 0">
                                            <label style="font-weight: bold; color: black;">{{element.Elements[0]}}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="element.Type === 'BodyText'">
                                        <div *ngIf="element.Elements && element.Elements.length > 0">
                                            <p>{{element.Elements[0]}}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="element.Type === 'List'">
                                        <div *ngIf="element.Elements && element.Elements.length > 0">
                                            <ul>
                                                <li *ngFor="let unorderedElement of element.Elements">{{unorderedElement}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div *ngIf="element.Type === 'NumberedList'">
                                        <div *ngIf="element.Elements && element.Elements.length > 0">
                                            <ol>
                                                <li *ngFor="let orderedElement of element.Elements">{{orderedElement}}</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div *ngIf="element.Type === 'Image'">
                                      <div *ngIf="element.Elements && element.Elements.length > 0" style="padding-left:10%;">
                                        <img src="{{element.Elements[0]}}" width="80%" height="auto" style="align-items: center;">
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
          </div>
        <div *ngIf='hasFix' class="form-group">
            <label for="RecoveryStepsId">Recovery Steps</label>
            <input class="form-control" id="RecoveryStepsId" type="text" placeholder="enter recovery steps (required)" maxlength="500" formControlName="RecoverySteps" />
            <small *ngIf="hasFix && !recoveryForm.controls.RecoverySteps.value" class="text-danger">Required</small>
            <small *ngIf="rsContainsHtml()" class="text-danger">HTML tags is not allowed</small>
        </div>
    </div>
    
    <div  [ngClass]="{'inactiveDiv': haspendingrequest}">
        <b>Flight Audience</b>
        <label formArrayName="flightAudiences" *ngFor="let item of formData.controls; let i = index" style="display: block; font-weight: normal">
            <input type="checkbox" [formControlName]="i">
            {{flightAudiences[i].display}}
        </label>
    </div>
</form>   