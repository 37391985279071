<div class="flex-container3">
    <mat-card>
        <mat-card-title>Pending Change Requests</mat-card-title>
        <mat-card-content>
            <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                    <th>Diag Portal Link</th>
                    <th>Type</th>
                    <th>Requestor</th>
                    <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredList">
                    <td><a href="{{item.DiagPortalLink}}">{{ item.DiagPortalLink}}</a></td>
                    <td>{{ item.Type }}</td>
                    <td>{{ item.Requestor }}</td>
                    <td>{{ item.Timestamp| date:'yyyy/MM/dd HH:mm'}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
 </div>