import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { Subject, Subscription } from 'rxjs';
import { SupportEligibilityUser } from '../shared/models/WfsModels';
import { GlobalService } from '../shared/services/global.service';

@Component({
  selector: 'app-entitle-list',
  templateUrl: './entitle-list.component.html',
  styleUrls: ['../app.component.css']
})
export class EntitleListComponent implements OnInit {
  public client:string;
  public filteredList:SupportEligibilityUser[];
  public overwriteNotification:string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinnerService:SpinnerService, private diagService:DiagDefinitionService, private util:Utils, private notificationBarService:NotificationBarService, private globalService:GlobalService){
    this.client = this.globalService.getClient();
  }
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
      this.dtTrigger.unsubscribe();
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true ,
      order: [[0, 'asc']]
    };
    this.spinnerService.displayLoader(true);
    let sub = this.diagService.GetAllSupportEntitlementUsersAsync(this.client).subscribe(
      response => {
          this.spinnerService.displayLoader(false);
          if (!response || response.length == 0)
          { 
            this.notificationBarService.openSnackBar("There is no users in table",'','');
          }
          this.filteredList = response;
          this.dtTrigger.next();
          this.finalize(sub);
        },
        error => {
            this.spinnerService.displayLoader(false);
            this.finalize(sub);
        });
  }

  private finalize(subscription: Subscription):void
  {
      this.spinnerService.displayLoader(false);
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }
}
