import {SpinnerService} from '../shared/services/spinner.service';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import saveAs from 'save-as';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '../shared/Utils/utils';
import { WfsService } from '../shared/services/wfs.service';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class PageDetailComponent implements OnInit {
  public bulkDownloadInProgress:boolean=false;
  public id:string;
  public message:string;
  public errorCode:string;
  constructor(private route: ActivatedRoute, 
    private service: WfsService,
    private spinService:SpinnerService,
    public sanitizer: DomSanitizer, private util:Utils){
    }

  ngOnInit() {
    this.id = this.route.snapshot.params['Id'];
    this.onBulkDownload();
  }

  public onBulkDownload()
  {      
      this.bulkDownloadInProgress=true;
      this.spinService.displayLoader(true);
      this.service.FileBulkDownloadAsync(this.id).subscribe(
          response => 
          {
            let file = response.body;
            this.bulkDownloadInProgress=false;
            saveAs(file, "bulkdownload_"+this.id +'.zip');
            this.spinService.displayLoader(false);
          },
          error => 
          {
            this.bulkDownloadInProgress=false;
            this.spinService.displayLoader(false);
            if(error!="500" || error!="503")
            {
              this.message = "Log file cannot be found."
            }else
            {
              this.message = "File download failed.";
            }
          });
  }     
}
