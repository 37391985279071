<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="flex-container3">
      <mat-card>
          <mat-card-title style="color:grey;font-size: 28px">Coming Soon</mat-card-title>
          <mat-card-content >
            <form [formGroup]="form">
              <div style="display: flex; flex-direction: column;">
                  <div style="font:100px" *ngIf="!!currentComingSoon">
                    <div class="form-group" [ngClass]='{"inactiveDiv": !isNewComingSoon}'>  
                      <label style="width: 100%">
                        Article ID
                        <input class="form-control" formControlName="articleId" required pattern="^[a-zA-Z0-9 ]*$" ng-pattern-restrict minlength="1" maxlength="50" (input)="newlinkShowIframe()" style="font-weight: normal">
                        <small *ngIf="!form.controls.articleId.valid" class="text-danger" >Required, max length 50, allow only alphanumeric and space</small>
                      </label>
                    </div>

                    <div class="form-group">  
                      <label style="width: 100%">
                          Friendly Name  
                        <input class="form-control" style="font-weight: normal" type="text" formControlName="friendlyName" required minlength="1" maxlength="50" pattern="^[a-zA-Z0-9 ]*$" ng-pattern-restrict>
                        <small *ngIf="!form.controls.friendlyName.valid" class="text-danger">Required, max length 50, allow only alphanumeric and space</small>
                      </label>
                    </div>

                    <div class="form-group">  
                      <label style="width: 100%">
                          <label style="width: 100%">Article Link</label>
                          <p style="font-weight: normal">{{articleUri}}</p>
                      </label>
                    </div>

                    <div class="form-group">  
                      <label style="width: 100%">
                          Flights
                          <input class="form-control" formControlName="flights" required maxlength="1000" pattern="^[a-zA-Z0-9.:;]*$" ng-pattern-restrict placeholder='; seperated for more than 1 flight' style="font-weight: normal">
                          <small *ngIf="!form.controls.flights.valid" class="text-danger">Required, max length 1000, allow only alphanumeric . : ;</small>
                      </label>
                    </div>

                    <mat-card style="overflow: auto">
                      <mat-card-title>Configs</mat-card-title>
                      <mat-card-content>
                          <table class='table' style="overflow: auto" id="configTable" style="line-height:0">
                              <tr>
                                  <th style="width:10%"></th>
                                  <th style="width:20%">Client</th>
                                  <th style="width:20%">Audience</th>
                                  <th style="width:10%">Channel</th>
                                  <th style="width:40%;text-align: center">Build Range</th>
                              </tr>
                              <tr *ngFor="let item of currentComingSoon.Configs">
                                  <td>
                                    <i class="fa fa-pencil" id="{{item.PreviewPlaceArticleInfoTableRowKey + 'edit'}}" (click)="onTriggerEditConfig(item)" style="margin-right:10px;color: orange;cursor: pointer"></i>
                                    <i class="fa fa-check-circle" id="{{item.PreviewPlaceArticleInfoTableRowKey+ 'ok'}}" (click)="onUpdateConfig(item)" style="display:none;color:darkgreen;margin-right:10px;cursor: pointer"></i>
                                    <i class="fa fa-undo" id="{{item.PreviewPlaceArticleInfoTableRowKey + 'withdraw'}}" (click)="onResetFromModify(item)" style="display:none;margin-right:10px;cursor: pointer"></i>
                                    <i class="fa fa-close" id="{{item.PreviewPlaceArticleInfoTableRowKey + 'delete'}}" (click)="onRemoveConfig(item)" style="color:indianred;cursor: pointer"></i></td>
                                  <td>{{item.Client}}</td>
                                  <td><p style="text-transform: capitalize">{{item.Audience}}</p></td>
                                  <td><p style="text-transform: capitalize">{{GetChannel(item.Channel)}}</p></td>
                                  <td style="text-align: center">
                                    <span id="{{item.PreviewPlaceArticleInfoTableRowKey + 'buildRange'}}">{{item.BuildRange}}</span>
                                    <span id="{{item.PreviewPlaceArticleInfoTableRowKey + 'modifyBuildRange'}}" style="display:none">
                                      <input id="{{item.PreviewPlaceArticleInfoTableRowKey + 'newBuild'}}" type="text" style="height:25px;border: 1px solid #ccc;border-radius: 4px"/>
                                    </span>
                                  </td>
                              </tr>
                              <!-- Section for adding a new config -->
                              <tr id="beforeAdd">
                                  <td>
                                      <i class="fa fa-plus-square" (click)="onCreateConfig()" style="color:dimgray;cursor: pointer"></i>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                              </tr>
                              <tr id="Adding" style="display:none">
                                  <td>
                                      <i class="fa fa-check-circle" id="newBugOk" (click)="createConfig()" style="color:darkgreen;margin-right:10px;cursor: pointer"></i>
                                      <i class="fa fa-undo" id="newBugWithdraw" (click)="createReset()" style="margin-right:10px;cursor: pointer"></i>
                                  </td>
                                  <td class="required">
                                      <select id="newConfigClient" style="height:25px;border: 1px solid #ccc;border-radius: 4px">
                                          <option *ngFor="let item of clientSelection" [value]="item">{{item}}</option>
                                      </select>
                                  </td>
                                  <td class="required">
                                      <select id="newConfigAudience" style="height:25px;border: 1px solid #ccc;border-radius: 4px">
                                          <option *ngFor="let item of audienceSelection" [value]="item">{{item}}</option>
                                      </select>
                                  </td>
                                  <td class="required">
                                      <select id="newConfigChannel" style="height:25px;border: 1px solid #ccc;border-radius: 4px">
                                          <option *ngFor="let item of channelSelection" [value]="item">{{item}}</option>
                                      </select>
                                  </td>
                                  <td class="required" style="text-align: center">
                                      <input id="newConfigBuildRange" type="text" placeholder='16.0.9320.0000-' style="height:25px;border: 1px solid #ccc;border-radius: 4px"/>
                                  </td>
                              </tr>
                          </table>
                      </mat-card-content>
                    </mat-card> 
                  </div>

                  <div style="margin-bottom:10px;margin-top: 10px;">
                    <button style="float: right" [routerLink]="[ '/comingsoons']">Cancel</button>
                    <button [disabled]="!form.valid || !currentComingSoon.Configs ||currentComingSoon.Configs.length==0" style="float: right;margin-right: 10px;" (click)="onSubmit()">Save</button>
                    <button [disabled]='isNewComingSoon' style="float: right;margin-right: 10px;" (click)="onDelete()" >Delete</button>
                  </div>

                  <iframe id="embeddedPage" height="500px"></iframe>
              </div>
            </form>
            
      </mat-card-content>
    </mat-card>
</div>



