<div fxLayout="row" *ngIf="!!data">
    <table>
        <tr>
            <td style="padding-right: 15px;">
                <mat-icon>{{getIcon}}</mat-icon>
            </td>
            <td>
                <span>{{data.message}}</span>
            </td>
        </tr>
    </table>
</div>