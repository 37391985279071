import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utils } from '../Utils/utils';
import { ConfigService } from './config.service';

@Injectable()
export class GlobalService {
    constructor(private http: HttpClient,
        private utils: Utils,
        private configService: ConfigService)
    {
    }
    
    updateClient(value: string) {
        localStorage.setItem("appClient",value.toLocaleLowerCase());
    }

    getClient()
    {
        let client = localStorage.getItem("appClient");
        return !!client?client:"outlookdesktop";
    }
    
    public VerifyAuthorization(): Observable<boolean>
    {
        let url = this.configService.odsControllerUrl+'v1/diagportal/verifyauth';
        let observable = this.http.get(url, {headers:this.utils.GetRequestHeaders(),observe:"response"}).map(response =>
            {
                localStorage.setItem("isAuthorized","true");
                return true;
            }).share().catch((error:HttpErrorResponse)=>{
                if(!error.ok)
                {
                    localStorage.setItem("authInstruction",error.error);
                    localStorage.setItem("isAuthorized","false");
                    return Observable.of(false);
                }
                else
                {
                    localStorage.setItem("isAuthorized","true");
                    return Observable.of(true);
                }
            });

            return observable;
    }

  public VerifyOncall(): Observable<boolean>
  {
      let url = this.configService.odsControllerUrl+'v1/odsportal/verifyoncall';
      let observable = this.http.get(url, {headers:this.utils.GetRequestHeaders(),observe:"response"}).map(response =>
      {
          localStorage.setItem("isOncall","true");
          return true;
      }).share().catch((error:Response)=>{
          return Observable.of(false);
      });

      return observable;
  }

  public GetAllowedClients(): Observable<string[]>
  {
      let url = this.configService.odsControllerUrl+'v1/odsportal/allowedclients';
      let observable = this.http.get(url, {headers:this.utils.GetRequestHeaders(),observe:"response"}).map(response =>
      {
        if(response.status == 200)
        {
            let list :string[] = <string[]>(response.body);
            if(!list|| list.length==0 || list.includes("*"))
            {
                list=['DWEngineV2','Edge','Excel','Monarch','OneNote','OutlookDesktop','OWA','PowerPoint','SurfaceDuo','Teams','Sandbox','Word'];
            }

            if(!list.includes("OutJio"))
            {
                list.push("OutJio")
            }
            localStorage.setItem("clients",JSON.stringify(list));
            return list;
        }
      }).share().catch((error:Response)=>{
          return Observable.of([]);
      });

      return observable;
  }
}