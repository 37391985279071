<div class="flex-container3">
            <mat-card>
                <mat-card-title>Incidents</mat-card-title>
                <mat-card-content>
                    <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Classification</th>
                                <th>Friendly Id</th>
                                <th>Title</th>
                                <th>Feature Group Name</th>
                                <th>Start Date</th>
                                <th>Last Updated Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredList">
                                <td>
                                    <span *ngIf='item.Incident.ClassificationIcon=="advisory"' style="color:orange;font-size: medium;"><i class="fa fa-info-circle"></i> Advisory</span>
                                    <span *ngIf='item.Incident.ClassificationIcon=="incident"' style="color:red;font-size: medium;"><i class="fa fa-exclamation-triangle"></i> Incident</span>
                                    <span *ngIf='item.Incident.ClassificationIcon=="investigation"'  style="color:blue;font-size: large;">? Investigation</span>
                                    <span *ngIf='item.Incident.ClassificationIcon=="close"'  style="color:green;font-size: large;">&#9745; Close</span>
                                </td>
                                <td><a [routerLink]="['/incidents', item.Incident.Id]"  target="_blank">{{ item.Incident.FriendlyId }}</a></td>
                                <td>{{ item.Incident.Title }}</td>
                                <td>{{ item.Incident.FeatureGroupName }}</td>
                                <td>{{ item.Incident.StartDate| date:'yyyy/MM/dd HH:mm'}}</td>
                                <td>{{ item.Incident.LastUpdatedTime| date:'yyyy/MM/dd HH:mm'}}</td>
                            </tr>
                        </tbody>
                                           </table>
                </mat-card-content>
            </mat-card>
</div>
