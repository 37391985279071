import {Component, Inject, Injectable} from '@angular/core';
import {MatSnackBar,MAT_SNACK_BAR_DATA,MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
  })
export class NotificationBarService {

    constructor(private snackBar: MatSnackBar) {}
    
    public openSnackBar(message: string, action: string, snackType?: string) {
        const _snackType =
          snackType !== undefined ? snackType : 'Success';
    
        this.snackBar.openFromComponent(NotificationBar, {
          duration: 9000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          data: { message: message, snackType: _snackType }
        });
      }

      dismiss(): void {
        this.snackBar.dismiss();
      }
}

@Component({
    selector: 'notification-bar',
    templateUrl: 'notificationbar.component.html',
})

export class NotificationBar {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
       console.log(data); 
    }

    snackBarRef: MatSnackBarRef<NotificationBar>;

    get getIcon() {
        switch (this.data.snackType) {
          case 'Success':
            return 'done';
          case 'Error':
            return 'error';
          case 'Warn':
            return 'warning';
          case 'Info':
            return 'info';
        }
    }
}