import {SpinnerService} from '../shared/services/spinner.service';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs/Rx';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosticsFiles, SaraTicket, RecoveryInfo, KeyValuePair, ChatMessage, ChatRequest, TicketRequest, Agent, TicketNote, TicketNoteRequest, TicketBug, TicketBugAssociationRequest, TicketTag, SurveyAnswers, SurveyResponse, FlightInfo} from '../shared/models/WfsModels';
import { WfsService } from '../shared/services/wfs.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import saveAs from 'save-as';
import { TreeNode} from './TreeView/tree-view'
import { Utils } from '../shared/Utils/utils';
import { Msal } from '../shared/services/msal.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
	selector: 'saraticketdetail',
	templateUrl: './saraticketdetail.component.html',
    styleUrls: ['../app.component.css'],
})

export class SaraTicketDetailComponent implements OnInit, OnDestroy{
    @ViewChild('scrollChat') private chatScrollContainer: ElementRef;
    @ViewChild('scrollNote') private noteScrollContainer: ElementRef;
    public signInAgentEmail:string;
    public isTokenExpired:boolean = false;
    public pageTitle: string = 'Ticket Details';
    public AutoTranslationOverrideKeyName = "DisableAutoTranslation";
    public saraTicket: SaraTicket;
    public errorMessage: string;
    public diagData: DiagnosticsFiles[];
    public filteredDiagData: DiagnosticsFiles[];
    public statusMessage: string;
    public diagnosticsFileStatus: string;
    public rowsOnPage = 100;
    public data:SaraTicket[];
    public sortBy = "FileName";
    public sortOrder = "asc";
    private id:string;
    loc: Location;
    public currentTab :string;
    public hideHome:boolean = false;
    public ticketLink:string;
    public threeSDashLink: string;
    public ticketCreatedTime: string;
    public metadataAttributes: {key:string, value:string}[];
    public metadataAttributesOSInfo: {key:string, value:string}[]=[];
    public metadataAttributesApplicationInfo: {key:string, value:string}[]=[];
    public metadataAttributesOther: {key:string, value:string}[]=[];
    public metadataStatus: string;
    public diagnosticResultsStatus: string;
    public diagnosticResultRecoveryList: Array<{time: string, recoveryList: RecoveryInfo[]}>
    public diagnosticClientFlightsStatus: string;
    public diagnosticClientFlightsList:FlightInfo[];
    public filteredClientFlightsList:FlightInfo[];
    public onlyShowAssignedFlights:boolean = true;
    public searchTextForClientFlight:string;
    public diagnosticRegistryStatus:string;
    public registryTree:TreeNode;
    public showTree:boolean=false;
    public messages:ChatMessage[];
    public draftMessage:string;
    public isClient:boolean;
    public ticketStatus:string;
    private timerSubscription: Subscription;
    public supportAgents: Agent[];
    public newAssigneeEmailToUpdate:string;
    public newTagToAdd:string;
    public timer:any;
    public bulkDownloadInProgress:boolean=false;
    public ticketNotes: TicketNote[];
    public draftNote:string;
    public ticketBugs:TicketBug[]=[];
    public allBugs:TicketBug[]=[];
    public selectedBugDatabase:string;
    public bugDatabaseOptions:string[]=["office","o365exchange"];
    public isDirtyBugUnsaved:boolean=false;
    public authType:string;
    public authMsg:string;
    public jsonViewerFileContent:string;
    public toggleJsonViewer:boolean=true;
    public chatRecoveryCommands: {key:string, value:string}[];
    public selectedChatRecovery:string;
    public disableAutoTranslationFlight:boolean;
    public defaultOpenDrawer:boolean = true;
    public openChat:boolean = false;
    public isFeedbackSession:boolean;
    public csvFiles:DiagnosticsFiles[]=[];
    public csvfileToOpen:DiagnosticsFiles;
    public csvFileContent:string;
    public accountFile:DiagnosticsFiles;
    public accountInfoFileContent:string;
    public internalFiles=[];
    public serviceFiles=[];
    public displayInternalFiles=false;
    public txtfileContent:string;
    public txtFileToOpen: DiagnosticsFiles;
    public textFileList: DiagnosticsFiles[]=[];
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    // public imageToShow: any;
    // public imageFile:DiagnosticsFiles;
    // public videoToShow: DiagnosticsFiles;
    public cannedResponses: {key:string, value:string}[]=[
        {
            key: 'Strike 1',
            value: 
`Hello,

We are following up to see if you have received our last message. Please let us know if you still need assistance or have other priorities at the moment. The ticket will be archived if we don't hear back from you in 2 business days. We hope to hear back from you soon.

Regards,`
        },
        {
            key: 'Strike 2',
            value: 
`Hello,

We are following up to see if you have received our last message. Please let us know if you still need assistance or have other priorities at the moment. The ticket will be archived if we don't hear back from you in 1 business day.

If we don’t receive a reply within the next 1 business day, we will archive the ticket.  If you need to work on the problem after then, or have any questions, you can always reach out to us through Help-> Contact support.

Regards,`
        },
    {
        key:'Rave case creation',
        value:
`In order for us to troubleshoot this issue, we will be opening a separate case to get additional service insights which will help us drive the case to a faster resolution. 
You will receive an email informing you of the creation of the diagnostics case.
There is no action from your side and the case will be closed after the diagnostics have been run.
`
    },
    {
    key:'OL crash 7/15',
    value:
`Thank you for reporting this issue. Currently, there is an issue happening with Outlook crashing when launched and our engineering team has identified and deployed a fix. In most cases, restarting the client will resolve the problem although in some cases multiple launches of the client are needed to fully deploy the update.
 
For more information, you can refer to the following link:
https://support.microsoft.com/en-us/office/active-investigation-into-outlook-crashing-on-launch-9c59ad4b-813c-432a-afdc-f14717a4528d?ui=en-us&rs=en-us&ad=us 

We apologize for the inconvenience caused.`
    }]
    public selectedCannedResponse:string;
    public isODSticket:boolean;
    public jsonViewerStatus:string;
    public jsonViewerFileList: DiagnosticsFiles[]=[];
    public JsonViewerfileToOpen:DiagnosticsFiles;
    public dcrJSON:string;
    public queryParamsForRule:any;
    public toggerDCR:boolean=false;
    public dcrError:string;
    public locale:string;
    public resizewindowoffset=355;
    public allTags:TicketTag[];
    myInnerHeight = window.innerHeight;
    public categories:string[] = [];
    public surveyAnswers: {key:string, value:SurveyResponse}[];
    public hasSurveyResponses: boolean = false;
    public surveysPresented: boolean = false;
    public hasNoSurveyAnswers: boolean = false;
    public ticketClient :string;
    public pageload:boolean=false;
    private sortKeyValuePairsArray = function(a:KeyValuePair,b:KeyValuePair) 
    {
        return (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0);
    }
    
    constructor(private route: ActivatedRoute, 
    private service: WfsService,
    private diagService:DiagDefinitionService,
    private router: Router,
    private spinService:SpinnerService,
    public sanitizer: DomSanitizer, 
    private util:Utils,
    private msal:Msal){
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.signInAgentEmail = this.msal.currentSmtpAddress;
    }

    ngOnInit()
    {   
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 100,
            lengthMenu:['10','50','100']
          };
          this.dtTrigger.next();
        this.id = this.route.snapshot.params['SaraSessionId'];
        this.spinService.displayLoader(true);
        let ticketSubsciption = this.service.GetTicketBySessionId(this.id).subscribe(ticket => 
            {
                if(!ticket)
                {
                    this.statusMessage ="Session is not found";
                    this.finalize(ticketSubsciption);
                    return;
                }
                
                ticketSubsciption.unsubscribe();
                this.saraTicket=ticket;
                let sub = this.service.GetAvailableTabs(this.id).subscribe(
                    response => {
                    if(response || response)
                    {
                        this.categories = [];
                    }
        
                    response = response.sort();
                    const index = response.indexOf("Overview");
                    if (index > -1) {
                        response.splice(index, 1);
                        response = ["Overview"].concat(response);
                    }
                    this.categories = response;
                    this.pageload=true;
                    this.finalize(sub);
                    
                    this.getAllTags();
                    this.GetSessionAuthType();
                    this.onTabChange("Overview");// Default home page to "Overview"

                    this.metadataAttributes = this.getMapFromObject(this.saraTicket.DiagnosticSessionAttributes);
                    this.threeSDashLink = "https://logcollector.asgfalcon.io/ticket?externalTicketId=" + this.saraTicket.DiagnosticSessionId;
                    this.ticketCreatedTime = this.getLocalTimeFromUtcTime(this.saraTicket.CreatedTime.toString());
                    this.TicketStatusMapping(this.saraTicket);
                    this.ticketStatus = this.saraTicket.UXTicketStatus;
                    if (!!this.saraTicket.TicketingSystemUrl)
                    {
                        this.ticketLink = this.saraTicket.TicketingSystemUrl;
                    }else{
                        if(this.saraTicket.HelpshiftDomain=="office365" && !this.isODSticket)
                        {
                            this.ticketLink="https://office365.helpshift.com/admin/issue/"+this.saraTicket.TicketId;
                        }else{
                            this.ticketLink="https://www.osupportweb.com/admin/issue/"+this.saraTicket.TicketId;
                        }
                    }
                    
                    if(!!this.saraTicket.TicketId)
                    {
                        this.openChat=true;
                        this.getChatMessage1stLoad();
                        // have to pass in the reference of the function to setinterval,otheriwse js take it as string and timer will only run once
                        this.timer = setInterval(()=>{this.getChatMessages()},1000);
                    
                        if(this.saraTicket.TicketingSystem!="Rave")
                        {
                            let subscription= this.diagService.GetAgentsAsync().subscribe(agents => 
                                {
                                    this.supportAgents = agents;
                                    let currentAgent = agents.filter((agent:Agent)=> agent.HelpshiftProfileId==this.saraTicket.TicketAssignee)[0];
                                    if(!!currentAgent)
                                    {
                                        this.saraTicket.TicketAssignee= this.getAliasFromEmail(currentAgent.DiagPortalEmail);
                                    }else if (!!this.saraTicket.TicketAssignee){
                                        this.saraTicket.TicketAssignee = this.getAliasFromEmail(this.saraTicket.TicketAssignee);
                                    }
        
                                    this.finalize(subscription);
                                },error =>
                                {
                                    this.finalize(subscription,error);
                                });
                        }
                    
                        let subscriptionForChatRecoveries= this.diagService.GetChatRecoveryCommandsAsync().subscribe(cmds => 
                            {
                                this.chatRecoveryCommands = this.getMapFromObject(cmds);
                                this.finalize(subscriptionForChatRecoveries);
                            },error =>
                            {
                                this.finalize(subscriptionForChatRecoveries,error);
                            });
                    }

                    //Prepare the metadata for Overview page
                    if(!this.metadataAttributes)
                    {
                        this.metadataStatus = "No session metadata was found for this ticket"
                    }else
                    {
                        this.ticketClient = this.metadataAttributes.filter(item=> item.key=="ClientName")[0] == null? 
                        "": this.metadataAttributes.filter(item=> item.key=="ClientName")[0].value;
                        let ticketSystems = this.metadataAttributes.filter(item=> item.key=="TicketingSystem")[0];
                        this.saraTicket.TicketingSystem = !!ticketSystems&&ticketSystems.value;
                        this.isODSticket = !!ticketSystems&&ticketSystems.value=="ODS";
                        let autoTrans = this.metadataAttributes.filter(item=> item.key==this.AutoTranslationOverrideKeyName)[0];
                        this.disableAutoTranslationFlight = !!autoTrans && autoTrans.value=="True" ? true: false;

                        let chatLastModifiedTime = this.metadataAttributes.filter(item=> item.key=="ChatLastModifiedTime")[0];
                        if (!!chatLastModifiedTime && !!chatLastModifiedTime.value)
                        {
                            ticket.ChatLastModifiedTime = this.util.getDate(chatLastModifiedTime.value);
                            ticket.ChatLastModifiedTimeInDate = new Date(ticket.ChatLastModifiedTime);
                        }else
                        {
                            // This is temp since not all tickets has chat last modified field as of today 7/9
                            // If not exist, set it min Date time so that when order desc it can show after the ones that has a time
                            // once our tickets all have a chat last modified field with time comesby, we can safely remove this line.
                            ticket.ChatLastModifiedTimeInDate = new Date(-8640000000000000);
                        }

                        let langTest = this.metadataAttributes.filter(
                            (item:{key:string,value:string})=> {
                                
                                return item.key == "Language";
                            }
                        );
                        if (!!langTest && langTest.length > 0 && !!langTest[0].value)
                        {
                            this.locale = langTest[0].value;
                        }

                        const oSInfo:string[] = ['OSVersion','OSPlatform','OSProductType','OSPendingRestart'];
                        const applicationInfo:string[] = ['ProductName','ProductVersion','FlightAudienceGroup','FlightAudience','ProductPlatform','ProductType','Language','CdnChannel'];
                        const excludes :string[] = ['ODSPortalTabs'];
                        this.metadataAttributes.forEach(
                            (item:{key:string,value:string})=> {
                                let key=item.key;
                                if(oSInfo.includes(key) || key.startsWith("OSInfo"))
                                {
                                    const keyname = key.indexOf('/')>=0 ?key.split('/')[1]: item.key;
                                    this.metadataAttributesOSInfo.push({key:keyname,value:item.value});
                                }
                                else if(applicationInfo.includes(key) || key.startsWith("AppInfo"))
                                {
                                    const keyname = key.indexOf('/')>=0 ?key.split('/')[1]: item.key;
                                    this.metadataAttributesApplicationInfo.push({key:keyname,value:item.value});
                                }
                                else if(!excludes.includes(key))
                                {
                                    this.metadataAttributesOther.push(item);
                                }
                            }
                        )
                        this.metadataAttributesOSInfo.sort(this.sortKeyValuePairsArray);                        
                        this.metadataAttributesApplicationInfo.sort(this.sortKeyValuePairsArray);
                        this.metadataAttributesOther.sort(this.sortKeyValuePairsArray);
                    }

                    if (this.categories.includes("Files"))
                    {
                        this.GetDiagnosticsFiles(this.saraTicket.DiagnosticSessionId, this.ticketClient);
                    }
                },
                error => {
                    this.finalize(sub);
                    
                });
            }, error => 
            {
                this.finalize(ticketSubsciption,<any>error);
            });

        //Timer to scroll chat window to bottom, will start after 3 seconds and then ticks every 2 seconds:
        let timer = Observable.timer(3000,2000);
        let lastMsgsLength= 0;
        let lastNotesLength= 0;
        this.timerSubscription = timer.subscribe(t=> {
            if(!!this.saraTicket&&!!this.saraTicket.TicketId)
            {
                if(this.saraTicket.TicketStatus.toLowerCase()!="rejected" && this.saraTicket.TicketStatus.toLowerCase()!="resolved")
                {
                    // If has messages and got new msg as well.
                    if(!!this.messages && lastMsgsLength!=this.messages.length)
                    {
                        if(this.scrollToBottomChat())
                        {
                            lastMsgsLength=this.messages.length;
                        } 
                    }
                }
            }
            if(!!this.ticketNotes && this.ticketNotes.length!=lastNotesLength)
            {
                if(this.scrollToBottomNote())
                {
                    lastNotesLength = this.ticketNotes.length;
                }
            }
        });
    }

    ngOnDestroy(){
        // Destroy timer after leaving this component
        this.timerSubscription.unsubscribe();
    }

    public onTabChange(tab:string)
    {
        this.currentTab = tab;
        if(this.currentTab=="Communication")
        {
            this.getPrivateNotes();
        }

        if(tab == "Flights")
        {
            this.parseClientFlights();
        }

        if(tab == "Registry")
        {
            this.parseRegistry();
        }

        if(this.currentTab=="Recoveries")
        {
            // Get diag results file and prepare the recoveries to be shown in Recoveries tab
            let diagnosticResultFile = this.diagData.filter((file:DiagnosticsFiles)=> file.FileName=="Diagnostic Results")[0];
            this.parseDiagnosticResultFile(diagnosticResultFile);
        }

        if(tab == "Data Collectors")
        {
            this.getDataCollections();
        }

        if(tab == "Json Viewer")
        {
            this.GetFileJson();
        }

        if(tab =='Bugs')
        {
            this.GetTicketBugs();
            this.GetAllBugs();
        }
        if(tab =='Survey Responses')
        {
            let surveyAnswersAttribute = this.metadataAttributes.filter(item=> item.key=="SurveyAnswers")[0];
            if (surveyAnswersAttribute) {
                this.surveysPresented = true;
                let surveyAnsersDict = JSON.parse(surveyAnswersAttribute.value) as SurveyAnswers;
                this.surveyAnswers = this.getMapFromObjectSurvey(surveyAnsersDict);
            }
        }
        if(tab =='Account Info')
        {
            this.GetAccountInfo();
        }
    }

    onClick(item: DiagnosticsFiles)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        item.DownloadInProgress = true;
        this.service.DownloadFile(item.FileId, this.id).subscribe(
            response => 
            {
                item.DownloadInProgress = false;
                let fileType = item.FileType;
                if (!!item.IsCompressed)
                {
                    fileType = "zip";
                }
                
                saveAs(response.body, item.FileName + '.' + fileType);
            },
            error => 
            { 
                item.DownloadInProgress = false;
            }
        );
    }

    // getImage(item: DiagnosticsFiles){
    //     const imageUrl = item.FileUrl.substring(0,item.FileUrl.indexOf('v2/file'))
    //     .concat('v1/screenshot/',this.id).concat('/',item.FileId);
    //     this.service.DownloadImage(imageUrl, this.id).subscribe(
    //         blob => 
    //         {
    //             const urlCreator = window.URL;
    //             this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
    //         }
    //     );
    // }

    private GetDiagnosticsFiles(id:string, ticketClientName:string)
    {
        if(!!this.diagData && this.diagData.length>0)
        {
            return;
        }

        this.spinService.displayLoader(true);
        if (!this.saraTicket.DiagnosticsFiles)
        {
            let filesSubscription = this.service.GetSaraDiagnosticsFiles(id).subscribe(diagnosticsFiles => this.diagData = diagnosticsFiles, error => this.statusMessage = <any>error, () => {
                this.statusMessage = "";
                this.finalize(filesSubscription);
                if (!this.diagData || (this.diagData.length == 0))
                {
                    return;
                }

                this.diagData.sort(function(a,b) {return (a.CreatedTime < b.CreatedTime) ? 1 : ((b.CreatedTime < a.CreatedTime) ? -1 : 0);} );
                this.diagData.forEach(file=>{
                    if(file.FileName=="AutoD file" || 
                    (ticketClientName.toLocaleLowerCase() == "monarch" && file.IsUploadComplete == false))
                    {
                        return;
                    }

                    if(file.FileType=="DiagnosticsResults" || file.FileType == "SessionProperty"
                     ||file.FileType.toLocaleLowerCase()=="json"
                     || file.FileType.toLocaleLowerCase()=="txt" || file.FileType.toLocaleLowerCase()=="text")
                    {
                        if(file.FileType.toLocaleLowerCase()=="txt" || file.FileType.toLocaleLowerCase()=="text")
                        {
                            file.displayMagnifier = true;
                            this.textFileList.push(file);
                        }else
                        {
                            this.jsonViewerFileList.push(file);
                            file.displayMagnifier = true;
                            if(file.FileType=="DiagnosticsResults" || file.FileType == "SessionProperty")
                            {
                                this.internalFiles.push(file);
                            }
                        }
                        
                    }else if( !file.IsFileUploadedFromClient)
                    {
                        this.internalFiles.push(file);
                    }
                  
                    if(file.FileName.toLocaleLowerCase().includes("accountinfo"))
                    {
                        file.displayMagnifier = true;
                        this.accountFile = file;
                    }
                    if(file.FileName.toLocaleLowerCase().includes("kusto data"))
                    {
                        this.csvFiles.push(file);
                        this.serviceFiles.push(file);
                    }
                    if(file.FileName.toLocaleLowerCase().includes("flightsinfo"))
                    {
                        file.displayMagnifier = true;
                    }
                })
                
                this.filteredDiagData = this.diagData.filter(file=>{
                    return !this.internalFiles.includes(file);
                })
                this.dtTrigger.next();
            });
        }
    }

    public openFileInTab(file: DiagnosticsFiles)
    {
        if(file.FileName.toLocaleLowerCase().includes("flightsinfo"))
        {
            this.currentTab = "Flights";
            this.onTabChange(this.currentTab);
        }
        else if(file.FileName.toLocaleLowerCase().includes("accountinfo"))
        {
            this.currentTab = "Account Info";
            this.onTabChange(this.currentTab);
        }else
        {
            if(file.FileType.toLocaleLowerCase()=="json" ||file.FileType=="DiagnosticsResults" || file.FileType == "SessionProperty")
            {
                console.dir(file);
                this.currentTab = "Json Viewer";
                this.JsonViewerfileToOpen = file;
                this.onTabChange(this.currentTab);
            }else if(file.FileType.toLocaleLowerCase()=="txt"||file.FileType.toLocaleLowerCase()=="text")
            {
                this.currentTab ="Log Viewer";
                this.txtFileToOpen = file;
                this.GetTxtFileContent();
            }
        }
        
    }

    public GetTxtFileContent()
    {
        this.txtfileContent="";
        this.spinService.displayLoader(true);
        let subscription = this.service.GetFileContent_Text(this.txtFileToOpen).subscribe(response => 
            {
                this.txtfileContent = response;
                this.spinService.displayLoader(false);
                this.finalize(subscription);
            },
            error => 
            { 
                this.finalize(subscription,<any>error);
            }
        );
    }

    public toggleFiles()
    {
        this.displayInternalFiles=!this.displayInternalFiles;
        if(!this.displayInternalFiles)
        {
            this.filteredDiagData = this.diagData.filter(file=>{
                return !this.internalFiles.includes(file);
            })
        }else{
            // join;
            this.filteredDiagData = this.diagData;
        }
    }

    private hasUnsavedChangeWhenAddingBugInField(fieldId:string):boolean
    {
        return document.getElementById("AddingBug").style.display=='table-row' && !!(<HTMLInputElement>document.getElementById(fieldId)).value
    }
    
    private getMapFromObjectSurvey(obj: SurveyAnswers):{key:string, value:SurveyResponse}[]
    {
        if(!obj)
        {
            return null;
        }

        let ret:{key:string, value:SurveyResponse}[] = [];
        var keys = Object.keys(obj);
        for (let i=0;i<keys.length; i++)
        {
          let questionFriendlyID = keys[i];
          let surveyResponse = obj[questionFriendlyID];
          if (!this.hasSurveyResponses && surveyResponse.SurveyAnswered == true) {
              this.hasSurveyResponses = true;
          } else if (surveyResponse.SurveyAnswered == false) {
              this.hasNoSurveyAnswers = true
          }

          ret.push({key: questionFriendlyID, value: surveyResponse});
         }   
        
        return ret;
    }


    public getMapFromObject(obj:Object):{key:string, value:string}[]
    {
        if(!obj)
        {
            return null;
        }

        let ret:{key:string, value:string}[] = [];
        var keys = Object.keys(obj);
        ret.length = keys.length;
        for (let i=0;i<keys.length; i++)
        {
          let key = keys[i];
          let value = obj[key];
    
          ret[i] = {key :key, value:value};
         }   
        
        return ret;
    }

    private getRecoveryArrayFromMap(diagnosticResultRecoveryMap: { [time: string]: RecoveryInfo[]; })
    {
       let recoveriesListGroupedByTime: Array<{time: string, recoveryList: RecoveryInfo[]}> =  new Array();
       var keys = Object.keys(diagnosticResultRecoveryMap);
       for (let i=0;i<keys.length; i++)
       {
         let key = keys[i];
         let value = diagnosticResultRecoveryMap[key];
   
         recoveriesListGroupedByTime.push({time:key, recoveryList:value});
        }   

        return recoveriesListGroupedByTime;
    }

    public getLocalTimeFromUtcTime(utcDate:string)
    {
        let localDate = new Date(utcDate);
        return localDate.toLocaleString();
    }

    public getLocalTimeFromTimeInUnixMilliseconds(timeInms:string)
    {
        
        let utcDate = new Date(timeInms).toString();
        let localDate = new Date(utcDate);
        return localDate.toLocaleString();
    }

    private finalize(subscription: Subscription, errorMsg?: string):void
    {
        if (errorMsg != undefined)
        {
            this.statusMessage = errorMsg;
        }

        this.spinService.displayLoader(false);

        if(subscription)
        {
            subscription.unsubscribe();
        }
    }

    private parseDiagnosticResultFile(diagnosticResultFile:DiagnosticsFiles)
    {
        this.spinService.displayLoader(true);
        let subscription = this.service.GetFile(diagnosticResultFile).subscribe(response => 
            {
                let recoveriesDictionaryFromService = this.getMapFromObject(response);
                if(recoveriesDictionaryFromService && recoveriesDictionaryFromService.length!=0)
                {
                    this.finalize(subscription);
                    let recoverySubscription = this.diagService.GetRecoveriesAsync().subscribe(recoveries => 
                    {
                        recoverySubscription.unsubscribe();
                        this.statusMessage = '';
                        let diagnosticResultRecoveryMap: { [time: string]: RecoveryInfo[]; } = { };
                        recoveriesDictionaryFromService.forEach(
                            item=>{
                                let recoveryId =item.key;
                                let localtime=this.getLocalTimeFromUtcTime(item.value);
                                let recovery = recoveries.filter((rec: RecoveryInfo) => rec.Id==recoveryId)[0];
                                
                                if(!!recovery)
                                {
                                    if(!diagnosticResultRecoveryMap[localtime])
                                    {
                                        let recoverylist : RecoveryInfo[] = new Array();
                                        recoverylist.push(recovery);
                                        diagnosticResultRecoveryMap[localtime]=recoverylist;
                                    }else{
                                        diagnosticResultRecoveryMap[localtime].push(recovery);
                                    }
                                }
                            });

                            this.diagnosticResultRecoveryList = this.getRecoveryArrayFromMap(diagnosticResultRecoveryMap);
                            if(!diagnosticResultRecoveryMap || Object.keys(diagnosticResultRecoveryMap).length==0)
                            {
                                this.diagnosticResultsStatus = 'diagnostic results has recovery found but it doesnt exist in recovery table';
                            }
                    },error =>
                    {
                        this.finalize(recoverySubscription, <any>error);
                    });
                }
            },
            error => 
            { 
                this.finalize(subscription,<any>error);
            }
        );

    }
    
    private parseClientFlights()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        this.spinService.displayLoader(true);
        if(this.isTokenExpired)
        {
            return;
        }
        if ( this.ticketClient.toLocaleLowerCase()!="outlookdesktop" 
        && this.saraTicket.ProductName.toLocaleLowerCase()!="outlook")
        {
            // non outlook desktop currently supposed to be using the new schema
            let subscription = this.service.GetSessionClientFlightsV3(this.saraTicket.DiagnosticSessionId).subscribe(response => 
                {
                    this.diagnosticClientFlightsList = response;
                    if(!this.diagnosticClientFlightsList || this.diagnosticClientFlightsList.length==0)
                    {
                        this.diagnosticClientFlightsStatus = "No client flights found";
                    }
    
                    this.filteredClientFlightsList = this.diagnosticClientFlightsList.filter(
                        (item:FlightInfo)=> {
                            return item.IsEnabled;
                        }
                    );
    
                    this.onlyShowAssignedFlights = true;
                    this.finalize(subscription);
                },
                error => 
                { 
                    this.diagnosticClientFlightsStatus = "No client flights found";
                    this.finalize(subscription, <any>error);
                }
            );
        }
        else {
            let subscription = this.service.GetSessionClientFlights(this.saraTicket.DiagnosticSessionId).subscribe(response => 
                {
                    this.diagnosticClientFlightsList = response;
                    if(!this.diagnosticClientFlightsList || this.diagnosticClientFlightsList.length==0)
                    {
                        this.diagnosticClientFlightsStatus = "No client flights found";
                    }
    
                    this.filteredClientFlightsList = this.diagnosticClientFlightsList.filter(
                        (item:FlightInfo)=> {
                            return item.IsEnabled;
                        }
                    );
    
                    this.onlyShowAssignedFlights = true;
                    this.finalize(subscription);
                },
                error => 
                { 
                    this.diagnosticClientFlightsStatus = "No client flights found";
                    this.finalize(subscription, <any>error);
                }
            );
        }
    }

    private getBlobNameFromFileUrl(fileUrl:string)
    {
        let blobName:string;
        let parts:string[] = fileUrl.split("/");
        if(!!parts && parts.length>=1){
            blobName= parts[parts.length-1];
        }

        return blobName;
    }

    public search(searchTerm : string)
    {
        this.searchTextForClientFlight = searchTerm;
        this.filteredClientFlightsList = this.diagnosticClientFlightsList;
        if (!!this.searchTextForClientFlight)
        {
            this.filteredClientFlightsList = this.diagnosticClientFlightsList.filter(
                (item:FlightInfo)=> {
                    return this.searchField(item.FlightName,searchTerm) || this.searchField(item.FlightValue,searchTerm);
                }
            );
        }

        if(this.onlyShowAssignedFlights == true)
        {
            let result = this.filteredClientFlightsList.filter(
                (item:FlightInfo)=> {
                    return item.IsEnabled;
                }
            );

            this.filteredClientFlightsList = result;
        }
    }

    searchField(fieldValue: string, searchTerm: string) {
        return this.util.searchField(fieldValue, searchTerm);
    }

    public OnShowAssignedFlightsChange(){
        this.onlyShowAssignedFlights = (<HTMLInputElement>document.getElementById("toggleflights")).checked;
        if(this.onlyShowAssignedFlights == true)
        {
            let result = this.filteredClientFlightsList.filter(
                (item:FlightInfo)=> {
                    return item.IsEnabled;
                }
            );
            this.filteredClientFlightsList = result;
        }else{
            this.filteredClientFlightsList = this.diagnosticClientFlightsList;
            if( !!this.searchTextForClientFlight)
            {
                let result = this.filteredClientFlightsList.filter(
                    (item:FlightInfo)=> {
                        return this.searchField(item.FlightName,this.searchTextForClientFlight) || this.searchField(item.FlightValue,this.searchTextForClientFlight);
                    }
                );

                this.filteredClientFlightsList = result;
            }
        }
    }

    private parseRegistry()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        if(!!this.registryTree)
        {
            // If tree exists, no need to call service again.
             return;
        }

        this.showTree = !!this.registryTree && this.registryTree.name!="/";

        this.spinService.displayLoader(true);
        let subscription = this.service.GetSessionRegistry(this.saraTicket.DiagnosticSessionId).subscribe(registries => 
            {
                if(!registries){
                    this.diagnosticRegistryStatus="No registry info found";
                    this.finalize(subscription);
                    return;
                }
                let registriesString= this.util.safelyParseJSON(registries);
                this.registryTree = new TreeNode(registriesString);
                
                this.showTree=!!this.registryTree && this.registryTree.name!="/";
                this.finalize(subscription);
            },
            error => 
            { 
                this.diagnosticRegistryStatus = "No Registry info found";
                this.finalize(subscription,<any>error);
            }
        );
    }

    public clickFileTree(fileNode:TreeNode):void {
        console.log(fileNode);
    }

    public expandALL()
    {
        if(this.registryTree)
        {
            this.expandNode(this.registryTree);
        }
    }

    private expandNode(nodeToExpand:TreeNode)
    {
        nodeToExpand.expand();
        if(nodeToExpand.children.length>0)
        {
            nodeToExpand.children.forEach(element => {
                this.expandNode(element);
            });
        }
    }
    
    public collapseALL()
    {
        if(this.registryTree)
        {
            this.collapseNode(this.registryTree);
        }
    }

    private collapseNode(nodeToCollapse:TreeNode)
    {
        nodeToCollapse.fold();
        if(nodeToCollapse.children.length>0)
        {
            nodeToCollapse.children.forEach(element => {
                this.collapseNode(element);
            });
        }
    }

    public GetFileJson()
    {
        if(!this.JsonViewerfileToOpen)
        {
            return;
        }

        this.toggleJsonViewer = false;
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }
        this.jsonViewerFileContent = null;
        this.spinService.displayLoader(true);
        if(this.JsonViewerfileToOpen.IsCompressed)
        {
            let subscription = this.service.GetFileContent_JSON(this.JsonViewerfileToOpen).subscribe(response => 
                {
                    if(!response){
                        this.jsonViewerStatus = "file is not found or empty;"
                        this.spinService.displayLoader(false);
                        this.finalize(subscription);
                        return;
                    }
    
                    this.jsonViewerFileContent = response;
                    this.spinService.displayLoader(false);
                    this.finalize(subscription);
                },
                error => 
                { 
                    this.finalize(subscription,<any>error);
                }
            );
        }else
        {
            const blobname = this.getBlobNameFromFileUrl(this.JsonViewerfileToOpen.FileUrl);
            let subscription = this.service.GetFileJson(blobname, this.id).subscribe(response => 
                {
                    if(!response){
                        this.jsonViewerStatus = "file is not found or empty;"
                        this.spinService.displayLoader(false);
                        this.finalize(subscription);
                        return;
                    }
    
                    this.jsonViewerFileContent = response;
                    this.spinService.displayLoader(false);
                    this.finalize(subscription);
                },
                error => 
                { 
                    this.finalize(subscription,<any>error);
                }
            );
        }
    }

    public GetAccountInfo()
    {
        if(!this.accountFile) return;
        this.isTokenExpired = this.util.GetIfTokenExpired();
        this.toggleJsonViewer = false;
        if(this.isTokenExpired)
        {
            return;
        }
        this.spinService.displayLoader(true);
        let blobName = this.getBlobNameFromFileUrl(this.accountFile.FileUrl);
        let subscription = this.service.GetFileJson(blobName, this.id).subscribe(response => 
            {
                this.accountInfoFileContent = response;
                this.spinService.displayLoader(false);
                this.finalize(subscription);
            },
            error => 
            { 
                this.finalize(subscription,<any>error);
            }
        );
    }

    public GetCsvFile()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired || !this.csvfileToOpen)
        {
            return;
        }

        this.csvFileContent = null;
        this.spinService.displayLoader(true);
        let subscription = this.service.GetFileContent_Text(this.csvfileToOpen).subscribe(response => 
            {
                this.csvFileContent = response;
                this.spinService.displayLoader(false);
                this.finalize(subscription);
            },
            error => 
            { 
                this.finalize(subscription,<any>error);
            }
        );
    }
    
    public getCSVFileName()
    {
        return this.csvfileToOpen.FileAttributes["friendly-name"];
    }

    public expandALLInJsonViewer()
    {
        this.toggleJsonViewer = true;
    }

    public collapseALLInJsonViewer()
    {
        this.toggleJsonViewer = false;
    }

    public getChatMessage1stLoad()
    {
        let subscription = this.diagService.GetChatMessagesAsync(this.id, null).subscribe(messages => 
            {
                if(!messages || messages.length == 0)
                {
                    // If no messages return, call to retrieve all again
                    this.timer = setInterval(()=>{this.getChatMessages()},null);
                    this.finalize(subscription);
                    return;
                }else{
                    // retrieved all messages as base
                    this.messages=messages;
                     // prepare other info after service reply
                    this.messages.forEach(msg=>{
                        msg.isClient=msg.origin=="User";
                    });
                }
            },error =>
            {
                this.finalize(subscription,<any>error);
            });
    }

    public getChatMessages(lastReadTime?:string)
    {   
        if ( document.hasFocus() ) {
            // only one timer can call service at a time, so once hit inside this function clear the timer immediately
            clearInterval(this.timer);
            let subscription = this.diagService.GetChatMessagesAsync(this.id, lastReadTime).subscribe(messages => 
            {
            // first time call retrieve all messages, lastreadtime is null. 
            if(!lastReadTime)
            {
                if(!messages || messages.length == 0)
                {
                    // If no messages return, call to retrieve all again
                    this.timer = setInterval(()=>{this.getChatMessages()},5000);
                    this.finalize(subscription);
                    return;
                }else{
                    // retrieved all messages as base
                    this.messages=messages;
                }
            }
            
            // if has lastReadTime means going to retrieve only new coming messages, need to concat a list of new message with the previous list
                if(!!lastReadTime)
                {
                    if(!!messages && messages.length>0)
                    {
                        if(messages.length == 1 && messages[0].created_at==this.messages[this.messages.length-1].created_at)
                        {
                        // handle serviec issue
                        }else{
                            this.messages = this.messages.concat(messages); 
                        }
                    }
                }

                // save the last'message's created_at as long type unix millisecond to send back to our service before we convert it to date time
                let readtimeoflastMessage = this.messages[this.messages.length-1].created_at; 

                // prepare other info after service reply
                this.messages.forEach(msg=>{
                        msg.isClient=msg.origin=="User";
                });

                // update ticket status display for in website change
                if(this.saraTicket.TicketStatus!=="resolved"&&this.saraTicket.TicketStatus!=="rejected")
                {
                    let lastMsgAuthorName = this.messages[this.messages.length-1].author["name"];
                    let lastMsgOrigin = this.messages[this.messages.length-1].origin;
                    if(lastMsgOrigin == "User")
                    {
                        this.ticketStatus = "Waiting For Agent";
                    }else if (lastMsgAuthorName != "Automations"){
                        this.ticketStatus = "Agent Replied";
                    }
                }
                
                if(this.saraTicket.TicketStatus!=="resolved"&&this.saraTicket.TicketStatus!=="rejected")
                {
                    this.timer = setInterval(()=>{this.getChatMessages(readtimeoflastMessage);},5000);
                }
                
                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription,<any>error);
            });

        }else{
            console.log("The document doesn't have the focus.");
        }
    }

    public sendMessage() {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let chatReq:ChatRequest= new ChatRequest();
        chatReq.Message=!!this.draftMessage?this.draftMessage.trim():this.draftMessage;
        if (!!this.locale && this.locale.indexOf("en") == -1 && this.draftMessage.length > 5000)
        {
            alert("There is a current limitation of 5,000 characters when responding to an auto-translated chat. Please reduce the response size by breaking the response into multiple messages. Sorry for the inconvenience.");
            return;        
        }

        chatReq.SessionId=this.id;
        
        this.spinService.displayLoader(true);
        let subscription = this.diagService.sendMessageAsAgent(chatReq).subscribe(() =>
            {
                return;
            },error =>
            {
                window.alert(error.Message);
            });

        this.draftMessage = '';
    }

    scrollToBottomChat(): boolean {
        if(!this.chatScrollContainer)
        {
            return false;
        }

        this.chatScrollContainer.nativeElement.scrollTop = this.chatScrollContainer.nativeElement.scrollHeight;
        return true;
    }

    scrollToBottomNote(): boolean {
        if(!this.noteScrollContainer)
        {
            return false;
        }

        this.noteScrollContainer.nativeElement.scrollTop = this.noteScrollContainer.nativeElement.scrollHeight;
        return true;
    }

    public onUpdateTicketStatus(updateTo:string)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let ticketRequest:TicketRequest = new TicketRequest();
        ticketRequest.SessionId = this.id;
        if(updateTo=="Rejected")
        {
            ticketRequest.Status = "Rejected";
        }
        else if(updateTo=="Resolved")
        {
            ticketRequest.Status = "Resolved";
        }else{
            return;
        }

        if(confirm("Are you sure you want to close the ticket?")) {
            let subscription = this.diagService.UpdateTicketAsync(ticketRequest).subscribe(isSuccess => 
                {
                    if(!isSuccess)
                    {
                        // If update failed reset
                        this.TicketStatusMapping(this.saraTicket);
                        this.ticketStatus = this.saraTicket.UXTicketStatus;
                    }
                    else{
                        this.ticketStatus = "Closed";
    
                        // Add a note to this session for this change
                        this.addNoteOnChange(updateTo + " ticket");
                    }
    
                    this.finalize(subscription);
                },error =>
                {
                    this.finalize(subscription,error);
                });
        }
    }

    public onUpdateTicketAssignee(newAssigneeEmailToUpdate:string)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        if(!newAssigneeEmailToUpdate)
        {
            return;
        }

        let ticketRequest:TicketRequest = new TicketRequest();
        ticketRequest.SessionId = this.id;
        ticketRequest.AssigneeEmail = newAssigneeEmailToUpdate;
        let subscription = this.diagService.UpdateTicketAsync(ticketRequest).subscribe(isSuccess => 
            {
                if(isSuccess)
                {
                    this.saraTicket.TicketAssignee = newAssigneeEmailToUpdate;
                   
                    // Add a note to this session for this change
                    this.addNoteOnChange("Reassigned ticket to: "+ newAssigneeEmailToUpdate);
                }

                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription,error);
            });
    }

    public addNewTagToTicket()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let ticketRequest:TicketRequest = new TicketRequest();
        ticketRequest.SessionId = this.id;
        let currentTags = this.saraTicket.Tags;
        if(!this.newTagToAdd || currentTags.indexOf(this.newTagToAdd)>-1)
        {
            // New tag value is null or empty, or it already exists
            return;
        }else
        {
            this.saraTicket.Tags.push(this.newTagToAdd);
            ticketRequest.Tags = this.saraTicket.Tags;
        }

        let subscription = this.diagService.UpdateTicketAsync(ticketRequest).subscribe(isSuccess => 
            {
                if(isSuccess)
                {
                    // Add a note to this session for this change
                    this.addNoteOnChange("Added tag: "+ this.newTagToAdd.toUpperCase());
                }
                else
                {
                    this.saraTicket.Tags = this.saraTicket.Tags.filter(item => item!== this.newTagToAdd);
                }
                
                this.newTagToAdd="";
                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription,error);
            });
    }

    public onBulkDownload()
    {      
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        this.bulkDownloadInProgress=true;
        this.service.FileBulkDownloadAsync(this.id).subscribe(
            response => 
            {
                let file = response.body;
                this.bulkDownloadInProgress=false;
                saveAs(file, "bulkdownload_"+this.id +'.zip');
            },
            error => 
            { 
            }
        );
    }

    public getAliasFromEmail(email:string){
        if(!email)
        {
            return;
        }
        let parts:string[] = email.split("@");
        return parts[0];
    }

    public onAddTicketNote()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.util.GetIfTokenExpired())
        {
            return;
        }

        if(!this.draftNote){
            return;
        }

        let req:TicketNoteRequest = new TicketNoteRequest();
        req.Note = this.draftNote;
        req.SessionId = this.id;
        this.SendPrivateNote(req);

        this.draftNote="";
    }

    public addNoteOnChange(noteContent:string)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }
        if(!!noteContent){
            let req:TicketNoteRequest = new TicketNoteRequest();
            req.Note = noteContent;
            req.SessionId = this.id;
           this.SendPrivateNote(req);
        }
    }

    private SendPrivateNote(req:TicketNoteRequest)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let subscription = this.diagService.AddTicketNotesAsync(req).subscribe(response => 
            {
                if(response==true)
                {
                    let subscriptionForGet = this.diagService.GetTicketNotesAsync(this.id).subscribe(notes => 
                    {
                        this.ticketNotes = notes;
                        this.scrollToBottomNote();
                        this.finalize(subscriptionForGet);
                    },error =>
                    {
                        this.finalize(subscriptionForGet, <any>error);
                    });
                }
                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription, <any>error);
            });
    }

    public GetTicketBugs()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let subscription1 = this.diagService.GetTicketAssociatedBugsAsync(this.id).subscribe(bugs => 
            {
                this.ticketBugs = bugs;
                this.ticketBugs.forEach(bug=>{
                    bug.BugLink = "https://"+ bug.Database +".visualstudio.com/OC/_workitems/edit/"+bug.BugNumber;
                });
                this.finalize(subscription1);
            },error =>
            {
                this.finalize(subscription1, <any>error);
            });
    }

    public GetAllBugs()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let retrieveBugsWithInDays = "30";
        let subscription2 = this.diagService.GetAllBugsFromTableAsync(this.id, retrieveBugsWithInDays).subscribe(bugs => 
            {
                this.allBugs = bugs;
                this.allBugs.forEach(bug=>{
                    bug.BugLink = "https://"+ bug.Database +".visualstudio.com/_workitems/edit/"+bug.BugNumber;
                });
                this.finalize(subscription2);
            },error =>
            {
                this.finalize(subscription2, <any>error);
            });
    }

    public onAddExistingBugToTicket(bug?:TicketBug)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let req:TicketBugAssociationRequest = new TicketBugAssociationRequest();
        req.SessionId = this.id;
        req.BugNumber = bug.BugNumber;
        req.Database = bug.Database;
        req.Title = bug.Title;
        req.IsExternal = bug.IsExternal;

        let subscription = this.diagService.AddTicketBugRelationAsync(req).subscribe(response => 
        {
            if(response==true)
            {
                (<HTMLInputElement>document.getElementById("newBugDB")).value= "";
                (<HTMLInputElement>document.getElementById("newBugNumber")).value= "";
                (<HTMLInputElement>document.getElementById("newBugTitle")).value= "";
                
                this.GetTicketBugs();
                this.GetAllBugs();
            }
            this.finalize(subscription);
        },error =>
        {
            this.finalize(subscription, <any>error);
        });
    }

    public onCreateNewBugToTicket()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

            let newBugDB = (<HTMLInputElement>document.getElementById("newBugDB")).value;
            let newBugNumber = (<HTMLInputElement>document.getElementById("newBugNumber")).value;
            
            // if bug already exist in table skip
            let result = this.allBugs.filter((existingBug:TicketBug)=>{
                return existingBug.BugNumber == newBugNumber && existingBug.Database == newBugDB;
            })
            if(!!result && result.length>0)
            {
                alert("Bug to be added already exists in our table, find it in the Select From Exisiting Bugs area and associate.")
                return;
            }

            let newBugTitle = (<HTMLInputElement>document.getElementById("newBugTitle")).value;
            let newBusIsExternal = (<HTMLInputElement>document.getElementById("newBugIsExternal")).checked;
            if(!!newBugDB && !!newBugNumber && !!newBugTitle)
            {
                let req:TicketBugAssociationRequest = new TicketBugAssociationRequest();
                req.SessionId = this.id;
                req.Database = newBugDB;
                req.BugNumber = newBugNumber;
                req.Title = newBugTitle;  
                req.IsExternal = newBusIsExternal;
                let subscription = this.diagService.AddTicketBugRelationAsync(req).subscribe(response => 
                    {
                        if(response==true)
                        {
                            this.onCreateReset()
                            this.GetTicketBugs();
                            this.GetAllBugs();
                        }
                        this.finalize(subscription);
                    },error =>
                    {
                        this.finalize(subscription, <any>error);
                    });
            }else{
                return;
            }
    }

    public onCreateNewBug()
    {
        document.getElementById("beforeAddingBug").style.display='none';
        document.getElementById("AddingBug").style.display='table-row';
    }

    public onCreateReset()
    {
        (<HTMLInputElement>document.getElementById("newBugDB")).value="";
        (<HTMLInputElement>document.getElementById("newBugNumber")).value="";
        (<HTMLInputElement>document.getElementById("newBugIsExternal")).checked=false;
        (<HTMLInputElement>document.getElementById("newBugTitle")).value="";
        document.getElementById("beforeAddingBug").style.display='table-row';
        document.getElementById("AddingBug").style.display='none';
    }
    public onRemoveBugFromTicket(bug:TicketBug)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        let req:TicketBugAssociationRequest = new TicketBugAssociationRequest();
        req.SessionId = this.id;
        req.BugNumber = bug.BugNumber;
        req.Database = bug.Database;
        req.Title = bug.Title;
        let subscription = this.diagService.DeleteTicketBugRelationAsync(req).subscribe(response => 
        {
            if(response==true)
            {
                this.GetTicketBugs();
                this.GetAllBugs();
            }
            this.finalize(subscription);
        },error =>
        {
            this.finalize(subscription, <any>error);
        });
    }

    public onDeleteBug(bug:TicketBug)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        if(confirm("Are you sure you want to delete bug # "+bug.BugNumber+"? Deleting this bug will unlink it from all support tickets.")) {
            let subscription = this.diagService.DeleteBugFromTableAsync(bug).subscribe(response => 
            {
                if(response==true)
                {
                    this.GetTicketBugs();
                    this.GetAllBugs();
                }

                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription, <any>error);
            });
        }
    }

    public onUpdateBug(bug:TicketBug)
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        bug.Title = (<HTMLInputElement>document.getElementById(bug.Database+bug.BugNumber+"newtitle")).value;
        bug.IsExternal = (<HTMLInputElement>document.getElementById(bug.Database+bug.BugNumber+"external")).checked;
        let subscription = this.diagService.UpdateBugFromTableAsync(bug).subscribe(response => 
            {
                if(response==true)
                {
                    document.getElementById(bug.Database+bug.BugNumber+"pencil").style.display='inline'; //show
                    document.getElementById(bug.Database+bug.BugNumber+"link").style.display='inline'; //show 
                    document.getElementById(bug.Database+bug.BugNumber+"delete").style.display='inline'; //show 
                    document.getElementById(bug.Database+bug.BugNumber+"ok").style.display='none'; //hide
                    document.getElementById(bug.Database+bug.BugNumber+"withdraw").style.display='none'; //hide
                    document.getElementById(bug.Database+bug.BugNumber+"oldtitle").style.display='inline'; //show 
                    document.getElementById(bug.Database+bug.BugNumber+"newtitle").style.display='none'; //hide
                    this.GetTicketBugs();
                    this.GetAllBugs();
                }
    
                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription, <any>error);
            });
    }

    public onTriggerEditBug(bug:TicketBug)
    {
        document.getElementById(bug.Database+bug.BugNumber+"pencil").style.display='none'; //hide 
        document.getElementById(bug.Database+bug.BugNumber+"link").style.display='none'; //hide 
        document.getElementById(bug.Database+bug.BugNumber+"delete").style.display='none'; //hide 
        document.getElementById(bug.Database+bug.BugNumber+"ok").style.display='inline'; //show
        document.getElementById(bug.Database+bug.BugNumber+"withdraw").style.display='inline'; //show
        document.getElementById(bug.Database+bug.BugNumber+"oldtitle").style.display='none'; //hide 
        document.getElementById(bug.Database+bug.BugNumber+"newtitle").style.display='inline'; //show
        (<HTMLInputElement>document.getElementById(bug.Database+bug.BugNumber+"newtitle")).value=bug.Title; //show default old title 
    }

    public onWithdrawUpdate(bug:TicketBug)
    {
        document.getElementById(bug.Database+bug.BugNumber+"pencil").style.display='inline'; //show 
        document.getElementById(bug.Database+bug.BugNumber+"link").style.display='inline'; //show 
        document.getElementById(bug.Database+bug.BugNumber+"delete").style.display='inline'; //show 
        document.getElementById(bug.Database+bug.BugNumber+"oldtitle").style.display='inline'; //show 

        document.getElementById(bug.Database+bug.BugNumber+"ok").style.display='none'; //hide
        document.getElementById(bug.Database+bug.BugNumber+"withdraw").style.display='none'; //hide
        document.getElementById(bug.Database+bug.BugNumber+"newtitle").style.display='none'; //show
        (<HTMLInputElement>document.getElementById(bug.Database+bug.BugNumber+"newtitle")).value="";
        (<HTMLInputElement>document.getElementById(bug.Database+bug.BugNumber+"external")).checked = bug.IsExternal;
    }

    public GetSessionAuthType()
    {
        let sub = this.diagService.GetSessionAuthType(this.id).subscribe(response => 
            {
                this.authType = response;
                if(!!this.authType && this.authType.toLowerCase()=="sessionauth")
                {
                    this.authMsg = "* This user has not been authenticated by the system, so his/her identity is not confirmed. Please do not divulge confidential information about the user's account."; 
                    
                }else{
                    this.resizewindowoffset = 300;
                }

                this.finalize(sub);
            },error =>
            {
                this.finalize(sub, <any>error);
            });
    }

    public onSelectChatRecovery()
    {
        if(!!this.selectedChatRecovery)
        {
            this.draftMessage = this.selectedChatRecovery;
            this.selectedCannedResponse = '';
        }
    }

    public onSelectCannedResponse()
    {
        if(!!this.selectedCannedResponse)
        {
            this.draftMessage = this.selectedCannedResponse;
            this.selectedChatRecovery = '';
        }
    }

    public toggerDCRTree()
    {
        this.toggerDCR = !this.toggerDCR;
    }
    public onRouteWithDataForAddingRule()
    {
        if(!this.id)
        {
            return;
        }

        this.router.navigate(['/custombuckets/0/0', {sessionid: this.id} ]);
    }

    public TicketStatusMapping(item:SaraTicket)
    {
        let ticketStatusInLowerCase = item.TicketStatus.toLowerCase();
        if (ticketStatusInLowerCase.indexOf("waiting")!=-1&&ticketStatusInLowerCase.indexOf("agent")!=-1)
        {
            item.UXTicketStatus = "Waiting For Agent";
        }
        else if (ticketStatusInLowerCase.indexOf("agent")!=-1&&ticketStatusInLowerCase.indexOf("replied")!=-1)
        {
            item.UXTicketStatus = "Agent Replied";
        }
        else if (ticketStatusInLowerCase == "resolved" || ticketStatusInLowerCase == "rejected")
        {
            item.UXTicketStatus = "Closed";
        }
        else if (ticketStatusInLowerCase.indexOf("new")!=-1 || ticketStatusInLowerCase == "pending" ||ticketStatusInLowerCase == "created")
        {
            item.UXTicketStatus = "New";
        }else
        {
            item.UXTicketStatus = item.TicketStatus;
        }
    }

    public AuthUserAndRefileTicket()
    {
        let subscription = this.diagService.AuthUserAndRefileTicket(this.saraTicket.DiagnosticSessionId).subscribe(response => 
            {
                if(!!response)
                {
                    this.saraTicket = response;
                    window.location.reload();
                }
                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription, <any>error);
            });
    }

    public AutoTranslationOverrideOnSession()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }

        this.spinService.displayLoader(true);
        let subscription = this.service.AutoTranslationOverrideOnSession(this.saraTicket.DiagnosticSessionId, this.disableAutoTranslationFlight).subscribe(response => 
            {
                this.spinService.displayLoader(false);
                if(!!response)
                {
                    let sessionAttributes = this.getMapFromObject(response.DiagnosticSessionAttributes);
                    if (!!sessionAttributes&&!!sessionAttributes.filter(item=> item.key==this.AutoTranslationOverrideKeyName))
                    {
                        let disableAutoTranslation = sessionAttributes.filter(item=> item.key==this.AutoTranslationOverrideKeyName)[0];
                    }
                }
                this.finalize(subscription);
            },error =>
            {
                this.spinService.displayLoader(false);
                this.finalize(subscription, <any>error);
            });
    }

    public goback()
    {
        this.isTokenExpired = false;
    }

    public refresh()
    {
        window.location.reload();
    }

    public toggleChat() {
        this.openChat=!this.openChat;
        if(this.openChat)
        {
            document.getElementById("chatWindow").style.width = "50%";
            document.getElementById("contentArea").style.width = "50%";
        }else{
            if (!!document.getElementById("chatWindow"))
            {
                document.getElementById("chatWindow").style.width = "0px";
            }
            if (!!document.getElementById("chatWindow"))
            {
                document.getElementById("contentArea").style.width = "100%";
            }
        }
    }

    private getAllTags()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }
        this.spinService.displayLoader(true);
        let sub = this.service.GetTicketTagListAsync().subscribe(
            response => {
            this.allTags = response;
            this.finalize(sub);
            this.spinService.displayLoader(false);
            },
            error => {
                this.finalize(sub);
                this.spinService.displayLoader(false);
            });
    }

    private getPrivateNotes()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }
        this.spinService.displayLoader(true);
        let subscription = this.diagService.GetTicketNotesAsync(this.id).subscribe(notes => 
            {
                this.ticketNotes = notes;
                this.spinService.displayLoader(false);
                this.finalize(subscription);
            },error =>
            {
                this.spinService.displayLoader(false);
                this.finalize(subscription, <any>error);
            });
    }

    private getDataCollections()
    {
        this.isTokenExpired = this.util.GetIfTokenExpired();
        if(this.isTokenExpired)
        {
            return;
        }
        this.spinService.displayLoader(true);
        this.queryParamsForRule = {
            sessionid:this.id
        };
        let subscription = this.service.GetDataCollectorResult(this.id).subscribe(res => 
            {
                this.dcrJSON = res;
                this.spinService.displayLoader(false);
                this.finalize(subscription);
            },error =>
            {
                this.spinService.displayLoader(false);
                this.dcrError = "Cannot load structured data collector results. Please refer to Json Viewer -> Diagnosis Properties-> DataCollectorResults"
                this.finalize(subscription);
            });
    }
}
