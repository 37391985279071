import {SpinnerService} from '../shared/services/spinner.service';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import saveAs from 'save-as';
import { DomSanitizer } from '@angular/platform-browser';
import { WfsService } from '../shared/services/wfs.service';
import { DiagnosticsFiles } from '../shared/models/WfsModels';
@Component({
  selector: 'app-diagnosticlog',
  templateUrl: './diagnosticlog.component.html',
  styleUrls: ['../app.component.css']
})
export class DiagnosticlogComponent implements OnInit {
  public downloadInProgress:boolean=false;
  public id:string;
  public fileId:string;
  public message:string;
  public errorCode:string;
  constructor(private route: ActivatedRoute, 
    private service: WfsService,
    private spinService:SpinnerService,
    public sanitizer: DomSanitizer){
    }

  ngOnInit() {
    this.id = this.route.snapshot.params['Id'];
    this.fileId = this.route.snapshot.params['FileId'];
    this.onDownload();
  }

  public onDownload()
  {      
        this.spinService.displayLoader(true);
        this.service.GetSaraDiagnosticsFiles(this.id).subscribe(
          files => 
          {
            
            if (files == null || files.length == 0 )
            {
              this.message = "File cannot be found."
              this.downloadInProgress = false;
              return;
            }

            let fileObj = files.filter((file:DiagnosticsFiles)=> {
              return file.FileId.toLowerCase()==this.fileId.toLowerCase();
            });
            
            if (fileObj == null || fileObj.length == 0 )
            {
              this.message = "File cannot be found."
              this.downloadInProgress = false;
              return;
            }

            let item = fileObj[0];
            const fileUrl = item.FileUrl.substring(0,item.FileUrl.indexOf('v2/file')).concat('v1/filedownload/',this.id).concat('/',item.FileId);
            this.service.DownloadFile(fileUrl, this.id).subscribe(
              response => 
              {
                  this.spinService.displayLoader(false);
                  item.DownloadInProgress = false;
                  let fileType = item.FileType;
                  if (!!item.IsCompressed)
                  {
                      fileType = "zip";
                  }
                  
                  saveAs(response.body, item.FileName + '.' + fileType);
                  this.downloadInProgress=false;
              },
              error => 
              { 
                  this.downloadInProgress=false;
                  this.spinService.displayLoader(false);
                  this.message = error;
                }
            );
          },
          error => 
          { 
            this.downloadInProgress=false;
            this.spinService.displayLoader(false);
            this.message = error;
          }
        );
  }     

}
