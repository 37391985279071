import { Component, OnInit, Directive } from '@angular/core';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute} from '@angular/router';
import { SpinnerService } from '../shared/services/spinner.service';
import { GlobalService } from '../shared/services/global.service';
@Component({
  selector: 'app-changerequest-detail',
  templateUrl: './changerequest-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class ChangeRequestDetailComponent implements OnInit {
  public success:boolean = false;
  public id:string;
  public type:string;
  public decision:string;
  public statusMessage:string;
  public objectUrl:string;
  public client:string;

  constructor( private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private spinService:SpinnerService,
    private globalService:GlobalService)  {
      this.client=this.globalService.getClient();
     }

  ngOnInit() {
    this.id = this.route.snapshot.params['Id']; 
    this.type = this.route.snapshot.params['Type']; 
    this.decision = this.route.snapshot.params['Decision']; 
    this.spinService.displayLoader(true);
    let sub = this.service.ExecuteChangeRequest(this.id,this.type,this.decision, this.client).subscribe(
      response => {
          if (!!response)
          {
            this.success = true;
            this.decision = response.RequestStatus;
            this.objectUrl = response.DiagPortalLink;
          }

          this.spinService.displayLoader(false);
          this.finalize(sub);
      },
      error => {
        this.spinService.displayLoader(false);
        this.finalize(sub, error);
      });
  }



  private finalize(subscribtion:Subscription, errorMsg?: string): void {
    if (errorMsg != undefined) {
        this.statusMessage = errorMsg;
    }

    if (subscribtion) {
        subscribtion.unsubscribe();
    } 
  }
}
