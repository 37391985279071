import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Msal } from '../services/msal.service';
import { RecoveryElement } from '../models/WfsModels';

@Injectable()
export class Utils {
    
    constructor(private msal:Msal)
    {
    }

    public GetIfTokenExpired()
    {
        let isTokenExpired = this.msal.isUserAuthenticated && this.msal.IsTokenExpired();
        return isTokenExpired;
    } 

    public SignInBackIfTokenExpired()
    {
        if(this.GetIfTokenExpired())
        {
            this.msal.logout();
            this.msal.loginRedirect();
        }
    }

    public GetRequestHeaders(saraSessionId?:string): HttpHeaders
    {   if(!saraSessionId)
        {
            saraSessionId = this.GenerateGuid();
        }
        
        let token = this.msal.getToken();
        let header =  new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set("Authorization", " Bearer " + token)
        .set("x-ms-sara-api-version", "schema-v2")
        .set("SaraSessionId", saraSessionId);

        return header;
    }

    public GetRequestHeadersWithoutSessionId(): HttpHeaders
    {  
        let token = this.msal.getToken();
        let header =  new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set("Authorization", " Bearer " + token)
        .set("x-ms-sara-api-version", "schema-v2")

        return header;
    }

    public GenerateGuid(): string
    {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now == "function") 
        {
            d += performance.now(); //use high-precision timer if available
        }

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => 
        {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    public getAliasFromEmail(email:string){
        if(!email)
        {
            return;
        }
        
        let parts:string[] = email.split("@");
        return parts[0];
    }


    public ValidUrl(str: string) 
    {
        var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (regex.test(str)) {
            return true;
        } else {
            return false;
        }
    }
    
    public ValidBuildVersion(str)
    {
        var regex = /^\d+(\.\d+){3}$/;
        if (regex.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    public IsGuid(customQuery: string) : boolean
    {
        let  regexp:RegExp = new RegExp("^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$");
        return regexp.test(customQuery);
    }

    public StringContainsHtmlTags(str:string)
    {
        var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
        return isHTML(str);
    }

    public ValidNumberLetterOnly(str)
    {
        var regex = /^[a-zA-Z0-9]*$/;
        if (regex.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    public ValidNumberOnly(str)
    {
        var regex = /^[0-9]*$/;
        if (regex.test(str)) {
            return true;
        } else {
            return false;
        }
    }
    
    public ValidEmail(str: string) 
    {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    public getLocalTimeFromUtcTime(utcTime:Date)
    {
      if(!utcTime)
      {
        return null;
      }
  
      let utcDate = utcTime.toString();
      let localDate = new Date(utcDate);
      return localDate.toLocaleString();
    }

    public searchField(fieldValue: string, searchTerm: string) {
        if(!fieldValue && !searchTerm)
        {
            return true;
        }
        else if (!!fieldValue && !!searchTerm)
        {
            fieldValue=fieldValue.trim().toLowerCase().replace(/\n/ig, '').replace(/\s+/g, "");
            searchTerm = searchTerm.trim().toLowerCase().replace(/\n/ig, '').replace(/\s+/g, "");
            return fieldValue.indexOf(searchTerm)>-1 ||  fieldValue.includes(searchTerm);
        }
        else
        {
            return false;
        }
    }

    public safelyParseJSON (json) {
        try 
        {
            return JSON.parse(json);
        } 
        catch (e) {
            return null;
        }
    }

    public isValidJSONForRichRecoveries (json) {
        try {
            if(!json) {
                return true; // handles the case where the text may be undefined
            }
            if (json) {
                let recoveryElements:RecoveryElement[] = JSON.parse(json) as RecoveryElement[];
                if(recoveryElements.length > 0) {
                    return true;
                }
            }
               
            return false;
        }
        catch (e) {
            return false;
        }
    }
    
    public getDate(dateString):string {
        let utc:Date = new Date(dateString);
        let year = utc.getFullYear();
        let month = utc.getMonth();
        let day = utc.getDate();
        let hours = utc.getHours();
        let minutes = utc.getMinutes();
        let seconds = utc.getSeconds();
        var utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds)).toUTCString();
        let utcstr = utcDate.toString();
        let localDate = new Date(utcstr);
        return localDate.toLocaleString();
      }
}