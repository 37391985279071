import { Utils } from '../Utils/utils';
import { Subscription, Observable } from 'rxjs/Rx';
import {ActivatedRoute, Router} from '@angular/router';
import { DiagDefinitionService } from '../services/diagdef.service';
import { AuditHistory} from '../models/WfsModels';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { SpinnerService } from '../services/spinner.service';
@Component({
    selector: 'history-display',
    templateUrl: './historydisplay.component.html',
    inputs: ['history'],
    styleUrls: ['../../app.component.css']
})
export class HistoryDisplayComponent implements OnInit
{
    @Input('history') history: AuditHistory[];
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute, 
        private service: DiagDefinitionService, 
        private router: Router, 
        private spinnerService: SpinnerService,
        private util: Utils) {
    }
    
    ngOnInit() {
    }

    ngOnDestroy() {
    }
}