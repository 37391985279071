import { TSMap } from "typescript-map";

export class Options
{
    lastPoints: number;
    avgPoints: number;
    reversed: boolean;
    avgMinimum: number;
}

export class SaraTicket
{
    DiagnosticSessionId: string;
    Symptom: string;
    ProblemStatement: string;
    Comments: string;
    UserName: string;
    UserUpn: string;
    UserUpnHash: string;
    UserLocale: string;
    UserAgent: string;
    ProductName: string;
    ProductVersion: string;
    OperatingSystem: string;
    OperatingSystemVersion: string;
    DeviceName: string;
    DiagnosticSessionAttributes: KeyValuePair[];
    UserSMTPEmail: string;
    ContactSMTPEmail: string;
    PhoneNumber: string;
    UserPuid: string;
    TenantId: string;
    Payload: string;
    IsTicket: boolean;
    TicketId: string;
    TicketTier: string;
    TicketStatus: string;
    UserAcknowledged: boolean;
    DiagnosticsUrl: string;
    Tags:string[];
    Source: string;
    CreatedTime: Date;
    LastModifiedTime: Date;
    DiagnosticsFiles: DiagnosticsFiles[];
    TicketAssignee:string;
    IsVip:boolean;
    HelpshiftDomain:string;
    TicketingSystemUrl:string;
    TicketingSystem:string;
    UXTicketStatus:string;
    InternalSessionAttributes : KeyValuePair[];
    ChatLastModifiedTime : string;
    ChatLastModifiedTimeInDate:Date;
    DataClassification:string;
}

export class DiagnosticsFiles
{
    ClientName: string;
    FileAttributes:{[key:string]:string};
    Description:string;
    FileName:string;
    FileType:string;
    FileUrl:string;
    IsSasUpload:boolean;
    SessionId:string;
    IsFileUploadedFromClient:boolean;
    DownloadInProgress: boolean;
    CreatedTime: Date;
    IsCompressed: boolean;
    FileSizeInKB:number;
    FileId:string;
    IsUploadComplete:boolean;
    displayMagnifier:boolean;
}

export class CrashInfo
{
    FailureId: string;
    ChannelIdAndOfficeMajorVersion: string;
    FixBuild: string;
    FixComponent: string;
    FixPatchForwardLinkId: number;
    MoreInfoForwardLinkId: number;
    WorkItemNumber: string;
    Recoveries: string[];
    Status: string;
    RecoveryType: string;
    RecoveryAudience: string[];
}

export class CrashPortalContract{
    CrashResolutionData: CrashInfo;
    Recovery: RecoveryInfo;
    ChangeRequest:ChangeRequest;
    AuditHistory:AuditHistory[];
}

export class ProblemSignature
{
    Client:string;
    CreatedBy:string;
    CreatedTime: Date;
    FriendlyName:string;
    Id:string;
    LastModifiedTime:Date;
    ProblemSignatureXml: string;
    Recoveries:string[];
    Checks:string[];
    SymptomIds:string[];
    RecoveryAudience:string[];
    Tags:string[];
    Attributes:object;
    Keywords:string;
    PrivateNote:string;
    RecoveryActions:RecoveryAction[];
    SendEmail:string[];
}

export class RecoveryAction
{
    Id:string;
    Params:KeyValuePair[];
}

export class ProblemSignaturePortalContract
{
    ProblemSignature:ProblemSignature;
    Recovery:RecoveryInfo;
    ChangeRequest:ChangeRequest;
    AuditHistory:AuditHistory[];
}

export enum RecoveryElementType
{
    BodyText = "BodyText",
    Image = "Image",
    Link = "Link",
    List = "List",
    Notes = "Notes",
    NumberedList = "NumberedList",
    Subtitle = "Subtitle",
    Video = "Video",
}

export class RecoveryElement
{
    Type:RecoveryElementType;
    Elements:string[];
}

export class RecoveryImageInfo 
{
    FileContent:string;
    FileName:string;
}

export class RecoveryInfo
{
    AdminMsg:string;
    Area:string;
    Audience:string[];
    Authoritative:boolean;
    ContactAdmin:boolean;
    CreatedBy:string;
    Description:string;
    HasAFix:boolean;
    Id:string;
    Link:string;
    Name:string;
    ProblemDescription:string;
    RecoverySteps:string;
    RecoveryTitle:string;
    RecoveryState:number;
    SupportMsg:string;
    Type:string;
    UserMsg:string;
    LocStatus:string;
    SymptomPath:string;
    Actions:string[];
    RecoveryElements:RecoveryElement[];
}

export class KeyValuePair
{
    [key:string]:string;
}

export class KeyValue{
    key:string;
    value:string;
}

export class RegistryType
{
    Path: string;
    Root: string;
    Subkeys: RegistrySubkey[];
}

export class RegistrySubkey
{
    KeyName:string;
    KeyType:string;
    KeyValue:string;
}

export class ChatMessage
{
    author:KeyValuePair[];
    body:string;
    created_at:string;
    id:string;
    origin:string;
    state:string;
    type:string;
    authorName:string;
    englishbody:string;
    translated:boolean;
    isClient:boolean; // Synthetic/UI-local - known only to the UI layer - the ODS servers have no awareness of this
}

export class ChatRequest
{
    SessionId:string;
    Message:string;
}

export class TicketRequest
{
    SessionId:string;
    Status:string;
    AssigneeEmail: string;
    Tags:string[];
}

export class Agent{
    DiagPortalEmail:string;
    HelpshiftEmail:string;
    HelpshiftProfileId:string;
}

export class TicketQueueInfo{
    QueueID:string;
    QueueLabel:string;
    DisplayName:string;
}

export class TicketNote
{
    author:string;
    created_at:string;
    body:string;
}

export class TicketNoteRequest
{
    SessionId:string;
    Note:string;
}

export class TicketBug
{
    BugNumber:string;
    Database:string;
    Title:string;
    TicketSessionList:string[];
    OpenedDate:string;
    IsExternal:boolean;
    BugLink:string;
}

export class TicketBugAssociationRequest
{
    SessionId:string;
    BugNumber:string;
    Title:string;
    Database:string;
    IsExternal:boolean;
}

export class ChangeRequest
{
    Id:string;
    Type:string;
    Requestor:string;
    ParentIds:string[];
    RequestStatus:string;
    Timestamp: Date;
    DiagPortalLink:string;
    ChangeObject:any;
}

export class AuditHistory
{
    Id :string;
    TimeInTicks :string;
    Changes :string;
    User :string;
    Status :string;
    Timestamp:Date;
}

export class PayloadFlightState
{
    FeatureName:string;
    FeatureState:string;
    IsOverriden:boolean;
}

export class PreviewPlaceConfig
{
    Client:string;
    Audience:string;
    Channel:string;
    BuildRange:string;
    PreviewPlaceArticleInfoTableRowKey:string;
}

export class PreviewPlace
{
    ArticleId:string;
    Name:string;
    Link:string;
    Flights:string;
    Configs:PreviewPlaceConfig[];
}

export class CrashRecovery
{
    Client:string;
    FailureCondition:string;
    FriendlyName:string;
    RecoveryId:string;
    Audience:string[];
    CreatedTime:Date;
    LastModifiedTime:Date;
}

export class CrashRecoveryPortalContract
{
    CrashRecovery:CrashRecovery;
    Recovery:RecoveryInfo;
    ChangeRequest:ChangeRequest;
    AuditHistory:AuditHistory[];
}

export class ParameterSchemaNode{
    Name:string;
    RuleType:string;
    Children:ParameterSchemaNode[];
    ParameterName:string;
    FieldPath:string;
    expanded: boolean;
}

export class ETLRule{
    ProviderName:string;
    EventName:string;
    EventPayloadFields:string;
    Count:string;
}

export class JSONSIMPLERule{
    ParameterName:string;
    FieldPath:string;
    FieldValue:string;
    Operator:string;
    EntirePath:string;
}

export class IncidentTableEntity
{
    Incident:Incident;
    LastChecked:Date
}

export class Incident
{
    Id: string;
    Title: string;
    ImpactDescription:string;
    StartDate: Date;
    EndDate:Date;
    LastUpdatedTime:Date;
    Classification:string;
    ClassificationIcon:string;
    Posts:Message[];
    IsClosed:boolean;
    FriendlyId:string;
    AffectedWorkload:Workload[];
    CMEngagedTime:Date;
    FeatureGroupName:string;
    FeatureName:string;
    IsHighImpact:boolean;
    LastPostTime:Date;
}

export class Workload
{
    Name:string;
    Id:number;
    DisplayName:string;
    CloudType:string;
}

export class Message{
    CreatedTime:Date;
    Message:string;
}

export class IncidentPortalContract{
    Incident:Incident;
    ChangeRequest:ChangeRequest;
    AuditHistory:AuditHistory[];
}

export class SupportEligibilityUserContract{
    SupportEligibilityUser:SupportEligibilityUser;
    AuditHistory:AuditHistory[];
}

export class SupportEligibilityUser{
    Upn:string;
    IsEligible:boolean;
    LastModifiedTime:Date;
    Client:string;
}

export class NotificationFeedbackTableData
{
    PartitionKey :string;
    RowKey :string;
    UserUPN :string;
    SessionId:string;
    OverallFeedback:string;
    Rating :number;
    VerbatimFeedback :string;
    NotificationIds:string; 
    TenantId:string;
}

export class StringMapping{
    Id:string;
    Locale:string;
    StringType:string;
    Message:string;
    LocalizationType:string;
    LastModifiedTime:Date;
    TimeStamp:Date;
    Status:string;
}

export class  LocDictionary {
    [key:string]: StringMapping[];
}

export class LocMapByField
{
    FieldName: string;
    StringMappingList:StringMapping[];
    expandList:boolean;
    AvaliableLocales:string[];
    MissingLocales:string[];
    LocSource:string;
}

export class BulkUploadResult
{
    m_Item1:RecoveryInfo[];
    m_Item2:string[]
}

export class NGramEntry
{
    Date:Date;
    Symptom:string;
    Trigram:string;
    Frequency:number;
    Total:number;
}
export class ProblemSignatureVsSessionRuleMatchResult
{
    m_Item1:boolean;
    m_Item2:string[];
}

export class  sessionRuleMatchResultDictionary {
    [key:string]: object[];
}

export class SessionAttributesContract{
    RequestedProperties: string[];
    FilterProperties:TSMap<string, string>;
}

export class PropertyValueObj{
    Value:string;
    Count:string;
}

export class PropertyNameValuesPair{
    Name:string;
    Values:string[];
}

export class PropertyTreeNode{
    Name:string;
    PropertyName:string;
    Filters:Map<string, string>;
    Count:number;
    Children: PropertyTreeNode[];
}

export class FeedbackSessionTableData
{
    PartitionKey :string;
    RowKey:string;
    Session:string;
    SessionObj : SaraTicket;
}

export class ChangeRequestApproverTableData
{
    PartitionKey :string;
    RowKey:string;
}

export class ExperimentFlight
{
    EnvironmentName :string;
    FlightName :string;
    FlightEnabled: boolean;
    FlightPercentage:number;
    FlightSalt:number;
    FlightedUsers:string[];
}

export class TicketTag
{
    Client: string;
    TagName: string;
}

export class SurveyResponse
{
    DataClassification: string;
    Responses: string[];
    EnglishQuestion: string;
    EnglishResponses: string[];
    ResponseReferenceIDs: string[];
    SurveyAnswered: boolean;
    Type: string;
}

export class SurveyAnswers
{
    [QuestionFriendlyId: string]: SurveyResponse;
}

export class FlightInfo
{
    FlightName:string;
    FlightValue:string;
    IsEnabled:boolean;
    IsOverriden:boolean;
}