import { Component, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {

    constructor(public dialog: MatDialog) { }
    
    openDialog(data:any): void {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '700px',
          data: data
        });
    
        dialogRef.afterClosed().subscribe(result => {
        });
      }
}

@Component({
  selector: 'error-diaglog',
  templateUrl: './errordialog.component.html'
})

export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}