import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SaraTicketListComponent } from './saraticket/saraticketlist.component';
import { CrashResolutionListComponent } from './crashes/crashresolutionlist.component';
import { CustomBucketListComponent } from './custombuckets/custombucketlist.component';
import { ChangeRequestDetailComponent } from './changerequest/changerequest-detail.component';
import { PreviewListComponent } from './previewplace/preview-list.component';
import { CrashListComponent } from './crashrecovery/crash-list.component';
import { IncidentListComponent } from './incident/incident-list.component';
import { EntitleListComponent } from './entitlement/entitle-list.component';
import { RecoveryListComponent } from './recovery/recovery-list.component';
import { PageDetailComponent } from './partnerpage/page-detail.component';
import { FbListComponent} from './notification-feedback/fb-list.component'
import { AnalysislistComponent } from './analysis/analysislist.component';
import { FeedbackListComponent } from './feedback/feedback-list.component';
import { DiagnosticlogComponent } from './partnerpage/diagnosticlog.component';
import { FlightsListComponent } from './oncall/flights-list.component';
const routes: Routes = [
    {path: 'saratickets', component: SaraTicketListComponent},
    {path: 'custombuckets', component: CustomBucketListComponent},
    {path: 'crashes', component: CrashResolutionListComponent},
    {path: 'comingsoons', component: PreviewListComponent},
    {path: 'crashrecovery', component: CrashListComponent},
    {path: 'changerequest', component: ChangeRequestDetailComponent},
    {path: 'incidents', component: IncidentListComponent},
    {path: 'entitlements', component: EntitleListComponent},
    {path: 'recovery', component: RecoveryListComponent},
    {path: 'officelogs', component: PageDetailComponent},
    {path: 'notificationfeedback', component:FbListComponent},
    {path: 'analysis', component:AnalysislistComponent},
    {path: 'feedback', component:FeedbackListComponent},
    {path: 'diagnosticlog', component: DiagnosticlogComponent},
    {path: 'oncall/experimentflight', component:FlightsListComponent},
    {path: '',  redirectTo: 'saratickets', pathMatch: 'full' },
    {path: '**', redirectTo: 'saratickets'}
];
export const AppRoutingModule: ModuleWithProviders<unknown> = RouterModule.forRoot(routes, { useHash: true });