import { Component,Input,Output,EventEmitter } from '@angular/core'
import { PropertyTreeNode } from './tree-node'

const NODE_COMPONENT_TEMPLATE = `
<li *ngIf="isExpandable() && node.Name=='root'" class="all-item">
    <a (click)="clickItem(node)"
       class="file-item"
       [ngClass]="{focus: node.focus}">
       <div style="white-space: nowrap">
        <span>
          {{ node.PropertyName }}: {{ node.Name }} <span *ngIf="!!node.Count">({{node.Count}})</span> <span *ngIf="!node.Count">(0)</span>
        </span>
       </div>
    </a>
    <ul class="children-items">
        <node *ngFor="let n of node.Children" [node]="n" (clicked)="propagate($event)"></node>
    </ul>
</li>

<li *ngIf="isExpandable() && node.Name!='root' && node.Count!=0" class="all-item">
    <a (click)="clickItem(node)"
       class="folder-item"
       [ngClass]="{focus: node.focus}">
        <div style="white-space: nowrap">
            <span class="point" (click)="expandFolder()">
                <i class="fa fa-fw fa-caret-right" *ngIf="!isExpanded()"></i>
                <i class="fa fa-fw fa-caret-down" *ngIf="isExpanded()"></i>
            </span>
            <span (click)="expandFolder()">
              <i class="fa fa-minus-square" style = "color: lightgray" *ngIf="isExpanded()"></i>
              <i class="fa fa-plus-square" style = "color: lightgray" *ngIf="!isExpanded()"></i>
              {{ node.PropertyName }}: {{ node.Name }} <span *ngIf="!!node.Count">({{node.Count}})</span> <span *ngIf="!node.Count">(0)</span>
            </span>
        </div>
    </a>

    <ul *ngIf="isExpanded()" class="children-items">
        <node *ngFor="let n of node.Children" [node]="n" (clicked)="propagate($event)"></node>
    </ul>
</li>

<li *ngIf="!isExpandable() && node.Count!=0" class="all-item">
    <a (click)="clickItem(node)"
       class="file-item"
       [ngClass]="{focus: node.focus}">
       <div style="white-space: nowrap" style="cursor: pointer;margin-left:12px">
       {{ node.PropertyName }}: {{ node.Name }} <span *ngIf="!!node.Count">({{node.Count}})</span> <span *ngIf="!node.Count">(0)</span>
       </div>
    </a>
</li>
`;

const DIRECTORY_TREE_STYLE = `
.all-item {
  list-style-type: none;
  display:inline;
}
.folder-item {
  color:black;
 }
.file-item { padding-left: 0px; color:black;}
.children-items {
  padding-left: 25px;
}
.focus { color: steelblue }
.fa-fw {
  width: 0.6em;
  text-align: center;
}
`;

@Component({
  selector: 'node',
  template: NODE_COMPONENT_TEMPLATE,
  styles: [DIRECTORY_TREE_STYLE]
})
export class NodeComponent {
  @Input() node:PropertyTreeNode;
  @Input() index:number;
  @Output() clicked:EventEmitter<PropertyTreeNode>;
  constructor() {
    this.clicked = new EventEmitter<PropertyTreeNode>();
  }

  isExpandable(): boolean {
    let isDirectory:boolean = this.node.isDir();
    return isDirectory;
  }

  isExpanded(): boolean {
    return this.node.isExpanded()
  }

  expandFolder():void {
    if (this.node.isExpanded()) {
      this.node.fold()
    } else {
      this.node.expand()
    }
  }

  clickItem(node:PropertyTreeNode) {
    this.clicked.emit(node)
  }

  propagate(node:PropertyTreeNode) {
    this.clicked.emit(node)
  }
}
