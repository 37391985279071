  <div class="flex-container3">
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/entitlements', 0]" right="align">+ Entitle a new user</a>
    </div>
    <div style="height:100%">
          <mat-card>
              <mat-card-title>User Entitlements</mat-card-title>
              <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
                  <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                          <tr>
                              <th>Upn
                              </th>
                              <th>Eligible
                              </th>
                              <th>Last Modified Time
                            </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of filteredList">
                              <td><a [routerLink]="['/entitlements', item.Upn]">{{ item.Upn }}</a></td>
                              <td>{{ item.IsEligible }}</td>
                              <td>{{ item.LastModifiedTime| date:'yyyy/MM/dd HH:mm'}}</td>
                          </tr>
                      </tbody>
                  </table>
              </mat-card-content>
          </mat-card>
    </div>
</div>