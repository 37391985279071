import { Utils } from '../shared/Utils/utils';
import { Observable } from 'rxjs/Rx';
import {ActivatedRoute, Router} from '@angular/router';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { ParameterSchemaNode, ETLRule, JSONSIMPLERule} from '../shared/models/WfsModels';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, AfterViewChecked, AfterContentInit } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators,FormGroupDirective, NgForm} from '@angular/forms';
import { SpinnerService } from '../shared/services/spinner.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { DOCUMENT } from '@angular/common';
/** Error when the parent is invalid */
class FieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'rule-view',
  templateUrl: './ruleview.component.html',
  styleUrls: ['../app.component.css']
})
export class RuleviewComponent implements OnInit,OnDestroy,AfterViewChecked {
  @Input('inputxml') inputxml: string;
  @Input('newjsonrule') newjsonrule: ParameterSchemaNode;
  @Output() outputxml = new EventEmitter<string>();
  public operator: Array<{ name: string, value: string }> = [
    { name: 'equals', value: 'eq'},
    { name: 'not equals', value: 'ne'},
    { name: 'less than', value: 'lt'},
    { name: 'less than or equals to', value: 'le'},
    { name: 'greater than', value: 'gt'},
    { name: 'greater than or equals to', value: 'ge'},
    { name: 'like', value: 'like'}
  ];
  public pd = require('pretty-data').pd; 
  public ruleRelation:string[] = ["And"]; // Currently we only supportting and relationship between rules
  public etlform: FormGroup;
  public jsform: FormGroup;
  public NodesFromService:ParameterSchemaNode[];
  
  public selectedRuleType:string;
  public currentNodesToSelect:ParameterSchemaNode[];
  public savedNodes:ParameterSchemaNode[]=[];
  
  public sessionform: FormGroup;
  public sessionJsonRule:JSONSIMPLERule;
  public ruleList :{type:string, obj:any}[] = [];
  public isRuleValid:boolean=true;
  public jsonSimpleRuleTemplate = `<JSONRule ParameterName="PLACEHOLDER_PN" FieldPath="PLACEHOLDER_FP" FieldValue="PLACEHOLDER_FV" Operator="PLACEHOLDER_OP" />`;
  public etlRuleTemplate = `<EtlRule ProviderName="PLACEHOLDER_PN" EventName="PLACEHOLDER_EN" EventPayloadFields="PLACEHOLDER_EPF" Count="PLACEHOLDER_CT" />`;
  public xml:string;
  public listFromInputXML = []; 
  public pageLoad:boolean = false;
  errorMatcher = new FieldErrorMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private service: DiagDefinitionService) {}

  ngOnInit() {
    this.etlform = this.formBuilder.group({
      etl_newProviderName: ['', [Validators.required, this.ETLinputForbiddenCharValidator2]],
      etl_newEventName: ['', [Validators.required, this.ETLinputForbiddenCharValidator2]],
      etl_newEventPayloadFields: ['', [Validators.required, this.ETLinputForbiddenCharValidator2]],
      etl_newCount: ['', [Validators.required, this.ETLinputForbiddenCharValidator2]],
    });

    this.jsform = this.formBuilder.group({
      parameter: [''],
      operator: ['', Validators.required],
      fieldValue: ['', Validators.required],
    }, {
      validator: this.JSInputForbiddenCharValidator
    });

    if(!!this.inputxml)
    {
      this.xml = this.inputxml;
      this.parseXMLToVisualEditor();
    }

    let sub = this.service.GetParamSchema().subscribe(res => {
      if (!!res) {
          this.NodesFromService = res;
          this.currentNodesToSelect =  this.NodesFromService;
      }
      sub.unsubscribe();
      }, error => {
          sub.unsubscribe();
      });
  }

  JSInputForbiddenCharValidator(form: FormGroup) {
    const condition = !!form.get('fieldValue') && !!form.get('fieldValue').value && (form.get('fieldValue').value.indexOf('&')>-1 || form.get('fieldValue').value.indexOf('"')>-1 || form.get('fieldValue').value.indexOf("'")>-1);
    return condition ? { inputContainsForbiddenChar: true} : null;
  }

  ETLinputForbiddenCharValidator2(form: FormGroup) {
    const condition = !!form.value && (form.value.indexOf('&')>-1 || form.value.indexOf('"')>-1 || form.value.indexOf("'")>-1);
    return condition ? { etlInputContainsForbiddenChar2: true} : null;
  }

  ngAfterViewChecked(){
    if (!this.pageLoad)
    {
      for(let i =0;i<this.listFromInputXML.length;i++)
      {
        if (!!document.getElementById(i + "_open")&& !!document.getElementById(i + "_close"))
        {
          (<HTMLInputElement>document.getElementById(i + "_open")).value=this.listFromInputXML[i].open;
          (<HTMLInputElement>document.getElementById(i + '_close')).value=this.listFromInputXML[i].close;
          if(i!=this.listFromInputXML.length-1)
          {
            (<HTMLInputElement>document.getElementById(i + '_andor')).value=this.listFromInputXML[i].operator=='&'?"And" : "Or";
          }

          this.pageLoad= true;
        }
      }
      
    }
  }

  ngOnDestroy(){}

  public onSelectNode()
  {
    let node : ParameterSchemaNode =this.jsform.controls.parameter.value;
    if(this.savedNodes.length>0) // has parent
    {

      let parentNode:ParameterSchemaNode = this.savedNodes[this.savedNodes.length-1];
      // see if last node in the list is really the parent
      if(parentNode.Children.filter(x=>x.Name==node.Name && x.RuleType==node.RuleType&&isArrayEquals(x.Children,node.Children)))
      {
        this.savedNodes.push(node);
      }
      else { // people is just change the same list, so pop up last node and save the new node
        this.savedNodes.pop();
        this.savedNodes.push(node);
      }
    }else // root
    {
      this.savedNodes.push(node);
    }

    if(!!node && !!node.Children && node.Children.length>0)
    {
      this.currentNodesToSelect = node.Children;
    }else{
      this.currentNodesToSelect = null;
    }
  }

  public onRemoveLastNode()
  {
    this.savedNodes.pop();
    if(this.savedNodes.length>0)
    {
      let parentOfRemovedNode = this.savedNodes[this.savedNodes.length-1];
      this.currentNodesToSelect = parentOfRemovedNode.Children;
    }else
    {
      this.currentNodesToSelect = this.NodesFromService;
    }
  }

  public getSavedNodes()
  {
    let result = "";
    this.savedNodes.forEach(x=>result = result + x.Name + ".");
    if(!this.currentNodesToSelect || this.currentNodesToSelect.length == 0)
    {
      result = result.substring(0, result.length - 1); 
    }
    return result;
  }

  public onSaveNewETLRule()
  {
    if(!this.etlform.valid)
    {
      return;
    }
    if(this.ruleList.length>=9)
    {
      alert("Sorry we are currently not supporting more than 10 rules together");
      return;
    }

    let newETLRule = new ETLRule();
    newETLRule.ProviderName = this.etlform.value.etl_newProviderName;
    newETLRule.EventName = this.etlform.value.etl_newEventName;
    newETLRule.EventPayloadFields = this.etlform.value.etl_newEventPayloadFields;
    newETLRule.Count = this.etlform.value.etl_newCount;
    this.ruleList.push({type:'etl', obj:newETLRule});
    
    this.onResetFromNew();
    this.GenerateXMLFromUI();
    this.selectedRuleType="";
  }


  public onSaveNewJsonSimpleRule()
  {
    if(!this.isJSformValid())
    {
      return;
    }
    if(this.ruleList.length>=9)
    {
      alert("Sorry we are currently not supporting more than 10 rules together");
      return;
    }

    if(!this.sessionJsonRule)
    {
      let newjsonrule = new JSONSIMPLERule();
      newjsonrule.Operator = this.jsform.controls.operator.value;
      newjsonrule.FieldValue = this.jsform.controls.fieldValue.value;
  
      let lastnode = this.savedNodes[this.savedNodes.length-1];
      newjsonrule.ParameterName = lastnode.ParameterName;
      newjsonrule.FieldPath = lastnode.FieldPath;
      newjsonrule.EntirePath = !!lastnode.FieldPath?lastnode.ParameterName+"."+lastnode.FieldPath:lastnode.ParameterName;
      this.ruleList.push({type:'json', obj:newjsonrule});
    }else
    {
      this.sessionJsonRule.Operator = this.jsform.controls.operator.value;
      this.sessionJsonRule.FieldValue = this.jsform.controls.fieldValue.value;
  
      this.ruleList.push({type:'json', obj:this.sessionJsonRule});
      this.sessionJsonRule = null;
    }
    this.onResetFromNew();
    this.GenerateXMLFromUI();
    this.selectedRuleType="";
  }

  public isJSformValid()
  {
    if(!this.sessionJsonRule && (!this.savedNodes || this.savedNodes.length==0 || this.getSavedNodes().slice(-1)=="."))
    {
      return false;
    }
    
    return !!this.jsform.controls.operator.value && !!this.jsform.controls.fieldValue.value;
  }

  public onResetFromNew()
  {
    this.etlform.reset();
    this.jsform.reset();
    this.currentNodesToSelect = this.NodesFromService;
    this.savedNodes=[];
    this.sessionJsonRule=null;
  } 

  public onDeleteETLRule(element:RuleTableItem)
  {
    let i = this.ruleList.indexOf(element, 0);
    if (i > -1) {
      this.ruleList.splice(i, 1);
    }

    this.GenerateXMLFromUI();
  }


  public onDeleteJSONRule(element:RuleTableItem)
  {
    let i = this.ruleList.indexOf(element, 0);
    if (i > -1) {
      this.ruleList.splice(i, 1);
    }

    this.GenerateXMLFromUI();
  }

  // Parse the given xml into the visual editor
  public parseXMLToVisualEditor()
  {
    if(!this.inputxml || this.inputxml.toLowerCase().indexOf("jsonlistrule")>-1)
    {
      return;
    }

    let exisitingXMLdoc = new DOMParser().parseFromString(this.inputxml, "text/xml");
    console.log(this.inputxml);
    // Build up a tree from given xml string
    let element = exisitingXMLdoc.getElementsByTagName("PayloadList")[0];
    let root = new TreeNode();
    if(!!element)
    {
      if(!!element.childNodes)
      {
        const array = Array.from(element.childNodes);
        array.forEach(c=>{
          if(c.nodeName!="#text")
          {
            root = this.inputxmlToTree(c);
          }
        });
      }
    }

    let expression = this.xmlTreeToExpression(root);
    if(expression.startsWith("("))
    {
      expression = expression.substring(1,expression.length-1); // this will eliminate the extra () surrounded by the 1st level
    }
    
    let start=0;
    let chararray = expression.split('');
    for(let i=0;i<chararray.length;i++)
    {
      if(chararray[i]=='&' || chararray[i]=='|')
      {
        //(start,i] maps to a table row
        let ruleListTableItem=this.BuildEntireRuleListTableRow(expression.substring(start,i), chararray[i]);
        this.listFromInputXML.push(ruleListTableItem);
        //reset start
        start=i+1;
      }
    }

    let ruleListTableItem=this.BuildEntireRuleListTableRow(expression.substring(start),null);
    this.listFromInputXML.push(ruleListTableItem);
    console.dir(this.listFromInputXML);
    for(let i =0;i<this.listFromInputXML.length;i++)
    {
      this.ruleList.push(this.listFromInputXML[i].ruleTableItem);
    }
   }

  // Inorder travelsal the tree to get expression
  public xmlTreeToExpression(root:TreeNode):string
  {
    if(!root) { return null;}

    if(!root.children) // no chilren than it must be a operand, like json rule or etl rule, so we can directly read it into RuleTableItem
    {
      let result = root.symbol;
      return  result;
    }

    let operator = root.symbol; // other wise the node must be a operator like & or
    let result ="(";
    root.children.forEach(child=>{
      let childexpression = this.xmlTreeToExpression(child);
      if(!!childexpression)
      {
        result = result.concat(childexpression,operator);
      }
    });

    let len = result.length;
    result = result.substring(0, len-1) + ")";
    return result;
  }

  // A table row contains parenthese and rule object itself and and or or operator, for example "(a& "   " c))| "    "CD"
  public BuildEntireRuleListTableRow(input:string, operator:string):RuleListTableItem 
  {
    let result = new RuleListTableItem();
    result.operator=operator;
    let i = 0;
    let chararray = input.split('');
    while(chararray[i]=='(')
    {
      i++;
    }
    if(i!=0) // ((rule operator
    {
        result.open="(".repeat(i);
        result.ruleTableItem = this.getRuleTableItemFromString(input.substring(i));
        return result;
    }

    i=chararray.length-1;
    let count = 0;
    while(chararray[i]==')'&&i>=0)
    {
      i--;
      count++;
    }

    if(i!=0)   // rule )) operator
    {
      result.close=")".repeat(count);
      
      result.ruleTableItem = this.getRuleTableItemFromString(input.substring(0,i+1));
      return result;
    }
    else{  // only rule
      result.ruleTableItem = this.getRuleTableItemFromString(input);
      return result;
    }
  }

  // Recursively construct tree by given html element node from xml
  public inputxmlToTree(node)
  {
    if( !node|| node.nodeName=="#text")
    {
      return null;
    }

    let newnode = new TreeNode();
    if(node.nodeName=="And")
    {
      newnode.symbol="&";
    }
    else if(node.nodeName=="Or")
    {
      newnode.symbol="|";
    }
    else
    {
      newnode.symbol=node.outerHTML.replace("&gt;",">").replace("&lt;","<");
    }
    let children = [];
    let childnodes = !!node.childNodes&& node.childNodes.length>0? node.childNodes:[];
    childnodes.forEach(element => {
      let child = this.inputxmlToTree(element);
      if(!!child){
        children.push(child);
      }
    });

    newnode.children = children.length>0?children:null;
    return newnode;
  }

  onSubmit(): Observable<string> {
    return Observable.create(observer => {
      if(!this.isRuleValid)
      {
        alert("Cannot save, problem signature is invalid.")
        return;
      }
      observer.next(this.xml);
      observer.complete();
    });
  }
 
  // Validate whether user input balanced parenthese
  public validateParentheses()
  {
    let toTest:string='';
    for (let i in this.ruleList)
    {
      let open = (<HTMLInputElement>document.getElementById(i+'_open')).value;
      let close = (<HTMLInputElement>document.getElementById(i+'_close')).value;
      toTest = toTest.concat(open,close);
    }

    var stack = [];
    var validFromCallBack = true;
    toTest.split('').forEach(x=>{
      if(x=='(')
      {
        stack.push(x);
      }else{
        if(stack.length == 0)
        {
          validFromCallBack=false;
          return;
        }
        else
        {
          stack.pop();
        }
      }
    });

    this.isRuleValid = validFromCallBack && stack.length==0;
  }

  // Get Entire input from UI and translate it into formatted xml
  public GenerateXMLFromUI()
  {
    if(!this.isRuleValid)
    {
      return;
    }
    
    if(this.ruleList.length>=10)
    {
      alert("Sorry we are currently not supporting more than 10 rules together");
      return;
    }
    
    if(this.ruleList.length==0)
    {
      this.xml="";
      return;
    }
    let internalLogicalExpression:string='';
    for (let i=0; i<this.ruleList.length-1;i++)
    {
      let open = (<HTMLInputElement>document.getElementById(i+'_open')) != null ? (<HTMLInputElement>document.getElementById(i+'_open')).value:'';
      let close = (<HTMLInputElement>document.getElementById(i+'_close')) !=null ? (<HTMLInputElement>document.getElementById(i+'_close')).value: '';
      let andor = (<HTMLInputElement>document.getElementById(i+'_andor')) !=null ? (<HTMLInputElement>document.getElementById(i+'_andor')).value:'';
      let operator = andor=="And"? "&" : andor=="Or"?"|":"";
      internalLogicalExpression = internalLogicalExpression.concat(open,i+'',close," ",operator," ");
    }

    let lastIndex = this.ruleList.length-1;
    let open = (<HTMLInputElement>document.getElementById(lastIndex+'_open')) != null ? (<HTMLInputElement>document.getElementById(lastIndex+'_open')).value:'';
    let close = (<HTMLInputElement>document.getElementById(lastIndex+'_close')) !=null ? (<HTMLInputElement>document.getElementById(lastIndex+'_close')).value: '';
    internalLogicalExpression = internalLogicalExpression.concat(open,lastIndex+'',close);
    let postOderExpression = this.inorderToPostorder(internalLogicalExpression);
    this.xml = this.constructXML(postOderExpression);
 }

  public getRuleObjAsString(item:RuleTableItem)
  {
    if(item.type=='json')
    {
      let newrow = this.jsonSimpleRuleTemplate.replace("PLACEHOLDER_PN", item.obj.ParameterName);
      let newfp=!item.obj.FieldPath?"#ROOT#":item.obj.FieldPath;
      newrow = newrow.replace("PLACEHOLDER_FP", newfp);
      newrow = newrow.replace("PLACEHOLDER_FV", item.obj.FieldValue);
      newrow = newrow.replace("PLACEHOLDER_OP", item.obj.Operator);
      return newrow;
    }

    if(item.type=='etl')
    {
      let newrow = this.etlRuleTemplate.replace("PLACEHOLDER_PN",item.obj.ProviderName);
      newrow = newrow.replace("PLACEHOLDER_EN", item.obj.EventName);
      newrow = newrow.replace("PLACEHOLDER_EPF", item.obj.EventPayloadFields);
      newrow = newrow.replace("PLACEHOLDER_CT", item.obj.Count);
      return newrow;
    }
  }

  public getRuleTableItemFromString(item:string):RuleTableItem
  {
    let exisitingXMLdoc = new DOMParser().parseFromString(item, "text/xml");
    let jsonRuleList = exisitingXMLdoc.getElementsByTagName("JSONRule");
    let etlRuleist = exisitingXMLdoc.getElementsByTagName("EtlRule");

    if(!!jsonRuleList && jsonRuleList.length>0)
    {
      let len= jsonRuleList.length;
      for (let i = 0; i < len; i++) 
      {
        let newnode = new JSONSIMPLERule();
        newnode.ParameterName = jsonRuleList[i].getAttribute("ParameterName");
        newnode.FieldPath = jsonRuleList[i].getAttribute("FieldPath").toLocaleLowerCase()=="#root#"?'': jsonRuleList[i].getAttribute("FieldPath");
        newnode.EntirePath = !!newnode.FieldPath? newnode.ParameterName+"."+newnode.FieldPath:newnode.ParameterName;
        newnode.FieldValue = jsonRuleList[i].getAttribute("FieldValue");
        newnode.Operator = jsonRuleList[i].getAttribute("Operator");

        return {type:'json', obj:newnode};
      }
    }

    if(!!etlRuleist && etlRuleist.length>0)
    {
      let len= etlRuleist.length;
      for (let i = 0; i < len; i++) 
      {
        let newnode = new ETLRule();
        newnode.ProviderName = etlRuleist[i].getAttribute("ProviderName");
        newnode.EventName = etlRuleist[i].getAttribute("EventName");
        newnode.EventPayloadFields = etlRuleist[i].getAttribute("EventPayloadFields");
        newnode.Count = etlRuleist[i].getAttribute("Count");
        return {type:'etl', obj:newnode};
      }
    }

    return null;
  }

  //// change and or operator express from inorder to postorder, so that we can construct tree from postorder.
  //// for example: a | b & c => a b c & |
  public inorderToPostorder(input:string):string
  {
    let output = "";
    if(!input) {return output;}
    var stack = [];
    let chararray = input.split('');
    chararray.forEach(currentChar=>{
        switch(currentChar)
        {
          case '|':
                output = this.handleOperator(stack, currentChar, 1, output);
                break;
            case '&':
                output = this.handleOperator(stack, currentChar, 2, output);
                break;
            case '(':
                stack.push(currentChar);
                break;
            case ')':
                while (stack.length!=0) {
                    var c = stack.pop();
                    if (c == '(')
                        break;
                    else
                        output = output + c;
                }
                break;
            default:
                output = output + currentChar;
        }
    });
    
    while (stack.length!=0)
    {
      output = output + stack.pop();
    }
    return output;
  }

  private handleOperator(stack, currentOperator, currentPrecedence:number, output:string):string {
    while (stack.length!=0) {
        let operatorTop = stack.pop();
        if (operatorTop == '(') {
            stack.push(operatorTop);
            break;
        } else {
            let topPrecedence = operatorTop == '|' ? 1:2 ;

            if (topPrecedence < currentPrecedence) {
                stack.push(operatorTop);
                break;
            } 
            else{
              output = output + operatorTop;
            }    
        }
    }

    stack.push(currentOperator);
    return output;
  }

  // Method to construct xml from the postindex representation of the operator expression
  public constructXML(input:string):string
  {
    let result="";
    if(!input){return result;}
    var stack = [];
    let chararray = input.split('');
    chararray.forEach(symbol=>{
        if (symbol >= '0' && symbol <= '9') {
            let newnode = new XMLNode();
            newnode.ruleListIndex = Number(symbol);
            newnode.value="";
            stack.push(newnode);
        } 
        else if (symbol == '&' || symbol == '|') {
            let right = stack.pop();
            let left  = stack.pop();
            let newnode = new XMLNode();
            let value ="";
            if(symbol=='&'){
              value = value.concat("<And> ", this.GetXMLNodeValue(left), this.GetXMLNodeValue(right)," </And>");
            } 
            else if (symbol =='|'){
              value = value.concat("<Or> ", this.GetXMLNodeValue(left), this.GetXMLNodeValue(right)," </Or>");
            }
            newnode.ruleListIndex = -1; // -1 means it is already a constructed node
            newnode.value = value;
            stack.push(newnode);
        }
    });

    result = result.concat("<PayloadList>", this.GetXMLNodeValue(stack.pop()),"</PayloadList>");
    result = this.pd.xml(result)
    return result;
  }

  // Method to get xml node value, if it has a >0 index means it is mapping to an item of rulelist, if index= -1 means it is already a constructed sub-tree
  public GetXMLNodeValue(node:XMLNode):string
  {
    if(!node)
    {
      return null;
    }

    if(node.ruleListIndex==-1 || !this.ruleList[node.ruleListIndex])
    {
      return node.value;
    }
    
    return this.getRuleObjAsString(this.ruleList[node.ruleListIndex]);
  }

  public doesContainAndOrOperator(input:string)
  {
    return !!input&&(input.indexOf("&")>=0 || input.indexOf("|")>=0 );
  }
  //  start for adding rules from session +++++++++++++++++++++++++:
   public AddRuleFromSession(node:ParameterSchemaNode)
   {
     this.selectedRuleType="JSON";
     // Only one session rule can be added at a time, so if you add another one the previous value without saving will be overriden.
     this.sessionJsonRule = new JSONSIMPLERule();
     this.sessionJsonRule.ParameterName = node.ParameterName;
     this.sessionJsonRule.FieldPath = node.FieldPath;
     this.sessionJsonRule.FieldValue = ''
     this.sessionJsonRule.Operator = '';
     this.sessionJsonRule.EntirePath = !!this.sessionJsonRule.FieldPath? this.sessionJsonRule.ParameterName+"."+this.sessionJsonRule.FieldPath:this.sessionJsonRule.ParameterName;
   }
 
   public SessionRuleSave()
   {
     if(this.ruleList.length>=9)
     {
       alert("Sorry we are currently not supporting more than 10 rules together");
       return;
     }
     this.sessionJsonRule.Operator = this.sessionform.controls.operator.value;
     this.sessionJsonRule.FieldValue = this.sessionform.controls.fieldValue.value;
 
     this.ruleList.push({type:'json', obj:this.sessionJsonRule});
     this.sessionJsonRule = null;
     this.sessionform.reset();
     this.GenerateXMLFromUI();
   }
   //  end for adding rules from session ----------------------------
}
 
export class TreeNode
{
  children:TreeNode[];
  symbol:string;
}
export class XMLNode
{
  ruleListIndex:number;
  value:string;
} 

export class RuleTableItem
{
  type:string;
  obj:any;
}

export class RuleListTableItem{
  ruleTableItem:RuleTableItem;
  open:string;
  close:string;
  operator:string;
}

var isArrayEquals = function (value, other) {
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isArrayEquals(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};
