import { CustomBucketDetailsComponent } from './custombucketdetails.component';
import { CustomBucketListComponent } from './custombucketlist.component';
import { RuleviewComponent } from './ruleview.component';
import { CustomBucketSectionComponent } from './custombucketsection.component';
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../shared/sharedmodule/shared.module';
import {FlexLayoutModule} from "@angular/flex-layout";
import {CustomBucketRoutingModule} from "./custombucket.routing";
import { DCRTreeViewModule } from '../saraticket/DCRTreeView/dcrtreeview.module';
import { SessionMatchComponent } from './session-match.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        DataTablesModule,
        MatCardModule,MatChipsModule, MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,
        FlexLayoutModule,
        SharedModule,
        CustomBucketRoutingModule,
        DCRTreeViewModule,
        MatTabsModule,
        MatSidenavModule
    ],
    declarations:[
        CustomBucketSectionComponent,
        CustomBucketListComponent,
        CustomBucketDetailsComponent,RuleviewComponent, SessionMatchComponent
    ],
})

export class CustomBucketModule{}