import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrashDetailComponent } from './crash-detail.component';
import { CrashListComponent } from './crash-list.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(
    [
      {
          path: 'crashrecovery',
          children: [
              {
                  path: '',
                  component: CrashListComponent,
              },
              {
                  path: ':Client/:Failure',
                  component: CrashDetailComponent,
              }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class CrashRecoveryRoutingModule { }
