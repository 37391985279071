import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Papa } from 'ngx-papaparse';
import { saveAs } from 'file-saver';
interface RowObject {
	// 👇️ key      value
	[key: string]: any;
}
@Component({
  selector: 'csv-reader',
  templateUrl: './csv-reader.component.html',
  styleUrls: ['./csv-reader.component.css']
})
export class CsvReaderComponent implements OnChanges {

  @Input() csvSource: any;
  @Input() csvFileName: string;
  public rowList: RowObject[]=[];
  public headers: string[]=[];
  public displayedColumns = [];
  public dataSource :MatTableDataSource<RowObject>; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private papa: Papa) {
  }
  ngOnInit() {
  } 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
	this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  ngOnChanges() {
	if(!!this.csvSource)
	{
		this.papa.parse(this.csvSource,{
			download: false,
			skipEmptyLines :true,
			complete: (result) => {
					this.papa.parse(this.csvSource,{
						download: false,
						skipEmptyLines :true,
						delimiter: "\t",
						complete: (result) => {
							console.log('Parsed: ', result);
							const resultarr:[] = result.data;
							let n = resultarr.length;
							if(n>=2) // must has at least 2 lines, including headers and rows
							{
								try
								{
									// default for now to only display 30 columns in the table.
									const defaultcol = 30;
									const headerRow:[][] = resultarr.slice(0,1);
									const col = Math.min(defaultcol, headerRow[0].length);
									for(var i=0; i < col; i++){
										this.headers.push(headerRow[0][i]);
									}

									// headers will be used as field name, becaust sorting is based on fieldname and must be same with header
									for(var i=1; i < n; i++){
										let obj:RowObject = this.createRowObj(resultarr[i], this.headers);
										this.rowList.push(obj);
									}
									this.displayedColumns = this.headers;
									this.dataSource = new MatTableDataSource(this.rowList); 
								}
								catch{
									this.rowList=[];
									this.displayedColumns=[];
									console.log("csvreader parse csv error");
								}
							}
						}
					});
				}
		});
	}
  }
  private createRowObj(arr: string[], headers: string[]): RowObject {
	var data = {
	};

	for(var i=0;i<headers.length;i++)
	{
		const header = headers[i];
		data[header] = arr[i];
	}
	return data;
  }

  public getFieldValue(obj: RowObject, column: string)
  {
	return obj[column];
  }

  downloadFile() {
    const header = this.headers;
    let csv = this.rowList.map(
		row => header.map(
			fieldName => this.replacer(row[fieldName])
			).join(',')
		);

    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
	const fileName = !!this.csvFileName? this.csvFileName + ".csv" : "exported.csv"; 
    saveAs(blob, fileName);
  }

  private replacer(str :any)
  {
	if(str ==null || str==undefined || !str)
	{
		return '';
	}

	let newstr = str.replaceAll(/\"/g, "\"\"");
	newstr = `\"${newstr}\"`
	return newstr;
  }
}
