import { Subscription } from 'rxjs/Rx';
import { SpinnerService } from './shared/services/spinner.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { GlobalService } from './shared/services/global.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Msal } from './shared/services/msal.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, timer } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy{
  public pageTitle: string = 'Diagnostic Portal';
  public objSpinnerStatus: boolean;
  public statusMessage: string;
  public comesFromOfficeLogs:boolean=false;
  public success: boolean = false;
  public isUserAuthorized:boolean;
  public isServiceOncall:boolean;
  public isTokenExpired:boolean = false;
  private timerSubscription: Subscription;
  public panelOpenState = false;
  public panelOpenState_analysis = false;
  public panelOpenState_oncall = false;
  public client:string;
  public openAccountMenu:boolean=false;
  public openSideBar:boolean=true;
  public clientList:string[];
  public isLoggedIn:boolean;
  
  private readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router, 
    private location:Location, 
    private spinService:SpinnerService, 
    private globalService:GlobalService,
    public msal:Msal) 
    {
      this.client = this.globalService.getClient();
      this.processRouteParams();
  }

  ngOnInit(): void
  {   
    this.spinService.loaderStatus.subscribe((val: boolean) => {
      this.objSpinnerStatus = val;
    });

    this.isLoggedIn = !!localStorage.getItem("MSALToken") && !this.msal.IsTokenExpired();
    if (this.isLoggedIn)
    {
      this.processAfterLogin();
    }
   
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
         msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
         ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.spinService.displayLoader(true);
        // do something with the result, such as accessing ID token
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.msal.getProfile(payload);
        this.isLoggedIn = !!localStorage.getItem("MSALToken") && !this.msal.IsTokenExpired();
        this.processAfterLogin();
      });
  }  
  
  public loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  private processAfterLogin()
  {
      //start 1s after web load, and every 10mins check the page if user still authorized, as well as is token expired
      let obj = timer(1000,600000);
      this.timerSubscription = obj.subscribe(t=> {
          // check if token expired
          if(this.msal.IsTokenExpired())
          {
              this.isTokenExpired=true;
              this.success=true;
          }else{
              //Session not expired, check if user is still authorized to the website
              this.spinService.displayLoader(true);
              this.globalService.VerifyAuthorization().subscribe(isAuthorized=>{
                  this.isUserAuthorized=isAuthorized;
                  this.success=true;
                  if(!isAuthorized)
                  {
                      this.statusMessage=localStorage.getItem("authInstruction");
                  }
                  
                  this.globalService.VerifyOncall().subscribe(response=>{
                      this.isServiceOncall=response;
                      this.globalService.GetAllowedClients().subscribe(response=>{
                        this.clientList=response;
                        this.spinService.displayLoader(false);
                      }); 
                  }); 
              }); 
          }
      });
  }
  private processRouteParams()
  {
      let params:string[] = this.location.path(true).split('/');
      if (params[1] != undefined)
      {
          if (params[1].toLowerCase() == "ticketdetails")
          {
              params[1] = "saratickets";
          }

          if (params[1].toLowerCase() == "officelogs" || params[1].toLowerCase() == "diagnosticlog")
          {
              this.comesFromOfficeLogs=true;
          }
          
          if (params[2] == undefined)
          {
              this.router.navigate([params[1]]);
              return;
          }

          if (params[3] == undefined)
          {
              this.router.navigate([params[1], params[2]]);
              return;
          }
          if (params[4] == undefined)
          {
              this.router.navigate([params[1], params[2], params[3]]);
              return;
          }
          else
          {
              this.router.navigate([params[1], params[2], params[3],params[4]]);
              return;
          }
      }
  }
 
  public refresh()
  {
      this.loginRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.timerSubscription.unsubscribe();
  }

  public onToggerClientSelection(client:string)
  {
        this.client = client.toLocaleLowerCase();
        this.globalService.updateClient(this.client);
        window.location.reload();
  }

  public getClientSelection(item:string)
  {
    return this.client==item.toLocaleLowerCase();
  }

  public onClickAccountMenu()
  {
    this.openAccountMenu = !this.openAccountMenu;
  }

  public toggleSideBar() {
    this.openSideBar=!this.openSideBar;
    if(this.openSideBar)
    {
        document.getElementById("mySidebar").style.width = "250px";
    }else{
        document.getElementById("mySidebar").style.width = "40px";
    }
  }

  public toggleOpenClassify(){
     this.panelOpenState=!this.panelOpenState;
  }

  public toggleOpenAnalysis(){
      this.panelOpenState_analysis = ! this.panelOpenState_analysis;
  }

  public toggleOpenOncall(){
    this.panelOpenState_oncall = ! this.panelOpenState_oncall;
  }

  public getInitials()
  {
      if (this.isLoggedIn && !!this.msal.currentUserName)
      {
        let fullname = this.msal.currentUserName;
        var names = fullname.split(' ');
        var initials = names[0].substring(0, 1).toUpperCase();
    
        if (names.length > 1) {
            initials += names[1].substring(0, 1).toUpperCase();
        }
        
        return initials;
      }
  }

  public goback()
  {
    this.isTokenExpired = false;
  }
}