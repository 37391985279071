<div class="flex-container3" *ngIf="success">
  <div style="margin-top: 10px;margin-bottom: 10px;">
    <form [formGroup]="form">
      <label style="width: 100%">
          New tag
          <input class = "form-control" formControlName="tag" required maxlength="50" style="font-weight: normal">
          <small *ngIf="!form.controls.tag.valid" class="text-danger" >Required, max length 50</small>
      </label>
    </form>
    <button style="margin-right: 10px;" [disabled]="!form.valid" type="button" (click)="onAddNewTag()">Add new tag</button>
  </div>
  <div *ngIf='allTags && allTags.length > 0'  style="height:100%">
      <mat-card>
          <mat-card-title>Tags</mat-card-title>
          <mat-card-content>
            <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                      <tr>
                        <th>Tag</th>
                        <th>Remove</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of allTags">
                        <td>{{ item.TagName }}</td>
                        <td><i class="fa fa-close" (click)="onRemoveTag(item)" style="color:indianred;cursor: pointer"></i></td>
                      </tr>
                  </tbody>
              </table>
          </mat-card-content>
      </mat-card>
    </div>
</div>

