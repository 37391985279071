import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnerpageRoutingModule } from './partnerpage-routing.module';
import { PageDetailComponent } from './page-detail.component';
import { DiagnosticlogComponent } from './diagnosticlog.component';

@NgModule({
  imports: [
    CommonModule,
    PartnerpageRoutingModule
  ],
  declarations: [PageDetailComponent, DiagnosticlogComponent]
})
export class PartnerpageModule { }
