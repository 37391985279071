import { ConfigService } from './config.service';
import { Utils } from '../Utils/utils';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RecoveryInfo, KeyValuePair, CrashInfo, ChatMessage, ChatRequest, Agent, TicketRequest, TicketQueueInfo, SaraTicket, TicketNote, TicketNoteRequest, TicketBugAssociationRequest, TicketBug, ProblemSignaturePortalContract, ProblemSignature, CrashPortalContract, ChangeRequest, PreviewPlace, CrashRecoveryPortalContract, CrashRecovery, ParameterSchemaNode, IncidentTableEntity, IncidentPortalContract, SupportEligibilityUserContract, SupportEligibilityUser, NotificationFeedbackTableData, LocDictionary, BulkUploadResult, NGramEntry, sessionRuleMatchResultDictionary, ProblemSignatureVsSessionRuleMatchResult, SessionAttributesContract, PropertyNameValuesPair, FeedbackSessionTableData, ChangeRequestApproverTableData, ExperimentFlight, TicketTag, Incident, RecoveryImageInfo} from '../models/WfsModels';
import { Observable } from 'rxjs/Rx';
import { HttpClient} from '@angular/common/http';
import { ErrorHandleService } from '../sharedmodule/errordialog.component';
import { TSMap } from "typescript-map";
@Injectable()
export class DiagDefinitionService
{
    private recoveryUrl:string;
    private problemSignatureUrl: string;
    private diagPortalUrl: string;
    private errorMessage: string;
    public observable: Observable<any>;
    public recoveries:RecoveryInfo[] = new Array();
    public problemSignatures: ProblemSignature[] = new Array();
    public crashes: CrashInfo[] = new Array();
    public odsUrl : string;
    public crashRecoveryList : CrashRecovery[];
    constructor(private http:HttpClient, private utils: Utils, private config:ConfigService, private errorHandleService:ErrorHandleService) 
    {
        this.recoveryUrl = config.odsControllerUrl + 'v1/recoveries?locale=en-us';
        this.problemSignatureUrl = config.odsControllerUrl + 'v2/problemsignature?locale=en-us';
        this.diagPortalUrl = config.odsControllerUrl + 'v1/diagportal';
        this.odsUrl = config.odsControllerUrl;
    }

    public GetProblemSignaturesByClientsAsync(clientlist:string): Observable<ProblemSignature[]>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat("v2/problemsignatures?clientlist=", clientlist);

        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                this.problemSignatures = <ProblemSignature[]>(response.body);;
                return this.problemSignatures;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetProblemSignatureByIdAndClientAsync(id:string, client:string): Observable<ProblemSignaturePortalContract>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.problemSignatureUrl.concat("&client=", client);
        url = url.concat("&id=", id);

        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                let problemSignature = <ProblemSignaturePortalContract>(response.body);
                return problemSignature;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public UpdateProblemSignatureAsync(problemSignature:ProblemSignaturePortalContract): Observable<ProblemSignaturePortalContract>
    {
        let headers = this.utils.GetRequestHeaders();
         let body = JSON.stringify(problemSignature);
        this.observable = this.http.put(this.problemSignatureUrl, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return this.observable;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public CreateProblemSignatureAsync(problemSignature:ProblemSignaturePortalContract): Observable<ProblemSignaturePortalContract>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(problemSignature);
        this.observable = this.http.post(this.problemSignatureUrl, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return this.observable;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public DeleteProblemSignatureAsync(id: string, client:string): Observable<Response>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/problemsignature?locale=en-us&client=' + client + '&id=' + id;

        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public GetRecoveriesAsync(): Observable<RecoveryInfo[]>
    {
        let headers = this.utils.GetRequestHeaders();
        headers.append("RecoveryInfoFields","Id,Name,Area");
        this.observable = this.http.get(this.recoveryUrl, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                this.recoveries = <RecoveryInfo[]>(response.body);;
                return this.recoveries;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetRecoveryAsync(recoveryid:string): Observable<RecoveryInfo>
    {
        let url:string;
        if(recoveryid != undefined && recoveryid != '')
        {
            url = this.recoveryUrl.concat("&recoveryid=", recoveryid);
        }

        let headers = this.utils.GetRequestHeaders();
        headers.append("RecoveryInfoFields","Id,Name,Area,RecoveryTitle,Description,UserMsg,AdminMsg,SupportMsg,Link,ProblemDescription,RecoverySteps,ContactAdmin,HasAFix");
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            return <RecoveryInfo>(response.body);
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetCrashResolutionsAsync(): Observable<CrashInfo[]> {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl+ 'v1/crashResolution';
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if (response.status == 200) {
                this.crashes = <CrashInfo[]>(response.body);;
                return this.crashes;
            }
        }).share().catch(error => {
            return this.HandleError(error);
        });

        return this.observable;
    }

    public GetCrashResolutionAsync(failureid: string, version:string): Observable<CrashPortalContract> {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl+'v3/crashResolution?failureid=' + failureid + '&version=' + version;
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if (response.status == 200) {
                return <CrashPortalContract>(response.body);
            }
        }).share().catch(error => {
            return this.HandleError(error);
        });

        return this.observable;
    }
    
    public UpdateCrashResolutionAsync(crash:CrashPortalContract): Observable<any>
    {
         let headers = this.utils.GetRequestHeaders();
         let body = JSON.stringify(crash);
         let url =this.odsUrl + 'v3/crashresolution';
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return true;
            }
            
            return false;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public CreateCrashResolutionAsync(crash:CrashPortalContract): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(crash);
        let url =this.odsUrl + 'v3/crashresolution';
        console.error(body);
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return true;
            }
            return false;
         
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });
        return this.observable;
    }

    // Delete crash wont auto delete the recovery associated with it, ui need to call delete recovery seperately
    public DeleteCrashResolutionAsync(failureid: string): Observable<Response>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v3/crashResolution?failureid=' + failureid;

        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    private HandleError(error: HttpErrorResponse)
    {
        this.observable = null;
        let errorMessage:string= "Something went wrong.";
        if (error instanceof HttpErrorResponse && !!error.status) {
            const err = JSON.stringify(error.error) || error.message;
            errorMessage = `${error.status} - ${error.statusText || '' } - Details ${err}`;
            if(error.status == 401)
            {
                errorMessage = localStorage.getItem("authInstruction");
            }
            if(error.status == 400)
            {
                errorMessage = `${JSON.stringify(error.error) || error.message}`;
            }
        } 

        let statuscode = !!error.status?error.status:"500";
       
        let data = {};
        data = {
            reason: errorMessage,
            status: statuscode
        };
        this.errorHandleService.openDialog(data)
        return Observable.throw(errorMessage);
    }

    public GetProblemSignatureXMLTemplatesAsync(): Observable<any> {
        let headers = this.utils.GetRequestHeaders();
        let url = this.diagPortalUrl + "/problemsignaturetemplates";
        this.observable = this.http.get( url, {headers:headers,observe:"response"}).map(response  => {
          if(response.status == 200)
            {
                let problemSignatureXmlTemplatesMap :KeyValuePair[] = <KeyValuePair[]>(response.body);
                return problemSignatureXmlTemplatesMap;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetChatMessagesAsync(sessionId:string, lastreadtime:string): Observable<ChatMessage[]> 
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl +"v2/chat?sessionid="+sessionId;
        if(!!lastreadtime)
        {
            url = url + "&lastreadtime="+lastreadtime;
        }

        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let ChatMessages :ChatMessage[] = <ChatMessage[]>(response.body);
                return ChatMessages;
            }
        }).share().catch(error=>
            {
                if (error instanceof Response)
                {
                    if(error.status == 401)
                    {
                        this.errorMessage = localStorage.getItem("authInstruction");
                    }
                    else if(error.status == 500)
                    {
                        // sometimes return 500 but most time success
                        this.errorMessage="";
                    }
                }
                return Observable.throw(this.errorMessage);             
            });

        return this.observable;
    }

    public sendMessageAsAgent(chatReq:ChatRequest): Observable<any> 
    {
        let headers = this.utils.GetRequestHeaders(chatReq.SessionId);
        let body = JSON.stringify(chatReq);
        let url=this.odsUrl+"v1/chat/agent";
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response =>{
            if(response.status == 200)
            {
                return this.observable;
            }
        }).share().catch(error=>
            {
                if(error.status == 400)
                {
                    // Tell the agent do not have permission to send message to user.
                    console.log(error.json());
                    return Observable.throw(error.json());
                }
                else if (error.status ==401)
                {
                    return Observable.throw(localStorage.getItem("authInstruction"));
                }
            });

        return this.observable;
    }

    public GetAgentsAsync(): Observable<Agent[]> 
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + "v1/diagportal/agents";
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let agents :Agent[] = <Agent[]>(response.body);;
                return agents;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public UpdateTicketAsync(ticketRequest:TicketRequest):Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(ticketRequest.SessionId);
        let body = JSON.stringify(ticketRequest);
        let url = this.odsUrl+"v1/chat/updateticket";
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
               let helpshiftstatuscode =<number>(response.body);
                if(helpshiftstatuscode==200)
                {
                    return true;
                }else{
                    return false;
                }
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetQueuesForUserAsync(client:string): Observable<TicketQueueInfo[]> 
    {
        this.trySignInBackIfTokenExpired();

        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl+'ticketqueues?client='+client;
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response => {
            if(response.status == 200)
            {
                let queues :TicketQueueInfo[] = <TicketQueueInfo[]>(response.body);
                return queues;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetTicketsInQueueAsync(queueId:string, client:string): Observable<SaraTicket[]> 
    {
        this.trySignInBackIfTokenExpired();

        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('queuetickets?queueid=', queueId).concat('&client=', client);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let tickets :SaraTicket[] = <SaraTicket[]>(response.body);
                return tickets;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetMyQueueAsync(userUpn:string,client:string): Observable<SaraTicket[]> 
    {
        this.trySignInBackIfTokenExpired();

        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('queuetickets?userupn=', userUpn).concat('&client=',client);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let tickets :SaraTicket[] = <SaraTicket[]>(response.body);
                return tickets;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public AddTicketNotesAsync(ticketRequest:TicketNoteRequest): Observable<any> 
    {
        let headers = this.utils.GetRequestHeaders(ticketRequest.SessionId);
        let body = JSON.stringify(ticketRequest);
        let url=this.odsUrl+"v1/note";
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response =>{
            if(response.status == 200)
            {
                return true;
            }else{
                return false;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetTicketNotesAsync(sessionId:string): Observable<TicketNote[]> 
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl.concat('v1/note?sessionid=', sessionId);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let ticketNotes :TicketNote[] = <TicketNote[]>(response.body);
                return ticketNotes;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }
    
    public AddTicketBugRelationAsync(request:TicketBugAssociationRequest): Observable<any> 
    {
        let headers = this.utils.GetRequestHeaders(request.SessionId);
        let body = JSON.stringify(request);
        let url=this.odsUrl+"bugticketrelation";
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response =>{
            if(response.status == 200)
            {
                return true;
            }else{
                return false;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public DeleteTicketBugRelationAsync(request:TicketBugAssociationRequest): Observable<any> 
    {
        let headers = this.utils.GetRequestHeaders(request.SessionId);
        let url=this.odsUrl+"bugticketrelation"+'?sessionid=' + request.SessionId + '&bugnumber=' + request.BugNumber + '&db=' + request.Database;
        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return true;
            }else{
                return false;
            }
            
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetTicketAssociatedBugsAsync(sessionId:string): Observable<TicketBug[]> 
    {
        let url = this.odsUrl.concat('bugticketrelation?sessionid=', sessionId);
        let headers = this.utils.GetRequestHeaders(sessionId);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let ticketBugs :TicketBug[] = <TicketBug[]>(response.body);;
                return ticketBugs;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetAllBugsFromTableAsync(sessionId:string, withInDays:string): Observable<TicketBug[]> 
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl.concat('bugs?withinDays=', withInDays);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                let allBugs :TicketBug[] = <TicketBug[]>(response.body);
                return allBugs;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public DeleteBugFromTableAsync(request:TicketBug): Observable<any> 
    {
        let headers = this.utils.GetRequestHeaders();
        let url=this.odsUrl+"bugs"+'?bugnumber=' + request.BugNumber + '&db=' + request.Database;
        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return true;
            }else{
                return false;
            }
            
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public UpdateBugFromTableAsync(req:TicketBug):Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(req);
        let url = this.odsUrl+"bugs";
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
               return true;
            }else{
                return false;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetSessionAuthType(sessionId:string): Observable<string> 
    {
        this.trySignInBackIfTokenExpired();

        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl.concat('authtype?sessionid=', sessionId);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  => {
            if(response.status == 200)
            {
                return <string>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });

        return this.observable;
    }

    public GetChatRecoveryCommandsAsync(): Observable<any> {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + "diagportal/chatRecoveries";
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response => {
            if(response.status == 200)
            {
                return <KeyValuePair[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetAutoRecoveryAsync(): Observable<any> {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + "diagportal/autoRecoveries";
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response => {
            if(response.status == 200)
            {
                return <RecoveryInfo[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public ExecuteChangeRequest(id:string, type:string, decision:string, client:string) : Observable<ChangeRequest>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = null;
        let url = this.odsUrl.concat('v1/changerequest?id=', id).concat('&type=', type).concat('&decision=', decision).concat("&client=", client);
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return <ChangeRequest>(response.body);;
            }
            
            return false;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

       return this.observable;
    }

    public GetComingSoonAsync(id:string): Observable<PreviewPlace>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/previewplace?id=', id);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return <PreviewPlace>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public SubmitComingSoonAsync(req:PreviewPlace): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(req);
        this.observable = this.http.post(this.odsUrl + 'v1/previewplace', body, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public UpdateComingSoonAsync(req:PreviewPlace): Observable<any>
    {
        let body = JSON.stringify(req);
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.put(this.odsUrl + 'v1/previewplace', body, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public DeleteComingSoonAsync(id: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/previewplace?id=' + id;

        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetComingSoonListAsync(): Observable<PreviewPlace[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/previewplaces';
        this.observable = this.http.get(url, {headers:headers,
            observe: 'response'
          }).map(response =>
        {
            if(response.status == 200)
            {
                return <PreviewPlace[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetCrashRecoveryAsync(client:string, failure:string): Observable<CrashRecoveryPortalContract>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/crashrecovery?client=', client).concat('&failure=', failure);
        return this.http.get<CrashRecoveryPortalContract>(
            url, {headers:headers,
                observe: 'response'
              }).map(response=>
                {
                    return <CrashRecoveryPortalContract>(response.body);
                }) .catch( error => {
                    return this.HandleError(error); 
                });
    }

    public SubmitCrashRecoveryAsync(req:CrashRecoveryPortalContract): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(req);
        this.observable = this.http.post(this.odsUrl + 'v1/crashrecovery', body, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });
        return this.observable;
    }

    public UpdateCrashRecoveryAsync(req:CrashRecoveryPortalContract): Observable<any>
    {
        
        let body = JSON.stringify(req);
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.put(this.odsUrl + 'v1/crashrecovery', body, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public DeleteCrashRecoveryAsync(client:string, failure:string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/crashrecovery?client=', client).concat('&failure=', failure);

        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetCrashRecoveryListAsync(client?:string): Observable<CrashRecovery[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/crashrecoveries';
        if(!!client)
        {
            url = url+"?client="+client;
        }
        
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                this.crashRecoveryList = <CrashRecovery[]>(response.body);
                return this.crashRecoveryList;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetCachedCrashRecoveryList()
    {
        return this.crashRecoveryList;
    }

    public GetParamSchema(): Observable<ParameterSchemaNode[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/paramschema';
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <ParameterSchemaNode[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public GetDataCollectorResultAsNodes(sessionid:string): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let headers  = this.utils.GetRequestHeaders(sessionid);
        let url = this.odsUrl + 'v1/datacollectorresultasnodes?sessionid='+sessionid;
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return (response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }

    public GetAllIncidents(): Observable<IncidentTableEntity[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'diagportal/incidents';
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <IncidentTableEntity[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }

    public GetAllTestIncidents(): Observable<IncidentTableEntity[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/testincidents';
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <IncidentTableEntity[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);
            });

        return this.observable;
    }

    public UpdateTestIncident(req: Incident): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/testincident';
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public SubmitTestIncident(req: Incident): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let url = this.odsUrl + 'v1/testincident';
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public DeleteTestIncident(incidentId: string): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/testincident/incidentId=', incidentId);
        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public GetAllChangeRequestsAsync(): Observable<ChangeRequest[]>
    {
        let url = this.odsUrl.concat('v1/changerequests');
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            return <ChangeRequest[]>(response.body);
        }).share().catch(error=>
        {
            return this.HandleError(error);                
        });

        return this.observable;
    }

    public GetChangeRequestApproverListAsync(client:string): Observable<string[]>
    {
        let url = this.odsUrl.concat('v1/changerequestapprover?app=',client);
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            return <string[]>(response.body);
        }).share().catch(error=>
        {
            return this.HandleError(error);                
        });

        return this.observable;
    }

    public AddChangeRequestApproverAsync(app:string, upn:string): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        var req = new TSMap<string,string>();
        req.set("app", app);
        req.set("upn", upn);
        let body = JSON.stringify(req);
        const url = this.odsUrl + 'v1/changerequestapprover';
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
                return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public DeleteChangeRequestApproverAsync(app:string, upn: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/changerequestapprover?upn=', upn).concat('&app=',app);

        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetIncidentAsync(id:string): Observable<IncidentPortalContract>
    {
        this.trySignInBackIfTokenExpired();
        let url = this.odsUrl.concat('v1/diagportal/incident?incidentid=', id);
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            return <IncidentPortalContract>(response.body);
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }

    public UpdateIncidentAsync(req:IncidentPortalContract): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        const url = this.odsUrl + 'v1/diagportal/incident';
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
                return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetArticleDetailsAsync(recoveryInfo:RecoveryInfo): Observable<RecoveryInfo>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(recoveryInfo);
        let url = this.odsUrl.concat('v1/articledetails');
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <RecoveryInfo[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetSupportEntitlementUserAsync(client:string, upn:string): Observable<SupportEligibilityUserContract>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/supporteligibilityuser?upn=', upn).concat('&client=', client);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <SupportEligibilityUserContract>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }
    
    public GetAllSupportEntitlementUsersAsync(client:string): Observable<SupportEligibilityUser[]>
    {
        this.trySignInBackIfTokenExpired();
        let url = this.odsUrl.concat('v1/supporteligibilityuserlist?client=',client);
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <SupportEligibilityUser[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }

    public UpdateSupportEntitlementUserAsync(req:SupportEligibilityUserContract): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v2/supporteligibilityuser';
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public SubmitSupportEntitlementUserAsync(req:SupportEligibilityUserContract): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let url = this.odsUrl + 'v2/supporteligibilityuser';
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public DeleteSupportEntitlementUserAsync(client:string, upn: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v2/supporteligibilityuser?upn=', upn).concat('&client=',client);
        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public trySignInBackIfTokenExpired()
    {
        // if(this.utils.GetIfTokenExpired())
        // {
        //     this.adalService.logOut();
        //     this.adalService.logIn();
        // }
    }

    public UpdateRecoveryInfoAsync(recoveryInfo:RecoveryInfo): Observable<any>
    {
         let headers = this.utils.GetRequestHeaders();
         let body = JSON.stringify(recoveryInfo);
         let url =this.odsUrl + 'v1/recoveries';
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return true;
            }
            
            return false;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public CreateRecoveryInfoAsync(recoveryInfo:RecoveryInfo): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(recoveryInfo);
        let url =this.odsUrl + 'v1/recoveries';
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return true;
            }
            
            return false;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public UploadImageAsync(imageInfo:RecoveryImageInfo): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(imageInfo);
        let url = this.odsUrl + 'v1/image';
        this.observable = this.http.post(url, body, {headers:headers, observe:"response"}).map(response => 
        {
            if(response.status == 200)
            {
                return <string>(response.body);
            }
        }).share().catch(error =>
            {
                return this.HandleError(error);
            });
        return this.observable;
    }

    public deleteImageAsync(fileName:string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/image?fileName=' + fileName;
        this.observable = this.http.delete(url, {headers:headers, observe:"response"}).map(response => 
        {
            if (response.status == 200)
            {
                return true;
            }
            return false;
        }).share().catch(error =>
            {
                return this.HandleError(error);
            })
        return this.observable;
    }

    public GetNotificationFeedbackAsync(time:string, id:string): Observable<NotificationFeedbackTableData>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/notificationfeedback?time=', time).concat('&id=', id).concat('&type=', "single");
        return this.http.get<NotificationFeedbackTableData>(
            url, {headers:headers,
                observe: 'response'
              }).map(response=>
                {
                    return <NotificationFeedbackTableData>(response.body);
                }) .catch( error => {
                    return this.HandleError(error); 
                });
    }

    public GetNotificationFeedbackListAsync(starttime:string, endtime:string): Observable<NotificationFeedbackTableData[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/notificationfeedback?starttime=', starttime).concat('&endtime=', endtime).concat('&type=', "list");
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <NotificationFeedbackTableData[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });


        return this.observable;
    }

    public GetLocalizationStatusAsync(id:string,singleRecovery:boolean): Observable<LocDictionary>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url='';
        if(!singleRecovery)
        {

            url = this.odsUrl.concat('v1/localizationstatus?psid=', id);
        }
        else{
            url = this.odsUrl.concat('v1/localizationstatus?recoveryid=', id);
        }
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <LocDictionary>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });

        return this.observable;
    }

    public UploadExcel(csv: string) : Observable<BulkUploadResult>
    {  
        this.trySignInBackIfTokenExpired();
        let url = this.odsUrl.concat('v1/bulkuploadrecoveries');
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(csv);
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                let res : BulkUploadResult = <BulkUploadResult>response.body; 
                return res;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
      }  

    public AuthUserAndRefileTicket(sessionId: string) : Observable<any>
    {  
        this.trySignInBackIfTokenExpired();
        let url = this.odsUrl.concat('v1/authuserandrefileticket');
        let headers = this.utils.GetRequestHeaders(sessionId);
        let body = JSON.stringify(sessionId);
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                let session : SaraTicket = <SaraTicket>response.body; 
                return session;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
      }  

      public GetNGramEntryListAsync(starttime:string, endtime:string, symptom:string): Observable<NGramEntry[]>
      {
          this.trySignInBackIfTokenExpired();
          let headers = this.utils.GetRequestHeaders();
          let url = this.odsUrl.concat('engineeringanalysis/toptrigrams?startDate=', starttime).concat('&endDate=', endtime);
          if (!!symptom && symptom!="All")
          {
              url = url.concat("&symptom=", symptom);
          }
          this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
          {
              if(response.status == 200)
              {
                  return <NGramEntry[]>(response.body);
              }
          }).share().catch(error=>
              {
                  return Observable.throw(error);      
              });
  
          return this.observable;
    }

    public testRuleMatchBetweenProblemSignatureAndSession(sessionid:string, problemSignatureId:string, problemSignatureClient:string): Observable<ProblemSignatureVsSessionRuleMatchResult>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/rulematchbysession?sessionid=', sessionid).concat('&psid=', problemSignatureId).concat('&psclient=', problemSignatureClient);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <ProblemSignatureVsSessionRuleMatchResult>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);      
            });

        return this.observable;
    }

    public TestRuleMatchBySessionWithAllProblemSignatures(sessionid:string): Observable<sessionRuleMatchResultDictionary>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/rulematchbysession?sessionid=', sessionid);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <sessionRuleMatchResultDictionary>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);     
            });

        return this.observable;
    }

    public GetSessionAttributes(request:SessionAttributesContract): Observable< Map<string, Map<string, string>>>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(request);
        let url = this.odsUrl.concat('engineeringanalysis/sessionattributes');
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <Map<string, Map<string, string>>>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetEngineeringAnalysisProperties(): Observable<string[]>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('engineeringanalysis/getproperties');
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <string[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public GetEngineeringAnalysisPropertyValues(property:string): Observable<KeyValuePair[]>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('engineeringanalysis/getpropertyvalues?property=', property);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <KeyValuePair[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public GetPropertyTree(request:PropertyNameValuesPair[]): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let body = JSON.stringify(request);
        let url = this.odsUrl.concat('engineeringanalysis/propertytree');
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public RuleMatchByFile(fileContent:string, id:string, client:string): Observable<ProblemSignatureVsSessionRuleMatchResult>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/rulematchbyfile');
        var req = new TSMap<string,string>();
        req.set("filejson", fileContent);
        req.set("id", id);
        req.set("client", client);
        let body = JSON.stringify(req);
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <ProblemSignatureVsSessionRuleMatchResult>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
	public GetFeedbackListAsync(client:string, symptom:string, starttime:string, endtime:string): Observable<FeedbackSessionTableData[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/feedbacks?starttime=', starttime).concat('&endtime=', endtime).concat('&client=', client).concat('&symptom=', symptom);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <FeedbackSessionTableData[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });
            

        return this.observable;
    }
	public GetSymptomsForClientAsync(client:string): Observable<string[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('/v2/feedback/symptoms?clientname=', client);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <string[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });
            

        return this.observable;
    }
    public GetExperimentFlightsAsync(): Observable<ExperimentFlight[]>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/oncall/configflight');
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <ExperimentFlight[]>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });
            

        return this.observable;
    }

    public GetExperimentFlightAsync(env:string, flightName:string): Observable<ExperimentFlight>
    {
        this.trySignInBackIfTokenExpired();
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/oncall/configflight?env=', env).concat('&flightname=', flightName);
        this.observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <ExperimentFlight>(response.body);
            }
        }).share().catch(error=>
            {
                return Observable.throw(error);      
            });
            

        return this.observable;
    }

    public UpdateExperimentFlightAsync(req:ExperimentFlight): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/oncall/configflight';
        this.observable = this.http.put(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }

    public SubmitExperimentFlightAsync(req:ExperimentFlight): Observable<any>
    {
        this.trySignInBackIfTokenExpired();
        let body = JSON.stringify(req);
        let url = this.odsUrl + 'v1/oncall/configflight';
        let headers = this.utils.GetRequestHeaders();
        this.observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
    
    public DeleteExperimentFlightAsync(env:string, flightName: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/oncall/configflight?env=', env).concat('&flightname=', flightName);
        this.observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return this.observable;
    }
}