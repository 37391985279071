  <div class="flex-container3">
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/crashrecovery', 0,0]" right="align">+ Create Crash Recovery</a>
    </div>
    <div style="height:100%">
        <mat-card >
              <mat-card-title>Crash Recoveries</mat-card-title>
              <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
                  <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                          <tr>
                              <th>Client
                              </th>
                              <th>Friendly Name
                              </th>
                              <th>Failure
                              </th>
                              <th>Audience
                              </th>
                              <th>Last Modified Time
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of filteredList">
                              <td>{{ getClient(item.Client) }}</td>
                              <td><a [routerLink]="['/crashrecovery', item.Client, item.FailureCondition]">{{ item.FriendlyName }}</a></td>
                              <td>{{ item.FailureCondition }}</td>
                              <td>{{ item.Audience }}</td>
                              <td>{{ item.LastModifiedTime| date:'yyyy/MM/dd HH:mm' }}</td>
                          </tr>
                      </tbody>
                  </table>
              </mat-card-content>
          </mat-card>
    </div>
  </div>
  