import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService
{
    private odsppeRootUrl: string = 'https://api.diagnosticssdf.office.com/';
    private odsprodRootUrl: string = 'https://api.diagnostics.office.com/';
    private odssdfRootUrl:  string = 'https://api.diagnosticssdf.office.com/';
    private environment: string = 'PROD';
    public odsControllerUrl: string='';
    constructor()
    {
        if (this.environment === 'PPE') 
        {
            this.odsControllerUrl = this.odsppeRootUrl;
        }
        else if(this.environment === 'PROD') 
        {
            this.odsControllerUrl = this.odsprodRootUrl;
        }
        else if(this.environment === 'SDF') 
        {
            this.odsControllerUrl = this.odssdfRootUrl;
        }
    }
}