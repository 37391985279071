<div style="margin-bottom: 10px; margin-top: 10px; padding-left: 10px;">
  <span class="glyphicon glyphicon-download-alt" style="cursor: pointer" (click)="downloadFile()"></span><span style="font-size: small;cursor: pointer" (click)="downloadFile()"> Export to CSV</span>
</div>
<mat-form-field appearance="standard" style="padding-left:12px;margin-bottom: -10px;width: 100%;">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>

<mat-paginator [pageSizeOptions]="[50, 100, 200, 500, 1000]"></mat-paginator>
<div style="margin-bottom: 15px;"></div>

 <section class="example-container mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort style="font-family: 'Segoe UI', 'Segoe', 'SegoeUI-Regular-final', Tahoma, Helvetica, Arial, sans-serif;">
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns;let j = index">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{column}} </th>
      <td mat-cell *matCellDef="let element"> {{getFieldValue(element, column)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
</section>