<head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="flex-container3" *ngIf="success&&!!incident">
    <mat-card style="word-break:break-word">
      <mat-card-title>Incident</mat-card-title>
      <mat-card-content>
            <div *ngIf="!!changeReqObj" style="margin-bottom: 20px">
                <div style="cursor: pointer;" (click)="ToggleChangeRequest()">
                    <i *ngIf="openChangeReq==false" class="fa fa-caret-right" style="margin-right:10px;color: grey"></i>
                    <i *ngIf="openChangeReq==true" class="fa fa-caret-down" style="margin-right:10px;color: grey"></i>
                    <span style="color:red">Pending Change Request Info</span>
                </div>
                <div *ngIf="openChangeReq==true" style="padding:18px;border: 1px solid #ccc;border-radius: 4px;background-color: #f5f5f5">
                    <a [routerLink]="[ '/changerequest',changeReqObj.Incident.Id,'incident','approved']" style="padding: 8px 12px; border: 1px solid #4682B4;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #4682B4; margin-right: 10px">
                        Approve</a>
                    <a [routerLink]="[ '/changerequest',changeReqObj.Incident.Id,'incident','denied']" style="padding: 8px 12px; border: 1px solid #FA8072;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #FA8072;">
                            Deny</a>
                </div>
            </div>

        <table>
            <tr>
                <th style="width: 20%">Friendly Id</th> <td>{{incident.FriendlyId}}</td>
            </tr>
            <tr>
                <th  style="width: 20%">Classification</th> <td>{{incident.Classification}}</td>
            </tr>
            <tr>
                <th  style="width: 20%">Title</th> <td>{{incident.Title}}</td>
            </tr>
            <tr>
                <th style="width: 20%">Impact</th> <td>{{incident.ImpactDescription}}</td>
            </tr>
            <tr>
                <th style="width: 20%">Feature</th> <td>{{incident.FeatureGroupName}}</td>
            </tr>
            <tr>
                <th style="width: 20%">Workload</th> 
                <td>{{getWorkloadNames(incident.AffectedWorkload)}}</td>
            </tr>
            <tr>
                <th  style="width: 20%">Start Date</th> <td>{{incident.StartDate| date:'yyyy/MM/dd HH:mm'}}</td>
            </tr>
            <tr>
                <th  style="width: 20%">Last Updated Time</th> <td>{{incident.LastUpdatedTime| date:'yyyy/MM/dd HH:mm'}}</td>
            </tr>
            <tr>
                <th style="width: 20%">End Date</th> <td>{{incident.EndDate| date:'yyyy/MM/dd HH:mm'}}</td>
            </tr>
            <tr>
                <th  style="width: 20%">Messages</th> 
                <td>
                        <div style="margin-top: 30px;margin-bottom: 30px">
                                <div style="cursor: pointer;" (click)="onToggle()">
                                      <i *ngIf="open==false" class="fa fa-caret-right" style="margin-right:10px;color: grey"></i>
                                      <i *ngIf="open==true" class="fa fa-caret-down" style="margin-right:10px;color: grey"></i>
                                      <span matBadge="{{incident.Posts.length}}" matBadgeOverlap="false"><span>Messages</span></span>
                                </div>
                                <div *ngIf="open==true" style="padding:18px;border: 1px solid #ccc;border-radius: 4px;background-color: #f5f5f5">
                                      <div *ngIf="!!incident.Posts && incident.Posts.length>0" >
                                            <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 80%">
                                                                Message
                                                            </th>
                                                            <th style="width: 20%">
                                                                Created Time
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of incident.Posts">
                                                            <td>{{ item.Message }}</td>
                                                            <td>{{ item.CreatedTime| date:'yyyy/MM/dd HH:mm' }}</td>
                                                        </tr>
                                                    </tbody>
                                            </table>
                                      </div>                  
                                </div>
                          </div>
                </td>
            </tr>
      </table>
        <div *ngIf='auditHistory.length>0'>
            <history-display [history]="auditHistory" #historyComponent></history-display>
        </div>
      </mat-card-content>
    </mat-card>
</div>
