import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { CrashRecovery } from '../shared/models/WfsModels';
import { ClientName } from '../shared/models/ConstValues';
import { GlobalService } from '../shared/services/global.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';

@Component({
  selector: 'app-crash-list',
  templateUrl: './crash-list.component.html',
  styleUrls: ['../app.component.css']
})
export class CrashListComponent implements OnInit, OnDestroy{
  public filteredList:CrashRecovery[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private util:Utils, private globalService:GlobalService, private notificationBarService:NotificationBarService){}

  ngOnInit() {
    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 100,
        processing: true,
        order: [[4, 'desc']]
      };
    let client=this.globalService.getClient();
    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetCrashRecoveryListAsync(client).subscribe(response => 
        {
            this.finalize(sub);
            this.filteredList = response;
            if (!response || response.length == 0)
            {
                this.notificationBarService.openSnackBar("no crash recoveries exist.",'','Info');
                return;
            }

            this.dtTrigger.next();
        },error =>
        {
            this.finalize(sub);
        });
  }

  ngOnDestroy() {
    this.notificationBarService.dismiss();
    this.dtTrigger.unsubscribe();
  }

private finalize(subscription: Subscription):void
{
    this.spinService.displayLoader(false);
    if(subscription)
    {
        subscription.unsubscribe();
    }
}

public getClient(client:string)
{
    if (client.toLowerCase()==ClientName.OUTLOOKDESKTOP){
        return "Outlook Desktop";
    } 
    else if(!!client)
    {
        return client.toUpperCase();
    }
}
}
