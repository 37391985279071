import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagListComponent } from './tag-list.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {FlexLayoutModule} from "@angular/flex-layout";
import { DataTablesModule } from 'angular-datatables';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [TagListComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MatCardModule, MatCheckboxModule,MatExpansionModule,MatTooltipModule,MatSidenavModule,MatTabsModule,MatSelectModule,MatSlideToggleModule,
    FlexLayoutModule,
    DataTablesModule
  ]
})
export class TagsModule { }
