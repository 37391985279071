import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntitleListComponent } from './entitle-list.component';
import { EntitleDetailComponent } from './entitle-detail.component'

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'entitlements',
          children: [
              {
                  path: '',
                  component: EntitleListComponent,
              },
              {
                  path: ':Id',
                  component: EntitleDetailComponent,
              }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class EntitlementRoutingModule { }
