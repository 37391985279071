import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { TicketTag } from 'src/app/shared/models/WfsModels';
import { GlobalService } from 'src/app/shared/services/global.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { WfsService } from 'src/app/shared/services/wfs.service';
import { NotificationBarService } from 'src/app/shared/sharedmodule/notificationbar.component';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['../../app.component.css']
})
export class TagListComponent implements OnInit, OnDestroy {
  public success:boolean;
  public client:string;
  public allTags:TicketTag[];
  public form: FormGroup;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private formBuilder: FormBuilder,
    private spinService:SpinnerService,
    private service: WfsService,
    private notificationBarService:NotificationBarService,
    private globalService:GlobalService) 
  {
    this.client = this.globalService.getClient();
    this.form = this.formBuilder.group({
      tag:['', Validators.required],
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true
    };
    this.getAllTags();
  }
  
  ngOnDestroy():void
  {
      this.dtTrigger.unsubscribe();
  }

  private getAllTags()
  {
    this.spinService.displayLoader(true);
    let sub = this.service.GetTicketTagListAsync().subscribe(
      response => {
        this.allTags = response;
        if(!this.allTags || this.allTags.length <=0)
        {
          this.notificationBarService.openSnackBar("No tags found",'','Info');
        }

        this.success=true;
        this.dtTrigger.next();
        this.finalize(sub);
        this.spinService.displayLoader(false);
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }

  public onAddNewTag()
  {
    this.spinService.displayLoader(true);
    let newTicketTag = new TicketTag();
    newTicketTag.TagName = this.form.value.tag.trim();
    let sub = this.service.AddTicketTagAsync(newTicketTag).subscribe(
      response => {
          this.form.reset();
          this.finalize(sub);
          this.getAllTags();
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }

  public onRemoveTag(ticketTag:TicketTag)
  {
    this.spinService.displayLoader(true);
    let sub = this.service.DeleteTicketTagAsync(ticketTag.Client, ticketTag.TagName).subscribe(
      response => {
        this.spinService.displayLoader(false);
        this.finalize(sub);
        this.getAllTags();
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }

  private finalize(subscribtion:Subscription): void {
    if (subscribtion) {
        subscribtion.unsubscribe();
    } 
  }
}
