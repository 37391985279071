<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<div class="appparent" style="display: flex;flex-direction: column;overflow: hidden">
    <header style="flex: 0 1 auto;height: 50px;">
            <div class="navheader" role="banner">
                <div *ngIf="isUserAuthorized&&!!clientList" class="navheader1">
                    <select  *ngIf="!comesFromOfficeLogs" class="client-dropdown" (change)="onToggerClientSelection($event.target.value)">
                        <option *ngFor="let item of clientList" [value]="item" [selected]="getClientSelection(item)">
                        <span>{{item}}</span>                          
                        </option>
                    </select>
                    <div *ngIf="comesFromOfficeLogs" style="margin-right: 40px;"></div>
                </div>
                <div style="text-align: center; flex: 1 0 auto;">
                    <span class="navheader2">Office Diagnostic Service Portal</span>
                </div>
                <div class="navheader3" style="display: flex; flex: 0 0 auto;">
                    <button *ngIf="isLoggedIn" class="accountSection"  (click)="onClickAccountMenu()">
                        <div style="width: 32px;height: 32px;position: relative;line-height:33px;overflow: hidden;display: inline-block;border-radius: 50%;vertical-align: middle;">
                            <div style="background-color: rgb(218, 83, 44);font-size: 16px;position: absolute;left: 0;top: 0;border-radius: 50%;text-align: center;height: 100%;width: 100%;display: inline-block;color: #fff;">
                                <span>{{getInitials()}}</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
    </header>
        
    <div [class.hidden]="isLoggedIn&&!msal.isUserAuthenticated" class="accountMenu" *ngIf="openAccountMenu">
            <div>
                <h2 style="margin: 30px 0 20px 18px;">My Account</h2>
                <div class="accounttext">
                        <div >{{msal.currentUserName}}</div>
                        <div>{{msal.currentSmtpAddress}}</div>
                    <div><a href="javascript:;" style="text-shadow:none" (click)="msal.logout()" class="app-logout">Log out</a></div>
                </div>
            </div>
    </div>
    
     <main style="flex: 1 1 auto;">
           <div *ngIf="!isLoggedIn" style="
            text-align: center;
            position: absolute;
            width:  500px;
            z-index: 15;
            top: 50%;
            left: 45%;
            margin: -100px 0 0 -150px;">
                <h4>Welcome to ODS portal, please click the button to <button  *ngIf="!isLoggedIn" (click)="loginRedirect()" style="color:#fff;background-color: #337ab7">Login</button> </h4> 
           </div>
           <div *ngIf="success" style= "display: flex;flex-direction: row; flex-grow: 1; color: rgb(51, 51, 51);height:100%">
                    <div role="presentation" *ngIf="!comesFromOfficeLogs&&isUserAuthorized==true" >
                            <nav id="mySidebar" role="navigation" class="leftnavigation">
                                    <ul role="menubar" *ngIf="msal.isUserAuthenticated&&isUserAuthorized" class="navulclass">
                                        <li class="navitem navtoggle" (click)="toggleSideBar()">
                                            <i class="fa fa-bars" style="font-size: 16px;color: rgb(51, 51, 51);z-index: 1;margin-left: 10px;"></i>
                                        </li>
                                        <li class="navitem"> 
                                            <a class="navaclass" routerLink='/saratickets' role="button" routerLinkActive="active">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/queue.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Queues</div>
                                            </a></li>
                                        <li class="navitem">
                                            <a class="navaclass" routerLink="/custombuckets" routerLinkActive="active">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/cb.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Classify</div>
                                            </a></li>
                                        <li class="navitem" (click)="toggleOpenClassify()" style="width: inherit;">
                                            <a class="navaclass" style="width: inherit;">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/crash.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Crashes</div>
                                                <i class="fa fa-angle-down" *ngIf="!panelOpenState" id="downicon" style="margin-right: 10px;"></i>
                                                <i class="fa fa-angle-up" *ngIf="panelOpenState" id="upicon" style="margin-right: 10px;"></i>
                                            </a>
                                            <div *ngIf="panelOpenState">
                                                    <a class="navsubclass" routerLink="/crashes">All Crashes</a>
                                                    <a class="navsubclass" routerLink="/crashrecovery">Custom Recoveries</a>
                                            </div>
                                        </li>
                                        <li class="navitem" *ngIf="client=='outlookdesktop'">
                                            <a class="navaclass" routerLink="/comingsoons" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/comingsoon.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Coming Soon</div>
                                            </a></li>
                                        <li class="navitem" *ngIf="client=='outlookdesktop'">
                                            <a class="navaclass" routerLink="/incidents" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/incident.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Incidents</div>
                                            </a>
                                        </li>
                                        <li class="navitem">
                                            <a class="navaclass" routerLink="/changerequest" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/changerequest.png" style="margin-right: 10px;">
                                                <div class="navwordclass">Change Requests</div>
                                            </a></li>
                                        <li class="navitem">
                                            <a class="navaclass" routerLink="/entitlements" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">   
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/entitle.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Entitlements</div>
                                            </a></li>
                                        <li class="navitem" *ngIf="client=='outlookdesktop'">
                                            <a class="navaclass" routerLink="/notificationfeedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/feedback.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Notification Feedback</div>
                                            </a></li>
                                        <li class="navitem" (click)="toggleOpenAnalysis()" style="width: inherit;" *ngIf="client=='outlookdesktop'">
                                            <a class="navaclass" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/analysis.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Analysis <span style="font-size: smaller;font-style: italic;margin-left: 5px;">(Beta)</span></div>
                                                <i class="fa fa-angle-down" *ngIf="!panelOpenState_analysis" id="downicon" style="margin-right: 10px;"></i>
                                                <i class="fa fa-angle-up" *ngIf="panelOpenState_analysis" id="upicon" style="margin-right: 10px;"></i>
                                            </a>
                                            <div *ngIf="panelOpenState_analysis">
                                                    <a class="navsubclass" routerLink="/analysis">Top trigrams</a>
                                                    <a class="navsubclass" routerLink="/analysis/drilldown">Drill down</a>
                                            </div>
                                        </li>
                                        <li class="navitem">
                                            <a class="navaclass" routerLink="/feedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/feedbackbyapp.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Feedback</div>
                                            </a>
                                        </li>
                                        <li *ngIf="isServiceOncall" class="navitem" (click)="toggleOpenOncall()" style="width: inherit;">
                                            <a class="navaclass" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                <img src="https://cdn-profile-odsportal.azureedge.net/assets/oncall.svg" style="margin-right: 10px;">
                                                <div class="navwordclass">Oncall</div>
                                                <i class="fa fa-angle-down" *ngIf="!panelOpenState_oncall" id="downicon" style="margin-right: 10px;"></i>
                                                <i class="fa fa-angle-up" *ngIf="panelOpenState_oncall" id="upicon" style="margin-right: 10px;"></i>
                                            </a>
                                            <div *ngIf="panelOpenState_oncall">
                                                    <a class="navsubclass" routerLink="/recovery">Recovery</a>
                                                    <a class="navsubclass" routerLink="/changerequest/config">Team Approvers</a>
                                                    <a class="navsubclass" routerLink="/oncall/experimentflight">Experiment Flight</a>
                                                    <a class="navsubclass" routerLink="/oncall/testincidents">Test Incidents</a>
                                            </div>
                                        </li>
                                    </ul>
                            </nav>
                    </div>
                        
                    <div id="main" *ngIf="isUserAuthorized==true" class="mainview" style="width: inherit;">
                            <div class="spinner" [hidden]="!objSpinnerStatus"></div>
                            <router-outlet></router-outlet>
                    </div>
                    <div *ngIf="isUserAuthorized==false" style="width: 100%;height: 100%;">
                            <img style="padding: 0;display: block;margin: 0 auto; max-height: 100%;width: 100%;height: 100%;max-width: 100%;background-size:cover" src="https://cdn-profile-odsportal.azureedge.net/assets/PreviewImage.png">
                            <div class="authInstructionDiv" [innerHTML]="statusMessage"></div> 
                    </div>
            </div>

            <div [ngClass]="{'tokenexpireDiv': isTokenExpired}">
                <!-- Only show when session expired -->
                <div *ngIf="isTokenExpired==true">
                    <div class="tokenexpiremsg">
                            <p>Your session has expired, please <button (click)="refresh()" style="color:#fff;background-color: #337ab7">Refresh</button> the page to continue.</p>
                            <p>Have unsaved changes? click <button (click)="goback()" style="color:#fff;background-color: #337ab7">Go Back</button> to the page, and refresh the page manually</p>
                    </div>
                </div>
            </div>
    </main> 
</div>


