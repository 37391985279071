import { CrashResolutionListComponent } from './crashresolutionlist.component';
import { CrashDetailsComponent } from './crashdetails.component';
import { CrashSectionComponent } from './crashsection.component';
import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { CrashResolutionRouting } from './crashresolution.routing';
import { SharedModule } from '../shared/sharedmodule/shared.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        CrashResolutionRouting,
        DataTablesModule,
        MatCardModule,
        MatButtonModule,
        FlexLayoutModule,
        SharedModule
    ],
    declarations: [
        CrashSectionComponent,
        CrashResolutionListComponent,
        CrashDetailsComponent
    ],
})

export class CrashResolutionModule { }