import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CustomBucketDetailsComponent } from './custombucketdetails.component';
import { CustomBucketListComponent } from './custombucketlist.component';
import { CustomBucketSectionComponent } from './custombucketsection.component';
import { SessionMatchComponent } from './session-match.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'custombuckets',
                component: CustomBucketSectionComponent,
                children: [
                    {
                        path: '',
                        component: CustomBucketListComponent,
                    },
                    {
                        path: 'sessionmatch',
                        component: SessionMatchComponent,
                    },
                    {
                        path: ':Id/:Client',
                        component: CustomBucketDetailsComponent,
                    }
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class CustomBucketRoutingModule {
}