<div class="flex-container3">
            <mat-card>
                    <mat-card-title>Crashes</mat-card-title>
                    <mat-card-content>
                        <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>
                                        FailureID
                                    </th>
                                    <th>
                                        Channel
                                    </th>
                                    <th>
                                        Work Item No.
                                    </th>
                                    <th>
                                        Fix Build
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of filteredData">
                                    <td><a [routerLink]="['/crashes', item.FailureId, item.ChannelIdAndOfficeMajorVersion]">{{ item.FailureId.length > 87 ? (item.FailureId | slice:0:87) + '...' : item.FailureId }}</a></td>
                                    <td>{{ item.ChannelIdAndOfficeMajorVersion}}</td>
                                    <td>{{ item.WorkItemNumber}}</td>
                                    <td>{{ item.FixBuild}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
</div>