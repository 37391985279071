<div style="text-align: center; background: bisque;border-width: 0 0 1px;padding: 15px;">
  <span><i class="fa fa-warning" style="font-size:20px;color:#FBBD08!important;margin-right: 10px"></i> 
  This page is internal for Ricardo's team only, note: any action on this page will not be record in audit history. so use with caution.
  </span>
</div>
<div class="flex-container3" >

  <div class="row" style="margin-top: 20px;">  
    <div class="col-md-6">  
        <input class="form-control" type="file" #fileInput />  
    </div>  
    <div class="col-md-6">  
        <button class="btn btn-primary" (click)="upload();">Upload</button>  
    </div>  
  </div>  


  <div *ngIf='success'>
        <div style="margin-top: 10px;color: #000!important;background-color: #FAF9F8!important;padding: 0.01em 16px;">
            <h3>Summary</h3>
            <p *ngIf="errorList && errorList.length > 0">
                <span style="color:red;font-size: medium;"><i class="fa fa-exclamation-triangle"></i></span> ( <b>{{errorList.length}}</b> ) lines failed to upload to table, please modify and try reupload these lines.</p>
            <p *ngIf='uploadedList && uploadedList.length > 0'>
                <span style="color:green;font-size: large;">&#9745;</span> ( <b>{{uploadedList.length}}</b> ) recoveries has been successfully uploaded to table.</p>
        </div>
        <div *ngIf='errorList && errorList.length > 0' style="margin-top: 10px;">
            <mat-card>
                <mat-card-title>Failed ( {{errorList.length}} ) lines in csv</mat-card-title>
                <mat-card-content style="overflow:auto;margin:0 auto;word-break:normal">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let item of errorList">
                                <td>{{ item }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>

        <div *ngIf='uploadedList && uploadedList.length > 0' style="margin-top: 10px;">
        <mat-card>
            <mat-card-title>Successfully Uploaded ( {{uploadedList.length}} )</mat-card-title>
            <mat-card-content style="overflow:overlay;margin:0 auto;word-break:normal">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 15%">
                                Id 
                            </th>
                            <th style="width: 5%">
                                Name 
                            </th>
                            <th style="width: 5%">
                                Description 
                            </th>
                            <th style="width: 5%">
                                RecoveryTitle 
                            </th>
                            <th style="width: 10%">
                                AdminMsg 
                            </th>
                            <th style="width: 5%">
                                Area
                            </th>
                            <th style="width: 5%">
                                SymptomPath
                            </th>
                            <th style="width: 5%">
                                Type
                            </th>
                            <th style="width: 5%">
                                Audience
                            </th>
                            <th style="width: 5%">
                                RecoveryState
                            </th>
                            <th style="width: 5%">
                                CreatedBy
                            </th>
                            <th style="width: 5%">
                                UserMsg
                            </th>
                            <th style="width: 5%">
                                Authoritative
                            </th>
                            <th style="width: 5%">
                                ContactAdmin
                            </th>
                            <th style="width: 5%">
                                HasAFix
                            </th>
                            <th style="width: 5%">
                                SupportMsg
                            </th>
                            <th style="width: 5%">
                                ProblemDescription 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploadedList">
                            <td><a [routerLink]="['/recovery', item.Id]" target="_blank">{{ item.Id }}</a></td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.Name}}">
                                    {{ (!!item.Name && item.Name.length>10)? (item.Name | slice:0:10)+'...':(item.Name) }}
                                </div>
                            </td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.Description}}">
                                    {{ (!!item.Description && item.Description.length>10)? (item.Description | slice:0:10)+'...':(item.Description) }}
                                </div>
                            </td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.RecoveryTitle}}">
                                    {{ (!!item.RecoveryTitle && item.RecoveryTitle.length>10)? (item.RecoveryTitle | slice:0:10)+'...':(item.RecoveryTitle) }}
                                </div>
                            </td>
                            <td> 
                                <div mat-raised-button matTooltip="{{item.AdminMsg}}">
                                    {{ (!!item.AdminMsg && item.AdminMsg.length>10)? (item.AdminMsg | slice:0:10)+'...':(item.AdminMsg) }}
                                </div>
                            </td>
                            <td>{{ item.Area }}</td>
                            <td>{{ item.SymptomPath }}</td>
                            <td>{{ item.Type }}</td>
                            <td>{{ getAudience(item.Audience) }}</td>
                            <td>{{ item.RecoveryState }}</td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.CreatedBy}}">
                                    {{ (!!item.CreatedBy && item.CreatedBy.length>10)? (item.CreatedBy | slice:0:10)+'...':(item.CreatedBy) }}
                                </div>
                            </td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.UserMsg}}">
                                    {{ (!!item.UserMsg && item.UserMsg.length>10)? (item.UserMsg | slice:0:10)+'...':(item.UserMsg) }}
                                </div>
                            </td>
                            <td>{{ item.Authoritative }}</td>
                            <td>{{ item.ContactAdmin }}</td>
                            <td>{{ item.HasAFix }}</td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.SupportMsg}}">
                                    {{ (!!item.SupportMsg && item.SupportMsg.length>10)? (item.SupportMsg | slice:0:10)+'...':(item.SupportMsg) }}
                                </div>
                            </td>
                            <td>
                                <div mat-raised-button matTooltip="{{item.ProblemDescription}}">
                                    {{ (!!item.ProblemDescription && item.ProblemDescription.length>10)? (item.ProblemDescription | slice:0:10)+'...':(item.ProblemDescription) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
        </div>
  </div>
</div>
