<div (click)="disableContextMenu()" oncontextmenu="return false;">
	<section class="dcr-viewer">
			<section *ngFor="let parentNode of inputList" [ngClass]="['segment']">
				<section (click)="toggle(parentNode)"
					[ngClass]="{
					'segment-main': true,
					'expandable': isExpandable(parentNode),
					'expanded': parentNode.expanded
					}">
					<div *ngIf="isExpandable(parentNode)" class="toggler"></div>
					<span (contextmenu)="onRightClick($event, parentNode)" style="cursor: pointer;">{{parentNode.Name}}</span>
				</section>
				<section *ngIf="parentNode.expanded && isExpandable(parentNode)" class="children">
					<dcr-tree-view [inputList]="parentNode.Children" (addRule)="onAddRuleEventEmit($event)"></dcr-tree-view>
				</section>
			</section>
	</section> 
</div>

<div *ngIf="contextmenu==true">
	<div style="position:fixed;background-color: #c9ddff;padding: 4px;;cursor:pointer;box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);border-radius: 2px;" 
	(click) = disableContextMenu() [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
		<span (click)="onAddRule()">Add rule</span>
		<i  class="icon-circle, fa fa-close" style="color: burlywood;margin-left: 20px"></i>
	</div>
</div>
