import { ConfigService } from './config.service';
import { Utils } from '../Utils/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';
import {
    DiagnosticsFiles,
    SaraTicket,
    KeyValuePair,
    PayloadFlightState,
    TicketTag,
    FlightInfo
} from '../models/WfsModels';
import { Msal } from './msal.service';

@Injectable()
export class WfsService
{
    private odsUrl: string;
    constructor(private http:HttpClient, 
        private utils: Utils, 
        private config:ConfigService, 
        private msal:Msal) 
    {
        this.odsUrl = this.config.odsControllerUrl;
    }

    public GetSaraTickets(query:string): Observable<SaraTicket[]>
    {
        this.utils.SignInBackIfTokenExpired();
                    
        let headers = this.utils.GetRequestHeaders();
        let ticketUrl = this.odsUrl + 'v1/ticket/';

        if(query.indexOf('sid:') >= 0)
        {
            if(query.split(':')[1].length > 0)
            {
                ticketUrl = this.odsUrl + 'v1/diagnosticsession/?id=' + query.split(':')[1];
            }
        }
        if(query.indexOf('usr:') >= 0)
        {
            if(query.split(':')[1].length > 0)
            {
                ticketUrl = this.odsUrl + 'v1/diagnosticsession/?userupn=' + query.split(':')[1];
            }
        }

        if(query.indexOf('tid:') >= 0)
        {
            if(query.split(':')[1].length > 0)
            {
                ticketUrl = this.odsUrl + 'v1/diagnosticsession/?ticketid=' + query.split(':')[1];
            }
        }

        let observable = this.http.get(ticketUrl, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return <SaraTicket[]>(response.body);
            }
        }).share().catch(error=>
        {
            return this.HandleError(error);                
        });
         
         return observable;
    }
    
    public GetTicketBySessionId(id:string):Observable<SaraTicket>
    {
        this.utils.SignInBackIfTokenExpired(); 
        
        let url = this.odsUrl + 'v1/diagnosticsession/?id=' + id;
        let headers = this.utils.GetRequestHeaders(id);
        let observable = this.http.get(url,{headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                let tickets = <SaraTicket[]>(response.body);
                if(tickets&&tickets.length>0){
                    return tickets[0];
                }else{
                    return null;
                }
            }
        }).share().catch(this.HandleError);

        return observable;
    }

    public AutoTranslationOverrideOnSession(sessionId:string, disableAutoTranslation:boolean):Observable<SaraTicket>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl + 'v1/diagnosticsession/autotranslationoverride/';
        let obj = "{sessionId:'"+sessionId+"',disableAutoTranslation:" + disableAutoTranslation+"}";
        let body = JSON.stringify(obj);
        let observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                let session : SaraTicket = <SaraTicket>response.body; 
                return session;
            }
        }).share().catch(this.HandleError);
        return observable;
    }

    public GetSaraDiagnosticsFiles(sessionId:string) : Observable<DiagnosticsFiles[]>
    {
        let diagnosticsFiles:DiagnosticsFiles[];
        let headers = this.utils.GetRequestHeaders(sessionId);
        let ticketUrl = this.odsUrl + 'v1/files/' + sessionId;
        let observable = this.http.get(ticketUrl, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                diagnosticsFiles = <DiagnosticsFiles[]>(response.body);;

                for (var i = 0; i < diagnosticsFiles.length; i++)
                {
                    diagnosticsFiles[i].DownloadInProgress = false;

                    if (!diagnosticsFiles[i].FileType && diagnosticsFiles[i].FileName.indexOf(".") != -1)
                    {
                        diagnosticsFiles[i].FileType = diagnosticsFiles[i].FileName.substr(diagnosticsFiles[i].FileName.lastIndexOf("."), diagnosticsFiles[i].FileName.length - diagnosticsFiles[i].FileName.lastIndexOf("."));
                    }
                }
                return diagnosticsFiles;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        
        return observable;
    }

    // Download single ile
    public DownloadFile(fileId: string, sessionId:string): Observable<any>
    {
        const url = this.odsUrl.concat('v1/filedownload/',sessionId).concat('/',fileId);
        let headers = this.utils.GetRequestHeaders(sessionId);
        return this.http.get(url, {headers:headers,responseType: 'blob',observe:"response"})
        .catch(error=>
            {
                return this.HandleError(error);                
            });
    }

    public DownloadImage(url: string, sessionId:string): Observable<Blob>
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        return this.http.get(url, {headers:headers,responseType: 'blob'})
        .catch(error=>
            {
                return this.HandleError(error);                
            });
    }

    // Bulk download
    public FileBulkDownloadAsync(sessionId:string): Observable<any>
    {
        let url = this.odsUrl + 'v1/files/bulkdownload/' + sessionId;
        let headers = this.utils.GetRequestHeaders(sessionId);
        return this.http.get(url, {headers:headers,responseType: 'blob',observe:"response"})
        .catch(error=>
            {
                return this.HandleError(error);                
            });
    }

    // Download single file and return key value pair as result
    public GetFile(diagnosticResultFile:DiagnosticsFiles): Observable<KeyValuePair[]>
    {
        let headers = this.utils.GetRequestHeadersWithoutSessionId();
        const url =this.odsUrl.concat('v1/filedownload/',diagnosticResultFile.SessionId).concat('/',diagnosticResultFile.FileId);
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return <KeyValuePair[]>response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return observable;
    }

    public GetSessionClientFlights(sessionId: string): Observable<FlightInfo[]>
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl+'v2/file/clientflights/'+sessionId+'/';
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                const res = <PayloadFlightState[]>response.body;
                let mapped=res.map((r)=>{
                    let f =  new FlightInfo();
                     f.FlightName = r.FeatureName;
                     f.FlightValue = r.FeatureState;
                     f.IsOverriden = r.IsOverriden;
                     f.IsEnabled = !!r.FeatureState&&r.FeatureState.toLocaleLowerCase()!=="false";
                     return f;
                });
                return mapped;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });
        
        return observable;
    }

    public GetSessionClientFlightsV3(sessionId: string): Observable<FlightInfo[]>
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl+'v3/file/clientflights/'+sessionId+'/';
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return <FlightInfo[]>response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });
        
        return observable;
    }

    public GetSessionRegistry(sessionId: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl+'file/registry/'+sessionId+'/';
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        
        return observable;
    }

    public GetFileJson(blobName: string, sessionId:string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(sessionId);
        let url = this.odsUrl+'v1/file/json/'+blobName+'/';
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        
        return observable;
    }

    public GetFileContent_JSON(file: DiagnosticsFiles): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(file.SessionId);
        let url = this.odsUrl+'v1/unzipfile/'+file.SessionId+'/'+file.FileId;
        let observable = this.http.get(url, {headers:headers,responseType: 'json',observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });
        return observable;
    }

    
    public GetFileContent_Text(file: DiagnosticsFiles): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(file.SessionId);
        let url = this.odsUrl+'v1/unzipfile/'+file.SessionId+'/'+file.FileId;
        // v1/unzipfile api can handle the cases whether text file is in a zip folder or not itself
        let observable = this.http.get(url, {headers:headers,responseType: 'text',observe:"response"}).map(response =>
        {
            if(response.status == 200)
            {
                return response.body;
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);
            });
        return observable;
    }

    public GetDataCollectorResult(sessionid:string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders(sessionid);
        let url = this.odsUrl + 'v1/datacollectorresult?sessionid='+sessionid;
        let observable = this.http.get(url,  {headers:headers,observe:"response"}).map(response=>
        {
            if(response.status == 200)
            {
                return (response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return observable;
    }

    public HandleError(error: any)
    {
        let errorMessage:string = "Something went wrong. Please contact our support by emailing <a href=mailto:olksvcs@microsoft.com>olksvcs@microsoft.com</a> Thank you!";
        if(error.status == 400)
        {
            errorMessage = `${error.status} - Bad Request`;
        }

        if(error.status == 401 && this.msal.isUserAuthenticated)
        {
            errorMessage = localStorage.getItem("authInstruction");
        }
        
        if(error.status == 403)
        {
            errorMessage = "This session is not mapped to a support ticket and the data is not visible for compliance reasons. This will be the case if data was submitted as part of Search Feedback, Outlook Cannot Start, Account Config or Contact Support via Email scenarios. We are working on getting these scenarios enabled.";
        }

        return Observable.throw(errorMessage);
    }

    public GetTicketTagListAsync(): Observable<TicketTag[]>
    {
        let url = this.odsUrl.concat('v1/tickettag');
        let headers = this.utils.GetRequestHeaders();
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            return <TicketTag[]>(response.body);
        }).share().catch(error=>
        {
            return this.HandleError(error);                
        });

        return observable;
    }

    public AddTicketTagAsync(ticketTag:TicketTag): Observable<any>
    {
        let body = JSON.stringify(ticketTag);
        const url = this.odsUrl + 'v1/tickettag';
        let headers = this.utils.GetRequestHeaders();
        let observable = this.http.post(url, body, {headers:headers,observe:"response"}).map(response  =>
        {
                return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return observable;
    }

    public DeleteTicketTagAsync(app:string, tag: string): Observable<any>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/tickettag?tag=', tag).concat('&app=',app);

        let observable = this.http.delete(url, {headers:headers,observe:"response"}).map(response =>
        {
            return response.status == 200;
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return observable;
    }
    
    public GetAvailableTabs(id:string): Observable<string[]>
    {
        let headers = this.utils.GetRequestHeaders();
        let url = this.odsUrl.concat('v1/portaltabs/', id);
        let observable = this.http.get(url, {headers:headers,observe:"response"}).map(response  =>
        {
            if(response.status == 200)
            {
                return <string[]>(response.body);
            }
        }).share().catch(error=>
            {
                return this.HandleError(error);                
            });

        return observable;
    }
}