import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { IncidentTableEntity } from '../shared/models/WfsModels';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';

@Component({
  selector: 'app-testincident-list',
  templateUrl: '../createtestincident/testincident-list.component.html',
  styleUrls: ['../app.component.css']
})
export class TestIncidentListComponent implements OnInit, OnDestroy {
  public incidentList:IncidentTableEntity[];
  public filteredList:IncidentTableEntity[];
  public overwriteNotification:string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private notificationBarService:NotificationBarService){}
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
      this.dtTrigger.unsubscribe();
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      order: [[5, 'desc']]
    };
    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetAllTestIncidents().subscribe(response => 
        {
          this.finalize(sub);
          if (!response || response.length == 0)
          {
              this.notificationBarService.openSnackBar("No test incident exists.",'','Info');
              return;
          }

          this.incidentList = response;
          this.incidentList.forEach(x=>{
            if(!!x.Incident.Classification && (x.Incident.Classification.toLowerCase()=='advisory' 
              || x.Incident.Classification.toLowerCase()=='incident'
              ||x.Incident.Classification.toLowerCase()=='investigation'
              ||x.Incident.Classification.toLowerCase()=='close'))
              {
                x.Incident.ClassificationIcon = x.Incident.Classification.toLowerCase();
              } 
            })

          this.filteredList = this.incidentList;
          this.dtTrigger.next();
        },error =>
        {
            this.finalize(sub);
        }
    );
  }

  public onOverwriteNotificartionChange(value:string)
  {
    this.overwriteNotification = value;
  }
 
  public numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  private finalize(subscription: Subscription):void
  {
    this.spinService.displayLoader(false);
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }
}
