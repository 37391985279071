<div class="flex-container3">
  <mat-card>
    <mat-card-title>Test session rule matches, (Currently only support keyword and json simple rule match, ETL and json list rule are not supported)</mat-card-title>
    <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
      <input type="text" style="width: 300px" placeholder="session id" [(ngModel)]="sessionId_forTestRule" />
      <button (click)="testRuleMatchBySessionWithAllProblemSignatures()" style="margin-left:10px; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 2px 20px 0 rgba(0,0,0,0.19)">Test a session among all problem signatures</button>
  
      <div *ngIf="success">
        <div *ngIf="(!matchedProblemSignatures || matchedProblemSignatures.length == 0 )&&(!matchedRecoveries||matchedRecoveries.length==0)">
          <h4>Nothing matched with this session</h4>
        </div>

        <div *ngIf="!!matchedProblemSignatures && matchedProblemSignatures.length>0">
          <h4>Problem signtures matched</h4> 
          <table class="table">
            <thead>
                <tr>
                    <th style="width: 30%">
                        Id
                    </th>
                    <th style="width: 20%">
                        Name
                    </th>
                    <th style="width: 20%">
                        Trigger Condition
                    </th>
                    <th style="width: 5%">
                        Recovery Audience
                    </th>
                    <th style="width: 25%">
                        Recovery
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of matchedProblemSignatures">
                    <td><a [routerLink]="['/custombuckets', item.Id.toLowerCase(), item.Client]" target="_blank">{{item.Id}}</a></td>
                    <td>{{ item.FriendlyName }}</td>
                    <td>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Trigger Condition
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <textarea disabled style="min-width: 100%" rows="10" [(ngModel)]="item.ProblemSignatureXml"></textarea>
                        </mat-expansion-panel>
                    </td>
                    <td>{{ getAudience(item.RecoveryAudience) }}</td>
                    <td>{{ item.Recoveries.join(', ') }}</td>
                </tr>
            </tbody>
        </table>
      </div>
  
      <!-- Display matched recoveries -->
      <div *ngIf="!!matchedRecoveries && matchedRecoveries.length>0">
        <h4>Recoveries matched after filtered by flight audience</h4> 
        <table class="table">
          <thead>
              <tr>
                  <th style="width: 30%">
                      Id
                  </th>
                  <th style="width: 25%">
                      ProblemDescription
                  </th>
                  <th style="width: 25%">
                      RecoveryTitle
                  </th>
                  <th style="width: 5%">
                      Type
                  </th>
                  <th style="width: 15%">
                      Audience
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of matchedRecoveries">
                  <td>{{ item.Id }}</td>
                  <td>{{ item.ProblemDescription }}</td>
                  <td>{{ item.RecoveryTitle }}</td>
                  <td>{{ item.Type }}</td>
                  <td>{{ getAudience(item.Audience) }}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    </mat-card-content>
  </mat-card>
</div> 
