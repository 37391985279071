<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
  <div class="flex-container3" *ngIf="success">
        <mat-card>
            <mat-card-title style="color:grey;font-size: 28px">Crash Recovery</mat-card-title>
            <mat-card-content >

                <div *ngIf="!!changeReqObj" style="margin-bottom: 20px">
                    <div style="cursor: pointer;" (click)="ToggleChangeRequest()">
                        <i *ngIf="openChangeReq==false" class="fa fa-caret-right" style="margin-right:10px;color: grey"></i>
                        <i *ngIf="openChangeReq==true" class="fa fa-caret-down" style="margin-right:10px;color: grey"></i>
                        <span style="color:red">Pending Change Request Info</span>
                    </div>
                    <div *ngIf="openChangeReq==true" style="padding:18px;border: 1px solid #ccc;border-radius: 4px;background-color: #f5f5f5">
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Recovery Title</label>
                            {{changeReqObj.Recovery.RecoveryTitle}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Problem Description</label>
                            {{changeReqObj.Recovery.ProblemDescription}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Recovery Steps</label>
                            {{changeReqObj.Recovery.RecoverySteps}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Has A Fix</label>
                            {{changeReqObj.Recovery.HasAFix}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Link</label>
                            {{changeReqObj.Recovery.Link}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Audience</label>
                            {{changeReqObj.Recovery.Audience.join(',')}}<br>
                        </div>
                        <a [routerLink]="[ '/changerequest',changeReqObj.Recovery.Id,'crashrecovery','approved']" style="padding: 8px 12px; border: 1px solid #4682B4;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #4682B4; margin-right: 10px">
                            Approve</a>
                        <a [routerLink]="[ '/changerequest',changeReqObj.Recovery.Id,'crashrecovery','denied']" style="padding: 8px 12px; border: 1px solid #FA8072;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #FA8072;">
                                Deny</a>
                    </div>
                </div>

                <form [formGroup]="form">
                  <div>
                    <div [ngClass]="{'inactiveDiv': hasPendingChangeRequest}">
                        <div class="form-group" [ngClass]='{"inactiveDiv": !isNew}'>  
                          <label style="width: 100%">
                            Failure
                            <input class="form-control" formControlName="failureCondition" required pattern="^[^\s]+(\s+[^\s]+)*$" ng-pattern-restrict style="font-weight: normal">
                            <small *ngIf="!form.controls.failureCondition.valid" class="text-danger">Required, without whitespace at begining and ending (Use either a full FailureID [EG: 'application_fault_28e01db_mso.dll!lookupdialogprocv2'] or just the FailureImageName [eg: 'mso.dll!lookupdialogprocv2'])</small>
                          </label>
                        </div>

                        <div class="form-group" *ngIf="!!friendlyName">  
                              <label style="width: 100%">Friendly Name</label>
                              <p style="font-weight: normal">{{friendlyName}}</p>
                        </div>
                    </div>

                    <recovery-detail *ngIf='success' [recovery]="recovery" [haspendingrequest]="hasPendingChangeRequest" #recoveryComponent></recovery-detail>                
                    <loc-status [localized]="recoveryId" page="singleRecovery" #locstatusComponent></loc-status>
                </div>
                  <div>
                      <button class="bluewhitebutton" style="float: right" [routerLink]="[ '/crashrecovery']">Cancel</button>
                      <button class="bluewhitebutton" style="float: right;margin-right: 10px;"  [disabled]="!form.valid" type="button" (click)="Submit()">Save</button>
                      <button class="bluewhitebutton" [disabled]='isNew' style="float: right;margin-right: 10px;" (click)="Delete()">Delete</button>
                      <div *ngIf='hasPendingChangeRequest' style="color:red">* Cannot make changes to this item because of a pending change request.</div>
                  </div>

                  <div *ngIf='auditHistory.length>0'>
                      <history-display [history]="auditHistory" #historyComponent></history-display>
                  </div>
                </form>
            </mat-card-content>
        </mat-card>
</div>




