
<div class="flex-container3" *ngIf="success">
  <mat-card style="display: flex; flex-flow: column; align-items: stretch;">
    <mat-card-title style="color:grey;font-size: 28px">Experiment flight details</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form">
        <div class="form-group">
          <select id="EnvironmentNameId" name="EnvironmentName" formControlName="EnvironmentName">
            <option [ngValue]="item" *ngFor="let item of envList">
              {{item}}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="FlightNameId">Flight Name</label>
          <input class="form-control" id="FlightNameId" type="text" placeholder="enter Flight Name (required)" maxlength="50" formControlName="FlightName" />
          <small *ngIf="form.controls.FlightName.invalid" class="text-danger">Required</small>
        </div>
        <div class="form-group">
          <label for="FlightEnabledId">Flight Enabled</label>
          <input type="checkbox" id="FlightEnabledId" name="FlightEnabled" formControlName="FlightEnabled">
        </div>
        <div class="form-group">
          <label for="FlightPercentageId">Flight Percentage</label>
          <input class="form-control" id="FlightPercentageId" type="number" pattern="\d{1,2}(?!\d)|100" ng-pattern-restrict formControlName="FlightPercentage" />
          <small *ngIf="!form.controls.FlightPercentage.valid" class="text-danger">Only 1 to 100</small>
                         
        </div>
        <div class="form-group">
          <label for="FlightSaltId">Flight Salt</label>
          <input class="form-control" id="FlightSaltId" type="number" formControlName="FlightSalt" />
        </div>
        <div class="form-group">
          <label>Flighted Users</label>
          <mat-form-field style="display: inline;">
              <mat-chip-list #chipList>
              <mat-chip *ngFor="let item of form.get('FlightedUsers').value" [selectable]="selectable"
                          [removable]="removable" (removed)="removeUser(item)">
                  {{item}}
                  <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
              </mat-chip>
              <input placeholder="Flighted User upns..."
                      maxlength="100"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="addUser($event)">
              </mat-chip-list>
          </mat-form-field>
        </div>
      </form>

      <div>
        <button class="bluewhitebutton" style="float: right" [routerLink]="[ '/oncall/experimentflight']">Cancel</button>
        <button class="bluewhitebutton" style="float: right;margin-right: 10px;"  [disabled]="!form.valid" type="button" (click)="onSubmit()">Save</button>
        <button class="bluewhitebutton" [disabled]='isNew' style="float: right;margin-right: 10px;" (click)="onDelete()">Delete</button>
    </div>
    </mat-card-content>
  </mat-card>
</div>