import { Utils } from '../shared/Utils/utils';
import {SpinnerService} from '../shared/services/spinner.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WfsService } from '../shared/services/wfs.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { SaraTicket, Agent, TicketQueueInfo, TicketRequest } from '../shared/models/WfsModels';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs/Rx';
import { GlobalService } from '../shared/services/global.service';
import { DataTableDirective } from 'angular-datatables';
import { Msal } from '../shared/services/msal.service';
@Component({
	selector: 'saraticketlist',
	templateUrl: './saraticketlist.component.html',
    styleUrls: ['../app.component.css']
})

export class SaraTicketListComponent implements OnInit ,OnDestroy
{
    public tickets: SaraTicket[];
    public filteredtickets: SaraTicket[];
    public statusMessage:string;
    public supportAgents: Agent[];
    public queues: TicketQueueInfo[]=[];
    public currentQueue: TicketQueueInfo;
    public signInAgentEmail:string;
    public openSearch:boolean=false;
    public searchForm: FormGroup;
    selected = new FormControl(0);
    public client :string;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    constructor(private diagService:DiagDefinitionService, 
        private wfsService: WfsService, 
        private formBuilder: FormBuilder, 
        private spinService:SpinnerService, 
        private utils: Utils,
        private globalService:GlobalService,
        private msal:Msal
    ) 
    {
        this.client = this.globalService.getClient();
        this.signInAgentEmail = this.msal.currentSmtpAddress;
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    public filter() 
    {
        let vip = this.searchForm.controls["Vip"].value;
        let ticketStatus = this.searchForm.controls["TicketStatus"].value;
        let ticketSystem = this.searchForm.controls["TicketSystem"].value;
        let ticketId = this.searchForm.controls["TicketId"].value;
        let assignTo = this.searchForm.controls["AssignTo"].value;
        let tags = this.searchForm.controls["Tags"].value;
        let problemStatement = this.searchForm.controls["ProblemStatement"].value;

        this.filteredtickets = this.tickets;
        if(vip!=null)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return ticket.IsVip == vip;}
            );
        }
        if(!!ticketStatus)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return this.utils.searchField(ticket.TicketStatus, ticketStatus)}
            );
        }
        if(!!ticketSystem)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return this.utils.searchField(ticket.TicketingSystem, ticketSystem)}
            );
        }
        if(!!ticketId)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return this.utils.searchField(ticket.TicketId, ticketId)}
            );
        }
        if(!!assignTo)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return this.utils.searchField(ticket.TicketAssignee, assignTo)}
            );
        }
        if(!!tags)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { return ticket.Tags!=null && this.utils.searchField(ticket.Tags.join(','), tags)}
            );
        }
        if(!!problemStatement)
        {
            this.filteredtickets = this.filteredtickets.filter(
                ticket=> { 
                    let test =  this.utils.searchField(ticket.ProblemStatement, problemStatement);
                    return test;
                }
            );
        }
    }

    resetFilter()
    {
        this.filteredtickets = this.tickets;
        this.searchForm.reset();
    }

    ngOnInit()
    {   
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            order: [[10, 'desc']],
            columnDefs: [ { 'type': 'date', 'targets': 10 }],
          };

        this.searchForm = this.formBuilder.group(
        {
            Vip: false,
            TicketStatus: [''],
            TicketSystem: [''],
            TicketId: [''],
            AssignTo: [''],
            Tags: [''],
            ProblemStatement: [''],
        });

        let queueSubscription= this.diagService.GetQueuesForUserAsync(this.client).subscribe(queues => 
            {
                this.queues = queues.filter(x=>!x.QueueID.startsWith("olk"));
                if(!!this.queues && this.queues.length>0)
                {
                    // this.queues will be outlookdesktop_dogfood, outlookdesktop_external,outlookdeskp_microsoft
                    this.queues.forEach(x=>{
                        if(x.QueueLabel=="dogfood")
                        {
                            x.DisplayName="Dogfood";
                        }
                        else if(x.QueueLabel=="msit")
                        {
                            x.DisplayName="Microsoft";
                        }
                        else if(x.QueueLabel=="external")
                        {
                            x.DisplayName="External";
                        }
                        else if(x.QueueLabel=="odstickets")
                        {
                            x.DisplayName="ODSTickets"
                        }
                    });

                    let myQueueItem = new TicketQueueInfo();
                    myQueueItem.DisplayName="My Queue";
                    myQueueItem.QueueLabel = "MyQueue";
                    this.queues.push(myQueueItem);
                }

                let searchQueueItem = new TicketQueueInfo();
                searchQueueItem.DisplayName="Search";
                searchQueueItem.QueueLabel = "Search";
                this.queues.push(searchQueueItem);
                this.finalize(queueSubscription);
                this.spinService.displayLoader(false);
            },error =>
            {
                this.finalize(queueSubscription, error);
                this.spinService.displayLoader(false);
            });
    }
       
    public onOpenTicket(id:String)
    {
        window.open("#/saratickets/" + id);
    }

    public onOpenQueue(queue:TicketQueueInfo)
    {
        this.openSearch=false;
        this.currentQueue = queue;
        this.tickets = null;
        this.filteredtickets = null;
        this.rerender();
        this.spinService.displayLoader(true);

        if(!!queue.QueueID)
        {
            // Note this code is temperary for queuebyapp transition, hard code to pull old queue and merge with the new queue
            let oldQueueId = "";
            if (queue.QueueLabel == "dogfood")
            {
                oldQueueId = "olkenduserchat_dogfood";
            }
            else if(queue.QueueLabel=="msit")
            {
                oldQueueId = "olkenduserchat_msit";
            }
            else if(queue.QueueLabel=="external")
            {
                oldQueueId = "olkenduserchat_external";
            }

            let sub = this.diagService.GetTicketsInQueueAsync(oldQueueId, this.client).subscribe(oldTickets => 
                {
                    let ticketsSubscription = this.diagService.GetTicketsInQueueAsync(queue.QueueID, this.client).subscribe(tickets => 
                        {
                            this.searchForm.reset();

                            oldTickets = oldTickets.filter(x=>{
                                let metadataAttributes = this.getMapFromObject(x.DiagnosticSessionAttributes);
                                let client = metadataAttributes.filter(item=> item.key=="ClientName")[0];
                                return !!client && client.value.toLowerCase() ==  this.client.toLocaleLowerCase();
                            });
                            if (oldTickets!=null && oldTickets.length>0)
                            {
                                tickets = tickets.concat(oldTickets);
                            }
                            else
                            {
                                console.log("Old queue: " + oldQueueId + " is empty please clean the table and hardcoded code");
                            }

                            if(!tickets || tickets.length < 1)
                            {
                                this.statusMessage = "No tickets found in this queue";
                                this.spinService.displayLoader(false);
                                return;
                            }
            
                            this.processTickets(tickets);
                            this.statusMessage="";
                            this.finalize(ticketsSubscription);
                        },error =>
                        {
                            this.finalize(ticketsSubscription,error);
                            this.spinService.displayLoader(false);
                        });
                },error =>
                {
                    this.finalize(sub,error);
                    this.spinService.displayLoader(false);
                });  
        }else if (queue.QueueLabel=="Search")
        {
            this.openSearch=true;
            this.tickets=null;
            this.filteredtickets=null;
            this.currentQueue = null;
            this.spinService.displayLoader(false);
        }else if (queue.QueueLabel=="MyQueue")
        {
            let sub= this.diagService.GetMyQueueAsync(this.msal.currentSmtpAddress, this.client).subscribe(tickets => 
            {
                    this.searchForm.reset();
                    if(!tickets || tickets.length < 1)
                    {
                        this.filteredtickets = [];
                        this.spinService.displayLoader(false);
                        return;
                    }
                    
                    // Myqueue needs to filter by client
                    tickets = tickets.filter(x=>{
                        let metadataAttributes = this.getMapFromObject(x.DiagnosticSessionAttributes);
                        let client = metadataAttributes.filter(item=> item.key=="ClientName")[0];
                        return !!client && client.value.toLowerCase() ==  this.client.toLocaleLowerCase();
                    });

                    this.processTickets(tickets);
                    this.statusMessage="";
                    this.finalize(sub);
                    this.spinService.displayLoader(false);
            },error =>
            {
                sub.unsubscribe();
                this.spinService.displayLoader(false);
            }); 
        }
    }

    private processTickets(tickets:SaraTicket[])
    {
        let agentsSubscription= this.diagService.GetAgentsAsync().subscribe(agents => 
        {
            this.supportAgents = agents;
            this.tickets = tickets.filter(ticket=>{return ticket.TicketStatus!=="resolved"&&ticket.TicketStatus!=="rejected";});
            this.tickets.forEach(ticket=>{
                if(ticket.TicketingSystem!="Rave")
                {
                    // Update assignee field to be diag portal account, from the session curently is their helpshift profile id 
                    let currentAgent = agents.filter((agent:Agent)=> agent.HelpshiftProfileId==ticket.TicketAssignee)[0];
                    if(!!currentAgent)
                    {
                        ticket.TicketAssignee= this.utils.getAliasFromEmail(currentAgent.DiagPortalEmail);
                    }else if (!!ticket.TicketAssignee){
                        ticket.TicketAssignee = this.utils.getAliasFromEmail(ticket.TicketAssignee);
                    }
                } 
           
                this.TicketStatusMapping(ticket);
           
                // Check if is Vip 
                 let tags = ticket.Tags;
                 let metadataAttributes = this.getMapFromObject(ticket.DiagnosticSessionAttributes);
                 let ticketSystems = metadataAttributes.filter(item=> item.key=="TicketingSystem")[0];
                 ticket.TicketingSystem = !!ticketSystems&&ticketSystems.value;
                 if(!!tags && (tags.indexOf("msft-vip")>-1) || tags.indexOf("dsk_escalate")>-1)
                 {
                     ticket.IsVip = true;
                 }else
                 {    
                     ticket.IsVip = false;
                 }
    
                 let chatLastModifiedTime = metadataAttributes.filter(item=> item.key=="ChatLastModifiedTime")[0];
                 if (!!chatLastModifiedTime && !!chatLastModifiedTime.value)
                 {
                     ticket.ChatLastModifiedTime = chatLastModifiedTime.value;
                     ticket.ChatLastModifiedTimeInDate = new Date(ticket.ChatLastModifiedTime + ' UTC');
                 }
                 else
                 {
                     // This is temp since not all tickets has chat last modified field as of today 7/9
                     // If not exist, set it min Date time so that when order desc it can show after the ones that has a time
                     // once our tickets all have a chat last modified field with time comesby, we can safely remove this line.
                     ticket.ChatLastModifiedTimeInDate = null;
                 }
            });
            
            this.filteredtickets = this.tickets.sort((a, b) =>  new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
            this.rerender();
            this.finalize(agentsSubscription);
            this.spinService.displayLoader(false);
        },error =>
        {
            agentsSubscription.unsubscribe();
            this.spinService.displayLoader(false);
        });
    }
    
    public getMapFromObject(obj:Object):{key:string, value:string}[]
    {
        let ret:{key:string, value:string}[] = [];
        var keys = Object.keys(obj);
        ret.length = keys.length;
        for (let i=0;i<keys.length; i++)
        {
          let key = keys[i];
          let value = obj[key];
    
          ret[i] = {key :key, value:value};
         }   
        
        return ret;
    }

    public onUpdateTicketAssignee(ticket:SaraTicket,newAssigneeEmailToUpdate:string)
    {
        if(!newAssigneeEmailToUpdate)
        {
            return;
        }

        let ticketRequest:TicketRequest = new TicketRequest();
        ticketRequest.SessionId = ticket.DiagnosticSessionId;
        ticketRequest.AssigneeEmail = newAssigneeEmailToUpdate;
        let subscription = this.diagService.UpdateTicketAsync(ticketRequest).subscribe(isSuccess => 
            {
                if(isSuccess)
                {
                   ticket.TicketAssignee = this.utils.getAliasFromEmail(newAssigneeEmailToUpdate);
                }

                this.finalize(subscription);
            },error =>
            {
                this.finalize(subscription,error);
            });
    }

    public getAliasFromEmail(email:string){
        if(!email)
        {
            return;
        }
        
        let parts:string[] = email.split("@");
        return parts[0];
    }

    private finalize(subscription: Subscription, errorMsg?: string):void
    {
        if (errorMsg != undefined)
        {
            this.statusMessage = errorMsg;
        }

        if(subscription)
        {
            subscription.unsubscribe();
        }
    }

    private IsEmail(customQuery: string) : boolean
    {
        let regExp:RegExp = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        return regExp.test(customQuery);
    }

    public onSearch(customQuery: string): void
    {
        // clean up exisiting search result
        this.statusMessage = "";
        this.filteredtickets = null;
        this.rerender();
        customQuery = customQuery.trim();
        if(this.utils.IsGuid(customQuery))
        {
            customQuery = 'sid:' + customQuery;
        }

        if(this.IsEmail(customQuery))
        {
            customQuery = 'usr:' + customQuery;
        }

        if(!this.IsSaraQueryValid(customQuery))
        {
            this.statusMessage = "Query is invalid.";
            return;
        }

        this.spinService.displayLoader(true);
        this.wfsService.GetSaraTickets(customQuery).subscribe(tickets => 
            {
                if (tickets!=null)
                {
                    // Search needs filter by client
                    tickets = tickets.filter(x=>{
                        let metadataAttributes = this.getMapFromObject(x.DiagnosticSessionAttributes);
                        let client = metadataAttributes.filter(item=> item.key=="ClientName")[0];
                        return !!client && client.value.toLowerCase() ==  this.client.toLocaleLowerCase();
                    });
                }
                this.tickets = tickets;
                if(!this.tickets|| this.tickets.length < 1 || (this.tickets.length==1 && this.tickets[0]==null))
                {
                    this.statusMessage = "No content exists in ODS service.";
                    this.spinService.displayLoader(false);
                    return;
                }

                let agentsSubscription= this.diagService.GetAgentsAsync().subscribe(agents => 
                    {
                        this.supportAgents = agents;
                        this.tickets.forEach(ticket=>{
                            // Check if is Vip 
                            let tags = ticket.Tags;
                            if(!!tags && (tags.indexOf("msft-vip")>-1) || tags.indexOf("dsk_escalate")>-1)
                            {
                                ticket.IsVip = true;
                            }else
                            {    
                                ticket.IsVip = false;
                            }
    
                            // Update assignee field to be diag portal account, from the session curently is their helpshift profile id 
                            let currentAgent = agents.filter((agent:Agent)=> agent.HelpshiftProfileId==ticket.TicketAssignee)[0];
                            if(!!currentAgent)
                            {
                                ticket.TicketAssignee= this.utils.getAliasFromEmail(currentAgent.DiagPortalEmail);
                            }else if (!!ticket.TicketAssignee){
                                ticket.TicketAssignee = this.utils.getAliasFromEmail(ticket.TicketAssignee);
                            }
                        });
                    
                        this.filteredtickets = this.tickets.sort((a, b) =>  new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
                        this.rerender();
                        this.finalize(agentsSubscription);
                        this.spinService.displayLoader(false);
                    },error =>
                    {
                        this.finalize(agentsSubscription, error);
                        this.spinService.displayLoader(false);
                    });

                this.statusMessage="";
                this.spinService.displayLoader(false);
            },error =>
            {
                this.statusMessage = <any>error;
                this.spinService.displayLoader(false);
            });
    }

    public IsSaraQueryValid(query:string): boolean
    {
        if(query.indexOf('sid:') >= 0)
        {
            return true;
        }
        if(query.indexOf('usr:') >= 0)
        {
            return true;
        }

        return false;
    }

    public resizeMainList() : EventListenerObject{
        var container = document.getElementById('mainlist');
        if (!container) 
        {
          return;
        }

        container.style.height = (window.innerHeight-135) + "px";
        container.style.maxHeight = (window.innerHeight-135) + "px";
    }

    public TicketStatusMapping(item:SaraTicket)
    {
        let ticketStatusInLowerCase = item.TicketStatus.toLowerCase();
        if (ticketStatusInLowerCase.indexOf("waiting")!=-1&&ticketStatusInLowerCase.indexOf("agent")!=-1)
        {
            item.UXTicketStatus = "Waiting For Agent";
        }
        else if (ticketStatusInLowerCase.indexOf("agent")!=-1&&ticketStatusInLowerCase.indexOf("replied")!=-1)
        {
            item.UXTicketStatus = "Agent Replied";
        }
        else if (ticketStatusInLowerCase == "resolved" || ticketStatusInLowerCase == "rejected")
        {
            item.UXTicketStatus = "Closed";
        }
        else if (ticketStatusInLowerCase.indexOf("new")!=-1 || ticketStatusInLowerCase == "pending" ||ticketStatusInLowerCase == "created")
        {
            item.UXTicketStatus = "New";
        }else
        {
            item.UXTicketStatus = item.TicketStatus;
        }
    }

    public onQueueChange(event :any)
    {
        let x = event.index;
        let ticketQueueInfo= this.queues[x];
        this.onOpenQueue(ticketQueueInfo);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
    rerender(): void {
        if(this.dtElement.dtInstance)
        {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            });
        }

        this.dtTrigger.next();
        }
    }