import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FbListComponent } from './fb-list.component';
import { FbDetailComponent } from './fb-detail.component';

const routes: Routes = [];

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'notificationfeedback',
          children: [
              {
                  path: '',
                  component: FbListComponent,
              },
            //   {
            //       path: ':time/:id',
            //       component: FbDetailComponent,
            //   }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class NotificationFeedbackRoutingModule { }
