<div *ngIf='statusMessage'>
    <p [innerHTML]="statusMessage" style="color:red;display: flex;justify-content: center"></p>
</div>

<div class="flex-container3" *ngIf="success">
  <div *ngIf='!!decision'>
    Your change request has been <span style="text-transform: capitalize">{{decision}}</span>
  </div>
  <div *ngIf='!!objectUrl'>
    You can now navigate to the item through this link <a href='{{ objectUrl }}'>{{objectUrl}}</a>
  </div>
</div>
