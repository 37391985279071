import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrashRecoveryRoutingModule } from './crashrecovery-routing.module';
import { CrashDetailComponent } from './crash-detail.component';
import { CrashListComponent } from './crash-list.component';

import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../shared/sharedmodule/shared.module';
import { FlexLayoutModule} from "@angular/flex-layout";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    FormsModule,
      CommonModule,
      ReactiveFormsModule,
      DataTablesModule,
      MatCardModule,
      MatButtonModule,
      FlexLayoutModule,
      SharedModule,
      CrashRecoveryRoutingModule
  ],
  declarations: [CrashDetailComponent, CrashListComponent]
})
export class CrashRecoveryModule { }
