import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { Utils } from '../shared/Utils/utils';
import {OnDestroy, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { DialogHandleService } from '../shared/sharedmodule/dialog.component';
import { ProblemSignature, RecoveryInfo } from '../shared/models/WfsModels';

@Component({
  selector: 'app-session-match',
  templateUrl: './session-match.component.html',
  styleUrls: ['../app.component.css']
})
export class SessionMatchComponent implements OnInit {
  public pd = require('pretty-data').pd; 
  public matchedProblemSignatures: ProblemSignature[];
  public matchedRecoveries: RecoveryInfo[];
  public success:boolean;
  public sessionId_forTestRule:string;
  public rowsOnPage_ps: number = 100;
  public sortBy_ps: string = "Id";
  public sortOrder_ps: string = "desc";
  public rowsOnPage_rs: number = 100;
  public sortBy_rs: string = "Id";
  public sortOrder_rs: string = "desc";
  constructor(
    private route: ActivatedRoute, private service: DiagDefinitionService, private spinnerService: SpinnerService, private router: Router, private util: Utils, private notificationBarService:NotificationBarService, private dialogHandleService:DialogHandleService) {
  }

  ngOnInit() {
  }

  public testRuleMatchBySessionWithAllProblemSignatures()
    {
      this.matchedProblemSignatures = [];
      this.matchedRecoveries = [];
      this.success = false;
      this.spinnerService.displayLoader(true);
        let sub= this.service.TestRuleMatchBySessionWithAllProblemSignatures(this.sessionId_forTestRule).subscribe(res => 
        {
            this.spinnerService.displayLoader(false);
            this.success = true;
            let dict = res;
            this.matchedProblemSignatures = <ProblemSignature[]>dict["MatchedProblemSignatures"];
            this.matchedRecoveries = <RecoveryInfo[]>dict["MatchedRecoveries"];
            this.finalize(sub);
        },error =>
        {
            this.spinnerService.displayLoader(false);
            this.finalize(sub);
        });
    }

    public getAudience(audienceList:string[])
    {
        if(!audienceList || audienceList.length ==0)
        {
            return null;
        }else
        {
            return audienceList.join(", ");
        }
    }
    private finalize(subscribtion:Subscription): void {
      if (subscribtion) {
          subscribtion.unsubscribe();
      }
  }
}
