import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { ProblemSignature} from '../shared/models/WfsModels';
import { ClientName} from '../shared/models/ConstValues';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utils } from '../shared/Utils/utils';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalService } from '../shared/services/global.service';
@Component({
	templateUrl: './custombucketlist.component.html',
    styleUrls: ['../app.component.css']
})

export class CustomBucketListComponent implements OnInit, OnDestroy
{
    public allBuckets: ProblemSignature[];
    public filteredBuckets: ProblemSignature[];
    public dtOptions: DataTables.Settings = {};
    public dtTrigger: Subject<any> = new Subject<any>();
    public searchForm: FormGroup;
    constructor(private globalService:GlobalService, private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private util:Utils, private notificationBarService:NotificationBarService,private formBuilder: FormBuilder){}
    ngOnDestroy():void
    {
        this.notificationBarService.dismiss();
        this.dtTrigger.unsubscribe();
    }

    ngOnInit()
    { 
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 100,
            processing: true,
            destroy:true,
            order: [[5, 'desc']]
          };
        this.searchForm = this.formBuilder.group(
            {
                Name: [''],
                TriggerCondition: [''],
                Audience: [''],
                ModifiedBy: [''],
            });

        let client=this.globalService.getClient();
        let clientlist:string = "BOT,OutlookDesktop";
        if (client.toLocaleLowerCase()!=ClientName.OUTLOOKDESKTOP)
        {
            clientlist = client;
        }
        this.spinService.displayLoader(true);
        let subscription1 = this.diagDefService.GetProblemSignaturesByClientsAsync(clientlist).subscribe(response => 
            {
                if (!response || response.length == 0)
                {
                    this.notificationBarService.openSnackBar("no published buckets exist.",'','Info');
                    this.finalize(subscription1);
                    return;
                }

                this.filteredBuckets = response;
                this.dtTrigger.next();
                this.finalize(subscription1);
            },error =>
            {
                this.finalize(subscription1);
            }
        );
    }

    private finalize(subscription: Subscription):void
    {
        this.spinService.displayLoader(false);
        if(subscription)
        {
            subscription.unsubscribe();
        }
    }

    public filter() 
    {
        let name = this.searchForm.controls["Name"].value;
        let triggerCondition = this.searchForm.controls["TriggerCondition"].value;
        let audience = this.searchForm.controls["Audience"].value;
        let modifiedBy = this.searchForm.controls["ModifiedBy"].value;

        this.filteredBuckets = this.allBuckets;
        if(!!name)
        {
            this.filteredBuckets = this.filteredBuckets.filter(
                item=> { return this.util.searchField(item.FriendlyName, name)}
            );
        }
        if(!!triggerCondition)
        {
            this.filteredBuckets = this.filteredBuckets.filter(
                item=> { return this.util.searchField(item.ProblemSignatureXml, triggerCondition)}
            );
        }
        if(!!audience)
        {
            this.filteredBuckets = this.filteredBuckets.filter(
                item=> { return item.RecoveryAudience!=null && this.util.searchField(item.RecoveryAudience.join(','), audience)}
            );
        }
        if(!!modifiedBy)
        {
            this.filteredBuckets = this.filteredBuckets.filter(
                item=> { return this.util.searchField(item.CreatedBy, modifiedBy)}
            );
        }
    }

    public getAudience(audienceList:string[])
    {
        if(!audienceList || audienceList.length ==0)
        {
            return null;
        }else
        {
            return audienceList.join(", ");
        }
    }
}