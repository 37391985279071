<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="flex-container3" *ngIf="success">
        <mat-card>
          <mat-card-title style="color:grey;font-size: 28px">Entitlement</mat-card-title>
          <mat-card-content >
            <div style="display: flex;flex-direction: column;">
                <form [formGroup]="form">
                    <div *ngIf="isNew">
                        <label style="width: 100%">
                            User Upn
                            <input class="form-control" formControlName="userUpn" required maxlength="254" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" ng-pattern-restrict style="font-weight: normal">
                            <small *ngIf="!form.controls.userUpn.valid" class="text-danger" >Required, max length 254, must a valid upn</small>
                          </label>
                    </div>
                    <div *ngIf="!isNew">  
                        User Upn : {{userupn}}
                    </div>
                    <div *ngIf="!isNew">
                      <div *ngIf="isEligible"> Eligible For Support</div>
                      <div *ngIf="!isEligible"> Not Eligible For Support</div>
                    </div>
                </form>
    
                <div style="margin-bottom:10px;margin-top: 10px;display: flex;flex-direction: row;float: right">
                  <button class="bluewhitebutton" style="margin-right: 10px;" [routerLink]="[ '/entitlements']">Cancel</button>
                  <button class="bluewhitebutton" style="margin-right: 10px;" [disabled]="!form.valid" type="button" (click)="onEntitlementChange('entitle')">Entitle</button>
                  <button class="bluewhitebutton" style="margin-right: 10px;" [disabled]="!form.valid" type="button" (click)="onEntitlementChange('undo entitle')">Undo Entitle</button>
                  <button class="bluewhitebutton" style="margin-right: 10px;" [disabled]="!form.valid || isNew" type="button" (click)="onDelete()">Delete</button>
                </div>

                <div *ngIf='auditHistory.length>0'>
                    <history-display [history]="auditHistory" #historyComponent></history-display>
                </div>
            </div>
      </mat-card-content>
    </mat-card>
  </div>


 