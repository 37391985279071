import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule} from './app.routing';
import { SaraTicketModule } from './saraticket/saraticket.module';
import { CrashResolutionModule } from './crashes/crashresolution.module';
import { CustomBucketModule } from './custombuckets/custombucket.module';
import { ChangeRequestModule } from './changerequest/changerequest.module';
import { PreviewplaceModule } from './previewplace/previewplace.module';
import { CrashRecoveryModule } from './crashrecovery/crashrecovery.module';
import { EntitlementModule } from './entitlement/entitlement.module';
import { NotificationFeedbackModule } from './notification-feedback/notification-feedback.module';
import { Utils } from './shared/Utils/utils';
import { WfsService } from './shared/services/wfs.service';
import { ConfigService } from './shared/services/config.service';
import { SpinnerService } from './shared/services/spinner.service';
import { GlobalService } from './shared/services/global.service';
import { DiagDefinitionService } from './shared/services/diagdef.service';
import { ErrorDialogComponent } from './shared/sharedmodule/errordialog.component';
import { DialogComponent } from './shared/sharedmodule/dialog.component';
import { NotificationBar } from './shared/sharedmodule/notificationbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { RuleviewComponent } from './custombuckets/ruleview.component';
import { IncidentModule} from './incident/incident.module';
import { RecoveryModule } from './recovery/recovery.module';
import { PartnerpageModule } from './partnerpage/partnerpage.module';
import { AnalysisModule } from './analysis/analysis.module';
import { FeedbackModule } from './feedback/feedback.module';
import { OncallModule } from './oncall/oncall.module';
import { DataTablesModule } from "angular-datatables";

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { Msal } from './shared/services/msal.service';
import { TestIncidentModule } from './createtestincident/testincident.module';
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          const day = date.getDate();
          const month = date.getMonth()+1;
          const year = date.getFullYear();
          return `${month}/${day}/${year}`;
      } else {
          return date.toDateString();
      }
  }
}
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '57da3f69-2d82-4c17-9e57-2e6d78b2dc60',
      authority: 'https://login.microsoftonline.com/common', 
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  // protectedResourceMap.set('https://diagnosticsportal.outlook.com/*', ['Diagnostics.ReadWrite']);
  // protectedResourceMap.set('https://api.diagnostics.office.com/*', ['Diagnostics.ReadWrite']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
    declarations: [
        AppComponent,
        ErrorDialogComponent,
        NotificationBar,
        DialogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        SaraTicketModule,
        CrashResolutionModule,
        CustomBucketModule,
        ChangeRequestModule,
        PartnerpageModule,
        PreviewplaceModule,
        CrashRecoveryModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        MatDialogModule, MatIconModule, MatSidenavModule, MatExpansionModule,
        IncidentModule,
        TestIncidentModule,
        EntitlementModule,
        NotificationFeedbackModule,
        RecoveryModule,
        AnalysisModule,
        FeedbackModule,
        OncallModule,
        DataTablesModule,
        MsalModule
    ],
    exports: [RouterModule],
    providers: [Utils, WfsService, DiagDefinitionService,
        ConfigService, SpinnerService, GlobalService, Msal,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { 
}
