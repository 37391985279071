import { RecoveryInfo } from '../shared/models/WfsModels';
import {OnDestroy, Component, OnInit, ViewChild} from '@angular/core';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription, Subject } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-recovery-list',
  templateUrl: './recovery-list.component.html',
  styleUrls: ['../app.component.css']
})
export class RecoveryListComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput; 
  public filteredList:RecoveryInfo[];
  public overwriteNotification:string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinnerService:SpinnerService, private diagService:DiagDefinitionService, private notificationBarService:NotificationBarService){}
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
      this.dtTrigger.unsubscribe();
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      order: [[0, 'asc']]
    };
    this.spinnerService.displayLoader(true);
    let sub = this.diagService.GetRecoveriesAsync().subscribe(
      response => {
          if (!response || response.length == 0)
          { 
            this.notificationBarService.openSnackBar("There is no items in table",'','');
          }
          this.filteredList = response.filter(item=>{ return item.Type!="Article" && item.Type!="Rule";});
          this.dtTrigger.next();
          this.finalize(sub);
        },
        (error:HttpErrorResponse) => {
            this.finalize(sub);
        });
  }

  private finalize(subscription: Subscription):void
  {
      this.spinnerService.displayLoader(false);
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }
  public getAudience(audienceList:string[])
  {
      if(!audienceList || audienceList.length ==0)
      {
          return null;
      }else
      {
          return audienceList.join(", ");
      }
  }
}
