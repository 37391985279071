<div class="flex-container3">
  <mat-card style="display: flex; flex-flow: column; align-items: stretch;">
    <mat-card-title style="color:grey;font-size: 28px">Top trigrams:</mat-card-title>
    <mat-card-content>
      <div style="margin-bottom: 20px;">
          <p style="color: grey; font-size: 20px;"> Select a date range</p>
          <mat-form-field style ="margin-right: 20px;">
            <input #fromInput matInput [matDatepicker]="fromDatePicker" placeholder="Choose a from date" (dateChange)="dateEvent('from', $event)">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field style ="margin-right: 20px;">
            <input #toInput matInput [matDatepicker]="toDatePicker" placeholder="Choose a to date" (dateChange)="dateEvent('to', $event)">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <div>
            <mat-form-field [style.marginTop]="'-0.2em'" style="width: 140px;margin-right: 10px;">
              <mat-label>Symptom (optional)</mat-label>
              <mat-select [(value)]="selectedSymptom">
                <mat-option *ngFor="let item of symptomList" [value]="item">
                  {{item}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button class="btn" style="margin-right:10px"  [disabled]="!validDateRange() || !timerangesearch" type="button" (click)="Search('timerange')">Search</button>
        </div>
        
    </mat-card-content>
  </mat-card>

  <mat-card>
      <mat-card-title style="color:grey;font-size: 28px">Search Result</mat-card-title>
      <mat-card-content>
          <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                    <th>Symptom</th>
                    <th>Trigram</th>
                    <th>Frequency</th>
                    <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of filteredList">
                      <td>{{ item.Symptom }}</td>
                      <td>{{ item.Trigram }}</td>
                      <td>{{ item.Frequency }}</td>
                      <td>{{ item.Total }}</td>
                  </tr>
              </tbody>
          </table>
      </mat-card-content>
    </mat-card>
   
</div>