import { SaraTicketListComponent } from './saraticketlist.component';
import { SaraTicketDetailComponent } from './saraticketdetail.component';
import { SaraTicketSectionComponent } from './saraticketsection.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TagListComponent } from './Tags/tag-list.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'saratickets',
                component: SaraTicketSectionComponent,
                children: [
                    {
                        path: '',
                        component: SaraTicketListComponent,
                    },
                    {
                        path: ':SaraSessionId',
                        component: SaraTicketDetailComponent,
                    },
                    {
                        path: 'tag/config',
                        component: TagListComponent,
                    }
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class SaraTicketRouting {
}