import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { ChangeRequest, CrashRecoveryPortalContract, IncidentPortalContract, ProblemSignaturePortalContract } from '../shared/models/WfsModels';
import { ClientName } from '../shared/models/ConstValues';
import { GlobalService } from '../shared/services/global.service';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-changerequest-list',
  templateUrl: './changerequest-list.component.html',
  styleUrls: ['../app.component.css']
})
export class ChangerequestListComponent implements OnInit, OnDestroy {
  public filteredList: ChangeRequest[];
  public client:string;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  public host:string;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  constructor(private spinService:SpinnerService,
    private service: DiagDefinitionService,
    private notificationBarService:NotificationBarService,
    private globalService:GlobalService) 
  {
    this.client = this.globalService.getClient();
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      destroy:true,
      order: [[3, 'desc']]
    };
    this.getChangeRequests();
    this.host = window.location.host;
  }

  ngOnDestroy(): void {
    this.notificationBarService.dismiss();
    this.dtTrigger.unsubscribe();
  }

  public getChangeRequests()
  {
    this.filteredList=[];
    this.spinService.displayLoader(true);
    let sub = this.service.GetAllChangeRequestsAsync().subscribe(
      response => {
        // Filter change request by client
        this.filteredList = response.filter((obj: ChangeRequest) => {
          // replace diagportal 
          const originalUrl = new URL(obj.DiagPortalLink);
          const finalUrl = obj.DiagPortalLink.replace(originalUrl.host, this.host);
          obj.DiagPortalLink = finalUrl;
          if (obj.Type == "problemsignature")
          {
               let ps :ProblemSignaturePortalContract = JSON.parse(obj.ChangeObject);
               return ps.ProblemSignature.Client.toLocaleLowerCase() == this.client.toLocaleLowerCase();
          }
          else if (obj.Type == "crashrecovery")
          {
            let cr :CrashRecoveryPortalContract = JSON.parse(obj.ChangeObject);
            return cr.CrashRecovery.Client.toLocaleLowerCase() == this.client.toLocaleLowerCase();
          } 
          else if (obj.Type == "incident")
          {
            let incident :IncidentPortalContract = JSON.parse(obj.ChangeObject);
            return this.client.toLocaleLowerCase() == ClientName.OUTLOOKDESKTOP.toLocaleLowerCase();
          }
        });
        if(!this.filteredList || this.filteredList.length <=0)
        {
          this.notificationBarService.openSnackBar("no pending change requests found",'','Info');
        }

        this.dtTrigger.next();
        this.finalize(sub);
      },
      error => {
          this.finalize(sub);
      });
  }

  private finalize(subscribtion:Subscription): void {
    this.spinService.displayLoader(false);
    if (subscribtion) {
        subscribtion.unsubscribe();
    } 
  }
}
