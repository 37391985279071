<div class="flex-container3">
  <mat-card style="display: flex; flex-flow: column; align-items: stretch;">
    <mat-card-title style="color:grey;font-size: 28px">Search feedback by</mat-card-title>
    <mat-card-content>
      <div style="margin-bottom: 20px;">
          <button class="btn" style="margin-right:10px"  type="button" (click)="Search('today')">Today</button>
          <button class="btn" style="margin-right:10px"  type="button" (click)="Search('week')">This Week</button>
          <button class="btn" style="margin-right:10px"  type="button" (click)="Search('month')">This Month</button>
          <button class="btn" style="margin-right:10px"  type="button" (click)="Search('3month')">Three Month</button>
          <button class="btn" style="margin-right:10px"  type="button" (click)="Search('year')">This Year</button>
      </div>
      <div>
          <p style="color: grey; font-size: 28px;"> or by specified time range</p>
          <mat-form-field style ="margin-right: 20px;">
            <input #fromInput matInput [matDatepicker]="fromDatePicker" placeholder="Choose a from date" (dateChange)="dateEvent('from', $event)">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
  
          <mat-form-field style ="margin-right: 20px;">
            <input #toInput matInput [matDatepicker]="toDatePicker" placeholder="Choose a to date" (dateChange)="dateEvent('to', $event)">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <button class="btn" style="margin-right:10px"  [disabled]="!validDateRange() || !timerangesearch" type="button" (click)="Search('timerange')">Search Feedbacks</button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
      <mat-card-title style="color:grey;font-size: 28px">Search Result ( <span *ngIf="!!filteredList">{{filteredList.length}} )</span></mat-card-title>
      <mat-card-content>
          <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                      <th style="width: 10%">
                          Date
                      </th>
                      <th style="width: 20%">
                          Overall Feedback
                      </th>
                      <th style="width: 40%">
                          Verbatim Feedback
                      </th>
                      <th style="width: 10%">
                        TenantId
                      </th>
                      <th style="width: 20%">
                        Notification Ids
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of filteredList">
                      <td>{{ item.PartitionKey }}</td>
                      <td>{{ item.OverallFeedback }}</td>
                      <td>{{ item.VerbatimFeedback }}</td>
                      <td>{{ item.TenantId }}</td>
                      <td>{{ item.NotificationIds }}</td>
                  </tr>
              </tbody>
          </table>
      </mat-card-content>
    </mat-card>
</div>