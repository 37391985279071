import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationFeedbackRoutingModule } from './notification-feedback-routing.module';
import { FbDetailComponent } from './fb-detail.component';
import { FbListComponent } from './fb-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FlexLayoutModule} from "@angular/flex-layout";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
@NgModule({
  imports: [
    CommonModule,
    NotificationFeedbackRoutingModule,
    FormsModule,
    CommonModule,
    DataTablesModule,
    MatCardModule, MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,MatDatepickerModule,MatNativeDateModule,
    FlexLayoutModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  declarations: [FbDetailComponent, FbListComponent]
})
export class NotificationFeedbackModule { }
