<div class="flex-container3">
<mat-card *ngIf="success && !!currentFb">
    <mat-card-title style="color:grey;font-size: 28px">Notification Feedback</mat-card-title>
    <mat-card-content>
      <table>
          <tr>
            <td>Date</td>
            <td>{{currentFb.PartitionKey}}</td>
          </tr>
          <tr>
            <td>Row Key</td>
            <td>{{currentFb.RowKey}}</td>
          </tr>
          <tr>
            <td>User UPN</td>
            <td>{{currentFb.UserUPN}}</td>
          </tr>
          <tr>
            <td>Session Id</td>
            <td>{{currentFb.SessionId}}</td>
          </tr>
          <tr>
            <td>Overall Feedback</td>
            <td>{{currentFb.OverallFeedback}}</td>
          </tr>
          <tr>
            <td>Rating</td>
            <td>{{currentFb.Rating}}</td>
          </tr>
          <tr>
            <td>Verbatim Feedback</td>
            <td>{{currentFb.VerbatimFeedback}}</td>
          </tr>
          <tr>
            <td>Notification Ids</td>
            <td>{{currentFb.NotificationIds}}</td>
          </tr>
      </table>
    </mat-card-content>
</mat-card>
<div *ngIf="success && !currentFb">
   Feedback doenst exist.
</div>
</div>
