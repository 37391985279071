import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription, Observable } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { Utils } from '../shared/Utils/utils';
import { RecoveryEditComponent } from '../shared/sharedmodule/recoveryedit.component';
import { HistoryDisplayComponent } from '../shared/sharedmodule/historydisplay.component';
import { RuleviewComponent } from './ruleview.component';
import {OnDestroy, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { DialogHandleService } from '../shared/sharedmodule/dialog.component';
import {
    RecoveryInfo,
    KeyValuePair,
    ProblemSignaturePortalContract,
    ProblemSignature,
    AuditHistory,
    ParameterSchemaNode,
    RecoveryAction,
    ProblemSignatureVsSessionRuleMatchResult
} from '../shared/models/WfsModels';
import { LocStatusComponent } from '../shared/sharedmodule/Locstatus.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA, SEMICOLON } from '@angular/cdk/keycodes';
import { KeyValue } from '@angular/common';
import { GlobalService } from '../shared/services/global.service';
import { TSMap } from 'typescript-map';
@Component({
    selector: 'custom-bucket',
    templateUrl: './custombucketdetails.component.html',
    styleUrls: ['../app.component.css']
})

export class CustomBucketDetailsComponent implements OnInit, OnDestroy {
    public pd = require('pretty-data').pd; 
    public bucketDefinitionName: string;
    public currentCustomBucket: ProblemSignaturePortalContract = null;
    public currentRecovery: RecoveryInfo = null;
    public bucketId: string;
    public bucketClient: string;
    public recoveryId: string;
    public recovery: RecoveryInfo;
    @ViewChild('recoveryComponent') recoveryComponent: RecoveryEditComponent;
    @ViewChild('historyComponent') historyComponent: HistoryDisplayComponent;
    @ViewChild('locstatusComponent') locstatusComponent: LocStatusComponent;
    @ViewChild('ruleviewComponent') ruleviewComponent: RuleviewComponent;
    @ViewChild('fileInput') fileInput; 
    public existingBucket: boolean = false;
    public success: boolean;
    public hasFix: boolean;
    public problemSignatureXmlTemplatesMap=new TSMap<string,string>();
    public isBotProblemSignature:boolean;
    public hasPendingChangeRequest:boolean = false;
    public auditHistory: AuditHistory[] = [];
    public availablelocales:string;
    public inputxmlForRuleView:string;
    public xmlForJSONlistView:string;
    public xmlFromRuleView:string;
    public triggerConditionSelection:string = "js_etl";;
    public sessionid:string;
    public routersub:Subscription;
    public dcrNodes:ParameterSchemaNode[];
    public newjsonrule: ParameterSchemaNode;
    public disableEditor:boolean;
    public dcrError:string;
    public changeReqObj:ProblemSignaturePortalContract;
    public openChangeReq:boolean=false;
    public privateNote:string;
    public hasPrivateNote:boolean;
    public Payloadtype:string;
    public payloadTypeList: Array<{ choiceText: string, choiceValue: string }> = [
        { choiceText: 'ProductType', choiceValue: 'Product Type'},
        { choiceText: 'JSON', choiceValue: 'JSON'},
        { choiceText: 'JSONList', choiceValue: 'JSON List'},
        { choiceText: 'ETL', choiceValue: 'ETL'},
    ];
    public ETLTemplate : string = `<PayloadList>
        <EtlRule ProviderName="Network" EventName ="NetworkEvtState" EventPayloadFields ="NewState = MAPIOFFLINE_STATE_OFFLINE" Count ="1" />
</PayloadList>`;
    public JSONTemplate : string =  `<PayloadList>
            <JONRule ParameterName="WatsonBucketId" FieldPath="#ROOT#" FieldValue="" Operator="ne" />
    </PayloadList>`;
    public JSONListTemplate : string =  `<PayloadList>
        <JSONListRule ParameterName="CantBootData" RelativeListPath="Payloads" Operator="Any">
          <JSONListElementRule RelativeFieldPath = "PossibleIssue" FieldValue = "RecreateProfile" Operator = "eq" />
        </JSONListRule>
    </PayloadList>`;
    public ProductTypeTemplate : string =  `<PayloadList>
            <JSONRule ParameterName="ProductInfo" FieldPath="ProductType" FieldValue="C2R" />
    </PayloadList>`;
    public templates = new TSMap<string,string>();
    public visible = true;
    public selectable = true;
    public removable = true;
    public addOnBlur = true;
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
    public tags : string[]=[];
    public sendEmail:string[]=[];
    public keywords : string[]=[];
    public attributes:KeyValuePair[]=[];
    public hasRecovery:boolean;
    public selectedAutoRecovery:string;
    public autoRecovery:RecoveryInfo[]=[];
    public sessionId_forTestRule:string;
    public sessionRuleMatchResult:ProblemSignatureVsSessionRuleMatchResult;
    public defaultOpenDrawer:boolean = false;
    public client:string;
    public changeReqId:string;
    public sub: Subscription = new Subscription();
    constructor(
        private route: ActivatedRoute, private service: DiagDefinitionService, private spinnerService: SpinnerService, private router: Router, private util: Utils, private notificationBarService:NotificationBarService, private dialogHandleService:DialogHandleService,private globalService:GlobalService) {
            this.client = this.globalService.getClient();
            this.problemSignatureXmlTemplatesMap.set('ETL', this.ETLTemplate);
            this.problemSignatureXmlTemplatesMap.set('ProductType', this.ProductTypeTemplate);
            this.problemSignatureXmlTemplatesMap.set('JSON', this.JSONTemplate);
            this.problemSignatureXmlTemplatesMap.set('JSONList', this.JSONListTemplate);
        }

    ngOnInit() {
        this.bucketId = this.route.snapshot.params['Id'];
        this.bucketClient = this.route.snapshot.params['Client'];
        this.routersub = this.route.queryParams
                    .subscribe(params => {
                        // Defaults to 0 if no query param provided.
                            this.sessionid = params.sessionid || 0;
                        });
                       
            this.spinnerService.displayLoader(true);
            let sub1= this.service.GetAutoRecoveryAsync().subscribe(res => 
            {
                this.autoRecovery = res;
                this.finalize(sub1);
            },error =>
            {
                this.finalize(sub1);
            });

            if(!!this.sessionid && this.sessionid!='0')
            {
                if(!this.isSessionIdGuid())
                {
                    return;
                }
                this.defaultOpenDrawer = true;
                let sub2= this.service.GetDataCollectorResultAsNodes(this.sessionid).subscribe(res => 
                    {
                        this.dcrNodes= res;
                        this.finalize(sub2);
                    },error =>
                    {
                        this.finalize(sub2);
                    });
            }


            if (this.bucketId != '0') 
            {
                let subscription2 = this.service.GetProblemSignatureByIdAndClientAsync(this.bucketId, this.bucketClient).subscribe(
                    response => {
                        this.currentCustomBucket = response;
                        if (!!this.currentCustomBucket && !!this.currentCustomBucket.ProblemSignature) 
                        {
                            this.existingBucket = true;
                            if (this.currentCustomBucket.ChangeRequest!=null)
                            {
                                this.changeReqId = this.currentCustomBucket.ChangeRequest.Id;
                            }
                            this.bucketDefinitionName = this.currentCustomBucket.ProblemSignature.FriendlyName;
                            this.selectedAutoRecovery = !!this.currentCustomBucket.ProblemSignature.RecoveryActions && this.currentCustomBucket.ProblemSignature.RecoveryActions.length>=1? this.currentCustomBucket.ProblemSignature.RecoveryActions[0].Id:'';
                            let xml = this.currentCustomBucket.ProblemSignature.ProblemSignatureXml;
                            if(!!xml && (xml.indexOf("JSONListRule")>-1 || xml.indexOf("TimberAnalyzerRule")>-1))
                            {
                                this.disableEditor = true;
                                this.xmlForJSONlistView = this.pd.xml(this.currentCustomBucket.ProblemSignature.ProblemSignatureXml);
                                this.triggerConditionSelection = "jslist";
                            }else{
                                this.inputxmlForRuleView = this.currentCustomBucket.ProblemSignature.ProblemSignatureXml;
                                this.triggerConditionSelection = "js_etl";
                            }

                            this.recoveryId = this.currentCustomBucket.ProblemSignature.Recoveries[0];
                            this.recovery = this.currentCustomBucket.Recovery;
                            this.hasRecovery = !!this.recovery;
                            this.auditHistory = this.currentCustomBucket.AuditHistory;
                            this.attributes = this.getMapFromObject(this.currentCustomBucket.ProblemSignature.Attributes);
                            this.tags=this.currentCustomBucket.ProblemSignature.Tags;
                            this.sendEmail = this.currentCustomBucket.ProblemSignature.SendEmail;
                            if(!!this.currentCustomBucket.ChangeRequest)
                            {
                                this.hasPendingChangeRequest = this.currentCustomBucket.ChangeRequest.RequestStatus == "pending";
                                if(this.hasPendingChangeRequest)
                                {
                                    this.changeReqObj = <ProblemSignaturePortalContract> this.util.safelyParseJSON(this.currentCustomBucket.ChangeRequest.ChangeObject);
                                    this.changeReqObj.ProblemSignature.ProblemSignatureXml=!!this.changeReqObj.ProblemSignature.ProblemSignatureXml?this.pd.xml(this.changeReqObj.ProblemSignature.ProblemSignatureXml):this.changeReqObj.ProblemSignature.ProblemSignatureXml;
                                }
                            }

                            this.isBotProblemSignature = this.currentCustomBucket.ProblemSignature.Client.toUpperCase()=="BOT";
                            this.keywords = !!this.currentCustomBucket.ProblemSignature.Keywords?this.currentCustomBucket.ProblemSignature.Keywords.split(','):[];
                            this.availablelocales = !!this.recovery? this.currentCustomBucket.Recovery.LocStatus: "";
                            this.hasPrivateNote = !!this.currentCustomBucket.ProblemSignature.PrivateNote;
                            this.privateNote = this.currentCustomBucket.ProblemSignature.PrivateNote;
                            this.success = true;
                        }else{
                            this.notificationBarService.openSnackBar("Problem signature doesn't exist",'','Warn');
                        }

                        this.spinnerService.displayLoader(false);
                        this.finalize(subscription2);
                    },
                    error => {
                        this.spinnerService.displayLoader(false);
                        this.finalize(subscription2);
                    }
                );
            }
            else {
                this.hasFix = false;
                this.existingBucket = false;
                this.bucketDefinitionName = '';
                this.currentCustomBucket = new ProblemSignaturePortalContract();
                this.spinnerService.displayLoader(false);
                this.success = true;
            }
    }

    ngOnDestroy() {
        this.notificationBarService.dismiss();
        this.routersub.unsubscribe();
    }

    public GetDCRNodesFromSession()
    {
        if(!this.isSessionIdGuid())
        {
            return;
        }

        let sub2= this.service.GetDataCollectorResultAsNodes(this.sessionid).subscribe(res => 
            {
                this.dcrNodes= res;
                this.finalize(sub2);
            },error =>
            {
                this.dcrError = "Cannot load structured data collector results from this session since it may have an invalid json file to parse with."
                this.finalize(sub2);
            });
    }

    public isSessionIdGuid()
    {
        return this.util.IsGuid(this.sessionid);
    }

    public onDelete() {
        if (confirm('Are you sure you want to delete?')) {
            let subscription = this.service.DeleteProblemSignatureAsync(this.currentCustomBucket.ProblemSignature.Id, this.bucketClient).subscribe(res => {
                this.finalize(subscription);
                this.router.navigate(['/custombuckets']);
            }, error => {
                this.finalize(subscription);
            });
        }
    }

    private finalize(subscribtion:Subscription): void {
        if (subscribtion) {
            subscribtion.unsubscribe();
        }
    }

    public onSubmit(): void {
        let validationMsg = this.getValidationMsg();
        if (validationMsg) { return; }
        
        if(this.currentCustomBucket.Recovery!=null && !this.hasRecovery)
        {
            if (!confirm('Are you sure you want to remove recovery from this problem signature?')) 
            {
                return
            }
        }

        this.spinnerService.displayLoader(true);
        if (this.bucketId == '0') {
            this.currentCustomBucket = new ProblemSignaturePortalContract();
            this.currentCustomBucket.ProblemSignature = new ProblemSignature();
            this.currentCustomBucket.Recovery = new RecoveryInfo();
            this.currentCustomBucket.ProblemSignature.Id = this.util.GenerateGuid();
        }

        this.currentCustomBucket.ProblemSignature.FriendlyName = this.bucketDefinitionName;
        this.currentCustomBucket.ProblemSignature.Keywords = this.keywords.join(',');
        this.currentCustomBucket.ProblemSignature.PrivateNote = this.hasPrivateNote? this.privateNote : '';
        this.currentCustomBucket.ProblemSignature.RecoveryActions = [];
        if(!!this.selectedAutoRecovery)
        {
            let recoveryAction = new RecoveryAction();
            recoveryAction.Id=this.selectedAutoRecovery;
            this.currentCustomBucket.ProblemSignature.RecoveryActions.push(recoveryAction);
        }
        
        if(this.triggerConditionSelection == "js_etl")
        {
            this.sub = this.ruleviewComponent.onSubmit().subscribe(
                result=>{
                    this.currentCustomBucket.ProblemSignature.ProblemSignatureXml = !!result?result:"";
                    this.finalize(this.sub);
                    this.retrieveRecoveryAndUpdatePS();
                },
                error => {
                    this.finalize(this.sub);
                    this.spinnerService.displayLoader(false);
                });

        }else if(this.triggerConditionSelection == "jslist")
        {
            this.currentCustomBucket.ProblemSignature.ProblemSignatureXml =!!this.xmlForJSONlistView?this.xmlForJSONlistView :'';
            this.currentCustomBucket.ProblemSignature.ProblemSignatureXml=!!this.currentCustomBucket.ProblemSignature.ProblemSignatureXml?this.currentCustomBucket.ProblemSignature.ProblemSignatureXml:"";
            this.retrieveRecoveryAndUpdatePS();
        }

        console.log("final xml to create or update to problem siganture is" + this.currentCustomBucket.ProblemSignature.ProblemSignatureXml);
    }

   private retrieveRecoveryAndUpdatePS()
   {
        if((<HTMLInputElement>document.getElementById("problemSignatureForBot")).checked)
        {
            this.currentCustomBucket.ProblemSignature.Client="BOT";

            // If the problem signature is created only for bot, the trigger condition must have "problemstatement" in Trigger condition
            if(this.currentCustomBucket.ProblemSignature.ProblemSignatureXml.toLowerCase().indexOf("problemstatement")<0)
            {
                alert('If the problem signature is created only for bot, the trigger condition must have "problemstatement" in it.');
                return;
            }
        }else{
            this.currentCustomBucket.ProblemSignature.Client = this.client;
        }

        // If user check associate a recovery, then retrieve recovery from recovery component
        if( this.hasRecovery)
        {
            this.sub = this.recoveryComponent.onSubmit(this.recoveryComponent.currentRecovery != undefined ? this.recoveryComponent.currentRecovery.Name : this.bucketDefinitionName + "_recovery", this.currentCustomBucket.ProblemSignature.Id).subscribe(
                newRecovery=>{
                    if(!newRecovery)
                    {
                        this.spinnerService.displayLoader(false);
                        return;
                    }
        
                    this.currentCustomBucket.Recovery = newRecovery;
                    this.currentCustomBucket.ProblemSignature.Recoveries = [this.currentCustomBucket.Recovery.Id];
                    this.currentCustomBucket.ProblemSignature.RecoveryAudience = newRecovery.Audience;
                    this.currentCustomBucket.ProblemSignature.Attributes=this.getObjectFromMap(this.attributes);
                    this.currentCustomBucket.ProblemSignature.Tags = this.tags;
                    this.currentCustomBucket.ProblemSignature.SendEmail = this.sendEmail;
                    this.createOrUpdateProblemSignature();
                    this.finalize(this.sub);
                },
                error => {
                    this.finalize(this.sub);
                    this.spinnerService.displayLoader(false);
                });
        }
        else 
        {
            this.currentCustomBucket.Recovery = null;
            this.currentCustomBucket.ProblemSignature.Recoveries = null;
            this.currentCustomBucket.ProblemSignature.RecoveryAudience = null;
            this.currentCustomBucket.ProblemSignature.Attributes=this.getObjectFromMap(this.attributes);
            this.currentCustomBucket.ProblemSignature.Tags = this.tags;
            this.currentCustomBucket.ProblemSignature.SendEmail = this.sendEmail;
            this.createOrUpdateProblemSignature();
        }
   }

   private createOrUpdateProblemSignature()
   {
    if (this.bucketId == '0') {
        let subscription = this.service.CreateProblemSignatureAsync(this.currentCustomBucket).subscribe(() => {
            this.onComplete(subscription);
        },
        error => {
            this.finalize(subscription);
            this.spinnerService.displayLoader(false);
        });
    } 
    else {
        let subscription = this.service.UpdateProblemSignatureAsync(this.currentCustomBucket).subscribe(() => {
            this.onComplete(subscription);
        },
        error => {
            this.finalize(subscription);
            this.spinnerService.displayLoader(false);
        });
    }
   }

    private onComplete(subscription:Subscription) {
        if(!!this.recoveryComponent)
        {
            this.recoveryComponent.recoveryForm.reset();
        }
        this.finalize(subscription);
        this.router.navigate(['/custombuckets']);
    }

    public onAddPayloadTemplate(selectedPayloadType:string) {
        if (!selectedPayloadType) {
            return;
        }
        let template = this.problemSignatureXmlTemplatesMap.get(selectedPayloadType);
        this.xmlForJSONlistView = this.pd.xml(template);
    }

    private getValidationMsg(): string {
        let validationMsg = '';

        if (!this.bucketDefinitionName) {
            validationMsg = validationMsg + 'Name: cannot be empty\n';
        }

        if(this.hasRecovery)
        {
            validationMsg = validationMsg + this.recoveryComponent.getValidationMsg(this.client);
        }

        return validationMsg;
    }

    public onOutputXMLEventEmit(response:string)
    {
        this.xmlFromRuleView = response;
    }

    public onTriggerConditionChange(value:string)
    {
        
        this.triggerConditionSelection=value;

        // display warning message
        if(value=="js_etl" && !!this.xmlForJSONlistView)
        {
            let data = {
                message: "Switch trigger condition view may lost unsaved change from the other view. Only one view's set up can be saved"
            };
            
            this.dialogHandleService.openDialog(data);
        }
        else if(value=="jslist")
        {
            this.sub = this.ruleviewComponent.onSubmit().subscribe(
                result=>{
                    if(result!=this.inputxmlForRuleView)
                    {
                        let data = {
                            message: "Switch trigger condition view may lost unsaved change from the other view. Only one view's set up can be saved"
                        };
                        this.dialogHandleService.openDialog(data);
                    }
                    
                    this.inputxmlForRuleView=result;
                    this.finalize(this.sub);
                },
                error => {
                    this.finalize(this.sub);
                    this.spinnerService.displayLoader(false);
                });

        }
    }

    public onAddRuleEventEmit (event:ParameterSchemaNode)
    {
        this.ruleviewComponent.AddRuleFromSession(event);
    }
  
    public toggleTree()
    {
        this.toggleTreeHelper(this.dcrNodes);
    }

    toggleTreeHelper(nodeList:ParameterSchemaNode[])
    {
        if(!nodeList || nodeList.length==0)
        {
            return;
        }

        nodeList.forEach(element => {
            if (!!element && !!element.Children && element.Children.length>0) {
                element.expanded = !element.expanded;
                this.toggleTreeHelper(element.Children);
            }
        });
    }
    
    public ToggleChangeRequest()
    {
        this.openChangeReq = !this.openChangeReq;
    }

    public addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        // Add non empty value
        if ((value || '').trim()) {
          this.tags.push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }

    public  removeTag(item: string): void {
        const index = this.tags.indexOf(item);
    
        if (index >= 0) {
          this.tags.splice(index, 1);
        }
      }

      public addSendEmail(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
    
        if(!this.util.ValidEmail(value) || value.indexOf('microsoft')==-1)
        {
            return;
        }

        // Add non empty value
        if ((value || '').trim()) {
          this.sendEmail.push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = '';
        }
      }

    public  removeSendEmail(item: string): void {
        const index = this.sendEmail.indexOf(item);
    
        if (index >= 0) {
          this.sendEmail.splice(index, 1);
        }
      }

  public onCreateSessioMetadata()
  {
      document.getElementById("beforeAdd").style.display='none';
      document.getElementById("Adding").style.display='table-row';
  }

  public createSessioMetadata()
  {
          let key = (<HTMLInputElement>document.getElementById("newMetadataKey")).value;
          let value = (<HTMLInputElement>document.getElementById("newMetadataValue")).value;
          if(!!key && !!value)
          {
              let newpair:KeyValuePair = new KeyValuePair();
              newpair.key = key;
              newpair.value = value;
              this.attributes.push(newpair);
              this.createSessioMetadataReset();
          }else{
              return;
          }
  }

  public createSessioMetadataReset()
  {
      (<HTMLInputElement>document.getElementById("newMetadataKey")).value="";
      (<HTMLInputElement>document.getElementById("newMetadataValue")).value="";
      document.getElementById("beforeAdd").style.display='table-row';
      document.getElementById("Adding").style.display='none';
  }

  public onRemoveSessioMetadata(item:KeyValuePair)
  {

    let index = this.attributes.findIndex(o => o.key === item.key); //find index in your array
    this.attributes.splice(index, 1);//remove element from array
  }

  public getMapFromObject(obj:Object):{key:string, value:string}[]
    {
        let ret:{key:string, value:string}[] = [];
        if(!obj)
        {
            return ret;
        }

        var keys = Object.keys(obj);
        ret.length = keys.length;
        for (let i=0;i<keys.length; i++)
        {
          let key = keys[i];
          let value = obj[key];
    
          ret[i] = {key :key, value:value};
         }   
        
        return ret;
    }

    public getObjectFromMap(map:KeyValuePair[])
    {
        let result :object = {};
        
        for (let i=0;i<map.length; i++)
        {
          let key = map[i].key;
          let value = map[i].value;
    
          result[key] =value;
         }   
        
        return result;
    }

    removeKeyword(item: string): void {
        const index = this.keywords.indexOf(item);

        if (index >= 0) {
            this.keywords.splice(index, 1);
        }
    }
    addKeyword(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add non empty value
        if ((value || '').trim()) {
            this.keywords.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    public OnHasRecoveryChange()
    {
        this.hasRecovery = ! this.hasRecovery;
        if(!!this.currentCustomBucket.Recovery && !this.hasRecovery)
        {
            // origioanlly has reovery but want to remove the recovery. we will pop up an alert window saying this will remove the existing recovery
            if (confirm('Are you sure you want to remove these messages to user from this problem signature?')) 
            {
                this.hasRecovery = false;
            }else
            {
                this.hasRecovery = true;
            }
        }
    }

    public OnSendPrivateNoteChange()
    {
        this.hasPrivateNote = ! this.hasPrivateNote;
        if(!!this.currentCustomBucket.ProblemSignature && !!this.currentCustomBucket.ProblemSignature.PrivateNote && !this.hasPrivateNote)
        {
            // origioanlly has reovery but want to remove the recovery. we will pop up an alert window saying this will remove the existing recovery
            if (confirm('Are you sure you want to remove these messages to user from this private note?')) 
            {
                this.hasPrivateNote = false;
            }else
            {
                this.hasPrivateNote = true;
            }
        }
    }

    public onChangeAutoRecovery(recoverySelected: string)
    {
        this.selectedAutoRecovery = recoverySelected!="-1"? recoverySelected:'';
    }

    public testRuleMatchBetweenProblemSignatureAndSession()
    {
        this.sessionRuleMatchResult = null;
        let sub= this.service.testRuleMatchBetweenProblemSignatureAndSession(this.sessionId_forTestRule, this.currentCustomBucket.ProblemSignature.Id, this.currentCustomBucket.ProblemSignature.Client).subscribe(res => 
        {
            this.sessionRuleMatchResult = res;
            this.finalize(sub);
        },error =>
        {
            this.finalize(sub);
        });
    }

    uploadFileForRuleMatch() {  
        let file:File = this.fileInput.nativeElement.files[0];
        var reader = new FileReader();
        reader.readAsText(file);
        return Observable.create(observer => {
          reader.onloadend = () => {
            observer.next(reader.result);
            observer.complete();
          };
        });
      }  
    
      public RuleMatchByFile()
      {
        this.sessionRuleMatchResult = null;
        this.uploadFileForRuleMatch().subscribe((fileData) => 
        {
          this.spinnerService.displayLoader(true);

          let sub = this.service.RuleMatchByFile(fileData, this.currentCustomBucket.ProblemSignature.Id, this.currentCustomBucket.ProblemSignature.Client).subscribe(response => {  
            this.sessionRuleMatchResult = response;
            this.spinnerService.displayLoader(false);
            sub.unsubscribe();
          },
          error => {
              this.spinnerService.displayLoader(false);
              sub.unsubscribe();
          }); 
        });
      }
}
