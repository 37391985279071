import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IncidentListComponent } from './incident-list.component';
import { IncidentDetailComponent } from './incident-detail.component'

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'incidents',
          children: [
              {
                  path: '',
                  component: IncidentListComponent,
              },
              {
                  path: ':Id',
                  component: IncidentDetailComponent,
              }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class IncidentRoutingModule { }
