import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { PreviewPlace } from '../shared/models/WfsModels';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
@Component({
  selector: 'app-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['../app.component.css']
})
export class PreviewListComponent implements OnInit,OnDestroy{
  public comingSoonList:PreviewPlace[];
  public filteredList:PreviewPlace[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private util:Utils, private notificationBarService:NotificationBarService){}
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
      this.dtTrigger.unsubscribe();
  }
  ngOnInit() {
    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 100,
        processing: true,
        order: [[0, 'asc']]
      };
    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetComingSoonListAsync().subscribe(response => 
        {
            this.finalize(sub);
            if (!response || response.length == 0)
            {
                this.notificationBarService.openSnackBar("no coming soons exist.",'','Info');
                return;
            }

            this.filteredList = response;
            this.dtTrigger.next();
        },error =>
        {
            this.finalize(sub);
        }
    );
  }

  private finalize(subscription: Subscription, errorMsg?: string):void
  {
      this.spinService.displayLoader(false);
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }
}
