import { RecoveryInfo, BulkUploadResult } from '../shared/models/WfsModels';
import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription, Observable } from 'rxjs/Rx';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['../app.component.css']
})
export class BulkUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput; 
  public success:boolean;
  public uploadedList:RecoveryInfo[]; 
  public errorList:string[]; 
  constructor(private spinnerService:SpinnerService, private diagService:DiagDefinitionService, private util:Utils, private notificationBarService:NotificationBarService){}
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
  }
  ngOnInit() {
  }

  uploadFile() {  
    let file:File = this.fileInput.nativeElement.files[0];
    if(file.name.indexOf("csv")<0 || file.type!="application/vnd.ms-excel")
    {
      alert("Only csv file is suppported");
    }

    var reader = new FileReader();
    reader.readAsText(file);
    return Observable.create(observer => {
      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      };
    });
  }  

  public upload()
  {
    this.uploadFile().subscribe((csvData) => 
    {
      this.spinnerService.displayLoader(true);
      let sub = this.diagService.UploadExcel(csvData).subscribe(response => {  
        let result : BulkUploadResult = response;
        if (!!result)
        {
          this.success=true;
          this.uploadedList = result.m_Item1;
          this.errorList = result.m_Item2; 
        }

        this.spinnerService.displayLoader(false);
        sub.unsubscribe();
      },
      error => {
          this.spinnerService.displayLoader(false);
          sub.unsubscribe();
      }); 
    });
  }

  public getAudience(audienceList:string[])
  {
      if(!audienceList || audienceList.length ==0)
      {
          return null;
      }else
      {
          return audienceList.join(", ");
      }
  }
}