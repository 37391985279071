import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { ActivatedRoute, Router} from '@angular/router';
import { ExperimentFlight, PreviewPlace } from '../shared/models/WfsModels';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA, SEMICOLON } from '@angular/cdk/keycodes';
import { Utils } from '../shared/Utils/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-flights-detail',
  templateUrl: './flights-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class FlightsDetailComponent implements OnInit {
  public form: FormGroup;
  public success:boolean = false;
  public env: string;
  public flightName:string;
  public currentFlight:ExperimentFlight;
  public flightEnabled:boolean;
  public isNew:boolean;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public envList:string[] = ['PROD','PREPROD','EUDB','SDF','TEST'];
  constructor(
    private formBuilder: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private util: Utils,
    private spinService:SpinnerService,
    private router: Router,
    private notificationBarService:NotificationBarService) 
  { 
    this.form = this.formBuilder.group({
      EnvironmentName:['', Validators.required],
      FlightName: ['', Validators.required],
      FlightEnabled: false,
      FlightPercentage: [''],
      FlightSalt: [''],
      FlightedUsers:[]
    });
  }

  ngOnInit() {
        this.env = this.route.snapshot.params['ENV'];
        this.flightName = this.route.snapshot.params['FN'];
        this.isNew = this.env == '0';
        if (!this.isNew)
        {
          this.form.get('EnvironmentName').disable();
          this.form.get('FlightName').disable();
          this.spinService.displayLoader(true);
          let sub = this.service.GetExperimentFlightAsync(this.env, this.flightName).subscribe(
            response => 
            {
              this.spinService.displayLoader(false);
              this.finalize(sub);
              this.success = true;
              if(!!response)
              {
                this.currentFlight = response;
                this.updateForm();
                this.success = true;
              }
              else{
                this.notificationBarService.openSnackBar("Experiment flight doesn't exist",'','Warn');
              }
            },
            (error:HttpErrorResponse) => {
              if (error.status == 401)
              {
                this.notificationBarService.openSnackBar(error.status + " " + error.statusText,'','Error');
              }
              this.spinService.displayLoader(false);
              this.finalize(sub);
            }
          );
        }
        else{
          this.success = true;
          this.currentFlight = new ExperimentFlight();
          this.currentFlight.FlightedUsers=[];
        }    
  }
  
  ngOnDestroy() {
    this.notificationBarService.dismiss();
  }

  onSubmit() {
    if(this.isNew)
    {
      this.currentFlight.EnvironmentName = this.form.controls["EnvironmentName"].value;
      this.currentFlight.FlightName = this.form.controls["FlightName"].value;
    }
    this.GetValueFromForm();
    
    if (this.isNew) {
      this.spinService.displayLoader(true);
      let sub = this.service.SubmitExperimentFlightAsync(this.currentFlight).subscribe(() => {
          this.onComplete(sub);
          this.spinService.displayLoader(false);
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
    } else {
      this.spinService.displayLoader(true);
        let sub = this.service.UpdateExperimentFlightAsync(this.currentFlight).subscribe(() => {
            this.onComplete(sub);
            this.spinService.displayLoader(false);
        },
        error => {
            this.finalize(sub);
            this.spinService.displayLoader(false);
        });
    }
  }

  public onDelete()
  {
    this.spinService.displayLoader(true);
    let sub = this.service.DeleteExperimentFlightAsync(this.env, this.flightName).subscribe(
      response => {
        this.onComplete(sub);
      },
      error => {
        this.spinService.displayLoader(false);
        this.finalize(sub);
      });
  }

  private onComplete(subscription:Subscription) {
    this.form.reset();
    this.finalize(subscription);
    this.spinService.displayLoader(false);
    this.router.navigate(['/oncall/experimentflight']);
  }

  private GetValueFromForm() 
    {
        
        this.currentFlight.FlightEnabled = this.form.controls["FlightEnabled"].value;
        this.currentFlight.FlightPercentage = this.form.controls["FlightPercentage"].value;
        this.currentFlight.FlightSalt = this.form.controls["FlightSalt"].value;
        this.currentFlight.FlightedUsers = this.form.controls["FlightedUsers"].value;
    }

    public updateForm() {
      this.form.patchValue({
        EnvironmentName:this.currentFlight == undefined ? '' : this.currentFlight.EnvironmentName,
        FlightName:this.currentFlight == undefined ?'' : this.currentFlight.FlightName,
        FlightEnabled: this.currentFlight == undefined ? false : this.currentFlight.FlightEnabled,
        FlightPercentage: this.currentFlight == undefined ? '' : this.currentFlight.FlightPercentage,
        FlightSalt: this.currentFlight == undefined ? '' : this.currentFlight.FlightSalt,
        FlightedUsers: this.currentFlight == undefined ? [] : this.currentFlight.FlightedUsers,
      });
  }

  public addUser(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add non empty value
    if ((value || '').trim()) {
      this.currentFlight.FlightedUsers.push(value.trim());
      this.form.patchValue({
        FlightedUsers: this.currentFlight.FlightedUsers,
      });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

public  removeUser(item: string): void {
    const index = this.currentFlight.FlightedUsers.indexOf(item);

    if (index >= 0) {
      this.currentFlight.FlightedUsers.splice(index, 1);
      this.form.patchValue({
        FlightedUsers: this.currentFlight.FlightedUsers,
      });
    }
  }

  private finalize(subscribtion:Subscription): void {
    if (subscribtion) {
        subscribtion.unsubscribe();
    }
  }
}
