import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { PreviewPlaceConfig, PreviewPlace } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import {DomSanitizer} from '@angular/platform-browser';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
@Component({
  selector: 'app-preview-detail',
  templateUrl: './preview-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class PreviewDetailComponent implements OnInit,OnDestroy,AfterViewInit{
  public statusMessage:string
  public form: FormGroup;
  public currentPP:any;
  public clientSelection:string[]=['EXCEL','WORD','POWERPOINT','OUTLOOKDESKTOP'];
  public audienceSelection:string[]=['Automation','Dogfood','Microsoft','Insiders','Production','*'];
  public channelSelection:string[]=['Cc','DevMain','*'];
  public currentComingSoon:PreviewPlace;
  public comingSoonId:string;
  public success:boolean = false;
  public isNewComingSoon:boolean;
  public sanitizedLink:any;
  public articleUri:string;
  
  constructor(
    private formBuilder: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinService:SpinnerService,
    public sanitizer: DomSanitizer, private notificationBarService:NotificationBarService) 
  { 
    this.form = this.formBuilder.group({
      articleId:['', Validators.required],
      friendlyName: ['', Validators.required],
      articleLink: [''],
      flights: ['', Validators.required]
    });
  }
  ngAfterViewInit(): void {
    this.newlinkShowIframe();
  }
  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
  }
  ngOnInit()  {
    this.comingSoonId = this.route.snapshot.params['Id']; 
    if(this.comingSoonId!='0')
    {
      this.isNewComingSoon = false;
      this.spinService.displayLoader(true);
      let sub = this.service.GetComingSoonAsync(this.comingSoonId).subscribe(
        response => {
          this.success = true;
          if(!!response)
          {
            this.currentComingSoon = response;
            this.form.patchValue({
              friendlyName: this.currentComingSoon.Name,
              articleId: this.currentComingSoon.ArticleId,
              articleLink: this.currentComingSoon.Link,
              flights: this.currentComingSoon.Flights
           });

           this.newlinkShowIframe();
          }
          else{
            this.notificationBarService.openSnackBar("Coming soon doesn't exist",'','Warn');
          }

          this.spinService.displayLoader(false);
          this.finalize(sub);
        },
        error => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
        }
      );
    }
    else{
      this.isNewComingSoon = true;
      this.currentComingSoon = new PreviewPlace();
      this.currentComingSoon.Configs = [];
    }
  }

  public onCreateConfig()
  {
      document.getElementById("beforeAdd").style.display='none';
      document.getElementById("Adding").style.display='table-row';
  }

  public createConfig()
  {
          let client = (<HTMLInputElement>document.getElementById("newConfigClient")).value;
          let audience = (<HTMLInputElement>document.getElementById("newConfigAudience")).value;
          let channel = (<HTMLInputElement>document.getElementById("newConfigChannel")).value;
          let build = (<HTMLInputElement>document.getElementById("newConfigBuildRange")).value;
          if(!build)
          {
            return;
          }
          let buildMin = build.split('-')[0].trim();
          let buildMax = !!build.split('-')[1]?build.split('-')[1].trim():undefined;
          // Checking existing pair
          let result = this.currentComingSoon.Configs.filter((item:PreviewPlaceConfig)=>{
              return item.Client == client && item.Audience == audience && item.Channel == channel;
          })
          if(!!result && result.length>0)
          {
              alert("The config with same client, audience and channel already exists, please modify the existing config");
              return;
          }

          if(!!client && !!audience && !!channel && !!buildMin && this.util.ValidBuildVersion(buildMin) && (!buildMax || this.util.ValidBuildVersion(buildMax)))
          {
              let newConfig:PreviewPlaceConfig = new PreviewPlaceConfig();
              newConfig.Client = client;
              newConfig.Audience = audience.toLocaleLowerCase();
              newConfig.Channel = channel.toLocaleLowerCase();
              newConfig.BuildRange = buildMin+'-';
              if(!!buildMax)
              {
                newConfig.BuildRange=newConfig.BuildRange+buildMax;
              }
              newConfig.PreviewPlaceArticleInfoTableRowKey = this.util.GenerateGuid();
              this.currentComingSoon.Configs.push(newConfig);
              this.createReset();
          }else{
              return;
          }
  }

  public createReset()
  {
      (<HTMLInputElement>document.getElementById("newConfigClient")).value="";
      (<HTMLInputElement>document.getElementById("newConfigAudience")).value="";
      (<HTMLInputElement>document.getElementById("newConfigChannel")).value="";
      (<HTMLInputElement>document.getElementById("newConfigBuildRange")).value="";
      document.getElementById("beforeAdd").style.display='table-row';
      document.getElementById("Adding").style.display='none';
  }

  public onRemoveConfig(config:PreviewPlaceConfig)
  {

    let index = this.currentComingSoon.Configs.findIndex(o => o.PreviewPlaceArticleInfoTableRowKey === config.PreviewPlaceArticleInfoTableRowKey); //find index in your array
    this.currentComingSoon.Configs.splice(index, 1);//remove element from array
  }

  
  public onTriggerEditConfig(item:PreviewPlaceConfig)
  {
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"edit").style.display='none'; //hide 
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"delete").style.display='none'; //hide 
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"ok").style.display='inline'; //show
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"withdraw").style.display='inline'; //show
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"buildRange").style.display='none'; //hide 
      document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"modifyBuildRange").style.display='inline'; //show
  }

  public onResetFromModify(item:PreviewPlaceConfig)
  {
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"edit").style.display='inline'; 
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"delete").style.display='inline'; 
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"ok").style.display='none';
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"withdraw").style.display='none';
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"buildRange").style.display='inline';
    document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"modifyBuildRange").style.display='none';
    (<HTMLInputElement>document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"newBuild")).value="";
  }

  public onUpdateConfig(item:PreviewPlaceConfig)
  {
    let build = (<HTMLInputElement>document.getElementById(item.PreviewPlaceArticleInfoTableRowKey+"newBuild")).value;
    if(!build)
    {
      return;
    }
    let buildMin = build.split('-')[0].trim();
    let buildMax = !!build.split('-')[1]?build.split('-')[1].trim():undefined;
    if(!!buildMin && this.util.ValidBuildVersion(buildMin) && (!buildMax || this.util.ValidBuildVersion(buildMax)))
    {    
      let newbuild = buildMin + '-';
      if(!!buildMax)
      {
        newbuild=newbuild+buildMax;
      }
      this.onRemoveConfig(item);
      item.BuildRange = newbuild;
      this.currentComingSoon.Configs.push(item);

      this.onResetFromModify(item);
    }else{
      return;
    }
  }

  public onSubmit()
  {
    if(!this.util.ValidNumberLetterOnly(this.form.value.articleId))
    {
      return;
    }

    this.currentComingSoon.Name = this.form.value.friendlyName;
    this.currentComingSoon.Link = this.articleUri;
    this.currentComingSoon.Flights = this.form.value.flights;
    if(this.comingSoonId=='0')
    {
      this.currentComingSoon.ArticleId = this.form.value.articleId.toLocaleLowerCase();
      this.comingSoonId = this.currentComingSoon.ArticleId.toLocaleLowerCase();
      let sub = this.service.SubmitComingSoonAsync(this.currentComingSoon).subscribe(
        response => {
          this.spinService.displayLoader(false);
          if(!response)
          {
            this.statusMessage = "failed to submit this coming soon";
            return;
          }

          this.finalize(sub);
          this.form.reset();
          this.router.navigate(['/comingsoons']);
        },
        error => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
        });
    }
    else
    {
      let sub = this.service.UpdateComingSoonAsync(this.currentComingSoon).subscribe(
        response => {
          this.spinService.displayLoader(false);
          if(!response)
          {
            this.statusMessage = "failed to update this coming soon";
            return;
          }

          this.finalize(sub);
          this.form.reset();
          this.router.navigate(['/comingsoons']);
        },
        error => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
        });
    }
  }

  public onDelete()
  {
    if (confirm('Are you sure you want to delete?')) {
      this.spinService.displayLoader(true);
      let sub = this.service.DeleteComingSoonAsync(this.comingSoonId).subscribe(
        response => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
          this.router.navigate(['/comingsoons']);
        },
        error => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
        });
    }
  }

  public newlinkShowIframe()
  {
    let id = this.form.value.articleId;
    if(this.util.ValidNumberLetterOnly(id))
    {
      this.articleUri = 'https://support.office.com/client/results?HelpID='+id+'&lcid=1033&syslcid=1033&uilcid=1033&ShowNav=false&VERSION=90&NS=OUTLOOK'
      const iframe =  document.getElementById('embeddedPage') as HTMLIFrameElement;
      if(!!iframe)
      {
        iframe.contentWindow.location.replace(this.articleUri);
      }
    }
  }

  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public GetChannel(input:string)
  {
    if(input=="devmain")
    {
      return "DevMain";
    }

    return input;
  }
}
