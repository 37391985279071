import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecoveryListComponent } from './recovery-list.component';
import { RecoveryDetailComponent } from './recovery-detail.component';
import { BulkUploadComponent } from './bulk-upload.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild([
        {
            path: 'recovery',
            children: [
                {
                    path: '',
                    component: RecoveryListComponent,
                },
                {
                    path: ':Id',
                    component: RecoveryDetailComponent,
                },
                {
                    path: 'bulkupload/display',
                    component: BulkUploadComponent,
                }

            ]
        }
    ])
],
exports: [
    RouterModule
]
})
export class RecoveryRoutingModule { }
