<div class="flex-container3" *ngIf="success">
  <div style="margin-top: 10px;margin-bottom: 10px;">
    <form [formGroup]="form">
      <label style="width: 100%">
          User Upn
          <input class = "form-control" formControlName="userUpn" required maxlength="254" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" ng-pattern-restrict style="font-weight: normal">
          <small *ngIf="!form.controls.userUpn.valid" class="text-danger" >Required, max length 254, must a valid upn</small>
      </label>
    </form>
    <button style="margin-right: 10px;" [disabled]="!form.valid" type="button" (click)="onAddNewApprover()">Add new approver</button>
  </div>
  <div *ngIf='allApprovers && allApprovers.length > 0'  style="height:100%">
      <mat-card>
          <mat-card-title>Change Request Approvers</mat-card-title>
          <mat-card-content>
            <table class="table">
                  <thead>
                      <tr>
                        <th style="width: 80%">
                            Approver
                        </th>
                        <th style="width: 20%">
                            Remove
                        </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of allApprovers">
                        <td>{{ item }}</td>
                        <td><i class="fa fa-close" (click)="onRemoveApprover(item)" style="color:indianred;cursor: pointer"></i></td>
                      </tr>
                  </tbody>
              </table>
          </mat-card-content>
      </mat-card>
    </div>
</div>

