import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { RecoveryEditComponent } from './recoveryedit.component';
import {HistoryDisplayComponent} from './historydisplay.component';
import {LocStatusComponent} from './Locstatus.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        DataTablesModule,
        FlexLayoutModule,
        MatBadgeModule,MatButtonModule,MatDialogModule,MatIconModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCardModule
    ],
    declarations: [
        RecoveryEditComponent,
        HistoryDisplayComponent,
        LocStatusComponent,
    ],
    exports: [
        RecoveryEditComponent,
        HistoryDisplayComponent,
        LocStatusComponent,
    ],
})

export class SharedModule{}