import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestIncidentDetailComponent } from '../createtestincident/testincident-detail.component';
import { TestIncidentListComponent } from '../createtestincident/testincident-list.component';

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'oncall',
          children: [
              {
                  path: 'testincidents',
                  component: TestIncidentListComponent,
              },
              {
                  path: 'testincidents/:Id',
                  component: TestIncidentDetailComponent,
              }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class TestIncidentRoutingModule { }
