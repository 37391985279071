import { CrashDetailsComponent } from './crashdetails.component';
import { CrashResolutionListComponent } from './crashresolutionlist.component';
import { CrashSectionComponent } from './crashsection.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'crashes',
                component: CrashSectionComponent,
                children: [
                    {
                        path: '',
                        component: CrashResolutionListComponent,
                    },
                    {
                        path: ':Id/:Version',
                        component: CrashDetailsComponent,
                    }
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class CrashResolutionRouting {
}