import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { ExperimentFlight } from '../shared/models/WfsModels';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-flights-list',
  templateUrl: './flights-list.component.html',
  styleUrls: ['../app.component.css']
})
export class FlightsListComponent implements OnInit {
  public flightList:ExperimentFlight[];
  public filteredList:ExperimentFlight[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private notificationBarService:NotificationBarService){}
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      order: [[1, 'asc']]
    };
    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetExperimentFlightsAsync().subscribe(response => 
        {
            this.finalize(sub);
            if (!response || response.length == 0)
            {
                this.notificationBarService.openSnackBar("no flights exist.",'','Info');
                return;
            }

            this.flightList = response;
            this.filteredList = this.flightList;
            this.dtTrigger.next();
        },(error :HttpErrorResponse) =>
        {
          if (error.status == 401)
          {
            this.notificationBarService.openSnackBar(error.status + " " + error.statusText,'','Error');
          }
          this.finalize(sub);
        });
  }

  ngOnDestroy() {
    this.notificationBarService.dismiss();
    this.dtTrigger.unsubscribe();
  }

  public getUsers(userList:string[])
  {
      if (!!userList && userList.length>0)
      {
        return userList.join(';');
      }

      return '';
  }
  private finalize(subscription: Subscription):void
  {
      this.spinService.displayLoader(false);
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

}
