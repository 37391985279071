import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalysislistComponent } from './analysislist.component';
import { SessionAttributesComponent } from './session-attributes.component';

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'analysis',
          children: [
              {
                  path: '',
                  component: AnalysislistComponent,
              },
              {
                  path: 'drilldown',
                  component: SessionAttributesComponent,
              },
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class AnalysisRoutingModule { }
