export class ClientName{
    static readonly OUTLOOKDESKTOP = "outlookdesktop";
    static readonly EXCEL = "excel";
    static readonly WORD = "word";
    static readonly PP = "powerpoint";
    static readonly MONARCH = "monarch";
    static readonly ONENOTE = "onenote";
    static readonly OWA = "owa";
    static readonly SURFACEDUO = "surfaceduo";
    static readonly DWENGINEV2 = "dwenginev2"
}