import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeedbackSessionTableData, SaraTicket } from '../shared/models/WfsModels';
import { ClientName } from '../shared/models/ConstValues';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { Subject, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from '../shared/services/global.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['../app.component.css']
})
export class FeedbackListComponent implements OnInit, OnDestroy {

  public from:string;
  public to:string;
  public timerangesearch:Boolean;
  public status:string;
  public feedbackSessionList:FeedbackSessionTableData[];
  public filteredList:FeedbackSessionTableData[];
  public client:string;
  public symptom:string;
  public symptomOptionList:string[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private utils:Utils, private formBuilder: FormBuilder,private globalService:GlobalService){
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  @ViewChild('fromInput', {
    read: MatInput
  }) fromInput: MatInput;
  
  @ViewChild('toInput', {
    read: MatInput
  }) toInput: MatInput;
  
  resetDatePickerForm() {
    this.fromInput.value = '';
    this.toInput.value = '';
    this.from ='';
    this.to = '';
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true
    };
    this.client = this.globalService.getClient();
    this.spinService.displayLoader(true);
    if (this.client.toLocaleLowerCase()=="outjio")
    {
      this.symptomOptionList = ["Feedback"];
    }else
    { 
      let sub = this.diagDefService.GetSymptomsForClientAsync(this.client).subscribe(response => 
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
            this.symptomOptionList = response;
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
      );
    }
   
  }

  dateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.timerangesearch = true;
    let entireDate= event.value;
    let formatedTime = this.getFormatedDate(entireDate);
    if(type=='from')
    {
      this.from = formatedTime;
    }
    else if (type=='to')
    {
      this.to = formatedTime ;
    }
  }

  private getFormatedDate(date:Date)
  {
    let year = date.getFullYear();
    let month = this.padLeadingZero(date.getMonth()+1); 
    let day = this.padLeadingZero(date.getDate());
    return `${year}_${month}_${day}`;
  }
  private padLeadingZero(number:number) 
  {
    return (number < 10 ? '0' : '') + number;
  }

  public validDateRange()
  {
    return !!this.from &&!! this.to && this.from<this.to;
  }

  public Search(type:string)
  {
    let starttime;
    let endtime;
    this.timerangesearch = type=="timerange";
    if(!this.timerangesearch)
    {
      this.resetDatePickerForm();
    }
    if(type=='today')
    {
      starttime = this.getFormatedDate(new Date());
      endtime = this.getFormatedDate(new Date());
    }
    else if(type=='week')
    {
      starttime = this.getFormatedDate(new Date(new Date().setDate(new Date().getDate() - 7)));
      endtime = this.getFormatedDate(new Date());
    }
    else if(type=='month')
    {
      var d = new Date();
      d.setMonth(d.getMonth() - 1);
      starttime = this.getFormatedDate(d);
      endtime = this.getFormatedDate(new Date());
    }
    else if(type=='3month')
    {
      var d = new Date();
      d.setMonth(d.getMonth() - 3);
      starttime = this.getFormatedDate(d);
      endtime = this.getFormatedDate(new Date());
    }
    else if(type=='year')
    {
      var date = new Date();
      date.setFullYear( date.getFullYear() - 1 );
      starttime = this.getFormatedDate(date);
      endtime = this.getFormatedDate(new Date());
    } else if (type =='timerange')
    {
      starttime = this.from;
      endtime = this.to;
    }

    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetFeedbackListAsync(this.client, this.symptom,starttime,endtime).subscribe(response => 
        {
            this.spinService.displayLoader(false);
            this.feedbackSessionList = response;
            this.feedbackSessionList.forEach(item=>{
              item.SessionObj = <SaraTicket>JSON.parse(item.Session);
            })
            
            // filter by app
            this.filteredList = this.feedbackSessionList;
            this.rerender();
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    if(this.dtElement.dtInstance)
    {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }

    this.dtTrigger.next();
  }

  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public getDate(pk:string)
  {
    let first = pk.indexOf("_");
    let second = pk.indexOf("_", first + 1);
    return pk.substring(second+1);
  }

  public onOpenTicket(id:String)
  {
      window.open("#/saratickets/" + id);
  }
}
