<div class="flex-container3">
  <div style="margin-top: 10px;margin-bottom: 10px;">
      <a [routerLink]="['/comingsoons', 0]" right="align">+ Create Coming Soon</a>
  </div>
    <mat-card>
        <mat-card-title>Coming Soon</mat-card-title>
        <mat-card-content>
            <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Article Id</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredList">
                        <td><a [routerLink]="['/comingsoons', item.ArticleId]">{{ item.Name }}</a></td>
                        <td>{{ item.ArticleId }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>
