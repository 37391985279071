import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecoveryRoutingModule } from './recovery-routing.module';
import { RecoveryDetailComponent } from './recovery-detail.component';
import { RecoveryListComponent } from './recovery-list.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../shared/sharedmodule/shared.module';
import { FlexLayoutModule} from "@angular/flex-layout";
import { BulkUploadComponent } from './bulk-upload.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  imports: [
    CommonModule,
    RecoveryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatCardModule,MatChipsModule, MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,MatTooltipModule,
    FlexLayoutModule,
    SharedModule,
  ],
  declarations: [RecoveryDetailComponent, RecoveryListComponent, BulkUploadComponent]
})
export class RecoveryModule { }
