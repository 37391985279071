import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreviewDetailComponent } from './preview-detail.component';
import { PreviewListComponent } from './preview-list.component';
@NgModule({
  imports: [RouterModule.forChild(
    [
      {
          path: 'comingsoons',
          children: [
              {
                  path: '',
                  component: PreviewListComponent,
              },
              {
                  path: ':Id',
                  component: PreviewDetailComponent,
              }
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class PreviewplaceRoutingModule { }
