<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div class="flex-container3">
    <div *ngIf="success">
        <div style="margin-left: 5%;margin-right: 5%;margin-top: 10px;margin-bottom: 10px; padding:20px;box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);">
            <h1>Problem Signature</h1>
            <div *ngIf="!!changeReqObj" style="margin-bottom: 20px">
                    <div style="cursor: pointer;" (click)="ToggleChangeRequest()">
                        <i *ngIf="openChangeReq==false" class="fa fa-caret-right" style="margin-right:10px;color: grey"></i>
                        <i *ngIf="openChangeReq==true" class="fa fa-caret-down" style="margin-right:10px;color: grey"></i>
                        <span style="color:red">Pending Change Request Info</span>
                    </div>
                    <div *ngIf="openChangeReq==true" style="padding:18px;border: 1px solid #ccc;border-radius: 4px;background-color: #f5f5f5">
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Trigger Condition</label>
                            {{changeReqObj.ProblemSignature.ProblemSignatureXml}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Recovery Title</label>
                            {{changeReqObj.Recovery.RecoveryTitle}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Problem Description</label>
                            {{changeReqObj.Recovery.ProblemDescription}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Recovery Steps</label>
                            {{changeReqObj.Recovery.RecoverySteps}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Has A Fix</label>
                            {{changeReqObj.Recovery.HasAFix}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Link</label>
                            {{changeReqObj.Recovery.Link}}<br>
                        </div>
                        <div style="margin-bottom: 5px" *ngIf="!!changeReqObj.Recovery">
                            <label style="width: 100%">Audience</label>
                            {{changeReqObj.Recovery.Audience.join(',')}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Keywords</label>
                            {{changeReqObj.ProblemSignature.Keywords}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">Tags</label>
                            {{changeReqObj.ProblemSignature.Tags.join(',')}}<br>
                        </div>
                        <div style="margin-bottom: 5px">
                            <label style="width: 100%">SendEmail</label>
                            {{changeReqObj.ProblemSignature.SendEmail.join(',')}}<br>
                        </div>
                        
                        <a [routerLink]="[ '/changerequest',changeReqId,'problemsignature','approved']" style="padding: 8px 12px; border: 1px solid #4682B4;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #4682B4; margin-right: 10px">
                            Approve</a>
                        <a [routerLink]="[ '/changerequest',changeReqId,'problemsignature','denied']" style="padding: 8px 12px; border: 1px solid #FA8072;border-radius: 2px;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;text-align: center;background-color: #FA8072;">
                                Deny</a>
                </div>
            </div>

            <div style="border: 0;box-shadow: none;margin-top: 10px;">
                <div *ngIf="bucketId!='0'">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Test if this problem signature can be hit by given session or Diagnostic Properties json file.(currently only keyword and json rule match are supported, no ETL).
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p><i class="fa fa-info-circle" style="color:orange;"></i> if you are updating this problem signature, please save it first and then come back to test so that we can pick up your change</p>
                        <mat-tab-group>
                            <mat-tab label="Test with session id"> 
                                <div style="padding: 20px;">
                                    <mat-form-field style="width:300px">
                                        <input matInput type="text" style="margin-left: 10px;width: 300px" placeholder="session id" [(ngModel)]="sessionId_forTestRule">
                                    </mat-form-field>
                                    <button (click)="testRuleMatchBetweenProblemSignatureAndSession()" style="margin-left:10px; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 2px 20px 0 rgba(0,0,0,0.19)">Test Rule Match with a session</button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Test with diagnostic property file"> 
                                <div style="padding: 20px;display: flex;">
                                    <div>  
                                        <input class="form-control" type="file" #fileInput />  
                                    </div>  
                                    <div style="margin-left: 20px;">  
                                        <button (click)="RuleMatchByFile();">Upload</button>  
                                    </div>  
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <div *ngIf="!!sessionRuleMatchResult">
                            <div *ngIf="sessionRuleMatchResult.m_Item1==true">
                                <p><i class="fa fa-check-circle" style="color:darkgreen;margin-right:10px;"></i>Matched</p>
                                <p>(**Note: Test against Diagnostic Properties and the session, but a recovery may not be returned based on its flight audience.)</p>
                            </div>
                            <div *ngIf="sessionRuleMatchResult.m_Item1==false">
                                <p><i class="fa fa-close" style="color:red;margin-right:10px;"></i>Not Matched</p>
                                <p *ngIf="!sessionRuleMatchResult.m_Item2 || sessionRuleMatchResult.m_Item2.length==0">None of the keyword or json simple rule matches.</p>
                                <div *ngIf="!!sessionRuleMatchResult.m_Item2 && sessionRuleMatchResult.m_Item2.length>0">Part of the json rule or keyword got matched are displayed as below:
                                    <ul *ngFor="let item of sessionRuleMatchResult.m_Item2">
                                        <li>{{ item }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      </mat-expansion-panel>
                </div>   

                <div style="margin-top: 10px;">
                    <div [ngClass]="{'inactiveDiv': hasPendingChangeRequest}">
                        <div style="margin-bottom:20px">
                            <label for="bucketDefinitionNameId">Name</label>
                            <input class="form-control"  id="bucketDefinitionNameId" type="text" placeholder="enter bucket name (required)" maxlength="100" [(ngModel)]="bucketDefinitionName" />
                            <small *ngIf="!bucketDefinitionName" class="text-danger">Required</small>
                        </div>
                        <div style="margin-bottom:20px">
                            <label><input type="checkbox" id="problemSignatureForBot" [checked]="isBotProblemSignature===true"  [disabled]="existingBucket==true"/>Serve in chat?</label>
                        </div>   
                        <div>
                            <mat-card style="margin-bottom:20px">
                                <mat-form-field style="display: inline;">
                                  <mat-chip-list #chipList2 aria-label="keyword selection">
                                    <mat-chip *ngFor="let item of keywords" [selectable]="selectable"
                                             [removable]="removable" (removed)="removeKeyword(item)">
                                        {{item}}
                                        <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                                    </mat-chip>
                                    <input placeholder="New keyword..."
                                           maxlength="30"
                                           [matChipInputFor]="chipList2"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="addKeyword($event)">
                                  </mat-chip-list>
                                </mat-form-field>
                            </mat-card>
                        </div>
                        <div style="margin-bottom: 30px">
                            <mat-drawer-container [hasBackdrop]="false" style="border: 1px solid #555;">
                                <mat-drawer #drawer mode="side" class="mat-sidenav" [(opened)]="defaultOpenDrawer">
                                    <div style="padding: 10px;">
                                        <b>Sample session</b> 
                                        <input type="text" style="margin-left: 10px;width: 300px" placeholder="session id must be a valid guid" [(ngModel)]="sessionid" />
                                        <button [disabled]="!isSessionIdGuid()" (click)="GetDCRNodesFromSession()" style="margin-left:10px; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 2px 20px 0 rgba(0,0,0,0.19)">Refresh</button>
                                        <span *ngIf="isSessionIdGuid()">
                                            <button style="margin-left:10px; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 2px 20px 0 rgba(0,0,0,0.19)"><a [routerLink]="['/saratickets', sessionid]">Navigate to this session</a></button>
                                        </span>
                                    </div>
                                    <div *ngIf="!!dcrError" style="padding: 10px;">
                                        {{dcrError}}
                                    </div>
                                    <div *ngIf="!!dcrNodes" style="padding: 10px;">
                                        <button (click)="toggleTree()" style="box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);border-radius: 3px;margin-bottom: 10px; padding: 5px;">Expand All / Collapse All</button>
                                        <dcr-tree-view [inputList]="dcrNodes" (addRule)="onAddRuleEventEmit($event)"></dcr-tree-view>
                                    </div>
                                </mat-drawer>
                                <div style="background-color: whitesmoke;overflow: auto;height: 600px; padding:20px">
                                    <button mat-raised-button (click)="drawer.toggle()" style="margin-bottom: 20px">Toggle sample session</button>
                                    <p style="font-size: 15px;font-weight: 400;margin-bottom: 20px;">Trigger Condition</p>
                                    <mat-button-toggle-group aria-label="Font Style" [value]="triggerConditionSelection">
                                        <mat-button-toggle [disabled]="disableEditor" value="js_etl" (change)="onTriggerConditionChange($event.value)">Editor</mat-button-toggle>
                                        <mat-button-toggle value="jslist" (change)="onTriggerConditionChange($event.value)">Raw</mat-button-toggle>
                                    </mat-button-toggle-group>

                                    <div *ngIf='triggerConditionSelection=="js_etl"'>
                                        <rule-view #ruleviewComponent [inputxml]="inputxmlForRuleView" (outputxml)="onOutputXMLEventEmit($event)"></rule-view> 
                                    </div>
            
                                    <div *ngIf='triggerConditionSelection=="jslist"' style="overflow:hidden;border: 1px solid rgba(0,0,0,.03);box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);padding:20px;background-color: whitesmoke">                              
                                        <mat-form-field style="margin-right: 20px">
                                            <mat-label>Payload Type</mat-label>
                                            <mat-select (selectionChange)="onAddPayloadTemplate(Payloadtype)" [(ngModel)]="Payloadtype">
                                            <mat-option *ngFor="let item of payloadTypeList" [value]="item.choiceText">
                                                {{item.choiceValue}}
                                            </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <textarea class="form-control" id="currentCustomBucketTextId" style="min-width: 100%;resize: none;" rows="14" [(ngModel)]="xmlForJSONlistView"></textarea>
                                    </div>
                                </div>
                            </mat-drawer-container>
                            <!-- Classfication ++ -->
                            <mat-card style="overflow: auto">
                                <mat-card-content>
                                    <label>Tagging</label>
                                    <mat-form-field style="display: inline;">
                                        <mat-chip-list #chipList aria-label="tag selection">
                                        <mat-chip *ngFor="let item of tags" [selectable]="selectable"
                                                    [removable]="removable" (removed)="removeTag(item)">
                                            {{item}}
                                            <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                                        </mat-chip>
                                        <input placeholder="New tag..."
                                                maxlength="30"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="addOnBlur"
                                                (matChipInputTokenEnd)="addTag($event)">
                                        </mat-chip-list>
                                    </mat-form-field>

                                    <div style="margin-bottom:10px;margin-top:10px">
                                        <label>Send email</label>
                                        <mat-form-field style="display: inline;">
                                            <mat-chip-list #chipList3 aria-label="send emails">
                                            <mat-chip *ngFor="let item of sendEmail" [selectable]="selectable"
                                                        [removable]="removable" (removed)="removeSendEmail(item)">
                                                {{item}}
                                                <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                                            </mat-chip>
                                            <input placeholder="only accepts microsoft emails"
                                                    maxlength="30"
                                                    [matChipInputFor]="chipList3"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    (matChipInputTokenEnd)="addSendEmail($event)">
                                            </mat-chip-list>
                                        </mat-form-field>
                                    </div> 

                                    <label style="margin-top: 10px;">Session Attributes</label>
                                    <table class='table' style="overflow: auto" id="configTable" style="line-height:0">
                                        <tr>
                                            <th style="width:20%">Attribute Name</th>
                                            <th style="width:20%">Value</th>
                                            <th style="width:10%"></th>
                                        
                                        </tr>
                                        <tr *ngFor="let item of attributes">
                                            <td>{{item.key}}</td>
                                            <td>{{item.value}}</td>
                                            <td>
                                                <i class="fa fa-close" (click)="onRemoveSessioMetadata(item)" style="color:indianred;cursor: pointer"></i>
                                            </td>
                                        </tr>
                                        <!-- Section for adding new -->
                                        <tr id="beforeAdd">
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <i class="fa fa-plus-square" (click)="onCreateSessioMetadata()" style="color:dimgray;cursor: pointer"></i>
                                            </td>
                                        </tr>
                                        <tr id="Adding" style="display:none">
                                                <td class="required">
                                                    <input id="newMetadataKey" maxlength="30" type="text" style="height:25px;border: 1px solid #ccc;border-radius: 4px"/>
                                                </td>
                                                <td class="required">
                                                    <input id="newMetadataValue" maxlength="30" type="text" style="height:25px;border: 1px solid #ccc;border-radius: 4px"/>
                                                </td>
                                                <td>
                                                    <i class="fa fa-check-circle" id="newBugOk" (click)="createSessioMetadata()" style="color:darkgreen;margin-right:10px;cursor: pointer"></i>
                                                    <i class="fa fa-undo" id="newBugWithdraw" (click)="createSessioMetadataReset()" style="margin-right:10px;cursor: pointer"></i>
                                                </td>
                                        </tr>
                                    </table>

                                    <label><input type="checkbox" (click)="OnSendPrivateNoteChange()" [checked]="hasPrivateNote==true"/>Send a private note?</label>
                                    <div *ngIf='hasPrivateNote==true'>
                                        <textarea class="form-control" id="privateNoteId" style="min-width: 100%;resize: none;" rows="14" [(ngModel)]="privateNote"></textarea>
                                    </div>
                                    <div style="margin-top: 10px;">
                                       <label style="margin-right: 10px;">Recovery action</label>
                                        <select (change)="onChangeAutoRecovery($event.target.value)" [(ngModel)]="selectedAutoRecovery">
                                            <option [value]='-1'>No Action</option>
                                            <option *ngFor="let item of autoRecovery" [value]="item.Id">{{item.Name}}</option>
                                        </select>
                                    </div>
                                </mat-card-content>
                            </mat-card> 
                            <!-- Classfication -- -->
                        </div>
                    </div>

                    <mat-card>
                        <label><input type="checkbox" id="hasRecovery" (click)="OnHasRecoveryChange()" [checked]="hasRecovery==true"/>Associate a Recovery?</label>
                        <div *ngIf='existingBucket&& !currentCustomBucket.Recovery' style="color:darkgoldenrod">* There is no recovery associated with this problem signature</div>
                        <div *ngIf='existingBucket&& !!currentCustomBucket.Recovery &&!hasRecovery' style="color:red">* Recovery information associated with this problem signature and all its existing translations will be permanently removed.</div>
                        <div *ngIf='hasRecovery==true'>
                            <recovery-detail [recovery]="recovery" [haspendingrequest]="hasPendingChangeRequest" #recoveryComponent></recovery-detail>
                        </div>
                    </mat-card>            
                </div>
                <div>
                    <loc-status [localized]="bucketId" *ngIf="!!recovery" page="custombucket" #locstatusComponent></loc-status>
                </div>
                <div style="margin-top: 10px;">
                    <button class="bluewhitebutton" style="float: right" [routerLink]="[ '/custombuckets']">Cancel</button>
                    <button class="bluewhitebutton" style="float: right;margin-right: 10px"  [disabled]="hasPendingChangeRequest" type="button" (click)="onSubmit()">Save</button>
                    <button class="bluewhitebutton" *ngIf="existingBucket" style="float: right;margin-right: 10px" (click)="onDelete()">Delete</button>
                    <div *ngIf='hasPendingChangeRequest' style="color:red">* Cannot make changes to this item because of a pending change request.</div>
                </div>

                <div *ngIf='auditHistory.length>0'>
                    <history-display [history]="auditHistory" #historyComponent></history-display>
                </div>
            </div>
        </div>
    </div>
</div>