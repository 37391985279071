import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { Incident, IncidentTableEntity, AuditHistory, IncidentPortalContract, Workload } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { HistoryDisplayComponent } from '../shared/sharedmodule/historydisplay.component';
@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class IncidentDetailComponent implements OnInit,OnDestroy {

  public statusMessage:string
  public success:boolean = false;
  public sanitizedLink:any;
  public id:string;
  public incident:Incident;
  public incidentPortalContract: IncidentPortalContract;
  public changeReqObj:IncidentPortalContract;
  public open:boolean=true;
  public rowsOnPage: number = 100;
  public saraSortBy: string = "CreatedTime";
  public saraSortOrder: string = "desc";
  public hasPendingChangeRequest : boolean  = false;
  public auditHistory: AuditHistory[] = [];
  public openChangeReq:boolean=false;
  @ViewChild('historyComponent') historyComponent: HistoryDisplayComponent;
  constructor(
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinService:SpinnerService,
    private notificationBarService:NotificationBarService) 
  { 
    
  }

  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
  }
  ngOnInit() {
    this.id = this.route.snapshot.params['Id']; 
    if(!this.id)
    {
      return;
    }

    this.spinService.displayLoader(true);
    let sub1 = this.service.GetIncidentAsync(this.id).subscribe(
      response => {
        this.success = true;
            this.incidentPortalContract = response;
            if (!!this.incidentPortalContract && !!this.incidentPortalContract.Incident)
            {
                this.incident = this.incidentPortalContract.Incident;
                this.auditHistory = this.incidentPortalContract.AuditHistory;
                if(!!this.incidentPortalContract.ChangeRequest)
                {
                    this.hasPendingChangeRequest = this.incidentPortalContract.ChangeRequest.RequestStatus == "pending";
                    if(this.hasPendingChangeRequest)
                    {
                      this.changeReqObj = <IncidentPortalContract> this.util.safelyParseJSON(this.incidentPortalContract.ChangeRequest.ChangeObject);
                    }
                }
            }else{
                this.notificationBarService.openSnackBar("Incident doesn't exist",'','Warn');
            }

            this.spinService.displayLoader(false);
            this.finalize(sub1);
      },
      error => {
        this.success = false;
        this.spinService.displayLoader(false);
        this.finalize(sub1);
      });

  
  }

  public onToggle()
  {
      this.open = !this.open;
  }

  public ToggleChangeRequest()
  {
      this.openChangeReq = !this.openChangeReq;
  }
  
  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public getWorkloadNames(Workloads: Workload[])
  {
    if (!!Workloads && Workloads.length>0)
    {
      const names:string[] = Workloads.map(obj=>{
        return obj.Name;
      })

      return names.join(',');
    }
  }
}
