import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { Subscription } from 'rxjs/Rx';
import { GlobalService } from '../shared/services/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-changereq-config',
  templateUrl: './changereq-config.component.html',
  styleUrls: ['../app.component.css']
})
export class ChangereqConfigComponent implements OnInit {
  public rowsOnPage: number = 100;
  public sortBy: string = "";
  public saraSortBy: string = "Timestamp";
  public saraSortOrder: string = "desc";
  public success:boolean;
  public client:string;
  public allApprovers:string[];
  public form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private spinService:SpinnerService,
    private service: DiagDefinitionService,
    private router: Router,
    private util: Utils, 
    private notificationBarService:NotificationBarService,
    private globalService:GlobalService) 
  {
    this.client = this.globalService.getClient();
    this.form = this.formBuilder.group({
      userUpn:['', Validators.required],
    });
  }

  ngOnInit() {
    this.getAllApprovers();
  }
  
  private getAllApprovers()
  {
    this.spinService.displayLoader(true);
    let sub = this.service.GetChangeRequestApproverListAsync(this.client).subscribe(
      response => {
        this.allApprovers = response;
        if(!this.allApprovers || this.allApprovers.length <=0)
        {
          this.notificationBarService.openSnackBar("No approvers found",'','Info');
        }

        this.success=true;
        this.finalize(sub);
        this.spinService.displayLoader(false);
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }
  public onAddNewApprover()
  {
    if(!this.util.ValidEmail(this.form.value.userUpn))
    {
      alert("User upn is not a valid email address");
      return;
    }

    this.spinService.displayLoader(true);
    let sub = this.service.AddChangeRequestApproverAsync(this.client,this.form.value.userUpn).subscribe(
      response => {
          this.form.reset();
          this.finalize(sub);
          this.getAllApprovers();
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }

  public onRemoveApprover(upn:string)
  {
    this.spinService.displayLoader(true);
    let sub = this.service.DeleteChangeRequestApproverAsync(this.client,upn).subscribe(
      response => {
        this.spinService.displayLoader(false);
        this.finalize(sub);
        this.getAllApprovers();
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
  }

  private finalize(subscribtion:Subscription): void {
    if (subscribtion) {
        subscribtion.unsubscribe();
    } 
  }
}
