import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { NotificationFeedbackTableData } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import {DomSanitizer} from '@angular/platform-browser';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';

@Component({
  selector: 'app-fb-detail',
  templateUrl: './fb-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class FbDetailComponent implements OnInit {

  public statusMessage:string
  public success:boolean = false;
  public currentTime:string;
  public currentId:string;
  public currentFb:NotificationFeedbackTableData;

  constructor(
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinService:SpinnerService,
    public sanitizer: DomSanitizer, private notificationBarService:NotificationBarService) 
  { 
  }

  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
  }

  ngOnInit() {
    this.currentTime = this.route.snapshot.params['time']; 
    this.currentId = this.route.snapshot.params['id']; 
    if(!!this.currentTime && !!this.currentId)
    {
      this.spinService.displayLoader(true);
      let sub = this.service.GetNotificationFeedbackAsync(this.currentTime, this.currentId).subscribe(
        response => 
        {
          this.spinService.displayLoader(false);
          this.finalize(sub);
          this.success = true;
          if(!!response)
          {
            this.currentFb = response;
          }
          else{
            this.notificationBarService.openSnackBar("Notification doesn't exist",'','Warn');
          }
        },
        error => {
          this.spinService.displayLoader(false);
          this.finalize(sub);
        }
      );
    }
  }

  private finalize(subscribtion:Subscription): void {
    if (subscribtion) {
        subscribtion.unsubscribe();
    }
}
}
