import {OnDestroy, Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { AuditHistory,RecoveryInfo, CrashRecoveryPortalContract, CrashRecovery } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import { DomSanitizer} from '@angular/platform-browser';
import { RecoveryEditComponent } from '../shared/sharedmodule/recoveryedit.component';
import { HistoryDisplayComponent } from '../shared/sharedmodule/historydisplay.component';
import { LocStatusComponent } from '../shared/sharedmodule/Locstatus.component';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { GlobalService } from '../shared/services/global.service';
@Component({
  selector: 'app-crash-detail',
  templateUrl: './crash-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class CrashDetailComponent implements OnInit, OnDestroy {
  public success:boolean;
  public form: FormGroup;
  public friendlyName:string;
  public isNew:boolean;
  public hasPendingChangeRequest:boolean = false;
  public auditHistory: AuditHistory[] = [];
  public allLocLanguages:string[]=['ar', 'bg', 'cs', 'da', 'de', 'el', 'es', 'et', 'fi', 'fr', 'he', 'hi', 'hr', 'hu', 'id', 'it', 'ja', 'kk', 'ko', 'lt', 'lv', 'ms', 'nl', 'no', 'pl', 'pt', 'pt-PT', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'th', 'tr', 'uk', 'vi', 'zh-Hans', 'zh-Hant'];
  public recovery: RecoveryInfo;
  public bucketLoaded: boolean = true;
  public currentCrash:CrashRecoveryPortalContract;
  public client:string;
  public failureCondition:string;
  public changeReqObj:CrashRecoveryPortalContract;
  public openChangeReq:boolean=false;
  public recoveryId :string;
  @ViewChild('recoveryComponent') recoveryComponent: RecoveryEditComponent;
  @ViewChild('historyComponent') historyComponent: HistoryDisplayComponent;  
  @ViewChild('locstatusComponent') locstatusComponent: LocStatusComponent;
  constructor(
    private formBuilder: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinnerService:SpinnerService,
    public sanitizer: DomSanitizer, private notificationBarService:NotificationBarService,private globalService:GlobalService) 
    {
      this.client = this.globalService.getClient(); 
      this.form = this.formBuilder.group({
        failureCondition: ['', Validators.required],
      });
    }

  ngOnInit() {
    this.failureCondition = this.route.snapshot.params['Failure'];
    if(this.failureCondition!='0')
    {
      this.spinnerService.displayLoader(true);
      let subscription2 = this.service.GetCrashRecoveryAsync(this.client.toLocaleLowerCase(), this.failureCondition).subscribe(
        response => {
            this.currentCrash = response;
            if (!!this.currentCrash && !!this.currentCrash.CrashRecovery && !!this.currentCrash.Recovery) {
                this.recovery = this.currentCrash.Recovery;
                this.auditHistory = this.currentCrash.AuditHistory;
                if(!!this.currentCrash.ChangeRequest)
                {
                    this.hasPendingChangeRequest = this.currentCrash.ChangeRequest.RequestStatus == "pending";
                    if(this.hasPendingChangeRequest)
                    {
                      this.changeReqObj = <CrashRecoveryPortalContract> this.util.safelyParseJSON(this.currentCrash.ChangeRequest.ChangeObject);
                    }
                }

                this.form.patchValue({
                    client: this.currentCrash.CrashRecovery.Client.toUpperCase(),
                    failureCondition: this.currentCrash.CrashRecovery.FailureCondition,
                });
                this.friendlyName = this.currentCrash.CrashRecovery.FriendlyName;
                this.recoveryId = this.currentCrash.Recovery.Id;
                this.success = true;
              }else{
                this.notificationBarService.openSnackBar("Crash Recovery doesn't exist",'','Warn');
              }

              this.spinnerService.displayLoader(false);
              this.finalize(subscription2);
          },
          error => {
              this.spinnerService.displayLoader(false);
              this.finalize(subscription2);
          });
    }else{
      this.spinnerService.displayLoader(false);
      this.isNew = true;
      this.success = true;
      this.currentCrash = new CrashRecoveryPortalContract();
      this.currentCrash.CrashRecovery = new CrashRecovery();
    }
  }

  ngOnDestroy() {
    this.notificationBarService.dismiss();
  }

  public Submit()
  {
    if(this.isNew)
    {
      this.currentCrash.CrashRecovery.Client = this.client.toLocaleLowerCase();
      this.currentCrash.CrashRecovery.FailureCondition = this.form.value.failureCondition.trim();
    }

    let subscription = this.recoveryComponent.onSubmit(this.recoveryComponent.currentRecovery != undefined ? this.recoveryComponent.currentRecovery.Name : "crashrecovery_"+this.form.value.client+"---"+this.form.value.failureCondition).subscribe(
      newRecovery=>{
        if(!newRecovery)
        {
            this.spinnerService.displayLoader(false);
            return;
        }

        this.currentCrash.Recovery = newRecovery;
        this.currentCrash.CrashRecovery.RecoveryId = newRecovery.Id;
        this.currentCrash.CrashRecovery.FriendlyName = newRecovery.RecoveryTitle;
        this.currentCrash.CrashRecovery.Audience = newRecovery.Audience;
        if (this.isNew) {
          this.spinnerService.displayLoader(true);
          let sub = this.service.SubmitCrashRecoveryAsync(this.currentCrash).subscribe(() => {
              this.onComplete(sub);
              this.spinnerService.displayLoader(false);
          },
          error => {
              this.finalize(sub);
              this.spinnerService.displayLoader(false);
          });
      } else {
        this.spinnerService.displayLoader(true);
          let sub = this.service.UpdateCrashRecoveryAsync(this.currentCrash).subscribe(() => {
              this.onComplete(sub);
              this.spinnerService.displayLoader(false);
          },
          error => {
              this.finalize(sub);
              this.spinnerService.displayLoader(false);
          });
      }

        this.finalize(subscription);
    },
    error => {
        this.finalize(subscription);
        this.spinnerService.displayLoader(false);
    });
  }

  private onComplete(subscription:Subscription) {
    this.form.reset();
    this.recoveryComponent.recoveryForm.reset();
    this.finalize(subscription);
    this.spinnerService.displayLoader(false);
    this.router.navigate(['/crashrecovery']);
  }

  public Delete()
  {
    if (confirm('Are you sure you want to delete?')) {
      this.spinnerService.displayLoader(true);
      let sub = this.service.DeleteCrashRecoveryAsync(this.client, this.failureCondition).subscribe(
        response => {
          this.spinnerService.displayLoader(false);
          this.finalize(sub);
          this.router.navigate(['/crashrecovery']);
        },
        error => {
          this.spinnerService.displayLoader(false);
          this.finalize(sub);
        });
    }
  }


  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public ToggleChangeRequest()
  {
      this.openChangeReq = !this.openChangeReq;
  }
}
