import {OnDestroy, Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup, Validators} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { AuditHistory,SupportEligibilityUserContract, SupportEligibilityUser } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { GlobalService } from '../shared/services/global.service';
@Component({
  selector: 'app-entitle-detail',
  templateUrl: './entitle-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class EntitleDetailComponent implements OnInit,OnDestroy {
  public success:boolean;
  public client:string;
  public form: FormGroup;
  public userupn:string;
  public isNew:boolean;
  public isEligible:boolean;
  public currentSupportEligibilityUserContract:SupportEligibilityUserContract;
  public auditHistory: AuditHistory[] = [];

  constructor( private formBuilder: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinnerService:SpinnerService,
    private notificationBarService:NotificationBarService,
    private globalService:GlobalService
    ) 
    {
      this.client = this.globalService.getClient();
      this.form = this.formBuilder.group({
        userUpn:['', Validators.required],
      });
     }

  ngOnInit() {
    this.userupn = this.route.snapshot.params['Id'];
    if(this.userupn!='0')
    {
      this.isNew = false;
      this.spinnerService.displayLoader(true);
      let sub = this.service.GetSupportEntitlementUserAsync(this.client, this.userupn).subscribe(
        response => {
            this.currentSupportEligibilityUserContract = response;
            this.spinnerService.displayLoader(false);
            if (!!this.currentSupportEligibilityUserContract && !!this.currentSupportEligibilityUserContract.SupportEligibilityUser)
            {  this.form.patchValue({
                  userUpn: this.currentSupportEligibilityUserContract.SupportEligibilityUser.Upn.toUpperCase(),
              });
              this.isEligible = this.currentSupportEligibilityUserContract.SupportEligibilityUser.IsEligible;
              this.auditHistory = this.currentSupportEligibilityUserContract.AuditHistory;
            }
            else{
              this.notificationBarService.openSnackBar("User doesn't exist in table",'','Warn');
            }
            
            this.success = true;
            this.finalize(sub);
          },
          error => {
              this.spinnerService.displayLoader(false);
              this.finalize(sub);
          });
    }else{
      this.success = true;
      this.isNew = true;
      this.currentSupportEligibilityUserContract = new SupportEligibilityUserContract();
      this.currentSupportEligibilityUserContract.SupportEligibilityUser = new SupportEligibilityUser();
    }
  }

  ngOnDestroy() {
    this.notificationBarService.dismiss();
  }

  public onEntitlementChange(overwriteEntitlement:string)
  {
    if(!this.util.ValidEmail(this.form.value.userUpn))
    {
      alert("User upn is not a valid email address");
      return;
    }

    if (confirm('Are you sure you want to ' + overwriteEntitlement + ' for user: ' + this.form.value.userUpn + '?')) 
    {
      if(overwriteEntitlement=="entitle")
      {
        this.currentSupportEligibilityUserContract.SupportEligibilityUser.IsEligible=true;
      }
      else if (overwriteEntitlement=="undo entitle")
      {
        this.currentSupportEligibilityUserContract.SupportEligibilityUser.IsEligible=false;
      }

      this.spinnerService.displayLoader(true);
      if(this.isNew)
      {
        this.currentSupportEligibilityUserContract.SupportEligibilityUser.Upn=this.form.value.userUpn;
        this.currentSupportEligibilityUserContract.SupportEligibilityUser.Client = this.client;
        let sub = this.service.SubmitSupportEntitlementUserAsync(this.currentSupportEligibilityUserContract).subscribe(
          response => {
            this.spinnerService.displayLoader(false);
            if(!response)
            {
              this.notificationBarService.openSnackBar("failed on submit",'','Warn');
            }

            this.onComplete(sub);
          },
          error => {
            this.spinnerService.displayLoader(false);
            this.finalize(sub);
          });
      }
      else
      {
        let sub = this.service.UpdateSupportEntitlementUserAsync(this.currentSupportEligibilityUserContract).subscribe(
          response => {
            this.spinnerService.displayLoader(false);
            if(!response)
            {
              this.notificationBarService.openSnackBar("failed on update",'','Warn');
            }

            this.onComplete(sub);
          },
          error => {
            this.spinnerService.displayLoader(false);
            this.finalize(sub);
          });
      }
    }
  }
  
  public onDelete()
  {
    this.spinnerService.displayLoader(true);
    let sub = this.service.DeleteSupportEntitlementUserAsync(this.client, this.currentSupportEligibilityUserContract.SupportEligibilityUser.Upn).subscribe(
      response => {
        this.onComplete(sub);
      },
      error => {
        this.spinnerService.displayLoader(false);
        this.finalize(sub);
      });
  }

  private onComplete(subscription:Subscription) {
    this.form.reset();
    this.finalize(subscription);
    this.spinnerService.displayLoader(false);
    this.router.navigate(['/entitlements']);
  }

  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }
}
