
  <div class="flex-container3" style="padding: 12px;padding-left: 30px;">
      <div style= "display: flex;flex-direction: row; flex-grow: 1; color: rgb(51, 51, 51);height:100%">
        <!-- 1. side bar ++ -->
        <div id="analysisSidebar" *ngIf="!!propertyList && propertyList.length>0" 
        style="max-width: 350px;width: 350px;word-break:break-word;display: flex;flex-flow: column;height: 100%;">
            <div style="height:20px;background: #666;"> 
              <span (click)="toggleSideBar()">
                <i *ngIf="openSideBar" class="fa fa-fw fa-caret-down ng-star-inserted" style="font-size: 16px;color: rgb(51, 51, 51);z-index: 1;margin-left: 10px;"></i>
                <i *ngIf="!openSideBar" class="fa fa-fw fa-caret-right ng-star-inserted" style="font-size: 16px;color: rgb(51, 51, 51);z-index: 1;margin-left: 10px;"></i>
              </span>
            </div>  

            <div *ngIf="openSideBar" style="height:60px;">
              <span>Choose property values to add to report</span>
              <button (click)="unSelectAllProperties()" class="bluewhitebutton">Unselect all</button>
            </div>
            <!-- checkbox list ++-->
            <div *ngIf="openSideBar" style="flex-grow:1;overflow: auto;">  
                <form [formGroup]="myForm">
                  <div *ngFor="let data of propertyList">
                    <input type="checkbox"  [checked]="data.isSelected==true" (change)="onChange(data, $event.target.checked)"> {{data.name}}<br>
                  </div>
                </form>
            </div>
              <!--  checkbox list -- -->

            <!-- foot:button ++ -->
            <div *ngIf="openSideBar" style="height:400px;width:inherit;padding: 5px 5px;display: flex;justify-content: space-between;flex-direction: column;border: 1px solid #ccc;border-radius: 4px;">
              <!-- selected filter view ++ -->
              <p>Selected Filters (Drag drop fields below to reorder)</p>
              <div *ngIf="!!myForm.controls.selectedProperties.value && myForm.controls.selectedProperties.value.length>0" style="overflow: auto;">
                <div cdkDropList class="dragdrop-list" (cdkDropListDropped)="drop($event)">
                  <div class="dragdrop-box" *ngFor="let item of myForm.controls.selectedProperties.value" style="cursor: pointer;" cdkDrag>
                    {{item.name}}<i class="fa fa-close" (click)="onRemoveProperty(item)" style="color:black;cursor: pointer"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- foot:button -- -->
        </div>
        <!-- side bar -- -->

        <!-- tree + result ++++ -->
        <div class="analysisdisplay">
          <!-- tree++ -->
          <div style="width: 50%;flex: 0 0 auto;height: inherit; margin-left: 5px;overflow-x: auto">
            <div *ngIf="!!propertyTree" style="border: 1px solid #ccc;border-radius: 4px;height: inherit;overflow: auto;">
              <file-tree [tree]="propertyTree" (onChange)="clickTree($event)" [keyboardWatch]=true ng-init="clickFileTree($event)"></file-tree>
            </div>
          </div>
          <!-- tree -- -->

          <!-- result ++ -->
          <div style="width: 50%;flex: 0 0 auto;height: inherit; overflow:auto; border: 1px solid #ccc;border-radius: 4px;">
            <p>Session Attributes Result ({{sessionAttributesResponse.length}})</p>
            <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                    <th>Session id</th>
                    <th>Problem Statement</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of sessionAttributesResponse">
                      <td><a (click)="onOpenTicket(item.id)" target="_blank">{{ item.id }}</a>
                      </td>
                      <td>{{ item.ps }}</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <!-- result -- -->
        </div>
        <!-- tree+result -- -->
      </div>
    </div>