import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlightsDetailComponent } from './flights-detail.component';
import { FlightsListComponent } from './flights-list.component';

const routes: Routes = [];

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'oncall',
          children: [
              {
                  path: 'experimentflight',
                  component: FlightsListComponent,
              },
              {
                  path: 'experimentflight/:ENV/:FN',
                  component: FlightsDetailComponent,
              },
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class OncallRoutingModule { }
