import { Utils } from '../Utils/utils';
import {ActivatedRoute, Router} from '@angular/router';
import { DiagDefinitionService } from '../services/diagdef.service';
import { Component, OnInit, Input} from '@angular/core';
import { FormBuilder,} from '@angular/forms';
import { SpinnerService } from '../services/spinner.service';
import { LocDictionary, StringMapping, LocMapByField } from '../models/WfsModels';
@Component({
    selector: 'loc-status',
    templateUrl: './Locstatus.component.html',
    inputs: ['Locstatus'],
    styleUrls: ['../../app.component.css']
})



export class LocStatusComponent implements OnInit
{
    @Input('localized') id: string;
    @Input('page') page: string;
    public countOfAvailableLans:number=0;
    public azureLanguages=['ar', 'da', 'de', 'en-us', 'es', 'fr', 'he', 'it', 'ja', 'ko', 'nb', 'nl', 'pl', 'pt', 'ru','sv', 'tr','zh-hans', 'zh-hant'];
    public touchdownLocLanguages=['ar', 'bg', 'cs', 'da', 'de', 'el','en-us', 'es', 'et', 'fi', 'fr', 'he', 'hi', 'hr', 'hu', 'id', 'it', 'ja', 'kk', 'ko', 'lt', 'lv', 'ms', 'nl', 'no', 'pl', 'pt', 'pt-PT', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'th', 'tr', 'uk', 'vi', 'zh-hans', 'zh-hant'];
    public availables:string[];
    public open:boolean=false;
    public localizationstatus: LocMapByField[]=[];
    public rowsOnPage: number = 300;
    public sortBy: string = "FieldName";
    public sortOrder: string = "asc";
    constructor(
        private route: ActivatedRoute, private service: DiagDefinitionService, private spinnerService: SpinnerService, private router: Router, private util: Utils) {
    }
    
    ngOnInit() {
        if(!!this.id)
        {
            
            let singleRecovery :boolean = this.page=="singleRecovery"? true:false;
            let sub= this.service.GetLocalizationStatusAsync(this.id, singleRecovery).subscribe(res => 
                {
                    let dict : LocDictionary = res;
                    for(let key in dict)
                    {
                        let locFieldValue :StringMapping[] = dict[key];
                        let locMapByField = new LocMapByField();
                        locMapByField.FieldName = key;
                        locMapByField.StringMappingList = locFieldValue.sort(this.sortStringMappingArrayByLastModifiedTimeDesc);
                        locMapByField.expandList =false;
                        this.localizationstatus.push(locMapByField);
                    }
                    
                    sub.unsubscribe();
                },error =>
                {
                    sub.unsubscribe();
                });

                
        }
    }

    ngOnDestroy() {}

    public onToggle()
    {
        this.open = !this.open;
    }
    private sortStringMappingArrayByLastModifiedTimeDesc = function(a:StringMapping,b:StringMapping) 
    {
        return (a.LastModifiedTime < b.LastModifiedTime) ? 1 : ((b.LastModifiedTime < a.LastModifiedTime) ? -1 : 0);
    }

    public getLocalTimeFromUtcTime(utcTime:Date)
    {
        return this.util.getLocalTimeFromUtcTime(utcTime);
    }

    public openStringMappingList(locMapByField:LocMapByField)
    { 
        if (!!locMapByField)
        {
           locMapByField.AvaliableLocales = [];
           locMapByField.MissingLocales = [];

           let enVersionFilterResult = locMapByField.StringMappingList.filter(item=>{
                return item.Locale == "en-us";
           });

           if(!!enVersionFilterResult && enVersionFilterResult.length>0)
           {
                
                let enVersion = enVersionFilterResult[0];
                
                if(enVersion.LocalizationType == "1")
                {
                    locMapByField.LocSource =  "Touchdown";
                }
                else if (enVersion.LocalizationType == "0" || !enVersion.LocalizationType ) // LocalizationType is enum type, and "Azure" is the default 0 of the enum type, service didnt return this field when it is actually Azure
                {
                    locMapByField.LocSource =  "Azure";

                }

                locMapByField.StringMappingList.forEach(s=>{
                    locMapByField.AvaliableLocales.push(s.Locale);
                });

                if(locMapByField.LocSource =="Azure")
                {
                    this.azureLanguages.forEach(c=>{
                        if(!locMapByField.AvaliableLocales.includes(c))
                        {
                            locMapByField.MissingLocales.push(c);
                        }
                    })
                }
                else if(locMapByField.LocSource =="Touchdown")
                {
                    this.touchdownLocLanguages.forEach(c=>{
                        if(!locMapByField.AvaliableLocales.includes(c))
                        {
                            locMapByField.MissingLocales.push(c);
                        }
                    })
                }
           }

           locMapByField.expandList = true;
            
        }
    }

    public closeStringMappingList(locMapByField:LocMapByField)
    { 
        if (!!locMapByField)
        {
            locMapByField.expandList = false;
        }
    }
}