<div class="flex-container3">
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/custombuckets', 0,0]" right="align">+ Create Problem Signature</a>
    </div>
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/custombuckets', 'sessionmatch']" right="align">+ Test session rule match against all problem signatures</a>
    </div>
    <mat-card>
        <mat-card-title>Problem Signatures</mat-card-title>
        <mat-card-content style="overflow: auto;word-break: break-word;">
            <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width: 30%">
                            Name
                        </th>
                        <th style="width: 25%">
                            Trigger Condition
                        </th>
                        <th style="width: 15%">
                            Recovery Audience
                        </th>
                        <th style="width: 10%">
                            Modified By
                        </th>
                        <th style="width: 10%">
                            Created Time
                        </th>
                        <th style="width: 10%">
                            Last Modified Time
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredBuckets">
                        <td><a [routerLink]="['/custombuckets', item.Id.toLowerCase(), item.Client]">{{ item.FriendlyName }}</a></td>
                        <td>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Trigger Condition
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <textarea disabled style="min-width: 100%" rows="10" [(ngModel)]="item.ProblemSignatureXml"></textarea>
                            </mat-expansion-panel>
                        </td>
                        <td>{{ getAudience(item.RecoveryAudience) }}</td>
                        <td>{{ item.CreatedBy }}</td>
                        <td>{{ item.CreatedTime| date:'yyyy/MM/dd HH:mm' }}</td>
                        <td>{{ item.LastModifiedTime| date:'yyyy/MM/dd HH:mm' }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

</div>