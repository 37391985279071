<div class="flex-container3">
    <div style="margin-top: 10px;margin-bottom: 10px;">
        <a [routerLink]="['/recovery', 0]" right="align">+ Create New Cloud Check Recovery</a>
    </div>
    <div>  
        <a routerLink='/recovery/bulkupload/display' right="align">+ Bulk Upload using CSV</a>
    </div> 

    <mat-card>
        <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
            <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width: 20%">Id</th>
                        <th style="width: 20%">Name</th>
                        <th style="width: 15%">ProblemDescription</th>
                        <th style="width: 15%">RecoveryTitle</th>
                        <th style="width: 10%">Type</th>
                        <th style="width: 10%">Audience</th>
                        <th style="width: 10%">CreatedBy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredList">
                        <td><a [routerLink]="['/recovery', item.Id]" target="_blank">{{ item.Id }}</a></td>
                        <td>{{ item.Name }}</td>
                        <td>{{ item.ProblemDescription }}</td>
                        <td>{{ item.RecoveryTitle }}</td>
                        <td>{{ item.Type }}</td>
                        <td>{{ getAudience(item.Audience) }}</td>
                        <td>{{ item.CreatedBy }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>
