<head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
    <div style="margin-top: 30px;margin-bottom: 30px">
      <div style="cursor: pointer;" (click)="onToggle()">
            <i *ngIf="open==false" class="fa fa-caret-right" style="margin-right:10px;color: grey"></i>
            <i *ngIf="open==true" class="fa fa-caret-down" style="margin-right:10px;color: grey"></i>
            <span>Localization Details</span>
      </div>
      <div *ngIf="open==true" style="padding:18px;border: 1px solid #ccc;border-radius: 4px;background-color: #f5f5f5">
            <div >
                  <mat-card *ngIf='localizationstatus.length > 0' >
                        <mat-card-content style="overflow:auto;margin:0 auto;word-break:break-word">
                              <table class="table">
                                    <thead>
                                          <tr>
                                                <th style="width: 20%">
                                                      Field name
                                                </th>
                                                <th style="width: 80%">
                                                      Localization status
                                                </th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          <tr *ngFor="let item of localizationstatus">
                                                <td>{{ item.FieldName }}</td>
                                                <td>
                                                      {{item.StringMappingList.length}}   
                                                      <button *ngIf="item.expandList==false" (click)="openStringMappingList(item)" >Open details</button>
                                                      <button *ngIf="item.expandList==true" (click)="closeStringMappingList(item)" >Close details</button>
                                                      <div *ngIf="item.expandList==true">
                                                            <p> <b>Localization source</b>:  <span style="color: green;font-weight: bold;"> {{item.LocSource}}</span></p>
                                                            <p> <b>Avaliable locales</b> ( <span style="color: green;font-weight: bold;">{{item.AvaliableLocales.length}}</span> ):
                                                                <span style="color: green;font-weight: bold;">{{item.AvaliableLocales.join(', ')}}</span>
                                                            </p>
                                                            <p *ngIf="!!item.MissingLocales && item.MissingLocales.length>0"> <b>Missing in these languages</b> ( <span style="color: red;font-weight: bold;">{{item.MissingLocales.length}}</span> ) :  <span style="color: red;font-weight: bold;">{{item.MissingLocales.join(', ')}}</span></p>
      
                                                            <b>All the locales exists in table sort by last modified time desc</b>:
                                                            <li *ngFor="let sm of item.StringMappingList">
                                                                  <span *ngIf="sm.Locale!='en-us'" ><span style="color: green;font-weight: bold;">{{sm.Locale}}</span> - {{sm.LastModifiedTime| date:'yyyy/MM/dd HH:mm'}} - {{sm.Message}}</span>
                                                                  <span *ngIf="sm.Locale=='en-us'" style= "background-color: sandybrown;"><span style="color: green;font-weight: bold;">{{sm.Locale}}</span> - {{sm.LastModifiedTime| date:'yyyy/MM/dd HH:mm'}} -Status: {{sm.Status}} - {{sm.Message}}</span>
                                                            </li>
      
                                                      </div>
                                                </td>
                                          </tr>
                                    </tbody>
                              </table>
                        </mat-card-content>
                  </mat-card>
            </div>      
      </div>
</div>