export enum FileType {
  file,
  dir
}
export interface PropertyTreeNodeParams {
  Name: string
  PropertyName:string
  Children?: Array<PropertyTreeNodeParams>
  Filters?:Map<string,string>;
  type?: FileType
  focus?: boolean
  Count?:number
}
export class PropertyTreeNode {
  public Name:string;
  public type:FileType;
  public Children:Array<PropertyTreeNode>;
  public Count:number;
  public Filters:Map<string,string>;
  public PropertyName:string;

  // Full file path from root node
  private fullFilepath: string;

  // Parent Node
  private parentNode:PropertyTreeNode;

  private _isFocused:boolean;
  private _isExpanded:boolean;

  constructor(params:PropertyTreeNodeParams, parent:PropertyTreeNode = null){
    this.Name = params.Name;
    this.PropertyName = params.PropertyName;
    this.Filters = params.Filters;
    this.Children = [];
    this.type = params.type || FileType.file;
    this.Count = params.Count;

    // update private values
    this.parentNode = parent;
    this._isFocused = false;
    this._isExpanded = this.type === FileType.dir || this.Children.length > 0;

    if (parent !== null) {
      let parentPath:string = this.parentNode.getFullPath();
      if (parentPath.slice(-1) === '/') {
        this.fullFilepath = `${parentPath}${this.Name}`;
      } else {
        this.fullFilepath = `${parentPath}/${this.Name}`;
      }
    } else {
      this.fullFilepath = this.Name;
    }

    if (typeof(params.Children) !== 'undefined' && params.Children !== null) {
      params.Children.forEach(
        (fileNodeParams) => this.Children.push(new PropertyTreeNode(fileNodeParams, this))
      );
    }
  }

  getFullPath():string {
    return this.fullFilepath;
  }

  public isDir():boolean {
    return this.type === FileType.dir ||
      this.Children.length > 0;
  }

  public getParentNode():PropertyTreeNode {
    return this.parentNode
  }

  public isExpanded():boolean {
    return this._isExpanded
  }

  public expand():void {
    this._isExpanded = true
  }

  public fold():void {
    this._isExpanded = false
  }

  public hasParent():boolean {
    return this.getParentNode !== null
  }

  public focus():void {
    this._isFocused = true
  }

  public blur():void {
    this._isFocused = false
  }

  public stringify() {
    return JSON.stringify(this, (key:string, value:any) => {
      if (key.includes('_')) return;
      return value
    })
  }
}
