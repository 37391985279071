import { Component, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogHandleService {

    constructor(public dialog: MatDialog) { }
    
    openDialog(data:any): void 
    {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '700px',
          data: data
        });
    
        dialogRef.afterClosed().subscribe(result => {});
    }
}

@Component({
  selector: 'msg-diaglog',
  templateUrl: './dialog.component.html'
})

export class DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    let test = this.data
  }
}