import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DiagnosticlogComponent } from './diagnosticlog.component';
import { PageDetailComponent } from './page-detail.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(
    [
      {
          path: 'officelogs',
          children: [
            {
                path: ':Id',
                component: PageDetailComponent,
            }
          ]
      },
      {
        path: 'diagnosticlog',
        children: [
          {
              path: ':Id/:FileId',
              component: DiagnosticlogComponent,
          }
        ]
    }
  ]
  )],
  exports: [RouterModule]
})
export class PartnerpageRoutingModule { }
