import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../shared/services/spinner.service';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { Subject, Subscription } from 'rxjs';
import { SessionAttributesContract, PropertyValueObj, PropertyNameValuesPair } from '../shared/models/WfsModels';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { PropertyTreeNode } from './TreeView/tree-view';
import { TSMap } from "typescript-map";
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-session-attributes',
  templateUrl: './session-attributes.component.html',
  styleUrls: ['../app.component.css']
})
export class SessionAttributesComponent implements OnInit, OnDestroy {
  public myForm: FormGroup;
  public openSideBar:boolean=true;
  public propertyList:{name:string, isSelected:boolean}[]=[];
  // Map key is property name, value is PropertyValueObj which contains value and its count
  public cachedPropertyValueMap: Map<string, PropertyValueObj[]> = new Map<string, PropertyValueObj[]>();
  public currentSelectedPropertyList : PropertyNameValuesPair[] = [];
  public propertyTree:PropertyTreeNode;
  public sessionAttributesResponse: {id:string, ps:string}[]=[];
  public cachedselection:string[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  constructor(private spinService:SpinnerService, private diagDefService:DiagDefinitionService, private utils:Utils,private fb: FormBuilder){}
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true
    };

    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetEngineeringAnalysisProperties().subscribe(response => 
        {
            this.spinService.displayLoader(false);
            if (!!response)
            {
              response = response.sort((one, two) => (one > two ? 1 : -1));
              response.forEach(element => {
                this.propertyList.push({name:element, isSelected:false});
              });
            }   
            this.myForm = this.fb.group({
              selectedProperties: this.fb.array([])
            });

            // If found user has cached selection in localstorage:
            if (!!localStorage.getItem("drilldown"))
            {
              let localStorageCache = localStorage.getItem("drilldown");
              this.cachedselection= JSON.parse(localStorageCache);
              this.cachedselection.forEach(element => {
                let obj = {
                  name: element,
                  isSelected: true
                };
                this.onChange(obj,true);
                this.propertyList.forEach(item => {
                  if (item.name == element)
                  {
                    item.isSelected = true;
                  }
                });
              });
            }
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
      );
  }

  // change on check box multi selection window, either check or uncheck a property gonna trigger this method
  public onChange(item: {name:string, isSelected:boolean}, isChecked: boolean) {
    const formArray = <FormArray>this.myForm.controls.selectedProperties;
    if (isChecked) {
      item.isSelected = true;
      formArray.push(new FormControl(item));
    } 
    else {
      item.isSelected=false;
      let index = formArray.controls.findIndex(
        x => x.value.name == item.name
        );
      if (index!=-1)
      {
        formArray.removeAt(index);
      }
    }

    // If cached map has already has the result, will avoid call service again
    if (this.cachedPropertyValueMap.has(item.name))
    {
      this.getCurrentSelectedPropertyValues();
    }
    else
    {
      this.spinService.displayLoader(true);
      let sub = this.diagDefService.GetEngineeringAnalysisPropertyValues(item.name).subscribe(response => 
        {
            this.spinService.displayLoader(false);
            let result:PropertyValueObj[] = this.GetPropertyValueObjListFromResponseObject(response);
            this.cachedPropertyValueMap.set(item.name, result);
            this.getCurrentSelectedPropertyValues();
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
      );
    }
  }

  // Every time checkbox list got changes either someone got checked or unchecked, call this method to get most recent selected property list
  public getCurrentSelectedPropertyValues()
  {
    let currentSelectedPropertyList = <{name:string, isSelected:boolean}[]>this.myForm.controls.selectedProperties.value;
    this.currentSelectedPropertyList = [];
    currentSelectedPropertyList.forEach(element => {
      if (this.cachedPropertyValueMap.has(element.name))
      {
        let newPropertyNameValuesPair = new PropertyNameValuesPair();
        newPropertyNameValuesPair.Name = element.name;
        newPropertyNameValuesPair.Values = this.cachedPropertyValueMap.get(element.name).map(x=>x.Value);
        this.currentSelectedPropertyList.push(newPropertyNameValuesPair);
      }
    });
    this.GetPropertyTreeView();
  }

  public GetPropertyTreeView()
  {
    // clean up tree view and session view so that can repop new result
    this.sessionAttributesResponse = [];
    this.propertyTree = null;

    if (this.currentSelectedPropertyList.length == 0)
    {
      return;
    }
    
    // Save user's last selection into localstorage, so that it can auto load next time.
    let selectedFilters :string[]=[];
    this.currentSelectedPropertyList.forEach(element => {
      selectedFilters.push(element.Name);
    });
    localStorage.setItem("drilldown",JSON.stringify(selectedFilters));

    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetPropertyTree(this.currentSelectedPropertyList).subscribe(response => 
        {
          this.spinService.displayLoader(false);
          this.finalize(sub);
          if(!response){
            return;
          }

          let responseString= JSON.parse(response);
          this.propertyTree = new PropertyTreeNode(responseString);
          
          // expand first level
          this.clickTree(this.propertyTree);
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
    );
  }

  public unSelectAllProperties()
  {
    // clean up tree view and session view
    this.sessionAttributesResponse = [];
    this.propertyTree = null;

    const formArray = <FormArray>this.myForm.controls.selectedProperties;
    while (formArray.length) {
      formArray.removeAt(0);
    }

    this.propertyList.forEach(element => {
      element.isSelected = false;
    });

    this.getCurrentSelectedPropertyValues();
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.myForm.controls.selectedProperties.value, event.previousIndex, event.currentIndex);
    this.getCurrentSelectedPropertyValues();
  }

  public onRemoveProperty(item:{name:string, isSelected:boolean})
  {
    const formArray = <FormArray>this.myForm.controls.selectedProperties;
    let index = formArray.controls.findIndex(x => x.value == item);
    if (index!=-1)
    {
      formArray.removeAt(index);
      this.propertyList.forEach(element => {
        if (element.name == item.name)
        {
          element.isSelected = false;
        }
      });
    }

    this.getCurrentSelectedPropertyValues();
  }

  private expandNode(nodeToExpand:PropertyTreeNode)
  {
      nodeToExpand.expand();
      if(nodeToExpand.Children.length>0)
      {
          nodeToExpand.Children.forEach(element => {
              this.expandNode(element);
          });
      }
  }

  public clickTree(node:PropertyTreeNode):void {
      let filtersMap = this.GetMapFromObject(node.Filters);
      let request = new SessionAttributesContract();
      request.RequestedProperties = Array.from(filtersMap.keys());
      request.RequestedProperties.push("ProblemStatement");
      request.FilterProperties = filtersMap;
      this.spinService.displayLoader(true);
      let sub = this.diagDefService.GetSessionAttributes(request).subscribe(response => 
        {
            this.spinService.displayLoader(false);
            if (!!response){
              let arr = []; 
              Object.keys(response).map(
                function(key){  
                  let value = response[key]; 
                  arr.push({id:[key], ps:value["ProblemStatement"]});
                  return arr;  
                });  
              this.sessionAttributesResponse = arr;
              this.rerender();
              node.Count = this.sessionAttributesResponse.length;
            }else
            {
              this.sessionAttributesResponse = [];
            }
            
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
      );

      if (!!node.Children && node.Children.length>0)
      {
        node.Children.forEach(element => {
          this.GetSessionAttributes(element);
        });
      }
  } 

  private GetSessionAttributes(element:PropertyTreeNode)
  {
    let filtersMap = this.GetMapFromObject(element.Filters);
    let request = new SessionAttributesContract();
    request.RequestedProperties = Array.from(filtersMap.keys());
    request.RequestedProperties.push("ProblemStatement");
    request.FilterProperties = filtersMap;
    this.spinService.displayLoader(true);
    let sub = this.diagDefService.GetSessionAttributes(request).subscribe(response => 
        {
            this.spinService.displayLoader(false);
            if (!!response)
            {
              element.Count = Object.keys(response).length;
            }
        },error =>
        {
            this.spinService.displayLoader(false);
            this.finalize(sub);
        }
    );
  }

  public toggleSideBar() {
    this.openSideBar=!this.openSideBar;
    if(this.openSideBar)
    {
        document.getElementById("analysisSidebar").style.width = "350px";
    }else{
        document.getElementById("analysisSidebar").style.width = "40px";
    }
  }

  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public GetPropertyValueObjListFromResponseObject(obj:Object):PropertyValueObj[]
    {
        if(!obj)
        {
            return [];
        }

        let ret:PropertyValueObj[]=[];
        var keys = Object.keys(obj);
        ret.length = keys.length;
        for (let i=0;i<keys.length; i++)
        {
          let key = keys[i];
          let propertyValueObj = new PropertyValueObj();
          propertyValueObj.Value = key;
          propertyValueObj.Count = obj[key];
          ret[i] = propertyValueObj;
         }   
        
        return ret;
    }

  public GetMapFromObject(obj:Object):TSMap<string,string>
  {
      var result = new TSMap<string,string>();
      if(!obj)
      {
          return result;
      }
      
      var keys = Object.keys(obj);
      for (let i=0;i<keys.length; i++)
      {
        let key = keys[i];
        result.set(key, obj[key]);
      }   
      
      return result;
  }

  public onOpenTicket(id:String)
  {
      window.open("#/saratickets/" + id);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    if(this.dtElement.dtInstance)
    {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }

    this.dtTrigger.next();
  }
}