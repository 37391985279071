import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Utils } from '../shared/Utils/utils';
import { Subscription } from 'rxjs/Rx';
import { RecoveryInfo, ProblemSignature, RecoveryElement, RecoveryImageInfo } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { SpinnerService } from '../shared/services/spinner.service';
import { DomSanitizer} from '@angular/platform-browser';
import { LocStatusComponent } from '../shared/sharedmodule/Locstatus.component';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-recovery-detail',
  templateUrl: './recovery-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class RecoveryDetailComponent implements OnInit {
  public isNew:boolean;
  public allLocLanguages:string[]=['ar', 'bg', 'cs', 'da', 'de', 'el', 'es', 'et', 'fi', 'fr', 'he', 'hi', 'hr', 'hu', 'id', 'it', 'ja', 'kk', 'ko', 'lt', 'lv', 'ms', 'nl', 'no', 'pl', 'pt', 'pt-PT', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'th', 'tr', 'uk', 'vi', 'zh-Hans', 'zh-Hant'];
  public recovery: RecoveryInfo;
  public currentRecovery:RecoveryInfo;
  public id:string;
  public recoveryId:string;
  public problemsignature:ProblemSignature;
  public problemsignatureNotExists:boolean;

  public allfields:boolean;
  public recoveryForm: FormGroup;
  public type:string;
  public success:boolean;
  public existingAudienceList : string[];
  public isPsRecovery:boolean = false;
  public RecoveryElements: RecoveryElement[];
  public collapsed: boolean = true;
  public isSubmitImageDisabled = true;
  public imageInfo: RecoveryImageInfo;
  public imageFile: File;
  public imageFileURI: string;
  public imageDescription: string;

  @ViewChild('locstatusComponent') locstatusComponent: LocStatusComponent;
  // choice text is for display in ui, choice value is for service communicate
  public flightAudiences = [
    {display: 'Automation', value:'Automation'},
    {display: 'DogFood', value:'DogFood'},
    {display: 'Microsoft', value:'Microsoft'},
    {display: 'Insiders', value:'Insiders'},
    {display: 'Production English', value:'ProductionEn'},
    {display: 'Production All Users', value:'ProductionAll'}
  ];
  constructor( 
    private fb: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private spinnerService:SpinnerService,
    public sanitizer: DomSanitizer, private notificationBarService:NotificationBarService,
    private util: Utils,
    private dialog: MatDialog)  { }
  @ViewChild('ImageUploadDialog') ImageUploadDialog: TemplateRef<any>;
  ngOnInit() {
    let audienceControls:FormControl[] = this.flightAudiences.map(c => new FormControl(false));
        this.recoveryForm = this.fb.group(
            {
              Id : ['', [Validators.required, this.IsIdGuid]],
              SymptomPath : [''],
              Description : [''],
              ProblemDescription : [''],
              RecoveryTitle : ['', Validators.required],
              UserMsg : [''],
              AdminMsg : [''],
              SupportMsg : [''],
              Link : [''],
              RecoveryState : [''],
              Area : [''],
              Authoritative :false,
              ContactAdmin :false,
              HasAFix :false,
              Name : [''],
              CreatedBy: [''],
              Type : [''],
              RecoverySteps : [''],
              flightAudiences: new FormArray(audienceControls),
              RecoveryElements: [''],
            });
    this.id = this.route.snapshot.params['Id'];
    if(this.id!='0')
    {
      this.isNew=false;
      this.spinnerService.displayLoader(true);
      let sub = this.service.GetRecoveryAsync(this.id).subscribe(
        response => {
            this.currentRecovery = response;
            this.spinnerService.displayLoader(false);
            this.finalize(sub);
            if (!this.currentRecovery)
            {
              this.notificationBarService.openSnackBar("Recovery doenst exist",'','Info');
              return;
            }

            this.recovery = response;
            this.recoveryId = this.currentRecovery.Id;
            let flightAudiences = this.recovery.Audience;
            this.existingAudienceList = this.recovery.Audience;
            flightAudiences.forEach(audience => {
                let index = this.flightAudiences.findIndex(o=>o.value===audience)
                if(index>=0 && index<6)
                {
                    audienceControls[index].setValue(true);
                }
            });

            this.isPsRecovery = this.recovery.Type=="Article" || this.recovery.Type=="Rule";
            this.success= true;
            this.updateForm();
            
          },
          error => {
              this.spinnerService.displayLoader(false);
              this.finalize(sub);
          });
    }
    else{
      this.success = true;
      this.isNew = true;
    }
  }

  IsIdGuid(form: FormGroup) {
    var regex = /^[{(]?[0-9A-Fa-f]{8}[-]?(?:[0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}[)}]?$/;
    const condition = !form.value || !regex.test(form.value);
    return condition ? { isidguid: true} : null;
  }
  
  onRecoveryElementsSelection() {
    let choice = (document.getElementById('ElementSelection') as HTMLInputElement).value;
    let recoveryElements = this.recoveryForm.controls["RecoveryElements"].value;
    if (recoveryElements) {
        recoveryElements = recoveryElements.trim();
    } else {
        recoveryElements = "";
    }
    if(choice === "List") {
        if (recoveryElements.length == 0) {
            recoveryElements = "[{\"Type\":\"List\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
        } else {
            recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
            recoveryElements += ",{\"Type\":\"List\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
        }
    } else if (choice === "Numbered List") {
        if (recoveryElements.length == 0) {
            recoveryElements = "[{\"Type\":\"NumberedList\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
        } else {
            recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
            recoveryElements += ",{\"Type\":\"NumberedList\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
        }
    } else if (choice === "Subtitle") {
        if (recoveryElements.length == 0) {
            recoveryElements = "[{\"Type\":\"Subtitle\", \"Elements\": [\"Edit this line Only\"]}]";
        } else {
            recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
            recoveryElements += ",{\"Type\":\"Subtitle\", \"Elements\": [\"Edit this item Only\"]}]";
        }
    } else if (choice === "Body Text") {
        if (recoveryElements.length == 0) {
            recoveryElements = "[{\"Type\":\"BodyText\", \"Elements\": [\"Edit this line Only\"]}]";
        } else {
            recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
            recoveryElements += ",{\"Type\":\"BodyText\", \"Elements\": [\"Edit this item Only\"]}]";
        }
    } else if (choice === "Image") {
        this.dialog.open(this.ImageUploadDialog, {width: "400px", height: "275px"});
    }
    this.recoveryForm.patchValue({
        RecoveryElements: recoveryElements,
    });

    (document.getElementById('ElementSelection') as HTMLInputElement).value = "Add Elements";
  }

  OnSubmitImage() {
    let sub = this.service.UploadImageAsync(this.imageInfo).subscribe((data) => {
      this.imageFileURI = data;
      this.OnCompleteImageUpload(sub);
    },
    error => {
      this.finalize(sub);
    });
    this.isSubmitImageDisabled = true;

    this.imageFile = null;
  }

  OnCompleteImageUpload(subscription:Subscription) {
    let choice = (document.getElementById('ElementSelection') as HTMLInputElement).value;
    let recoveryElements = this.recoveryForm.controls["RecoveryElements"].value;
    if (recoveryElements.length == 0) {
      recoveryElements = "[{\"Type\":\"Image\", \"Elements\": [\"" + this.imageFileURI + "\", \"" + this.imageDescription + "\"]}]";
    } else {
      recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
      recoveryElements += ",{\"Type\":\"Image\", \"Elements\": [\"" + this.imageFileURI + "\", \"" + this.imageDescription + "\"]}]";
    }
    this.recoveryForm.patchValue({
      RecoveryElements: recoveryElements,
    });
    this.imageFileURI = '';
    this.finalize(subscription);
  }

  OnImageChangeFile(event) {
    this.imageFile = event.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.imageInfo = new RecoveryImageInfo();
      if (this.imageDescription && this.imageDescription.length > 0) {
        this.isSubmitImageDisabled = false;
      }
      this.imageInfo.FileContent = reader.result.toString().replace('data:', '').replace(/^.+,/, '');
      this.imageInfo.FileName = this.imageFile.name;
    }
    reader.readAsDataURL(this.imageFile);
  }

  OnImageChangeText() {
    this.imageDescription = (document.getElementById('ImageDescription') as HTMLInputElement).value;
    if (this.isSubmitImageDisabled) {
      if (this.imageFile) {
        this.isSubmitImageDisabled = false;
      }
    } else {
      if (this.imageDescription && this.imageDescription.length > 0) {
        this.isSubmitImageDisabled = false;
      } else {
        this.isSubmitImageDisabled = true;
      }
    }
  }

  onSubmit()
  {
    const selectedAudiences = this.recoveryForm.value.flightAudiences.map((v, i) => v ? this.flightAudiences[i].value : null).filter(v => v !== null);      
    let newindex = selectedAudiences.findIndex(o=>o==="ProductionAll");
    let oldindex = this.existingAudienceList!=null? this.existingAudienceList.findIndex(o=>o==="ProductionAll"):-1;
   
        if (this.isNew) // new recovery 
        {
            this.currentRecovery = new RecoveryInfo();
            this.currentRecovery.Audience = selectedAudiences
            this.GetValueFromForm();
        }
        else // modify existing recovery
        {
            if (newindex>=0)
            {
                if(oldindex<0)
                {
                    if(!confirm("Flighting a recovery to all users incurs localization costs in 39 languages. Are you sure you still want flight to all users?"))
                    {
                        this.spinnerService.displayLoader(false);
                        return;
                    }
                }
            }

            this.GetValueFromForm();
            this.currentRecovery.Audience = selectedAudiences;
        }

        if (this.isNew) {
          let sub = this.service.CreateRecoveryInfoAsync(this.currentRecovery).subscribe(() => {
              this.onComplete(sub);
          },
          error => {
              this.finalize(sub);
              this.spinnerService.displayLoader(false);
          });
        } else {
            let sub = this.service.UpdateRecoveryInfoAsync(this.currentRecovery).subscribe(() => {
                this.onComplete(sub);
            },
            error => {
                this.finalize(sub);
                this.spinnerService.displayLoader(false);
            });
        }
  }

  private GetValueFromForm() 
  {
      this.currentRecovery.Id = this.recoveryForm.controls["Id"].value.trim().toLowerCase();
      this.currentRecovery.Name = !!this.recoveryForm.controls["Name"].value?this.recoveryForm.controls["Name"].value.trim():'';
      this.currentRecovery.SymptomPath = !!this.recoveryForm.controls["SymptomPath"].value?this.recoveryForm.controls["SymptomPath"].value.trim():'';
      this.currentRecovery.Description = !!this.recoveryForm.controls["Description"].value?this.recoveryForm.controls["Description"].value.trim():'';
      this.currentRecovery.ProblemDescription = !!this.recoveryForm.controls["ProblemDescription"].value?this.recoveryForm.controls["ProblemDescription"].value.trim():'';
      this.currentRecovery.RecoveryTitle = !!this.recoveryForm.controls["RecoveryTitle"].value?this.recoveryForm.controls["RecoveryTitle"].value.trim():'';
      this.currentRecovery.UserMsg = !!this.recoveryForm.controls["UserMsg"].value?this.recoveryForm.controls["UserMsg"].value.trim():'';
      this.currentRecovery.AdminMsg = !!this.recoveryForm.controls["AdminMsg"].value?this.recoveryForm.controls["AdminMsg"].value.trim():'';
      this.currentRecovery.SupportMsg = !!this.recoveryForm.controls["SupportMsg"].value?this.recoveryForm.controls["SupportMsg"].value.trim():'';
      this.currentRecovery.Area = !!this.recoveryForm.controls["Area"].value?this.recoveryForm.controls["Area"].value.trim():'';
      this.currentRecovery.CreatedBy = !!this.recoveryForm.controls["CreatedBy"].value?this.recoveryForm.controls["CreatedBy"].value.trim():'';
      this.currentRecovery.Type = this.recoveryForm.controls["Type"].value;
      this.currentRecovery.HasAFix = this.recoveryForm.controls["HasAFix"].value=="true"? true: false;
      this.currentRecovery.Authoritative = this.recoveryForm.controls["Authoritative"].value=="true"? true: false;
      this.currentRecovery.ContactAdmin = this.recoveryForm.controls["HasAFix"].value=="true"? true: false;
      this.currentRecovery.Link = this.recoveryForm.controls["Link"].value;
      this.currentRecovery.RecoveryState = this.recoveryForm.controls["RecoveryState"].value=="1"? 1:0;
      this.currentRecovery.RecoveryElements = this.RecoveryElements; // This value is constantly being updated (see RecoveryElementsValid()) as it is being always checked if its valid if not valid it defaults to empty array
      if (this.currentRecovery.HasAFix == false) {
          this.currentRecovery.RecoverySteps = "";
      }
      if (!this.currentRecovery.Type)
      {
        this.currentRecovery.Type = "NotSet";
      }
  }

  public get formData() { return <FormArray>this.recoveryForm.get('flightAudiences'); }
  public updateForm() {
      this.recoveryForm.patchValue({
              Id: this.currentRecovery == undefined ? '' : this.currentRecovery.Id,
              Name: this.currentRecovery == undefined ? '' : this.currentRecovery.Name,
              SymptomPath: this.currentRecovery == undefined ? '' : this.currentRecovery.SymptomPath,
              Description: this.currentRecovery == undefined ? '' : this.currentRecovery.Description,
              ProblemDescription: this.currentRecovery == undefined ? '' : this.currentRecovery.ProblemDescription,
              RecoveryTitle: this.currentRecovery == undefined ? '' : this.currentRecovery.RecoveryTitle,
              UserMsg: this.currentRecovery == undefined ? '' : this.currentRecovery.UserMsg,
              AdminMsg: this.currentRecovery == undefined ? '' : this.currentRecovery.AdminMsg,
              SupportMsg: this.currentRecovery == undefined ? '' : this.currentRecovery.SupportMsg,
              Link: this.currentRecovery == undefined ? '' : this.currentRecovery.Link,
              RecoveryState: this.currentRecovery == undefined ? '' : this.currentRecovery.RecoveryState==1?"1":"0",
              Area: this.currentRecovery == undefined ? '' : this.currentRecovery.Area,
              HasAFix: this.currentRecovery == undefined ? false : this.currentRecovery.HasAFix? "true":"false",
              Authoritative: this.currentRecovery == undefined ? '' : this.currentRecovery.Authoritative? "true":"false",
              ContactAdmin: this.currentRecovery == undefined ? '' : this.currentRecovery.ContactAdmin? "true":"false",
              CreatedBy: this.currentRecovery == undefined ? '' : this.currentRecovery.CreatedBy,
              Type: this.currentRecovery == undefined ? '' : this.currentRecovery.Type,
              RecoverySteps: this.currentRecovery == undefined ? '' : this.currentRecovery.RecoverySteps,
              RecoveryElements: this.currentRecovery == undefined ? '' : JSON.stringify(this.currentRecovery.RecoveryElements),
      });
  }


  public onSearchProblemsiganture()
  {
    let subscription1 = this.service.GetProblemSignaturesByClientsAsync("BOT,OutlookDesktop").subscribe(response => 
      {
          this.finalize(subscription1);
          if(!!response)
          {
            const ps = response.filter((item: ProblemSignature) => item.Id == this.id || (!!item.Recoveries&&item.Recoveries[0]==this.id) );
            if(!!ps)
            {
              this.problemsignature = ps[0];
            }else{
              this.problemsignatureNotExists=true;
            }
          }

      },error =>
      {
          this.finalize(subscription1);
      }
  );
  }

  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  private onComplete(subscription:Subscription) {
    this.recoveryForm.reset();
    this.finalize(subscription);
    this.spinnerService.displayLoader(false);
    this.router.navigate(['/recovery']);
  }

  public RecoveryElementsValid()
  {
    let recoveryelementsValid = this.util.isValidJSONForRichRecoveries(this.recoveryForm.controls["RecoveryElements"].value);
    if (recoveryelementsValid && this.recoveryForm.controls["RecoveryElements"].value) {
      this.RecoveryElements = JSON.parse(this.recoveryForm.controls["RecoveryElements"].value) as RecoveryElement[];
    } else {
      this.RecoveryElements = [];
    }

    return recoveryelementsValid;
  }
}
