import {Component, Input, Output, EventEmitter} from '@angular/core';  
import { ParameterSchemaNode } from 'src/app/shared/models/WfsModels';
@Component({  
    selector: 'dcr-tree-view',      
    templateUrl: './dcrtreeview.component.html',  
    styleUrls: ['./dcrtreeview.component.css']      
})  
export class DCRTreeViewComponent {  
    @Input() inputList: any;  
    @Output() addRule = new EventEmitter();
    contextmenu = false;
    contextmenuX = 0;
    contextmenuY = 0;
    public curNode : ParameterSchemaNode;

    disableContextMenu(){
        this.contextmenu= false;
        this.contextmenuX = 0;
        this.contextmenuY = 0;
    }

    public onAddRule()
    {
        if(!this.curNode)
        {
            return;
        }
        
        this.addRule.emit(this.curNode);
    }

    public onAddRuleEventEmit (event:ParameterSchemaNode)
    {
        this.addRule.emit(event);
    }

    //  Tree method+++++++++++
    toggle(node: ParameterSchemaNode) {
        if (this.isExpandable(node)) {
            node.expanded = !node.expanded;
        }
    }


    isExpandable(node: ParameterSchemaNode) {
        return !!node && !!node.Children && node.Children.length>0;
    }

    onRightClick(event, curnode:ParameterSchemaNode) {
        var position = getPosition(event);
        this.contextmenuX=position.x + 10;
        this.contextmenuY=position.y + 5;
        
        event.preventDefault();
     
        this.contextmenu=true;
        this.curNode = curnode;
    }
    // Tree method ----------
} 

function getPosition(e) {
    var posx = 0;
    var posy = 0;
  
    if (!e) 
    {
        e = window.event;
    }

    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + 
                            document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + 
                        document.documentElement.scrollTop;
    }

    return {
        x: posx,
        y: posy
    }
  }