import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { SaraTicketListComponent } from './saraticketlist.component';
import { SaraTicketDetailComponent } from './saraticketdetail.component';
import { SaraTicketSectionComponent } from './saraticketsection.component';
import { SaraTicketRouting } from './saraticket.routing';
import {FlexLayoutModule} from "@angular/flex-layout";
import { TreeViewModule } from './TreeView/tree-view';
import { JsonViewerModule } from './JsonViewer/index';
import { DCRTreeViewModule } from './DCRTreeView/dcrtreeview.module';
import { TagsModule } from './Tags/tags.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CsvReaderModule } from './CsvReader';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DataTablesModule,
        ReactiveFormsModule,
        SaraTicketRouting,
        MatCardModule, MatCheckboxModule,MatExpansionModule,MatTooltipModule,MatSidenavModule,MatTabsModule,MatSelectModule,MatSlideToggleModule,
        MatButtonModule,
        MatListModule,
        FlexLayoutModule,
        TreeViewModule,
        JsonViewerModule,
        DCRTreeViewModule,
        TagsModule,
        CsvReaderModule
    ],
    declarations:[
        SaraTicketDetailComponent,
        SaraTicketListComponent,
        SaraTicketSectionComponent
    ],
})

export class SaraTicketModule{}