import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {FlexLayoutModule} from "@angular/flex-layout";
import { PreviewplaceRoutingModule } from './previewplace-routing.module';
import { PreviewDetailComponent } from './preview-detail.component';
import { PreviewListComponent } from './preview-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    PreviewplaceRoutingModule
  ],
  declarations: [PreviewDetailComponent, PreviewListComponent]
})
export class PreviewplaceModule { }
