import { Inject, Injectable, OnInit } from '@angular/core';
import { JwtHelper } from './JwtHelper';
import {  MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';

@Injectable()
export class Msal implements OnInit {
  private Token:string;
  private userName:string;
  private userEmail:string;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) { }
  ngOnInit() {
  }
  
  getProfile(response:AuthenticationResult) {
    this.Token = response.idToken;  // our backend service is expecting id token instead of access token
    this.userName = response.account.name;
    this.userEmail = response.account.username;
    localStorage.setItem("MSALToken", this.Token);
    localStorage.setItem("userName", this.userName);
    localStorage.setItem("userEmail", this.userEmail);
  }

  getToken()
  {
    return localStorage.getItem("MSALToken");
  }
  
  public IsTokenExpired():boolean
  {
      let jwtHelper:JwtHelper = new JwtHelper();
      const token = this.getToken();
      return !token|| jwtHelper.isTokenExpired(token);
  }

  get isUserAuthenticated(): boolean {
    return !!localStorage.getItem("userName");
  }

  get currentUserName(): string {
    return localStorage.getItem("userName");;
  }

  get currentSmtpAddress(): string {
      return localStorage.getItem("userEmail");
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
      this.authService.logoutRedirect();
  }
}

