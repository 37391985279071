import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangeRequestRoutingModule } from './changerequest-routing.module';
import { ChangeRequestDetailComponent } from './changerequest-detail.component';
import { ChangerequestListComponent } from './changerequest-list.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FlexLayoutModule} from "@angular/flex-layout";
import { ChangereqConfigComponent } from './changereq-config.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
@NgModule({
  imports: [
    CommonModule,
    ChangeRequestRoutingModule,
    FormsModule,
    CommonModule,
    DataTablesModule,
    MatCardModule,MatButtonToggleModule,MatIconModule,MatButtonModule,MatBadgeModule,MatDialogModule,MatSnackBarModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatTableModule,MatCheckboxModule,MatExpansionModule,
    FlexLayoutModule,
    FormsModule, 
    ReactiveFormsModule,
  ],
  declarations: [ChangeRequestDetailComponent, ChangerequestListComponent, ChangereqConfigComponent]
})
export class ChangeRequestModule { }
