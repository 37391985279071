<div style="overflow: auto">
    <h3>Audit History</h3>
    <table>
      <tr>
        <th style="width: 40%">Changes</th>
        <th style="width: 20%">User</th>
        <th style="width: 20%">Time</th>
        <th style="width: 20%">Action</th>
      </tr>
      <tr *ngFor="let item of history">
        <td>{{item.Changes}}</td>
        <td>{{item.User}}</td> 
        <td>{{item.Timestamp| date:'yyyy/MM/dd HH:mm'}}</td>
        <td>{{item.Status}}</td>
      </tr>
    </table>
</div>