<div *ngIf="!!data">
    <div mat-dialog-content>
        <table>
            <tr *ngIf="data.reason">
                <td>
                        <span *ngIf="data.status==500||data.status==503">
                            Please contact our support by emailing <a href=mailto:olksvcs@microsoft.com>olksvcs@microsoft.com</a><br>
                            Thank you!
                        </span>
                        <span *ngIf="!!data.reason">
                            <div [innerHTML]="data.reason"></div>
                        </span>
                </td>
            </tr>
            <tr style="margin-top:15px;" *ngIf="data.status">
                <td valign="top"><b>Status</b></td>
                <td style="padding-left:10px">
                    <p *ngIf="data.status==400">
                        Bad Request
                    </p>
                    <p *ngIf="data.status==401">
                        Not Authorized
                    </p>
                    <p *ngIf="data.status==500 || data.status==503">
                        Service Error
                    </p>
                </td>
            </tr>
        </table>
    </div>
</div>