import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackListComponent } from './feedback-list.component';

const routes: Routes = [];

@NgModule({
  imports:  [RouterModule.forChild(
    [
      {
          path: 'feedback',
          children: [
              {
                  path: '',
                  component: FeedbackListComponent,
              },
          ]
      }
  ]
  )],
  exports: [RouterModule]
})
export class FeedbackRoutingModule { }
