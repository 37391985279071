import { Utils } from '../../shared/Utils/utils';
import { Subscription, Observable } from 'rxjs/Rx';
import {ActivatedRoute, Router} from '@angular/router';
import { DiagDefinitionService } from '../../shared/services/diagdef.service';
import { RecoveryElement, RecoveryInfo, RecoveryImageInfo} from '../../shared/models/WfsModels';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder,ReactiveFormsModule ,FormsModule, AbstractControl,FormGroup, FormArray, FormControl, ValidatorFn,Validators,ValidationErrors, Validator, NG_VALIDATORS, Form} from '@angular/forms';
import { SpinnerService } from '../../shared/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'recovery-detail',
    templateUrl: './recoveryedit.component.html',
    inputs: ['recovery'],
    styleUrls: ['../../app.component.css']
})
export class RecoveryEditComponent implements OnInit
{
    @Input('recovery') recovery: RecoveryInfo;
    @Input('haspendingrequest') haspendingrequest: boolean;
    public recoveryForm: FormGroup;
    public currentRecovery: RecoveryInfo = null;
    public success: boolean = true;
    public hasFix: boolean = false;
    public Areas: string[] = ['Account Setup', 'Add-ins', 'Boot', 'Calendar', 'Connectivity', 'Conversations', 'Config', 'CrashesAndHangs', 'Email', 'Folders', 'Groups', 'Licensing', 'Mailflow', 'People', 'PlaceHolder'];
    public existingAudienceList : string[];
    public isArticle:boolean;
    public articleMessage:string;
    public RecoveryElementsHtml: string;
    public RecoveryElements: RecoveryElement[] = [];
    public collapsed: boolean = true;
    public isSubmitImageDisabled = true;
    public imageInfo: RecoveryImageInfo;
    public imageFile: File;
    public imageFileURI: string;
    public imageDescription: string;
    public choices: Array<{ choiceId: number, choiceText: string, choiceValue: boolean }> = [
        {
            choiceId: 1,
            choiceText: 'Known issue',
            choiceValue: false
        },
        {
            choiceId: 2,
            choiceText: 'Has a fix',
            choiceValue: true
        }
    ];

    // choice text is for display in ui, choice value is for service communicate
    public flightAudiences = [
        {display: 'Automation', value:'Automation'},
        {display: 'DogFood', value:'DogFood'},
        {display: 'Microsoft', value:'Microsoft'},
        {display: 'Insiders', value:'Insiders'},
        {display: 'Production English', value:'ProductionEn'},
        {display: 'Production All Users', value:'ProductionAll'}
    ];

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute, 
        private service: DiagDefinitionService, 
        private router: Router, 
        private spinnerService: SpinnerService,
        private util: Utils,
        private dialog: MatDialog) {
    }
    @ViewChild('ImageUploadDialog') ImageUploadDialog: TemplateRef<any>;
    ngOnInit() {
        const urlRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        let audienceControls:FormControl[] = this.flightAudiences.map(c => new FormControl(false));
        this.recoveryForm = this.fb.group(
            {
                hasFix: false,
                RecoveryTitle: ['', Validators.required],
                ProblemDescription: ['', Validators.required],
                Link: ['', Validators.pattern(urlRegex)],
                RecoverySteps: [''],
                flightAudiences: new FormArray(audienceControls),
                RecoveryElements: ['']
            });

        let id = this.route.snapshot.params['Id'];
        if (id != 0)
        {
            let recoveryInfo = this.recovery;
            if (recoveryInfo != undefined) {
                this.currentRecovery = recoveryInfo;
                let flightAudiences = recoveryInfo.Audience;
                this.existingAudienceList = recoveryInfo.Audience;
                this.isArticle = recoveryInfo.Type == "Article";
                if(this.isArticle)
                {
                    this.recoveryForm.get( 'RecoveryTitle' ).disable();
                    this.recoveryForm.get( 'ProblemDescription' ).disable();
                }
                flightAudiences.forEach(audience => {
                    let index = this.flightAudiences.findIndex(o=>o.value===audience)
                    if(index>=0 && index<6)
                    {
                        audienceControls[index].setValue(true);
                    }
                });

                this.updateForm();
            }
        }
    }

    ngOnDestroy() {
    }

    hasFormChanged(): boolean {
        return this.recoveryForm.dirty;
    }

    onRecoveryElementsSelection() {
        let choice = (document.getElementById('ElementSelection') as HTMLInputElement).value;
        let recoveryElements = this.recoveryForm.controls["RecoveryElements"].value;
        if (recoveryElements) {
            recoveryElements = recoveryElements.trim();
        } else {
            recoveryElements = "";
        }
        if(choice === "List") {
            if (recoveryElements.length == 0) {
                recoveryElements = "[{\"Type\":\"List\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
            } else {
                recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
                recoveryElements += ",{\"Type\":\"List\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
            }
        } else if (choice === "Numbered List") {
            if (recoveryElements.length == 0) {
                recoveryElements = "[{\"Type\":\"NumberedList\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
            } else {
                recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
                recoveryElements += ",{\"Type\":\"NumberedList\", \"Elements\": [\"Edit this item\", \"Edit this item\", \"Add a comma to add more\"]}]";
            }
        } else if (choice === "Subtitle") {
            if (recoveryElements.length == 0) {
                recoveryElements = "[{\"Type\":\"Subtitle\", \"Elements\": [\"Edit this line Only\"]}]";
            } else {
                recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
                recoveryElements += ",{\"Type\":\"Subtitle\", \"Elements\": [\"Edit this item Only\"]}]";
            }
        } else if (choice === "Body Text") {
            if (recoveryElements.length == 0) {
                recoveryElements = "[{\"Type\":\"BodyText\", \"Elements\": [\"Edit this line Only\"]}]";
            } else {
                recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
                recoveryElements += ",{\"Type\":\"BodyText\", \"Elements\": [\"Edit this item Only\"]}]";
            }
        } else if (choice === "Image") {
            this.dialog.open(this.ImageUploadDialog, {width: "400px", height: "275px"});
        }
        this.recoveryForm.patchValue({
            RecoveryElements: recoveryElements,
        });
    
        (document.getElementById('ElementSelection') as HTMLInputElement).value = "Add Elements";
      }
    
      OnSubmitImage() {
        let sub = this.service.UploadImageAsync(this.imageInfo).subscribe((data) => {
          this.imageFileURI = data;
          this.OnCompleteImageUpload(sub);
        },
        error => {
          this.finalize(sub);
        });
        this.isSubmitImageDisabled = true;
    
        this.imageFile = null;
      }
    
      OnCompleteImageUpload(subscription:Subscription) {
        let choice = (document.getElementById('ElementSelection') as HTMLInputElement).value;
        let recoveryElements = this.recoveryForm.controls["RecoveryElements"].value;
        if (recoveryElements.length == 0) {
          recoveryElements = "[{\"Type\":\"Image\", \"Elements\": [\"" + this.imageFileURI + "\", \"" + this.imageDescription + "\"]}]";
        } else {
          recoveryElements = recoveryElements.substring(0, recoveryElements.length - 1);
          recoveryElements += ",{\"Type\":\"Image\", \"Elements\": [\"" + this.imageFileURI + "\", \"" + this.imageDescription + "\"]}]";
        }
        this.recoveryForm.patchValue({
          RecoveryElements: recoveryElements,
        });
        this.imageFileURI = '';
        this.finalize(subscription);
      }
    
      OnImageChangeFile(event) {
        this.imageFile = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = () => {
          this.imageInfo = new RecoveryImageInfo();
          if (this.imageDescription && this.imageDescription.length > 0) {
            this.isSubmitImageDisabled = false;
          }
          this.imageInfo.FileContent = reader.result.toString().replace('data:', '').replace(/^.+,/, '');
          this.imageInfo.FileName = this.imageFile.name;
        }
        reader.readAsDataURL(this.imageFile);
      }
    
      OnImageChangeText() {
        this.imageDescription = (document.getElementById('ImageDescription') as HTMLInputElement).value;
        if (this.isSubmitImageDisabled) {
          if (this.imageFile) {
            this.isSubmitImageDisabled = false;
          }
        } else {
          if (this.imageDescription && this.imageDescription.length > 0) {
            this.isSubmitImageDisabled = false;
          } else {
            this.isSubmitImageDisabled = true;
          }
        }
      }
    

    onSubmit(recoveryName: string, recoveryId?:string): Observable<RecoveryInfo> {
        const selectedAudiences = this.recoveryForm.value.flightAudiences.map((v, i) => v ? this.flightAudiences[i].value : null).filter(v => v !== null);      
        let newindex = selectedAudiences.findIndex(o=>o==="ProductionAll");
        let oldindex = this.existingAudienceList!=null? this.existingAudienceList.findIndex(o=>o==="ProductionAll"):-1;
        return Observable.create(observer => {
            if (this.currentRecovery == undefined) // new recovery 
            {
                this.currentRecovery = new RecoveryInfo();
                if(!!recoveryId)
                {
                    this.currentRecovery.Id = recoveryId;
                }else
                {
                    this.currentRecovery.Id = this.util.GenerateGuid();
                }
                this.currentRecovery.Type = this.isArticle?"Article":"Rule";
                if (newindex>=0 && !this.isArticle)
                {
                    if(!confirm("Flighting a recovery to all users incurs localization costs in 39 languages. Are you sure you still want flight to all users?"))
                    {
                        return;
                    }
                }

                this.currentRecovery.Audience = selectedAudiences
                this.GetValueFromForm(recoveryName);
                if (this.util.StringContainsHtmlTags(this.currentRecovery.RecoveryTitle)
                    || this.util.StringContainsHtmlTags(this.currentRecovery.ProblemDescription)
                    || this.util.StringContainsHtmlTags(this.currentRecovery.RecoverySteps))
                {
                    observer.next(null);
                }
                else
                {
                    observer.next(this.currentRecovery);
                }
                observer.complete(); 
            }
            else // modify existing recovery
            {
                if (newindex>=0 && !this.isArticle)
                {
                    if(oldindex<0)
                    {
                        if(!confirm("Flighting a recovery to all users incurs localization costs in 39 languages. Are you sure you still want flight to all users?"))
                        {
                            this.spinnerService.displayLoader(false);
                            return;
                        }
                    }
                }

                this.GetValueFromForm(recoveryName);
                this.currentRecovery.Audience = selectedAudiences;
                this.currentRecovery.Type = this.isArticle?"Article":"Rule";
                if (this.util.StringContainsHtmlTags(this.currentRecovery.RecoveryTitle)
                    || this.util.StringContainsHtmlTags(this.currentRecovery.ProblemDescription)
                    || this.util.StringContainsHtmlTags(this.currentRecovery.RecoverySteps))
                {
                    observer.next(null);
                }
                else
                {
                    observer.next(this.currentRecovery);
                }
                observer.complete(); 
            }
        });
    }

    private GetValueFromForm(recoveryName: string) 
    {
        this.currentRecovery.Name = !!recoveryName? recoveryName : this.recoveryForm.controls["RecoveryTitle"].value;
        this.currentRecovery.HasAFix = this.hasFix;
        this.currentRecovery.RecoveryTitle = this.recoveryForm.controls["RecoveryTitle"].value;
        this.currentRecovery.ProblemDescription = this.recoveryForm.controls["ProblemDescription"].value;
        this.currentRecovery.RecoverySteps = this.recoveryForm.controls["RecoverySteps"].value;
        this.currentRecovery.Link = this.recoveryForm.controls["Link"].value;
        this.currentRecovery.RecoveryElements = this.RecoveryElements; // This value is constantly being updated (see RecoveryElementsValid()) as it is being always checked if its valid if not valid it defaults to empty array
        if (this.hasFix == false) {
            this.currentRecovery.RecoverySteps = "";
        }
    }

    public get formData() { return <FormArray>this.recoveryForm.get('flightAudiences'); }
	
    public getValidationMsg(client:string): string {
        let validationMsg = '';
        if (!this.recoveryForm.controls['RecoveryTitle'].value) {
            validationMsg = validationMsg + 'Title: cannot be empty\n';
        }

        if (!this.recoveryForm.controls['ProblemDescription'].value) {
            validationMsg = validationMsg + 'Problem Description: cannot be empty\n';
        }

        let link = this.recoveryForm.controls['Link'].value;
        if (client.toLocaleLowerCase()=="outlookdesktop")
        {
            if (link == undefined || !this.util.ValidUrl(link)) {
                validationMsg = validationMsg + 'More Info Link: cannot be empty and must be a valid http link\n';
            }
        }
        else{
            if (!!link&&!this.util.ValidUrl(link)) {
                validationMsg = validationMsg + 'More Info Link: must be a valid http link\n';
            }
        }

        if (this.hasFix == true && !this.recoveryForm.controls['RecoverySteps'].value) {
            validationMsg = validationMsg + 'Recovery steps: cannot not be empty and must be at least 3 letters long if you select Has a fix issue type\n';
        }

        return validationMsg;
    }

    public updateForm() {
        let recoverySteps: string;
        if (this.currentRecovery != undefined && this.currentRecovery.HasAFix) {
            recoverySteps = this.currentRecovery.RecoverySteps;
        } else {
            recoverySteps = '';
        }

        this.recoveryForm.patchValue({
            hasFix: this.currentRecovery == undefined ? false : this.currentRecovery.HasAFix,
            RecoveryTitle: this.currentRecovery == undefined ? '' : this.currentRecovery.RecoveryTitle,
            ProblemDescription: this.currentRecovery == undefined ? '' : this.currentRecovery.ProblemDescription,
            Link: this.currentRecovery == undefined ? '' : this.currentRecovery.Link,
            RecoveryElements: this.currentRecovery == undefined ? '' : JSON.stringify(this.currentRecovery.RecoveryElements),
            RecoverySteps: recoverySteps
        });

        this.hasFix = this.recoveryForm.controls['hasFix'].value;
    }

    public onSelectionChange(choice: { choiceId: number, choiceText: string, choiceValue: boolean }) {
        this.hasFix = choice.choiceValue;
    }

    public OnIsArticleChange()
    {
        this.isArticle = (<HTMLInputElement>document.getElementById("isArticle")).checked;
        if(this.isArticle)
        {
            this.spinnerService.displayLoader(false);
            let request : RecoveryInfo = new RecoveryInfo;
            request.Link = this.recoveryForm.controls["Link"].value;
            this.recoveryForm.patchValue({
                RecoveryTitle: "Loading from Article...",
                ProblemDescription: "Loading from Article...",
            });
            let subscription = this.service.GetArticleDetailsAsync(request).subscribe((response) => {
                this.recoveryForm.patchValue({
                    RecoveryTitle: response.RecoveryTitle,
                    ProblemDescription: response.ProblemDescription,
                });
            },
            error => {
                subscription.unsubscribe();
                this.spinnerService.displayLoader(false);
                this.articleMessage = "Error parse title and desctiption from this article link";
            }); 
        }

        if(this.isArticle)
        {
            this.recoveryForm.get( 'RecoveryTitle' ).disable();
            this.recoveryForm.get( 'ProblemDescription' ).disable();
        }
        else
        {
            this.articleMessage = "";
            this.recoveryForm.patchValue({
                RecoveryTitle: "",
                ProblemDescription: "",
            });
            this.recoveryForm.get( 'RecoveryTitle' ).enable();
            this.recoveryForm.get( 'ProblemDescription').enable();
        }
    }

    public titleContainsHtml()
    {
        return this.util.StringContainsHtmlTags(this.recoveryForm.controls["RecoveryTitle"].value);
    }
    public RecoveryElementsValid()
    {
        let recoveryelementsValid = this.util.isValidJSONForRichRecoveries(this.recoveryForm.controls["RecoveryElements"].value);
        if (recoveryelementsValid && this.recoveryForm.controls["RecoveryElements"].value) {
            this.RecoveryElements = JSON.parse(this.recoveryForm.controls["RecoveryElements"].value) as RecoveryElement[];
        } else {
            this.RecoveryElements = [];
        }
        return recoveryelementsValid;
    }
    public psContainsHtml()
    {
        return this.util.StringContainsHtmlTags(this.recoveryForm.controls["ProblemDescription"].value);
    }
    public rsContainsHtml()
    {
        return this.util.StringContainsHtmlTags(this.recoveryForm.controls["RecoverySteps"].value);
    }

    private finalize(subscription: Subscription):void
    {
        if(subscription)
        {
            subscription.unsubscribe();
        }
    }
}