<div class="flex-container3">
    <div *ngIf='success'>
        <mat-card>
            <mat-card-title>Crash</mat-card-title>
            <mat-card-content>
                <form novalidate [formGroup]="crashForm" style="border: 0;box-shadow: none;">
                    <div style="font:100px">
                        <div>
                            <div class="form-group">
                                <label for="WatsonFailureId">Watson Failure Id</label>
                                <input class="form-control" id="WatsonFailureId" type="text" [readonly]="true" formControlName="WatsonFailureId" />
                            </div>
                            <div class="form-group">
                                <label for="Channel">Channel</label>
                                <input class="form-control" id="Channel" type="text" [readonly]="true" formControlName="Channel" />
                            </div>
                            <div class="form-group">
                                <label for="WorkItemNo">VSO Bug Number</label>
                                <input class="form-control" id="WorkItemNo" type="text" [readonly]="true" formControlName="WorkItemNo" />
                            </div>
                            <div class="form-group">
                                <label for="FixBuild">Fix Build</label>
                                <input class="form-control" id="FixBuild" type="text" [readonly]="true" formControlName="FixBuild" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="btn" style="float: right" [routerLink]="[ '/crashes']">Cancel</button>
                        <button class="btn" style="float: right" [disabled]='id=="0"' (click)="onDelete()" type="button">Delete</button>
                    </div>

                    <div *ngIf='auditHistory.length>0'>
                        <history-display [history]="auditHistory" #historyComponent></history-display>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
