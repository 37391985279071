import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DiagDefinitionService } from '../shared/services/diagdef.service';
import { Subscription } from 'rxjs/Rx';
import { Incident, AuditHistory, IncidentPortalContract, Workload } from '../shared/models/WfsModels';
import { ActivatedRoute, Router} from '@angular/router';
import { Utils } from '../shared/Utils/utils';
import { SpinnerService } from '../shared/services/spinner.service';
import { NotificationBarService } from '../shared/sharedmodule/notificationbar.component';
import { HistoryDisplayComponent } from '../shared/sharedmodule/historydisplay.component';
@Component({
  selector: 'app-testincident-detail',
  templateUrl: '../createtestincident/testincident-detail.component.html',
  styleUrls: ['../app.component.css']
})
export class TestIncidentDetailComponent implements OnInit,OnDestroy {
  public form: FormGroup;
  public statusMessage:string
  public success:boolean = false;
  public sanitizedLink:any;
  public id:string;
  public incident:Incident;
  public incidentPortalContract: IncidentPortalContract;
  public changeReqObj:IncidentPortalContract;
  public open:boolean=true;
  public isNew:boolean;
  public rowsOnPage: number = 100;
  public saraSortBy: string = "CreatedTime";
  public saraSortOrder: string = "desc";
  public hasPendingChangeRequest : boolean  = false;
  public auditHistory: AuditHistory[] = [];
  public openChangeReq:boolean=false;
  @ViewChild('historyComponent') historyComponent: HistoryDisplayComponent;
  constructor(
    private formBuilder: FormBuilder,
    private service: DiagDefinitionService,
    private route: ActivatedRoute, 
    private router: Router,
    private util: Utils,
    private spinService:SpinnerService,
    private notificationBarService:NotificationBarService) 
  { 
    this.form = this.formBuilder.group({
      FriendlyId:['', Validators.required],
      Classification: [''],
      Title: ['', Validators.required],
      ImpactDescription: ['', Validators.required],
      FeatureGroupName: [''],
      StartDate:[''],
      LastUpdatedTime:[''],
      EndDate:['']
    });
  }

  ngOnDestroy():void
  {
      this.notificationBarService.dismiss();
  }
  ngOnInit() {
    this.id = this.route.snapshot.params['Id']; 
    this.isNew = this.id == '0';
    if(!this.id)
    {
      return;
    }

    if (!this.isNew)
    {
      this.form.get('FriendlyId').disable();
      this.spinService.displayLoader(true);
      let sub1 = this.service.GetIncidentAsync(this.id).subscribe(
        response => {
          this.success = true;
              this.incidentPortalContract = response;
              if (!!this.incidentPortalContract && !!this.incidentPortalContract.Incident)
              {
                  this.incident = this.incidentPortalContract.Incident;
                  this.auditHistory = this.incidentPortalContract.AuditHistory;
                  if(!!this.incidentPortalContract.ChangeRequest)
                  {
                      this.hasPendingChangeRequest = this.incidentPortalContract.ChangeRequest.RequestStatus == "pending";
                      if(this.hasPendingChangeRequest)
                      {
                        this.changeReqObj = <IncidentPortalContract> this.util.safelyParseJSON(this.incidentPortalContract.ChangeRequest.ChangeObject);
                      }
                  }
                  this.updateForm();
              }else{
                  this.notificationBarService.openSnackBar("Test Incident doesn't exist",'','Warn');
              }

              this.spinService.displayLoader(false);
              this.finalize(sub1);
        },
        error => {
          this.success = false;
          this.spinService.displayLoader(false);
          this.finalize(sub1);
        });
    } else {
      this.success = true;
      this.incident = new Incident();
    }

  
  }

  public onToggle()
  {
      this.open = !this.open;
  }

  public ToggleChangeRequest()
  {
      this.openChangeReq = !this.openChangeReq;
  }
  
  private finalize(subscription: Subscription):void
  {
      if(subscription)
      {
          subscription.unsubscribe();
      }
  }

  public getWorkloadNames(Workloads: Workload[])
  {
    if (!!Workloads && Workloads.length>0)
    {
      const names:string[] = Workloads.map(obj=>{
        return obj.Name;
      })

      return names.join(',');
    }
  }

  onSubmit() {
    if(this.isNew)
    {
      this.incident.FriendlyId = this.form.controls["FriendlyId"].value;
    }
    this.GetValueFromForm();
    
    if (this.isNew) {
      this.spinService.displayLoader(true);
      let sub = this.service.SubmitTestIncident(this.incident).subscribe(() => {
          this.onComplete(sub);
          this.spinService.displayLoader(false);
      },
      error => {
          this.finalize(sub);
          this.spinService.displayLoader(false);
      });
    } else {
      this.spinService.displayLoader(true);
        let sub = this.service.UpdateTestIncident(this.incident).subscribe(() => {
            this.onComplete(sub);
            this.spinService.displayLoader(false);
        },
        error => {
            this.finalize(sub);
            this.spinService.displayLoader(false);
        });
    }
  }

  public onDelete() {
    this.spinService.displayLoader(true);
    let sub = this.service.DeleteTestIncident(this.incident.Id).subscribe(
      response => {
        this.onComplete(sub);
      },
      error => {
        this.spinService.displayLoader(false);
        this.finalize(sub);
      });
  }

  private onComplete(subscription:Subscription) {
    this.form.reset();
    this.finalize(subscription);
    this.spinService.displayLoader(false);
    this.router.navigate(['/oncall/testincidents']);
  }

  private GetValueFromForm() {      
      this.incident.Classification = this.form.controls["Classification"].value;
      this.incident.Title = this.form.controls["Title"].value;
      this.incident.ImpactDescription = this.form.controls["ImpactDescription"].value;
      this.incident.FeatureGroupName = this.form.controls["FeatureGroupName"].value;
  }

  public updateForm() {
    this.form.patchValue({
      FriendlyId: this.incident == undefined ? '' : this.incident.FriendlyId,
      Classification: this.incident == undefined ? '' : this.incident.Classification,
      Title: this.incident == undefined ? '' : this.incident.Title,
      ImpactDescription: this.incident == undefined ? '' : this.incident.ImpactDescription,
      FeatureGroupName: this.incident == undefined ? '' : this.incident.FeatureGroupName,
    });
  }
}
