<link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
<div style="border: 1px solid rgba(0,0,0,.03); box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);padding:20px">
  <table>
    <tr>
      <div style="margin-bottom: 20px">
        <mat-form-field>
            <mat-label>Add Rule</mat-label>
            <mat-select [(ngModel)] ="selectedRuleType" (selectionChange)="onResetFromNew()">
              <mat-option value="JSON">JSON Simple Rule</mat-option>
              <mat-option value="ETL">ETL Rule</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </tr>

    <tr style="background-color: whitesmoke">
        <!-- AddingNew block start ++++++++++++++++++++ -->
        <div *ngIf='selectedRuleType=="JSON"'  style="margin-bottom: 30px;">
          <form [formGroup]="jsform">
            <span *ngIf="!!sessionJsonRule" style="margin-right: 10px">
              {{sessionJsonRule.EntirePath}}
            </span>
            <span *ngIf="!sessionJsonRule">
              <span *ngIf="!!savedNodes && savedNodes.length>0" style="margin-right: 10px">
                  {{getSavedNodes()}}
              </span>
              <span *ngIf="!!currentNodesToSelect && currentNodesToSelect.length>0" style="margin-right: 20px">
                  <mat-form-field>
                      <mat-label>Parameter to select</mat-label>
                      <mat-select (selectionChange)="onSelectNode()" formControlName="parameter" required>
                        <mat-option *ngFor="let item of currentNodesToSelect" [value]="item">
                          {{item.Name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="jsform.controls.parameter.hasError('required')">Please choose a parameter full path</mat-error>
                  </mat-form-field>
                </span>
                <i (click) = onRemoveLastNode() class="fa fa-arrow-circle-left" style="color: darkred;font-size: 18px;cursor: pointer;margin-right: 20px"></i>
            </span>

            <mat-form-field style="margin-right: 20px">
                <mat-label>Operators</mat-label>
                <mat-select formControlName="operator" required>
                  <mat-option *ngFor="let item of operator" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="jsform.controls.operator.hasError('required')">Please choose an operator</mat-error>
            </mat-form-field>

            <mat-form-field style="margin-right: 20px">
                <input matInput maxlength="100" placeholder="Field Value" formControlName="fieldValue" required [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="jsform.controls.fieldValue.hasError('required')">Please type field value</mat-error>
                <mat-error *ngIf="jsform.hasError('inputContainsForbiddenChar')">" or & or ' are prohibited</mat-error>
            </mat-form-field>
          
            <button (click) =onSaveNewJsonSimpleRule() *ngIf='isJSformValid()&&!jsform.hasError("inputContainsForbiddenChar")' style="background-color: darkolivegreen;margin-right: 20px" class="iconbutton">Add <i class="fa fa-save" (click) = onSaveNewJsonSimpleRule() style="cursor: pointer;font-size: 18px;"></i></button>
          </form>
        </div>

        <div *ngIf='selectedRuleType=="ETL"'  style="margin-top:10px; margin-bottom: 30px">
          <form [formGroup]="etlform">
            <mat-form-field style="margin-right: 20px">
                <input  matInput placeholder="Provider Name" formControlName="etl_newProviderName" maxlength="100" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="etlform.controls.etl_newProviderName.hasError('etlInputContainsForbiddenChar2')">" or & or ' are prohibited</mat-error>
                <mat-error *ngIf="etlform.controls.etl_newProviderName.hasError('required')">required</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-right: 20px">
                <input  matInput placeholder="Event Name" formControlName="etl_newEventName" maxlength="100" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="etlform.controls.etl_newEventName.hasError('etlInputContainsForbiddenChar2')">" or & or ' are prohibited</mat-error>
                <mat-error *ngIf="etlform.controls.etl_newEventName.hasError('required')">required</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-right: 20px">
                <input  matInput placeholder="Event Payload Fields" formControlName="etl_newEventPayloadFields" maxlength="100" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="etlform.controls.etl_newEventPayloadFields.hasError('etlInputContainsForbiddenChar2')">" or & or ' are prohibited</mat-error>
                <mat-error *ngIf="etlform.controls.etl_newEventPayloadFields.hasError('required')">required</mat-error>
            </mat-form-field>
            <mat-form-field style="margin-right: 20px">
                <input  matInput placeholder="Count" formControlName="etl_newCount" maxlength="100" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="etlform.controls.etl_newCount.hasError('etlInputContainsForbiddenChar2')">" or & or ' are prohibited</mat-error>
                <mat-error *ngIf="etlform.controls.etl_newCount.hasError('required')">required</mat-error>
            </mat-form-field>
            <button (click) =onSaveNewETLRule() *ngIf="etlform.valid" style="background-color: darkolivegreen;margin-right: 20px" class="iconbutton">Add <span class="fa fa-save" (click) = onSaveNewETLRule() style="cursor: pointer;font-size: 18px;"></span></button>
          </form>
        </div>
        <!-- AddingNew block end--------------- -->
    </tr>
    <tr>
          <div *ngIf="!!ruleList&&ruleList.length>0" style="margin-bottom: 10px;margin-top: 10px;overflow: hidden"  [ngClass]="{'ruleFormValid':isRuleValid, 'ruleFormInValid':!isRuleValid}">
              <p style="font-weight: 600;">Rules</p>
              <table style="margin: 15px;" class="smallertable">
                <tr>
                  <th style="width: 5%;"></th>
                  <th style="width: 5%;">Operator</th>
                  <th style="width: 50%;">Rule</th>
                  <th style="width: 5%;"></th>
                  <th style="width: 5%;">And/Or</th>
                </tr>
                <tr *ngFor="let element of ruleList; let i=index">
                      <td>
                        <span *ngIf='element.type=="json"'><i (click) = onDeleteJSONRule(element) class="icon-circle, fa fa-close" style="color: darkred;cursor: pointer;"></i></span>
                        <span *ngIf='element.type=="etl"'><i (click) = onDeleteETLRule(element) class="icon-circle, fa fa-close" style="color:darkred;cursor: pointer;"></i></span>
                      </td>
                      <td>
                          <div>
                              <select (change)="[validateParentheses(),GenerateXMLFromUI()]"  id="{{i + '_open'}}">
                                  <option value=""></option>
                                  <option value="(">(</option>
                                  <option value="((">((</option>
                                  <option value="(((">(((</option>
                                  <option value="((((">((((</option>
                                  <option value="(((((">(((((</option>
                                  <option value="((((((">((((((</option>
                                  <option value="(((((((">(((((((</option>
                                </select>
                          </div>
                      </td>
                     
                      <td>
                        <div *ngIf='element.type=="json"'>
                            <span style="width:60px;height:40px;background-color:grey;padding:5px;margin-right:10px;font-size: small;color: white;font-weight: 700;">JSON</span>
                            <span style="margin-right: 10px">
                              <mat-form-field class="example-full-width">
                                <input matInput placeholder="Parameter" disabled value="{{element.obj.EntirePath}}" style="color:black">
                              </mat-form-field>
                            </span>
                            <span style="margin-right: 10px">
                                  <mat-form-field class="example-full-width">
                                      <input matInput placeholder="Operator" disabled value="{{element.obj.Operator}}" style="color:black">
                                    </mat-form-field>
                                  </span>
                            <span style="margin-right: 10px">
                                <mat-form-field class="example-full-width">
                                  <input matInput placeholder="Field Value" disabled value="{{element.obj.FieldValue}}" style="color:black">
                                </mat-form-field>
                            </span>
                        </div>
                        <div  *ngIf='element.type=="etl"'>
                            <span style="width:60px;height:40px;background-color:grey;padding: 5px;margin-right: 10px;font-size: small;color: white;font-weight: 700;">ETL</span>
                            <span style="margin-right: 10px">
                                  <mat-form-field class="example-full-width">
                                    <input matInput placeholder="Provider Name" disabled value="{{element.obj.ProviderName}}" style="color:black">
                                  </mat-form-field>
                            </span>
                          <span style="margin-right: 10px">
                              <mat-form-field class="example-full-width">
                                <input matInput placeholder="Event Name" disabled value="{{element.obj.EventName}}" style="color:black">
                              </mat-form-field>
                            </span>
                          <span style="margin-right: 10px">
                                <mat-form-field class="example-full-width">
                                  <input matInput placeholder="Event Payload Fields" disabled value="{{element.obj.EventPayloadFields}}" style="color:black">
                                </mat-form-field>
                            </span>
                          <span style="margin-right: 10px">
                              <mat-form-field class="example-full-width">
                                <input matInput placeholder="Count" disabled value="{{element.obj.Count}}" style="color:black">
                              </mat-form-field>
                            </span>
                        </div>
                      </td>
                      <td>
                          <div>
                              <select (change)="[validateParentheses(),GenerateXMLFromUI()]"  id="{{i + '_close'}}"> 
                                  <option value=""></option>
                                  <option value=")">)</option>
                                  <option value="))">))</option>
                                  <option value=")))">)))</option>
                                  <option value="))))">))))</option>
                                  <option value=")))))">)))))</option>
                                  <option value="))))))">))))))</option>
                                  <option value=")))))))">)))))))</option>
                              </select>
                          </div>
                      </td>
                      <td>
                          <div>
                              <select id="{{i + '_andor'}}" (change)="GenerateXMLFromUI()">
                                  <option value="And">And</option>
                                  <option value="Or">Or</option>
                                </select>
                          </div>
                      </td>
                </tr>
              </table>
                            
              <div *ngIf="isRuleValid">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            XML
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <textarea disabled style="min-width: 100%;resize: none;" rows="20" [(ngModel)]="xml"></textarea>
                  </mat-expansion-panel>
              </div>
          </div>
    </tr>
  </table>
</div>


