<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<div [ngClass]="{'tokenexpireDiv': isTokenExpired}">
    <!-- Only show when session expired -->
    <div *ngIf="isTokenExpired==true">
        <div class="tokenexpiremsg">
                <p>Your session has expired, please <button (click)="refresh()" style="color:#fff;background-color: #337ab7">Refresh</button> the page to continue.</p>
                <p>Have unsaved changes? click <button (click)="goback()" style="color:#fff;background-color: #337ab7">Go Back</button> to the page, and refresh the page manually</p>
        </div>
    </div>
</div>
<div class="ticket-container">
        <div *ngIf='statusMessage'>
            <font style="font-size: 100%" color="blue">
                <p [innerHTML]="statusMessage"> </p>
            </font>
        </div>

        <div *ngIf="!!authMsg" style="text-align: center; background: bisque;border-width: 0 0 1px;padding: 15px;">
            <span><i class="fa fa-warning" style="font-size:20px;color:#FBBD08!important;margin-right: 10px"></i> <span style="color:red" [innerHTML]="authMsg"> </span></span>
        </div>
        
        <div *ngIf='pageload' style="display: flex; flex-direction: column;align-items: stretch; height: inherit">
            <div class="tab">
                <button *ngFor="let item of categories" (click)="onTabChange(item)"><span style="font-size: 14px;">{{item}}</span></button>
            </div>
            <div *ngIf='saraTicket' style="position: relative;height:calc(100% - 36px);display: flex;flex-flow: row;align-items:stretch;flex-direction: row;box-sizing: border-box;">
                <div *ngIf='!!this.saraTicket.TicketId'>
                    <a id="tkpgbt" class="chat-binded" (click)="toggleChat()">
                        <span><img src="https://cdn-profile-odsportal.azureedge.net/assets/chat.svg"></span>
                       <span class="btwd">Chat</span>
                    </a>
                </div>

                <div [ngClass]="{'contentWindowFull': !openChat, 'contentWindowHalf': openChat}" style="overflow: auto;height: 100%;">
                    <!-- Communication -->
                    <div *ngIf='currentTab==="Communication"' class="flex-container2" style="height: inherit;word-break:break-word;">
                                <!-- title -->
                                <div class="panel-heading" style="display: flex;flex-flow: row nowrap;justify-content: space-between;border-right: 1px solid white">
                                        <div style="margin-right: 3px;">
                                            <img src="https://cdn-profile-odsportal.azureedge.net/assets/privatenote.svg">Private Notes<br>
                                        </div>
                                        <div style="margin-right: 3px;">
                                            <span class="glyphicon glyphicon-user"></span><b>Upn: </b>{{ saraTicket.UserUpn }}
                                        </div>
                                        <div>
                                            <div mat-raised-button matTooltip="{{saraTicket.ProblemStatement}}">
                                                <b>Problem Statement: </b>{{ (!!saraTicket.ProblemStatement && saraTicket.ProblemStatement.length>30)? (saraTicket.ProblemStatement | slice:0:30)+'..':(saraTicket.ProblemStatement) }}
                                            </div>
                                        </div>
                                </div>
                                <!-- messages -->
                                <div class="msg-container-base main" #scrollNote style="overflow: scroll; height: xyz; overflow-x: auto;overflow-y: scroll;">
                                        <div class="note-container" *ngFor="let note of ticketNotes">
                                                <div class="notes">
                                                    <p style="color: #aaa;">{{note.author}}  •  {{getLocalTimeFromTimeInUnixMilliseconds(note.created_at)}}</p> 
                                                    <pre style="    border: 0px;
                                                    border-radius: 0px;  ">{{note.body}}</pre>
                                                </div>
                                        </div>
                                </div>
                                <!-- foot -->
                                <div class="foot">
                                    <textarea class="note-input" placeholder="Write your note here..." [(ngModel)]="draftNote"></textarea>
                                    <div style="display:flex;justify-content: flex-end;flex-direction: row">
                                        <button class="bluewhitebutton" type="button" (click)="onAddTicketNote()">Add</button> 
                                    </div>
                                </div>
                    </div>

                    <!-- Overview page -->
                    <div *ngIf='currentTab==="Overview"' style="overflow: auto;height: inherit;">
                        <mat-card style="word-break:break-word">
                            <mat-card-title>Overview</mat-card-title>
                            <mat-card-content>
                                <table>
                                    <tr *ngIf='saraTicket.TicketId'>
                                        <th scope="col" style="width:20%">Ticket Status</th>
                                        <td style="text-transform: capitalize">{{ ticketStatus }}</td>
                                    </tr>
                                    <tr *ngIf='saraTicket.TicketId&&!isODSticket'>
                                        <th scope="col" style="width:20%" >Ticket Link</th>
                                        <td><a href='{{ ticketLink }}'  target="_blank">{{ ticketLink }}</a></td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%" >3S Dash Link</th>
                                        <td><a href='{{ threeSDashLink }}'  target="_blank">{{ threeSDashLink }}</a></td>
                                    </tr>
                                    <tr *ngIf='saraTicket.TicketId'>
                                        <th scope="col" style="width:20%">Assign To</th>
                                        <td>
                                            <div *ngIf="saraTicket.TicketingSystem!='Rave'">
                                                    <div *ngIf='(ticketStatus=="Closed")'>
                                                            <p>{{getAliasFromEmail(saraTicket.TicketAssignee)}}</p>
                                                    </div>
                                                    <div *ngIf='(ticketStatus!="Closed")'>
                                                        <p *ngIf='saraTicket.TicketAssignee&&saraTicket.TicketAssignee.includes("acompli")'>{{saraTicket.TicketAssignee}}</p>
                                                        <select (change)="onUpdateTicketAssignee($event.target.value)">
                                                            <option></option>
                                                            <option [value]="signInAgentEmail">-Me-</option>
                                                            <option *ngFor="let agent of supportAgents" [value]="agent.DiagPortalEmail" [selected]="saraTicket.TicketAssignee==getAliasFromEmail(agent.DiagPortalEmail)">
                                                                {{getAliasFromEmail(agent.DiagPortalEmail)}}
                                                            </option>
                                                        </select>
                                                    </div>
                                            </div>
                                            <div *ngIf="saraTicket.TicketingSystem=='Rave'">
                                                    {{saraTicket.TicketAssignee}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Created time</th>
                                        <td>{{ ticketCreatedTime }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Chat last modified time</th>
                                        <td>{{ ticketCreatedTime }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">User Upn</th>
                                        <td>{{ saraTicket.UserUpn }}
                                            <!-- <div *ngIf="authType=='SessionAuth'">
                                                <button class="bluewhitebutton" type="button" (click)="AuthUserAndRefileTicket()">Authenticate user and refile ticket</button>
                                            </div> -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">User Puid</th>
                                        <td>{{ saraTicket.UserPuid }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Tenant Id</th>
                                        <td>{{ saraTicket.TenantId }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Tags</th>
                                        <td>
                                            {{ saraTicket.Tags.join(', ') }}
                                            <div *ngIf='ticketStatus!="Closed"'>
                                                <div style="display: flex;flex-direction: column;">
                                                    <div style="display: flex;flex-direction: row;">
                                                        <mat-form-field [style.marginTop]="'-0.2em'" style="width: 140px;margin-right: 10px;">
                                                            <mat-label>Tag selection</mat-label>
                                                            <mat-select [(value)]="newTagToAdd">
                                                              <mat-option *ngFor="let item of allTags" [value]="item.TagName">
                                                                {{item.TagName}}
                                                              </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <button class="btn" style="margin-right:10px; height: 38px;"  type="button" (click)="addNewTagToTicket()">Add Tag to session</button>
                                                    </div>
                                                    <a [routerLink]="['/saratickets/tag/config']" target="_blank" style="background-color: #eee;font-family: 'Arial';border-radius: 4px;height: 30px;padding: 5px;width: 120px;color: black;">Config the tag list</a>
                                                </div>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                    <tr *ngIf='isODSticket'>
                                        <th scope="col" style="width:20%" >ODS Ticket Id</th>
                                        <td>{{ saraTicket.TicketId }}</td>
                                    </tr>
                                    <tr *ngIf='saraTicket.TicketId'>
                                        <th scope="col" style="width:20%" >Ticketing System</th>
                                        <td>{{ saraTicket.TicketingSystem }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Authentication</th>
                                        <td>{{ authType }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">App Version</th>
                                        <td>{{ saraTicket.ProductVersion }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" style="width:20%">Problem Statement</th>
                                        <td>{{ saraTicket.ProblemStatement }}</td>
                                    </tr>
                                    <tr *ngIf='saraTicket.TicketId'>
                                        <th scope="col" style="width:20%">Close Ticket</th>
                                        <td>
                                            <div *ngIf='ticketStatus=="Closed"'>
                                                This ticket is already closed.
                                            </div>
                                            <div *ngIf='(ticketStatus!="Closed")'>
                                                <button class="bluewhitebutton" style="float:left; margin-right:10px" type="button" (click)='onUpdateTicketStatus("Rejected")'>Reject</button>
                                                <button class="bluewhitebutton" style="float:left; margin-right:10px" type="button" (click)='onUpdateTicketStatus("Resolved")'>Resolve</button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <div style = "position:relative" *ngIf='metadataAttributesOSInfo'>
                                            <mat-card>
                                                <mat-card-title>OS Information</mat-card-title>
                                                <mat-card-content>
                                                    <table>
                                                            <tr *ngFor="let item of metadataAttributesOSInfo">
                                                                <th scope="col" style="width:30%">{{item.key}}</th>
                                                                <td>{{item.value}}</td>
                                                            </tr>
                                                    </table>
                                                </mat-card-content>
                                            </mat-card>
                                </div>
                                <div style="margin-bottom:20px"></div>
                                <div style = "position:relative" *ngIf='metadataAttributesApplicationInfo'>
                                            <mat-card>
                                                <mat-card-title>Application Information</mat-card-title>
                                                <mat-card-content>
                                                    <table>
                                                            <tr *ngFor="let item of metadataAttributesApplicationInfo">
                                                                <th scope="col" style="width:30%">{{item.key}}</th>
                                                                <td>{{item.value}}</td>
                                                            </tr>
                                                    </table>
                                                </mat-card-content>
                                            </mat-card>
                                </div>
                                <div style="margin-bottom:20px"></div>
                                <div style = "position:relative" *ngIf='metadataAttributesOther'>
                                            <mat-card>
                                                <mat-card-title>Other</mat-card-title>
                                                <mat-card-content>
                                                    <table>
                                                            <tr *ngFor="let item of metadataAttributesOther">
                                                                <th scope="col" style="width:30%">{{item.key}}</th>
                                                                <td>{{item.value}}</td>
                                                            </tr>
                                                    </table>
                                                </mat-card-content>
                                            </mat-card>
                                </div>
                                
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <!-- Files page -->
                    <div *ngIf='currentTab==="Files"&&diagnosticsFileStatus'>
                            {{diagnosticsFileStatus}}
                    </div>
                    <div *ngIf='currentTab==="Files"&&diagData && diagData.length>0' style="overflow: auto;height: inherit;padding: 5px;">
                        <div *ngIf='diagData.length>0'>
                            <div style="margin-bottom:10px">
                                <label><input type="checkbox" (click)="toggleFiles()" [checked]="displayInternalFiles==true"/> Show internal files</label>
                            </div>   
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="flightstable">
                                     <thead>
                                        <tr>
                                            <th style="width: 10%">
                                                </th>
                                            <th style="width: 40%">
                                                File name
                                            </th>
                                            <th style="width: 10%">
                                                File size
                                            </th>
                                            <th style="width: 10%">
                                                File type
                                            </th>
                                            <th style="width: 10%">
                                                Download as zip
                                            </th>
                                            <th style="width: 20%">
                                                Upload time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of filteredDiagData">
                                                <td style="width: 10%" *ngIf='item.DownloadInProgress == false && item.IsUploadComplete==true'> 
                                                    <span class="glyphicon glyphicon-download-alt" style="cursor: pointer; margin-right: 10px;" (click)="onClick(item)"></span>
                                                    <i *ngIf="item.displayMagnifier" class="fa fa-search" style="cursor: pointer;" (click)="openFileInTab(item)"></i>
                                                </td>
                                                <td style="width: 10%" *ngIf='item.DownloadInProgress == false && item.IsUploadComplete==false'> <span class="glyphicon glyphicon-remove" style="color:red" title="File upload incomplete"></span></td>
                                                <td style="width: 10%" *ngIf="item.DownloadInProgress == true"> <button class="floated" disabled type="button" (click)="onClick(item)">Downloading...</button></td>
                                                <td style="width: 40%"> {{ item.FileName }} </td>
                                                <td style="width: 10%"> 
                                                    {{ (item.FileSizeInKB==0)? '\< 1': item.FileSizeInKB }} K
                                                </td>
                                                <td style="width: 10%"> {{ item.FileType }} </td>
                                                <td style="width: 10%">
                                                    <span *ngIf="!!item.IsCompressed">&#10003;</span>
                                                </td>
                                                <td style="width: 20%">
                                                    {{getLocalTimeFromUtcTime(item.CreatedTime)}}
                                                </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <button *ngIf='bulkDownloadInProgress == false' class="bluewhitebutton" (click)="onBulkDownload()">Bulk Download</button>
                        <button *ngIf='bulkDownloadInProgress == true' class="bluewhitebutton" disabled type="button">Downloading...</button>
                        <!-- <div style="display: flex;width: 100%;padding:20px">
                            <div style="width: 50%;border:1px black #2B579A">
                                <div *ngIf="!!imageToShow">
                                    <img [src]="imageToShow" alt="Screenshot not available" 
                                    style="max-width: 100%;max-height: 100%;"
                                    (click)="onClick(imageFile)"/>
                                </div>
                            </div>
                            <div style="width: 50%;border:1px black #2B579A">
                                <div *ngIf="!!videoToShow">
                                    <img src="https://cdn-profile-odsportal.azureedge.net/owa/videoicon.png" 
                                    alt="video not available"
                                    style="max-width: 100%;max-height: 100%;"
                                    (click)="onClick(videoToShow)"/>
                                </div>
                            </div>
                        </div> -->
                    </div> 

                    <!-- Recoveries page  -->
                    <div *ngIf='currentTab==="Recoveries"&&!!diagnosticResultsStatus'>
                        {{diagnosticResultsStatus}}
                    </div>
                    <div *ngIf='currentTab==="Recoveries"&&!!diagnosticResultRecoveryList&&diagnosticResultRecoveryList.length>0' style="overflow: auto;height: inherit;word-break: break-word;">
                            <mat-card>
                                    <mat-card-title>Diagnostic Results</mat-card-title>
                                    <mat-card-content>
                                        <div *ngIf="diagnosticResultRecoveryList.length>0">
                                            <h4>Recoveries served:</h4>
                                            <table class='table table-bordered table-striped table-hover'>
                                                    <tr *ngFor="let item of diagnosticResultRecoveryList">
                                                        <th scope="col" style="width:10%">{{item.time}}</th>
                                                        <td>
                                                                <div style="margin-bottom:10px"></div>
                                                                <div *ngFor='let recovery of item.recoveryList' style="vertical-align: top">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-body">
                                                                                <table class="logspagetable">
                                                                                    <tr>
                                                                                        <th>Recovery Id</th>
                                                                                        <td>{{recovery.Id}}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>RecoveryTitle</th>
                                                                                        <td>{{recovery.RecoveryTitle}}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>Has a fix</th>
                                                                                        <td>{{recovery.HasAFix}}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>Problem Description</th>
                                                                                        <td>{{recovery.ProblemDescription}}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>More Info Link</th>
                                                                                        <td><a href='{{recovery.Link}}'>{{recovery.Link}}</a></td>
                                                                                    </tr>
                                                                                    <tr *ngIf="recovery.HasAFix==true" >
                                                                                        <th>Recovery Steps</th>
                                                                                        <td>recovery.RecoverySteps</td>
                                                                                    </tr>
                                                                                </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </td>
                                                    </tr>
                                            </table>
                                        </div>
                                    </mat-card-content>
                            </mat-card>
                    </div>

                    <!-- Flights page -->
                    <div *ngIf='currentTab==="Flights"&&diagnosticClientFlightsStatus'>
                            {{diagnosticClientFlightsStatus}}
                    </div>
                    <div *ngIf='currentTab==="Flights"&&!diagnosticClientFlightsStatus' style="padding:5px;overflow: auto;height: inherit;">
                        <div>
                            <input class="form-control" id="SearchTerm" type="text" placeholder="type to start searching" (input)="search($event.target.value)"/>
                        </div>
                        <div style="margin-bottom:20px"></div>
                        <div>
                                <label><input id="toggleflights" type="checkbox" (click)="OnShowAssignedFlightsChange()"  [checked]="onlyShowAssignedFlights===true"/>Only show Assigned flights?</label>
                        </div>      
                        <div style="margin-bottom:20px"></div>
                        <div>
                                <table class="flightstable">
                                    <tr>
                                        <th style="width:40%">Name</th>
                                        <th style="width:25%">Is enabled</th>
                                        <th style="width:25%">Value</th>
                                        <th style="width:10%">Overriden</th>
                                    </tr>
                                    <tr *ngFor='let item of filteredClientFlightsList'>
                                        <td>{{item.FlightName}}</td>
                                        <td>{{item.IsEnabled}}</td>
                                        <td>{{item.FlightValue}}</td>
                                        <td>{{item.IsOverriden}}</td>
                                    </tr>
                                </table>
                        </div>
                    </div>

                    <!-- Registry page -->
                    <div *ngIf='currentTab==="Registry"&&diagnosticRegistryStatus'>
                            {{diagnosticRegistryStatus}}
                    </div>
                    <div id="registrysection" *ngIf='currentTab==="Registry"&&showTree==true' style="padding:5px;overflow: auto;height: inherit;">
                        <button class="bluewhitebutton" style="margin-right:10px" type="button" (click)="expandALL()">Expand All</button>
                        <button class="bluewhitebutton" type="button" (click)="collapseALL()">Collapse All</button>
                        <div style="margin-bottom:20px"></div>
                        <file-tree [tree]="registryTree" (onChange)="clickFileTree($event)" [keyboardWatch]=true ng-init="clickFileTree($event)"></file-tree>
                    </div>

                    <!-- Json Viewer page -->
                    <div id="jsonViewerSection" *ngIf='currentTab==="Json Viewer"&&!!jsonViewerFileList&&jsonViewerFileList.length>0' style="margin:16px;height:calc(100% - 40px);">
                        <div *ngIf="!!jsonViewerFileList&&jsonViewerFileList.length>0" style="height: 100%;">
                            <div style="margin-bottom: 20px;">
                                <p><b>Select a json file</b></p>
                                <mat-form-field>
                                    <mat-select  (selectionChange)="GetFileJson()" [(ngModel)]="JsonViewerfileToOpen">
                                        <mat-option *ngFor="let file of jsonViewerFileList" [value]="file">
                                                {{file.FileName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="!!JsonViewerfileToOpen">
                                <button class="bluewhitebutton" style="margin-right:10px" type="button" (click)="expandALLInJsonViewer()">Expand All</button>
                                <button class="bluewhitebutton" type="button" (click)="collapseALLInJsonViewer()">Collapse All</button>
                                <div style="margin-bottom: 20px;"></div>
                                <json-viewer [json]="jsonViewerFileContent" [expanded]="toggleJsonViewer"></json-viewer>
                            </div>
                        </div>
                    </div>

                    <!-- Log Viewer page -->
                    <div *ngIf='currentTab==="Log Viewer"' style="margin:16px;height:calc(100% - 40px);">
                        <div *ngIf="!!textFileList&&textFileList.length>0" style="height: 100%;">
                            <div style="height:100px">
                                <p><b>Select a text file</b></p>
                                <mat-form-field>
                                    <mat-select  (selectionChange)="GetTxtFileContent()" [(ngModel)]="txtFileToOpen">
                                        <mat-option *ngFor="let file of textFileList" [value]="file">
                                                {{file.FileName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="!!txtfileContent" style="height:calc(100% - 100px);">
                                <div style="margin-bottom: 10px;margin-top: 10px;"> 
                                    <p style="padding-right: 20px;display: inline-block"><b>File</b> {{txtFileToOpen.FileName}}</p>
                                    <span class="glyphicon glyphicon-download-alt" style="cursor: pointer" (click)="onClick(txtFileToOpen)"></span>
                                </div>
                                <textarea style="width: 100%;height: 90%;box-sizing: border-box;">{{txtfileContent}}</textarea>
                            </div>
                        </div>
                    </div>

                      <!-- CSV page -->
                      <div *ngIf='currentTab==="Telemetry"' style="height:calc(100% - 1px);">
                        <div *ngIf="!!csvFiles&&csvFiles.length>0" style="height:inherit;
                                overflow: auto;
                                position:relative;">
                            <div style="height:100px;
                                min-height: 100px;
                                padding: 10px;
                                margin-top:0;
                                position: absolute;
                                overflow: hidden; font-size: small;">
                                <p><b>Select a query</b></p>
                                <mat-form-field>
                                    <mat-select  (selectionChange)="GetCsvFile()" [(ngModel)]="csvfileToOpen">
                                        <mat-option *ngFor="let file of csvFiles" [value]="file">
                                            <p style="font-size: small;">{{file.FileAttributes["friendly-name"]}}</p>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                           <div *ngIf="!!csvfileToOpen" style="
                                padding: 10px;
                                margin-top: 100px;
                                overflow-y: auto;
                                max-height: 180px;
                                font-size: small;">
                                <p *ngIf="csvfileToOpen.FileAttributes['cluster']"><b>Cluster</b> {{csvfileToOpen.FileAttributes["cluster"]}}</p>
                                <p><b>Query</b></p>
                                <p>{{csvfileToOpen.FileAttributes["query-contents"]}}</p>
                           </div>
                            <div *ngIf="csvFileContent" style="display:flex;justify-content: space-between;
                                box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
                                margin-left: 5px;">
                                <csv-reader [csvSource]="csvFileContent" [csvFileName]='getCSVFileName()' style="width: 100%;"></csv-reader>
                            </div>
                        </div>
                    </div>

                    <!-- Account page -->
                    <div *ngIf='currentTab==="Account Info"' style="padding:5px;overflow: auto;height: inherit;">
                        <div *ngIf="!!accountInfoFileContent">
                            <div style="margin-bottom:10px"></div>
                            <button class="bluewhitebutton" style="margin-right:10px" type="button" (click)="expandALLInJsonViewer()">Expand All</button>
                            <button class="bluewhitebutton" type="button" (click)="collapseALLInJsonViewer()">Collapse All</button>
                            <div style="margin-bottom:20px"></div>
                            <json-viewer [json]="accountInfoFileContent" [expanded]="toggleJsonViewer"></json-viewer>
                        </div>
                    </div>

                    <!-- Data collector result page -->
                    <div *ngIf='currentTab==="Data Collectors"' style="padding:5px;overflow: auto;height:100%">
                        <div *ngIf="!!dcrJSON">
                            <div style="display: flex;flex-direction: row;" class="gotootherpage">
                                <a class="awithoutstyle" [routerLink]="['/custombuckets/0/0']" [queryParams]="queryParamsForRule">Navigate to Classify page to Add rule</a>
                                <i class="glyphicon glyphicon-arrow-right" style="margin-left: 10px;line-height: inherit;"></i>
                            </div>
                            <button (click)="toggerDCRTree()" class="bluewhitebutton">Expand All / Collapse All</button>
                            <json-viewer [json]="dcrJSON" [expanded]="toggerDCR"></json-viewer>
                        </div>
                        <div *ngIf="!dcrJSON&&!!dcrError">
                            {{dcrError}}
                        </div>
                    </div>
                    <!-- Bugs page -->
                    <div *ngIf='currentTab==="Bugs"' style="overflow: auto;height:100%">
                        <div>
                            <mat-card>
                                <mat-card-title>Associated Bugs</mat-card-title>
                                <mat-card-content>
                                    <table class='table table-bordered table-striped table-hover' id="ticketBugTable" *ngIf='ticketBugs'>
                                        <tr>
                                            <th style="width:10%"></th>
                                            <th style="width:20%">Bug DB</th>
                                            <th style="width:20%">Bug #</th>
                                            <th style="width:10%">External</th>
                                            <th style="width:40%;text-align: center">Title</th>
                                        </tr>
                                        <tr *ngFor="let bug of ticketBugs">
                                            <td><i class="fa fa-unlink" (click)="onRemoveBugFromTicket(bug)" style="cursor: pointer"></i></td>
                                            <td>{{bug.Database}}</td>
                                            <td><a href='{{bug.BugLink}}'  target="_blank">{{bug.BugNumber}}</a></td>
                                            <td><span class="fa fa-check" style="color:#FFD700" *ngIf="bug.IsExternal"></span></td>
                                            <td style="text-align: center">{{bug.Title}}</td>
                                        </tr>
                                        <!-- Section for adding a new bug -->
                                        <tr id="beforeAddingBug">
                                            <td>
                                                <i class="fa fa-plus-square" id="newBugAdd" (click)="onCreateNewBug()" style="color:dimgray;cursor: pointer"></i>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr id="AddingBug" style="display:none">
                                            <td>
                                                <!-- <i class="fa fa-plus-square" id="newBugAdd" (click)="onCreateNewBug()" style="color:dimgray;cursor: pointer"></i> -->
                                                <i class="fa fa-check-circle" id="newBugOk" (click)="onCreateNewBugToTicket()" style="color:darkgreen;margin-right:10px;cursor: pointer"></i>
                                                <i class="fa fa-undo" id="newBugWithdraw" (click)="onCreateReset()" style="margin-right:10px;cursor: pointer"></i>
                                            </td>
                                            <td class="required">
                                                <select id="newBugDB" style="height:25px;border: 1px solid #ccc;border-radius: 4px">
                                                    <option *ngFor="let db of bugDatabaseOptions" [value]="db">{{db}}</option>
                                                </select>
                                            </td>
                                            <td class="required">
                                                <input id="newBugNumber" type="text" style="height:25px;border: 1px solid #ccc;border-radius: 4px;width:90%"/>
                                            </td>
                                            <td><input id="newBugIsExternal" type="checkbox"/></td>
                                            <td class="required">
                                                <input type="text" id="newBugTitle" style="height:25px;border: 1px solid #ccc;border-radius: 4px;width:90%;text-align: center"/>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-card-content>
                            </mat-card> 
                            <mat-card *ngIf='allBugs&&allBugs.length>0'>
                                <mat-card-title>Select From Existing Bugs</mat-card-title>
                                <mat-card-content>
                                    <table class='table table-bordered table-striped table-hover'>
                                        <thead>
                                            <tr>
                                                <th style="width:15%"></th>
                                                <th style="width:15%">Bug DB</th>
                                                <th style="width:10%">Bug #</th>
                                                <th style="width:10%">External</th>
                                                <th style="width:10%">Tickets</th>
                                                <th style="width:40%;text-align: center">Title</th>
                                            </tr>
                                        </thead>
                    
                                        <tbody>
                                            <tr *ngFor="let bug of allBugs">
                                                <td>
                                                    <i class="fa fa-link" id="{{bug.Database+bug.BugNumber + 'link'}}" (click)="onAddExistingBugToTicket(bug)" style="margin-right:10px;cursor: pointer"></i>
                                                    <i class="fa fa-pencil" id="{{bug.Database+bug.BugNumber + 'pencil'}}" (click)="onTriggerEditBug(bug)" style="margin-right:10px;color: orange;cursor: pointer"></i>
                                                    <i class="fa fa-check-circle" id="{{bug.Database+bug.BugNumber + 'ok'}}" (click)="onUpdateBug(bug)" style="display:none;color:darkgreen;margin-right:10px;cursor: pointer"></i>
                                                    <i class="fa fa-undo" id="{{bug.Database+bug.BugNumber + 'withdraw'}}" (click)="onWithdrawUpdate(bug)" style="display:none;margin-right:10px;cursor: pointer"></i>
                                                    <i class="fa fa-close" id="{{bug.Database+bug.BugNumber + 'delete'}}" (click)="onDeleteBug(bug)" style="color:indianred;cursor: pointer"></i>
                                                </td>
                                                <td>{{bug.Database}}</td>
                                                <td><a href='{{ bug.BugLink }}'  target="_blank">{{bug.BugNumber}}</a></td>
                                                <td><input id="{{bug.Database+bug.BugNumber + 'external'}}" type="checkbox" (click)="onTriggerEditBug(bug)" [checked]="bug.IsExternal===true"/></td>
                                                <td>{{bug.TicketSessionList.length}}</td>
                                                <td style="text-align: center">
                                                    <div id="{{bug.Database+bug.BugNumber + 'oldtitle'}}" >{{bug.Title}}</div>
                                                    <input id="{{bug.Database+bug.BugNumber + 'newtitle'}}" type="text" style="height:25px;border: 1px solid #ccc;border-radius:4px;width:90%;display:none"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div *ngIf="!dcrJSON&&!!dcrError">
                            {{dcrError}}
                        </div>
                    </div>
                    
                    <!-- SurveyAnswers page -->
                    <div *ngIf='currentTab==="Survey Responses"' style="overflow: visible;height:100%;">
                        <div *ngIf='surveysPresented; else noSurveyMessage'>
                            <div style="display: flex;flex-direction: row;" class="surveyresponsespage">
                                <ol>
                                    <div style="text-align: left; color: darkred;" *ngIf='hasSurveyResponses === true'>
                                        <h3>
                                            Survey Responses
                                        </h3>
                                    </div>
                                    <tr *ngFor="let item of surveyAnswers">
                                        <div *ngIf="item.value.SurveyAnswered === true">
                                            <li style="font-weight: bold;">{{item.value.EnglishQuestion}}</li>
                                            <ul>
                                                <h5>Responses</h5>
                                                <ul>
                                                    <div *ngIf="item.value.Type === 'Choice'; else responses">
                                                        <tr *ngFor="let response of item.value.EnglishResponses">
                                                            <li style="color: darkblue;">{{response}}</li>
                                                        </tr>
                                                    </div>
                                                    <ng-template #responses>
                                                        <tr *ngFor="let response of item.value.Responses">
                                                            <li style="color: darkblue">{{response}}</li>
                                                        </tr>
                                                    </ng-template>
                                                </ul>
                                            </ul>
                                        </div>
                                    </tr>
                                </ol>
                            </div>
                            <div *ngIf='hasNoSurveyAnswers' style="display: flex; flex-direction: row;" class="surveyresponsespage">
                               <ol>
                                <div style="text-align: left; color: darkred;">
                                    <h3>
                                        Surveys Not Answered
                                    </h3>
                                </div>
                                <tr *ngFor="let item of surveyAnswers">
                                    <div *ngIf="item.value.SurveyAnswered === false">
                                        <li style="font-weight: bold;">{{item.value.EnglishQuestion}}</li>
                                    </div>
                                </tr>
                               </ol>     
                            </div>
                        </div>
                        <ng-template #noSurveyMessage>
                            <div style="display: flex;flex-direction: row;" class="surveyresponsespage">
                                <h5 style="color: red;">*There was no surveys presented to the user</h5>
                            </div>
                        </ng-template>

                    </div>
            
                </div>       
                    
                <div [ngClass]="{'chatWindowNone': !openChat, 'chatWindowHalf': openChat}" style="height: 100%;overflow: auto;word-break:break-word;">   
                        <div *ngIf='!!saraTicket.TicketId' class="flex-container2" style="height: inherit;overflow: auto;word-break:break-word">
                                            <div class="panel-heading" style="display: flex; flex-wrap: nowrap;">
                                                <div style="width: 33%;">
                                                    <span class="panel-title">
                                                        <img src="https://cdn-profile-odsportal.azureedge.net/assets/chat.svg"> Chat
                                                    </span>
                                                </div>
                                                <div  style="width: 33%;">
                                                    <mat-slide-toggle [(ngModel)]="disableAutoTranslationFlight" 
                                                    (change)="AutoTranslationOverrideOnSession()">Disable translation
                                                    </mat-slide-toggle>
                                                </div>
                                                <div  style="width: 33%;">
                                                    <span (click)="toggleChat()" style="cursor: pointer;float: right;">
                                                        <span class="glyphicon glyphicon-collapse-down"></span> Collapse
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="msg-container-base main" #scrollChat id="msgcontainer" style="overflow: scroll; height: xyz; overflow-x: auto;overflow-y: scroll;">
                                                <div class="msg-container" *ngFor="let message of messages">
                                                    <div class="messages"  [ngClass]="{'client-message': message.isClient, 'agent-message': !message.isClient}">
                                                        <p class="time">{{message.author["name"]}}  •  {{getLocalTimeFromTimeInUnixMilliseconds(message.created_at)}}</p> 
                                                        <pre *ngIf="!message.translated">{{message.body}}</pre>
                                                        <pre *ngIf="message.translated">{{message.englishbody}}</pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="foot">
                                                <div *ngIf='ticketStatus!="Closed"' style="overflow: hidden;height: inherit;word-break:break-word;">
                                                    <textarea class="chat-input" id="chatMsg" placeholder="Write your message here..." [(ngModel)]="draftMessage" ></textarea>
                                                    <div style="display:flex;justify-content: space-between;flex-direction: row;max-height: 42px;">
                                                        <div style="display:flex;justify-content: flex-start;flex-direction: row;">
                                                            <mat-form-field [style.marginTop]="'-0.2em'" style="width: 140px; margin-right: 10px;">
                                                                <mat-label>Chat Recovery</mat-label>
                                                                <mat-select [(value)]="selectedChatRecovery" (selectionChange)="onSelectChatRecovery()">
                                                                <mat-option *ngFor="let item of chatRecoveryCommands" [value]="item.value">
                                                                    {{item.key}}
                                                                </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <mat-form-field [style.marginTop]="'-0.2em'" style="width: 140px;margin-right: 10px;">
                                                                <mat-label>Canned Responses</mat-label>
                                                                <mat-select [(value)]="selectedCannedResponse" (selectionChange)="onSelectCannedResponse()">
                                                                    <mat-option *ngFor="let item of cannedResponses" [value]="item.value">
                                                                    {{item.key}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            
                                                        </div>
                                                        <div style="display:flex;justify-content: flex-end;flex-direction: row">
                                                            <button class="bluewhitebutton" type="button" (click)="sendMessage()" style="align-self: center;">Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf='ticketStatus=="Closed"' style="text-align: center;">
                                                    This ticket has been closed.
                                                </div>
                                            </div>
                                            
                        </div>
                </div> 
            </div>
        </div>
</div>