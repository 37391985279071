<div class="flex-container3">
  <mat-card style="display: flex; flex-flow: column; align-items: stretch;">
    <mat-card-title style="color:grey;font-size: 28px">Search feedback by</mat-card-title>
    <mat-card-content>
      <div>
        <mat-form-field [style.marginTop]="'-0.2em'" style="width: 140px;margin-right: 10px;">
          <mat-label>Symptom (Required)</mat-label>
          <mat-select [(value)]="symptom">
            <mat-option *ngFor="let item of symptomOptionList" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="margin-bottom: 20px;">
          <button class="btn" style="margin-right:10px"  type="button" [disabled]="!symptom" (click)="Search('today')">Today</button>
          <button class="btn" style="margin-right:10px"  type="button" [disabled]="!symptom" (click)="Search('week')">This Week</button>
          <button class="btn" style="margin-right:10px"  type="button" [disabled]="!symptom" (click)="Search('month')">This Month</button>
          <button class="btn" style="margin-right:10px"  type="button" [disabled]="!symptom" (click)="Search('3month')">Three Month</button>
          <button class="btn" style="margin-right:10px"  type="button" [disabled]="!symptom" (click)="Search('year')">This Year</button>
      </div>
      <div>
          <p style="color: grey; font-size: 28px;"> or by specified time range</p>
          <mat-form-field style ="margin-right: 20px;">
            <input #fromInput matInput [matDatepicker]="fromDatePicker" placeholder="Choose a from date" (dateChange)="dateEvent('from', $event)">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
  
          <mat-form-field style ="margin-right: 20px;">
            <input #toInput matInput [matDatepicker]="toDatePicker" placeholder="Choose a to date" (dateChange)="dateEvent('to', $event)">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <button class="btn" style="margin-right:10px"  [disabled]="!symptom || !validDateRange() || !timerangesearch" type="button" (click)="Search('timerange')">Search Feedbacks</button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
      <mat-card-title style="color:grey;font-size: 28px">Search Result ( <span *ngIf="!!filteredList">{{filteredList.length}} )</span></mat-card-title>
      <mat-card-content>
          <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                  <tr>
                      <th>Date</th>
                      <th>Feedback Session Id</th>
                      <th>Problem Statement</th>
                      <th>Tenant Id</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of filteredList">
                      <td>{{ getDate(item.PartitionKey) }}</td>
                      <td><a (click)="onOpenTicket(item.SessionObj.DiagnosticSessionId)" target="_blank">{{ item.SessionObj.DiagnosticSessionId}}</a></td>
                      <td>{{ item.SessionObj.ProblemStatement }}</td>
                      <td>{{ item.SessionObj.TenantId }}</td>
                  </tr>
              </tbody>
          </table>
      </mat-card-content>
    </mat-card>
   
</div>